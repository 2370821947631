import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import styled from "@emotion/styled";

const StyledOrder = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  top: 20px;
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;
export default function EnhancedTableHeaderWithSort({headRow = [], order, orderBy, onRequestSort}) {
  const createSortHandler = (headCellId) => (event) => {
    onRequestSort(event, headCellId);
  };

  const sortLabel = headCell => {
    if (headCell.notSortable) {
      return headCell.label
    } else
      return <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={createSortHandler(headCell.id)}
      >
        {headCell.label}
        {orderBy === headCell.id ? (
          <StyledOrder>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</StyledOrder>
        ) : null}
      </TableSortLabel>;
  };

  return (
    <TableHead>
      <TableRow>
        {headRow.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={headCell.notSortable ? null : (orderBy === headCell.id ? order : false)}
          >
            {sortLabel(headCell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeaderWithSort.propTypes = {
  headRow: PropTypes.array,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
