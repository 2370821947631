import React from 'react';
import {FilterChip} from "./FilterChip";


export default function FilterChipGroupBuildType({
                                                   buildTypesList,
                                                   selectedBuildTypes,
                                                   setSelectedBuildTypes,
                                                   disabled
                                                 }) {

  const sortedSelectedBuildTypes = [...selectedBuildTypes].filter(selected => buildTypesList.indexOf(selected) > -1).sort();
  const handleDelete = (buildTypeToDelete) => {
    setSelectedBuildTypes(selectedBuildTypes.filter(type => type !== buildTypeToDelete));
  };

  return (
    <React.Fragment>
      {sortedSelectedBuildTypes.map(buildType =>
        <FilterChip
          key={buildType}
          tooltip={"Type"}
          disabled={disabled}
          label={buildType}
          onDelete={() => handleDelete(buildType)}/>
      )}
    </React.Fragment>
  );
}

