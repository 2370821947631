export function getDocumentFragmentContainsProperty(window, key) {
  const props = getDocumentFragmentProperties(window);

  return props.hasOwnProperty(key);
}

export function getDocumentFragmentContainsPropertyWithValue(window, key, value) {
  const props = getDocumentFragmentProperties(window);

  if (Array.isArray(value)) {
    let found = false;

    for (let i = 0; i < value.length; i++) {
      if (props[key] === value[i]) {
        found = true;
        break;
      }
    }

    return found;
  } else {
    return props.hasOwnProperty(key) && props[key] === value;
  }
}

export function getDocumentFragmentProperties(window) {
  const documentFragment = window.location.hash;

  if (documentFragment.startsWith('#')) {
    return getProperties(documentFragment.substring(1));
  } else {
    return {};
  }
}

export function getProperties(params) {
  return params.split('&').map(s => {
    const index = s.indexOf('=');

    if (index >= 0) {
      return {
        key: s.substring(0, index),
        value: s.substring(index + 1, s.length)
      };
    } else {
      return {
        key: s,
        value: undefined
      }
    }
  }).reduce((map, obj) => {
    map[obj.key] = obj.value;
    return map;
  }, {});
}

export function initBuildProduct(categories, buildAudiences, task) {
  if (!task.parameter) {
    return null;
  }
  const getProduct = (list, key) => {
    const filteredItems = list.filter(item => item.productKey === key);
    return filteredItems?.length === 1 ? filteredItems[0] : null;
  }
  const getOS = (list, key) => {
    const filteredItems = list.filter(item => item.os === key);
    return filteredItems?.length === 1 ? filteredItems[0] : null;
  }
  const getBuildType = (list, key) => {
    const filteredItems = list.filter(item => item.buildType === key);
    return filteredItems?.length === 1 ? filteredItems[0] : null;
  }
  const getBuildAudiences = (list, values) => {
    const filteredItems = list.filter(item => values.includes(item.buildAudience));
    return filteredItems;
  }

  const getAttribute = (attributesMap) => {
    if (attributesMap === null || attributesMap?.keys().length === 0 || categories === null) {
      return null;
    }
    // first version - use only first meta attribute
    const currentSelectedAttribute = attributesMap.entries().next().value||[];
    const [key, values] = currentSelectedAttribute;
    const value = values && values.length > 0 ? values[0] : null;
    return value === null ? null : {key: key, value: value};
  }
  const filteredCategories = categories?.filter(category => getProduct(category.productList, task.parameter.productKey) !== null);
  const currentCategory = filteredCategories?.length === 1 ? filteredCategories[0] : null;
  const currentProduct = getProduct(currentCategory?.productList || [], task.parameter.productKey);
  const currentOsVersion = getOS(currentProduct?.osList || [], task.parameter.os);
  const currentBuildType = getBuildType(currentProduct?.buildTypeList || [], task.parameter?.buildType);
  const currentBuildAudiences = task.parameter?.buildAudienceList ? getBuildAudiences(buildAudiences || [], task.parameter?.buildAudienceList) : [];
  const currentAttribute = task.parameter?.attributes ? getAttribute(new Map(Object.entries(task.parameter?.attributes))) : [];
  return ({
    category: currentCategory,
    product: currentProduct,
    version: task.parameter?.version,
    osVersion: currentOsVersion,
    buildType: currentBuildType,
    license: task.parameter?.license,
    buildAudienceList: currentBuildAudiences,
    buildAttribute: currentAttribute,
    releaseDate: task.parameter?.releaseDate,
  });
}
