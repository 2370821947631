import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

function TabPanel(props) {
  const {children, selectedTabPath, tabPath, ...other} = props;

  const panel = selectedTabPath === tabPath ? <Typography
    component="div"
    role="tabpanel"
    hidden={selectedTabPath !== tabPath}
    id={`full-width-tabpanel-${tabPath}`}
    aria-labelledby={`full-width-tab-${tabPath}`}
    {...other}
  >
    <Box sx={{
      margin: (theme) => theme.spacing(1),
      paddingBottom: (theme) => theme.spacing(1),
    }} p={0}>{children}</Box>
  </Typography> : null;

  return panel;
}

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  tabPath: PropTypes.any.isRequired,
  selectedTabPath: PropTypes.any.isRequired,
};
