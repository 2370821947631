import React from 'react';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {usePageMeta} from '../contexts/PageMetaContext';
import SWCLink from './SWCLink';
import {styled as muiStyled} from "@mui/material/styles";
import styled from '@emotion/styled';

const StyledLabel = styled.span`
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 30px;
  padding-left: 30px;
  font-size: 0.875rem;
  letter-spacing: 0.02857em;
`;

function TabLabel({name, icon}) {
  return (<StyledLabel>{name}{' '}{icon}</StyledLabel>);
}

const StyledTab = muiStyled((props) => <Tab {...props} />)(
  ({theme}) => ({
    borderBottom: '2px solid rgba(0, 0, 0, 0.54)',
    marginLeft: '2px',
    '&:hover': {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-selected': {
      color: theme.palette.mode === 'light' ? theme.palette.primary.main : 'white',
      borderBottom: 'none',
    },
    '&.Mui-focusVisible': {
      color: theme.palette.primary.main,
    },
  }),
);

export default function SWCTabs({activeTabPath, tabsInfo, tabIcons, changeCallback = null, children, ...props}) {

  const {goTo} = usePageMeta();

  const handleChange = (tabPath) => {
    if (changeCallback === null) {
      goTo(tabPath);
    } else {
      changeCallback(tabPath);
    }
  };

  return (<Paper sx={{
    padding: (theme) => `10px 10px 10px ${theme.spacing(3)}`,
  }} square>
    <Tabs {...props}
          value={activeTabPath}
          indicatorColor="primary"
          textColor="primary">
      {tabsInfo.map((tabInfo, index) => <StyledTab
        label={<SWCLink path={tabInfo.path}
                        clickCallback={() => handleChange(tabInfo.path)}><TabLabel name={tabInfo.name}
                                                                                   icon={tabIcons !== undefined ? tabIcons[index] : null}/></SWCLink>}
        value={tabInfo.path}
        key={index}/>)}
    </Tabs>
    {children}
  </Paper>);
}
