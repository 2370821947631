import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import EnhancedTableHeaderWithSort from "../table/EnhancedTableHeaderWithSort";
import {ORDERABLE_ATTRIBUTES} from "./searchConstants";
import {StyledTableCell} from "../SimpleTableWithPaging";
import styled from "@emotion/styled";

const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};
  margin: ${({theme}) => theme.spacing(1)};
  display: flex;
  overflow: auto;
  flex-direction: column;
`;
const StyledTableContainer = styled.div`
  overflow-x: auto;
`;
const StyledContent = styled.div`
  ${props => props.showTitle ? `margin-top: ${props => props.theme.spacing(3)};
      padding-top: ${props => props.theme.spacing(4)};
      padding-bottom: ${props => props.theme.spacing(4)};` : ''}
`;

const noOp = () => {
};

export default function BuildTable({
                                     title = '',
                                     rows,
                                     headRow,
                                     showTitle = true,
                                     showPagination = true,
                                     disabled = false,
                                     order = {by: "", direction: ORDERABLE_ATTRIBUTES.DIRECTION.ASC},
                                     setOrder = ({by, direction}) => {
                                     },
                                     pagination = {page: 0, size: 25, numberOfPages: 0, overallCount: 0},
                                     setPagination = ({page, size}) => {
                                     },
                                     onRowClicked = (row) => {
                                     },
                                   }) {

  function handleChangePage(event, newPage) {
    setPagination({...pagination, page: newPage});
  }

  function handleChangeRowsPerPage(event) {
    const newSize = (+event.target.value) || 25;
    const currentSize = pagination.size || 0;

    if (currentSize !== newSize) {
      setPagination({...pagination, page: 0, size: newSize});
    }
  }

  const handleRequestSort = (event, property) => {
    const headCellMeta = headRow.find(headCell => headCell.id === property && !headCell.notSortable);

    if (headCellMeta) {

      let direction;
      if (order.by === property) {
        direction = order.direction === ORDERABLE_ATTRIBUTES.DIRECTION.ASC ? ORDERABLE_ATTRIBUTES.DIRECTION.DESC : ORDERABLE_ATTRIBUTES.DIRECTION.ASC;
      } else {
        direction = headCellMeta.defaultOrderDirection;
      }

      setOrder({by: property, direction: direction});
    }
  };

  const tableHead = <EnhancedTableHeaderWithSort
    headRow={headRow}
    order={order.direction}
    orderBy={order.by}
    onRequestSort={disabled ? () => {
    } : handleRequestSort}/>

  const tableBody = rows.map((row, index) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={index}
        onClick={event => {
          event.preventDefault();
          onRowClicked(row)
        }}
      >
        {headRow.map((headRowCell, headIndex) => (
          <StyledTableCell
            component="td"
            id={`enhanced-table-checkbox-${index}`}
            scope="row"
            key={headIndex}
            className={headRowCell.cssClass ? headRowCell.cssClass : undefined}
          >
            {row[headIndex]}
          </StyledTableCell>
        ))}
      </TableRow>
    );
  })

  const paginationFooter = showPagination && (
    <TablePagination
      backIconButtonProps={{'aria-label': 'Previous Page'}}
      nextIconButtonProps={{'aria-label': 'Next Page'}}
      component="div"
      count={pagination.overallCount}
      rowsPerPage={pagination.size}
      page={pagination.page}
      onPageChange={disabled ? noOp : handleChangePage}
      onRowsPerPageChange={disabled ? noOp : handleChangeRowsPerPage}
    />);

  let content = (
    <StyledPaper>
      {showTitle &&
        <Typography variant="h6">
          {title}
        </Typography>
      }
      <StyledTableContainer>
        <Table
          sx={{
            minWidth: 750,
          }}
          aria-labelledby="tableTitle"
          size="small"
        >
          {tableHead}
          <TableBody>
            <React.Fragment>
              {tableBody}
            </React.Fragment>
          </TableBody>
        </Table>
      </StyledTableContainer>
      {paginationFooter}
    </StyledPaper>
  );

  if (rows.length === 0) {
    content = (
      <StyledPaper>
        <Typography variant="subtitle2">
          No data found
        </Typography>
      </StyledPaper>
    )
  }

  return (
    <StyledContent showTitle={showTitle}>{content}</StyledContent>
  );
}

BuildTable.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  showHeadRow: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    by: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }),
  setOrder: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    overallCount: PropTypes.number.isRequired
  }),
  setPagination: PropTypes.func.isRequired,
  headRow: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    cssClass: PropTypes.string,
  })).isRequired,
};
