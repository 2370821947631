import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { useAuth } from '../react-auth-wrapper';
import { useNotifications } from './NotificationsContext';

export const PermissionsContext = React.createContext();
export const usePermissions = () => useContext(PermissionsContext);
export const PermissionsContextProvider = ({ children }) => {

  const [permissions, setPermissions] = useState(null);
  const { callApi } = useAuth();
  const { notify } = useNotifications();
  const cancel = {};

  const loadPermissions = () => {
    callApi('/swc/api/profiles/my', { method: 'GET' }, cancel)
    .then((profile) => { console.log(`profile: ${profile?.emailAddress}`); return profile; })
    .then(() => callApi('/swc/api/profiles/my/permissions', { method: 'GET' }, cancel))
    .then((response) => {
      console.log('[PermissionsContext] user permissions', response);
      setPermissions(response.values);
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setPermissions(null);
        notify('commonNotificator', {
          text: 'Error while loading user permissions:',
          error: error,
          type: 'error',
          handleClose: () => {
          },
        });
      }
    });
  };

  const hasPermission = (perform) => {
    return permissions !== undefined && permissions !== null && permissions.includes(`ROLE_${perform}`);
  };

  useEffect(() => {
    loadPermissions();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  return (
    <PermissionsContext.Provider value={{
      permissions,
      hasPermission
    }}>{children}</PermissionsContext.Provider>)
};
