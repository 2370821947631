import React from "react";

// https://icon-icons.com/de/symbol/Schicht/71160
// Schicht Kostenlos Symbol by Icomoon (http://icomoon.io/) licensed under CC BY 4.0 (https://creativecommons.org/licenses/by/4.0/)
// imported with svgr (https://github.com/gregberge/svgr)

function LayerStackIcon(props) {
  return <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}>
    <path d="M512,160L256,32L0,160l256,128L512,160z M256,74.485L427.029,160L256,245.515L84.971,160L256,74.485z M460.723,230.361
		L512,256L256,384L0,256l51.278-25.639L256,332.723L460.723,230.361z M460.723,326.361L512,352L256,480L0,352l51.278-25.639
		L256,428.722L460.723,326.361z"/>
  </svg>
}

export default LayerStackIcon;
