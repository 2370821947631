import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import FormattedDateTime from "../../FormattedDateTime";
import SWCLink from "../../SWCLink";

export default function BuildReleaseDateCell({ item, clickCallback = () => {}}) {
  const rawReleaseDate = item?.version?.releaseDate;
  const tooltip = rawReleaseDate;
  const label = rawReleaseDate ? <FormattedDateTime dateTimeString={rawReleaseDate}/> : null;

  return rawReleaseDate ?
    (
      <SWCLink clickCallback={clickCallback}>
        <Tooltip
          title={tooltip}
          release-time={rawReleaseDate}>
          <span>{label}</span>
        </Tooltip></SWCLink>
    ) : null;
}

BuildReleaseDateCell.propTypes = {
  clickCallback: PropTypes.func,
  item: PropTypes.shape({
    version: PropTypes.shape({
        releaseDate: PropTypes.string,
    }),
  }).isRequired,
};
