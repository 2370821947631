import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import EnhancedTableHeaderWithSort from './table/EnhancedTableHeaderWithSort';
import {styled as muiStyled} from "@mui/material/styles";
import styled from "@emotion/styled";

function descendingComparator(a, b, orderBy, headRow) {
  let orderByColumnIndex = undefined;
  let orderByColumn = undefined;
  headRow.forEach((column, index) => {
    if (column.id === orderBy) {
      orderByColumnIndex = index;
      orderByColumn = column;
    }
  });
  if (orderByColumnIndex === undefined || b[headRow.length] === undefined || a[headRow.length] === undefined) {
    return 0;
  }
  const leftValue = b[headRow.length][orderByColumnIndex];
  const rightValue = a[headRow.length][orderByColumnIndex];
  const left = orderByColumn.date && leftValue ? new Date(leftValue) : leftValue;
  const right = orderByColumn.date && rightValue ? new Date(rightValue) : rightValue;
  if (left < right) {
    return -1;
  }
  if (left > right) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy, headRow) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, headRow)
    : (a, b) => -descendingComparator(a, b, orderBy, headRow);
}

function stableSort(array, comparator, headRow) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const compareResult = comparator(a[0], b[0], headRow);
    if (compareResult !== 0) return compareResult;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const StyledTableCell = muiStyled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.mode === 'light' ? 'black' : 'white',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.mode === 'light' ? 'black' : 'white',
  },
}));
const StyledPaperContainer = styled.div`
  ${props => props.showTitle ? `width: 100%;
      margin-top: ${({theme}) => theme.spacing(3)};
      padding-top: ${({theme}) => theme.spacing(4)};
      padding-bottom: ${({theme}) => theme.spacing(4)};` : ''};
`;
const StyledTableOverflow = styled.div`
  overflow-x: auto;
`;

export default function SimpleTableWithPaging({
                                                title = '',
                                                rows,
                                                headRow,
                                                showTitle = true,
                                                showHeadRow = false,
                                                showPagination = true,
                                                sortable = false
                                              }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headRow[0].id);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  function getNextRows() {
    if (showPagination) {
      return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return rows;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const tableHead = showHeadRow ? (sortable ? <EnhancedTableHeaderWithSort headRow={headRow}
                                                                           order={order}
                                                                           orderBy={orderBy}
                                                                           onRequestSort={handleRequestSort}/> :
      <TableHead>
        <TableRow>
          {(headRow || []).map(headRowCell => (
            <TableCell key={headRowCell.id}>{headRowCell.label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
  ) : null;

  return (
    <StyledPaperContainer showTitle={showTitle}>
      <Paper sx={showTitle ? {
        padding: (theme) => theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      } : undefined}>
        {showTitle &&
          <Typography variant="h6">
            {title}
          </Typography>
        }
        <StyledTableOverflow>
          <Table
            sx={{
              minWidth: 750,
            }}
            aria-labelledby="tableTitle"
            size="small"
          >
            {tableHead}
            <TableBody>
              {(sortable ? stableSort(getNextRows(),
                getComparator(order, orderBy, headRow),
                headRow) : getNextRows()).map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {headRow.map((headRowCell, headIndex) => (
                      <StyledTableCell
                        component="td"
                        id={labelId}
                        scope="row"
                        key={headIndex}
                        sx={headRowCell.sx}
                      >
                        {row[headIndex]}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableOverflow>
        {showPagination &&
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </Paper>
    </StyledPaperContainer>
  );
}

SimpleTableWithPaging.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array.isRequired,
  showHeadRow: PropTypes.bool.isRequired,
  headRow: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    cssClass: PropTypes.string,
  })).isRequired,
};
