import React from 'react';
import Snackbar from '@mui/material/Snackbar';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
      open: false
    };

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info,
      open: true
    });
  }

  handleClose() {
    this.setState({open: false});
  }

  render() {
    if (this.state.hasError) {
      const message = `Something went wrong!
      The error: ${this.state.error.message.toString()}`;
      return (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.open}
            onClose={this.handleClose}
            message={<span id="message-id">{message}</span>}
          >
          </Snackbar>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
