export default class Selection {
  constructor(product, os, type, version, classifier, betaType, size) {
    this.product = product;
    this.os = os;
    this.type = type;
    this.version = version;
    this.classifier = classifier;
    this.betaType = betaType;
    this.size = size;
  }

  toRestData() {
    return {
      productKey: this.product.productKey,
      os: this.os,
      buildType: this.type,
      version: this.version,
      classifier: this.classifier,
    };
  }
}
