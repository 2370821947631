import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import breadcrumbCalculator from './fileBreadcrumbsCalculator'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const itemCss = {
  cursor: 'pointer',
};

const empty = [{name: 'Tools', path: ''}];

export default function FileBreadcrumbs({rootFolderName = 'Tools', folderPath, handleBreadcrumbSelected}) {

  const [breadcrumbElements, setBreadcrumbElements] = useState(empty);

  function updateState(folderPathParam) {
    const newBreadcrumbs = breadcrumbCalculator(rootFolderName, folderPathParam);
    setBreadcrumbElements(newBreadcrumbs);
  }

  useEffect(() => updateState(folderPath), [folderPath]);

  function renderHomeLink() {
    let link = null

    if (breadcrumbElements) {
      const elem = breadcrumbElements[0]

      link = <Link
        sx={itemCss}
        key={elem.path}
        color="inherit"
        onClick={() => handleBreadcrumbSelected(elem.path)}
      >
        <Typography variant="h6">
          <HomeOutlinedIcon sx={{
            position: 'relative',
            top: '4px',
            marginRight: '3px',
          }}/>Home
        </Typography>
      </Link>
    }

    return link;
  }

  function renderLink(elem) {
    return (
      <Link
        sx={itemCss}
        key={elem.path}
        color="inherit"
        onClick={() => handleBreadcrumbSelected(elem.path)}
      >
        <Typography variant="h6">
          {elem.name}
        </Typography>
      </Link>
    );
  }

  function renderLastLink(elem) {
    return (
      <Typography
        variant="inherit"
        key={elem.path}
        color="textPrimary"
      >
        <Typography variant="h6">
          {elem.name}
        </Typography>
      </Typography>
    );
  }

  return (

    <Breadcrumbs aria-label="breadcrumb">
      {renderHomeLink()}
      {breadcrumbElements ? breadcrumbElements.slice(1, -1).map(renderLink) : null}
      {breadcrumbElements && breadcrumbElements.length > 1 ? breadcrumbElements.slice(-1).map(renderLastLink) : null}
    </Breadcrumbs>
  );
}


FileBreadcrumbs.propTypes = {
  rootFolderName: PropTypes.string.isRequired,
  folderPath: PropTypes.string.isRequired,
  handleBreadcrumbSelected: PropTypes.func.isRequired,
};

FileBreadcrumbs.defaultProps = {
  rootFolderName: 'Tools',
};
