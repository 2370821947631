import React, {useEffect, useState} from 'react';
import {usePageMeta} from "../../contexts/PageMetaContext";
import {Button, Pagination} from "@mui/material";
import {useNotifications} from "../../contexts/NotificationsContext";
import Paper from "@mui/material/Paper";
import {useAuth} from "../../react-auth-wrapper";
import LoadingIcon from "../../components/LoadingIcon";
import List from "@mui/material/List";
import useInterval from "../../hooks/useInterval";
import TaskDetails from "./ProductUpload/wizard/TaskDetails";
import {initBuildProduct} from "../../util";
import useBuildUploadMeta from "../../hooks/useBuildUploadMeta";
import styled from "@emotion/styled";

const StyledPaginationContainer = styled.div`
  float: right;
  text-align: center;
  padding-bottom: ${({theme}) => theme.spacing(2)};
`;
const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};
  margin: ${({theme}) => theme.spacing(1)};
`;
const StyledButton = styled(Button)`
  width: fit-content;
  margin-bottom: ${({theme}) => theme.spacing(2)};
  float: left;
`;
const StyledList = styled(List)`
  width: 100%;
  padding-bottom: ${({theme}) => theme.spacing(4)};
  background-color: ${({theme}) => theme.palette.background.paper};
`;
const NOTIFICATOR_NAME = 'adminProductUploadNotificator';
export default function ProductUpload() {
  const [tasks, setTasks] = useState(null);
  const [page, setPage] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const itemsPerPage = 5;

  const {categories, buildAudiences} = useBuildUploadMeta(signalApiError);
  const {setTitle, goTo} = usePageMeta();
  const {notificator, notify, clearNotificator} = useNotifications();
  const {callApi} = useAuth();
  const cancel = {};

  const pageChanged = (event, page) => {
    setPage(page);
  }

  function loadTasks() {
    setDataLoading(true);
    setTasks(null);
    console.log('[ProductUpload] Loading upload tasks...');
    callApi('/swc/api/admin/builds/upload/my/tasks', {method: 'GET'}, cancel).then((response) => {
      console.log('[ProductUpload]', response);
      setTasks(response);
      setDataLoading(false);
      clearNotificator(NOTIFICATOR_NAME);
    }).catch((error) => {
      if (error.message !== 'Cancelled') {
        setDataLoading(false);
        setTasks(null);
        notify(NOTIFICATOR_NAME,
          {
            text: 'Error while loading upload tasks:',
            error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
  }

  function signalApiError(message, error) {
    notify(NOTIFICATOR_NAME,
      {
        text: message,
        error,
        type: 'error',
        handleClose: () => {
        },
      });
  }

  const getCurrentTaskProductMeta = (task) => {
    const product = initBuildProduct(categories, buildAudiences, task);
    return product || null;
  }

  function TasksNavigation({pagesCount}) {
    return (<StyledPaginationContainer>
      <Pagination count={pagesCount} page={page} onChange={pageChanged}/>
    </StyledPaginationContainer>);
  }

  useEffect(() => {
    setTitle('Administration - Upload Status Overview');
    loadTasks();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  useInterval(loadTasks, 45 * 1000);

  return <>
    {notificator(NOTIFICATOR_NAME)}
    <div>
      {dataLoading && <LoadingIcon/>}
      {tasks !== null && <>
        <StyledPaper square>
          <StyledList>
            <StyledButton
              variant="outlined"
              color="primary"
              disabled={dataLoading}
              onClick={() => goTo("/admin/product-upload/start")}
            >
              Create upload job
            </StyledButton>
            <TasksNavigation pagesCount={Math.ceil(tasks?.length / itemsPerPage)}/>
            {tasks.slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((task, index) =>
              <TaskDetails
                key={`task_${task.id}`} task={task} productMeta={getCurrentTaskProductMeta(task)} index={index}/>)}
          </StyledList>
        </StyledPaper>
      </>}
    </div>
  </>;
}
