import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import LayerStackIcon from '../components/LayerStackIcon';
import VersionBar from '../components/VersionBar';
import Menu from './Menu';
import AdvantestIcon from '../components/AdvantestIcon';
import {usePageMeta} from "../contexts/PageMetaContext";
import {useLocation} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import styled from "@emotion/styled";

const drawerWidth = 330;
const StyledContainer = styled.div`
  display: flex;
  height: 100vh;
`;
const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Styled80Container = styled.div`
  height: 80px;
`;
const StyledMenuContainer = styled.div`
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
`;
const StyledBuildNrContainer = styled.div`
  width: ${drawerWidth}px;
  bottom: ${({theme}) => theme.spacing(2)};
  position: fixed;
`;
const StyledLayerStackIcon = styled(LayerStackIcon)`
  font-size: ${({theme}) => theme.typography.pxToRem(32)};
  fill: ${({theme}) => theme.palette.primary.main};
`;
const StyledListItemContainerWithPadding = styled.div`
  padding-left: 16px;
`;
const StyledListItemContainerWithHeight = styled.div`
  display: flex;
  height: 48px;
`;
const StyledIconButton = styled(IconButton)`
  margin-left: 100px;
`;
const StyledTypography = styled(Typography)`
  margin-top: -15px;
`;

export default function MenuAppBar({children}) {
  const currentLocation = useLocation();
  const [mainOpen, setMainOpen] = useState(true);
  const [menu, setMenu] = useState(null);
  const theme = useTheme();
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const {goTo} = usePageMeta();
  const cancel = {};

  function handleMainMenu() {
    setMainOpen(!mainOpen);
  }

  function buildMenu(menuItem) {
    return (
      <List
        key={menuItem.key}
        subheader={mainOpen && (
          <ListSubheader
            disableSticky
            sx={{
              fontSize: (theme) => theme.typography.pxToRem(16),
              zIndex: 0,
            }}
          >
            {menuItem.label}
          </ListSubheader>
        )}
      >
        {menuItem.items.map(item => (
          <Menu
            key={item.key}
            menuItemInfo={item}
            mainOpen={mainOpen}
            currentPath={currentLocation.pathname}
          />
        ))}
      </List>
    );
  }

  function loadMenu() {
    console.log('[MenuAppBar]', 'Fetching menu...');

    callApi('/swc/api/menu', {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[MenuAppBar]', 'menu', response);
        setMenu(response.menu);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setMenu([]);
          notify('commonNotificator',
            {
              text: 'Error while loading menu:',
              error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      })
      .finally(() => {
      });
  }

  useEffect(() => {
    loadMenu();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  return (
    <StyledContainer>
      <CssBaseline/>
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            overflowY: 'unset',
            ...(!mainOpen && {
              overflowX: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: theme.spacing(7),
              [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
              },
            })
          }
        }}
        open={mainOpen}
      >
        <StyledListContainer>
          <Styled80Container>
            <List>
              {
                mainOpen ? (
                  <StyledListItemContainerWithPadding onClick={() => goTo("/")}>
                    <StyledListItemContainerWithHeight>
                      <AdvantestIcon/>
                      <StyledIconButton onClick={handleMainMenu} size="large"><ChevronLeftIcon/></StyledIconButton>
                    </StyledListItemContainerWithHeight>
                    <StyledTypography variant="overline" display="block" gutterBottom>
                      Software Center
                    </StyledTypography>
                  </StyledListItemContainerWithPadding>
                ) : (
                  <ListItem sx={{
                    paddingLeft: '0',
                  }}>
                    <IconButton onClick={handleMainMenu} size="large">
                      <StyledLayerStackIcon strokeWidth={4}/>
                    </IconButton>
                  </ListItem>
                )
              }
            </List>
          </Styled80Container>
          <StyledMenuContainer>
            {menu && menu.map(menuItem => buildMenu(menuItem))}
          </StyledMenuContainer>
          {mainOpen && (
            <StyledBuildNrContainer id="build-no">
              <VersionBar/>
            </StyledBuildNrContainer>
          )}
        </StyledListContainer>
      </Drawer>
      {children}
    </StyledContainer>
  );
}

MenuAppBar.propTypes = {
  children: PropTypes.element.isRequired,
};
