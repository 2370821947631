import React from 'react';
import SWCTabs from '../../components/SWCTabs';
import { CLOUD_OVERVIEW } from '../../components/PathConstants';

export default function AdminCloudOverviewTabs({ activeTabPath, children }) {

  const tabsInfo = [
    CLOUD_OVERVIEW.vms,
    CLOUD_OVERVIEW.volumes,
    CLOUD_OVERVIEW.billing,
  ];

  return (<SWCTabs activeTabPath={activeTabPath} tabsInfo={tabsInfo}>
    {children}
  </SWCTabs>);
}
