import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {awsStateLabel} from './vmConstants';
import PropTypes from 'prop-types';
import VmStateIcon from "./VmStateIcon";
import styled from "@emotion/styled";

const StyledContainer = styled.span`
  white-space: nowrap;
`;
export default function VmState({instance}) {

  const label = awsStateLabel(instance.state);

  return (
    <Tooltip title={label} data-instance-state={instance.state}>
      <StyledContainer>
        <VmStateIcon state={instance.state}/>
        {' '}
        {label}
      </StyledContainer>
    </Tooltip>
  );
}

VmState.propTypes = {
  instance: PropTypes.shape({
    state: PropTypes.string.isRequired,
  }).isRequired,
};
