import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useAuth} from '../../../react-auth-wrapper';
import {useNotifications} from '../../../contexts/NotificationsContext';
import TabPanel from '../../../components/TabPanel';
import {usePageMeta} from '../../../contexts/PageMetaContext';
import SWCTabs from '../../../components/SWCTabs';
import {ADMIN_SETTINGS,} from '../../../components/PathConstants';
import {useFileExplorerPreferences} from '../../FileExplorer/FileExplorerPreferences';
import {useParams} from "react-router-dom";

export default function FilesTabs({firstLevelActiveTabPath, TabContent}) {

  const {bucketId} = useParams();

  const [buckets, setBuckets] = useState(null);
  const [currentSecondLevelTabPath, setSecondLevelCurrentTabPath] = useState(null);
  const {notify} = useNotifications();
  const {callApi} = useAuth();
  const {goTo} = usePageMeta();
  const fileExplorerPreferences = useFileExplorerPreferences();
  const cancel = {};

  function loadBuckets() {
    console.log('[FilesTabs]', 'Fetching buckets...');

    callApi('/swc/api/buckets', {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[FilesTabs]', 'buckets', response);
        setBuckets(response);
        setCurrentBucketTab(response);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setBuckets([]);
          notify('commonNotificator',
            {
              text: "Error while loading cloud:",
              error: error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      })
      .finally(() => {
      });
  }

  function setCurrentBucketTab(loadedBuckets) {
    if (loadedBuckets && bucketId) {
      const foundBuckets = loadedBuckets.filter(bucket => bucket.bucketId === bucketId);
      if (foundBuckets.length > 0) {
        setSecondLevelCurrentTabPath(`${firstLevelActiveTabPath}/${bucketId}`)
      } else {
        goTo(`${firstLevelActiveTabPath}/${loadedBuckets[0].bucketId}`)
      }
    }
  }

  const getTabNames = () => {
    return buckets.map((bucket) => ({
      name: bucket.label,
      path: `${ADMIN_SETTINGS.buckets.path}/${bucket.bucketId}`
    }));
  };

  useEffect(() => {
    loadBuckets();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  useEffect(() => {
    if (buckets && buckets.length > 0 && !bucketId) {
      goTo(`${ADMIN_SETTINGS.buckets.path}/${buckets[0].bucketId}/`)
    }
  }, [buckets]);

  useEffect(() => {
    setCurrentBucketTab(buckets);
  }, [bucketId]);

  let content = null;
  let tabs = null;

  if (buckets && buckets.length > 0) {
    const tabsInfo = getTabNames();
    content = (<React.Fragment>
      {tabsInfo.map((tabInfo, index) => <TabPanel selectedTabPath={currentSecondLevelTabPath}
                                                  tabPath={tabInfo.path}
                                                  key={`panel_${index}`}><TabContent
        visible={currentSecondLevelTabPath === tabInfo.path}
        isAdminView={true}
        readOnly={false}
        fileExplorerPreferences={fileExplorerPreferences}
        key={`tab_${index}`}/></TabPanel>)}
    </React.Fragment>);

    tabs = (
      <SWCTabs activeTabPath={currentSecondLevelTabPath} tabsInfo={tabsInfo}>{content}</SWCTabs>);
  }

  return <>{tabs}</>;
}

FilesTabs.propTypes = {
  TabContent: PropTypes.func.isRequired,
};
