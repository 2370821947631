import React from 'react';
import Tooltip from '@mui/material/Tooltip';


const abbreviateString = (str, maxLength) => {
  if (str == null || str.length <= maxLength) {
    return str;
  }

  return `${str.substring(0, maxLength - 3)}...`;
};

export default function UrlCell({ url }) {

  const label = abbreviateString(url, 64);
  const tooltip = url || '';

  return label ? (
    <Tooltip title={tooltip}>
      <span>{label}</span>
    </Tooltip>
  ) : null;
}
