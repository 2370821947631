import { useEffect, useState } from 'react';
import { useAuth } from '../react-auth-wrapper';
import { useNotifications } from '../contexts/NotificationsContext';

const useClouds = (cloudKeyFromUrl, notificatorName, cancel, resource = '/swc/api/clouds/meta') => {

  const [clouds, setClouds] = useState(null);
  const [validCloudKey, setValidCloudKey] = useState(null);
  const { callApi } = useAuth();
  const { notify } = useNotifications();

  function loadClouds() {
    console.log('[useClouds]', `Fetching clouds meta ${resource} ...`);
    callApi(resource, { method: 'GET' }, cancel)
    .then((response) => {
      console.log('[useClouds]', 'clouds', response && response.clouds);
      setClouds(response.clouds);
      calculateAndSetDefaultCloudKey(response.clouds);
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setClouds([]);
        notify(notificatorName,
          {
            text: 'Error while loading clouds:',
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    })
    .finally(() => {
    });
  }

  function calculateAndSetDefaultCloudKey() {
    const calculatedCloudKey = calculateDefaultCloudKey();
    console.log('[useClouds]', 'validCloudKey', calculatedCloudKey);
    setValidCloudKey(calculatedCloudKey);
  }

  function calculateDefaultCloudKey() {
    if (clouds && clouds.length > 0) {
      const defaultCloudKey = clouds[0].key;
      if (cloudKeyFromUrl !== undefined) {
        const foundClouds = clouds.filter(cloud => cloud.key === cloudKeyFromUrl)
        if (foundClouds.length > 0) {
          return cloudKeyFromUrl;
        } else {
          return defaultCloudKey;
        }
      } else {
        return defaultCloudKey;
      }
    }
    return null;
  }

  useEffect(() => {
    loadClouds();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [resource]);
  useEffect(calculateAndSetDefaultCloudKey, [cloudKeyFromUrl, clouds]);

  return { clouds, validCloudKey };
};

export default useClouds;
