/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {usePermissions} from '../../contexts/PermissionsContext';
import {useAuth} from '../../react-auth-wrapper';
import {useNotifications} from '../../contexts/NotificationsContext';
import useModal from '../../hooks/useModal';
import ImpersonationDialog from './ImpersonationDialog';
import ModalDialog from '../ModalDialog';


const DEFAULT_IMPERSONATION_STATE = {
  impersonated: false,
  adminUser: {
    emailAddress: '',
  },
  user: {
    emailAddress: '',
  },
};

export default function UserAvatarMenu(props) {

  const { callApi } = useAuth();
  const { notify } = useNotifications();
  const { hasPermission } = usePermissions();
  const { isShowing, toggle } = useModal();
  const [impersonatedUserState, setImpersonatedUserState] = useState(DEFAULT_IMPERSONATION_STATE);
  const cancel = {};

  const hasImpersonationPermission = hasPermission('IMPERSONATE_USER');
  const impersonatedUserEmail = (impersonatedUserState && impersonatedUserState.user) ? impersonatedUserState.user.emailAddress : null;

  const handleLogout = () => {
    if (hasImpersonationPermission) {
      callApi('/swc/api/admin/impersonation', { method: 'DELETE' }, {}, false);
    }
    props.logout();
  };

  const handleShowProfile = props.profile;

  function load() {
    callApi('/swc/api/admin/impersonation', { method: 'GET' }, cancel, false)
    .then((response) => {
      setImpersonatedUserState({ ...DEFAULT_IMPERSONATION_STATE, ...response });
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setImpersonatedUserState(DEFAULT_IMPERSONATION_STATE);
        notify('commonNotificator',
          {
            text: 'Error while impersonation data:',
            error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
  }

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleStartImpersonation = (emailAddress) => {
    callApi('/swc/api/admin/impersonation',
      { method: 'PUT', body: JSON.stringify({ impersonatedUserEmailAddress: emailAddress }) },
      cancel,
      false)
    .then((response) => {
      setImpersonatedUserState({ ...DEFAULT_IMPERSONATION_STATE, ...response });
      window.location.pathname = '/welcome';
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setImpersonatedUserState(DEFAULT_IMPERSONATION_STATE);
        notify('commonNotificator',
          {
            text: 'Error while impersonating:',
            error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
  };

  const handleStopImpersonation = () => {
    callApi('/swc/api/admin/impersonation',
      { method: 'DELETE' },
      cancel,
      false)
    .then((response) => {
      setImpersonatedUserState({ ...DEFAULT_IMPERSONATION_STATE, ...response });
      window.location.pathname = '/welcome';
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setImpersonatedUserState(DEFAULT_IMPERSONATION_STATE);
        notify('commonNotificator',
          {
            text: 'Error while impersonating:',
            error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
  };

  useEffect(() => {
    setImpersonatedUserState(DEFAULT_IMPERSONATION_STATE);
    if (hasImpersonationPermission) {
      load();
    }
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [hasImpersonationPermission]);


  const menuItem = (label, onClick, disabled = false, hasMenuPermission = true) => hasMenuPermission ? (
      <MenuItem
        key={label}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </MenuItem>
    ) : null;

  const menuItems = [
        menuItem('Profile', handleShowProfile),
        menuItem('Start Impersonation', toggle, impersonatedUserState.impersonated, hasImpersonationPermission),
        menuItem('Stop Impersonation', handleStopImpersonation, !impersonatedUserState.impersonated, hasImpersonationPermission),
        menuItem('Logout', handleLogout),
      ];

  const menu = isShowing ? null : (
    <Menu {...props}>
      {menuItems}
    </Menu>
  );

  return (
    <React.Fragment>
      {menu}
      <ModalDialog
        toggle={toggle}
        open={isShowing}
        title="Impersonation"
      >
        <ImpersonationDialog
          email={impersonatedUserEmail}
          setEmail={handleStartImpersonation}
          emailValid={validateEmail}
          hide={toggle}
        />
      </ModalDialog>
    </React.Fragment>
  );
}
