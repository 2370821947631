import React, {useEffect, useState} from 'react';
import {usePageMeta} from '../../contexts/PageMetaContext';
import Paper from '@mui/material/Paper';
import {useAuth} from '../../react-auth-wrapper';
import {useNotifications} from '../../contexts/NotificationsContext';
import {ADMIN_SETTINGS} from '../../components/PathConstants';
import styled from "@emotion/styled";
import AdminCloudSettingsTabs from "../../menu/admin/AdminCloudSettingsTabs";

const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(3)};
`;
const StyledContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(3)};
`;
export default function TradeComplianceLogging() {
  const [awsCloudWatchUrl, setAwsCloudWatchUrl] = useState(null);
  const {setTitle} = usePageMeta();
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const cancel = {};

  function getAWSCloudWatchLogsUrl() {
    console.log('[AdminDownloads]', 'Fetching cloud data...');

    callApi(`/swc/api/admin/download/aws/watch/url`, {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[AdminDownloads]', 'aws cloud watch url', response.url);
        setAwsCloudWatchUrl(response.url);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setAwsCloudWatchUrl(null);
          notify('commonNotificator',
            {
              text: "Error while loading aws cloud watch url:",
              error: error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      })
      .finally(() => {
      });
  }

  useEffect(() => {
    setTitle('Administration - Trade Compliance Logging');
    getAWSCloudWatchLogsUrl();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  return (<AdminCloudSettingsTabs activeTabPath={ADMIN_SETTINGS.complianceLogging.path}>
    <StyledPaper square>
      Please login first here: <a href="https://416027568646.signin.aws.amazon.com/console"
                                  target="_blank">RnD-1</a>
      {awsCloudWatchUrl && <StyledContainer>Browse to the compliance logs here: <a
        href={awsCloudWatchUrl}
        target="_blank">AWS CloudWatch Logs</a></StyledContainer>}
    </StyledPaper>
  </AdminCloudSettingsTabs>);
}
