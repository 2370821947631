import React, { useEffect, useState } from 'react';
import OverviewList from '../components/OverviewList';
import { useAuth } from '../react-auth-wrapper';
import { useNotifications } from '../contexts/NotificationsContext';
import VMOverview from './vms/VMOverview';

export default function VMsOverview() {

  const [clouds, setClouds] = useState([]);
  const { callApi } = useAuth();
  const { notificator, notify } = useNotifications();
  const cancel = {};

  function loadClouds() {
    console.log('[VMsOverview]', 'Fetching clouds meta...');

    callApi('/swc/api/clouds/meta', { method: 'GET' }, cancel)
    .then((response) => {
      console.log('[VMsOverview]', 'clouds meta', response);
      setClouds(response.clouds);
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setClouds([]);
        notify('commonNotificator',
          {
            text: "Error while loading clouds meta:",
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    })
    .finally(() => {
    });
  }

  useEffect(() => {
    loadClouds()
  }, [])

  return (<React.Fragment>
    {notificator('instancesOverviewNotificator')}
    <OverviewList items={clouds}
                  DetailComponent={VMOverview}
                  title="VMs Overview"
                  pathPrefix="/vms/"/>
  </React.Fragment>)
}
