import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SWCLink from '../SWCLink';

export default function VmRegion({ instance, meta, path }) {

  const currentRegion = meta.regions.filter(region => region.key === instance.regionKey)[0];
  const label = currentRegion ? currentRegion.label : instance.regionKey;
  const tooltip = currentRegion ? currentRegion.description : instance.regionKey;

  return (
    <SWCLink path={path}>
      <Tooltip title={tooltip} data-region-key={instance.regionKey}>
        <span>{label}</span>
      </Tooltip>
    </SWCLink>
  );
}

VmRegion.propTypes = {
  instance: PropTypes.shape({
    regionKey: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({ regions: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
};
