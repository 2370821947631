import React, {useState} from 'react';
import FileListItem from './FileListItem';
import {SWC_PROP_TYPES} from "../../SwcPropTypes";
import prettyBytes from "pretty-bytes";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import SimpleTableWithPaging from "../SimpleTableWithPaging";
import BuildDetailPanel from "./BuildDetailPanel";
import PanelHeaderBox from "./PanelHeaderBox";
import DownloadButton from "./DownloadButton";
import styled from "@emotion/styled";
import CopyToClipboardButton from "../CopyToClipboardButton";

const headRow = [
  {
    id: 'name', sx: {width: '80%'}, disablePadding: false, label: 'Name',
  },
  {
    id: 'sha', sx: {}, disablePadding: false, label: 'SHA256',
  },
  {
    id: 'size', sx: {}, disablePadding: false, label: 'Size',
  },
];

export default function BuildDetailPanelFileList({versionItem}) {

  const versionObj = versionItem?.version;
  const productMeta = versionItem?.productMeta;
  const enabled = versionObj && versionObj.files && versionObj.files.length > 0;

  const itemContainer = (file, index) => ([
    <FileListItem
      key={file.fileName + "-" + index}
      fileObject={file}
      productKey={productMeta.productKey}
      versionObj={versionObj}
      downloadType={productMeta.downloadType}
    />,
    <FileSha256CopyButton key={file.sha256} file={file} disabled={false}/>,
    <FileSize file={file}/>
  ]);

  const nonHiddenFile = file => file.fileName && file.fileName[0] !== ".";

  const rows = enabled ? versionObj.files
    .filter(nonHiddenFile)
    .map(itemContainer) : null;

  const titleComponent = (
    <PanelHeaderBox title={
      <Typography variant={'h6'}>Files</Typography>}
    >
      {versionObj && productMeta ? (
        <DownloadButton
          productMeta={productMeta}
          versionObj={versionObj}/>
      ) : null}
    </PanelHeaderBox>
  );

  return enabled
    ? (
      <BuildDetailPanel title={titleComponent}>
        <SimpleTableWithPaging
          headRow={headRow}
          rows={rows}
          showHeadRow={false}
          showTitle={false}
          showPagination={false}
          sortable={false}
        />
      </BuildDetailPanel>
    ) : null;
}

BuildDetailPanelFileList.propTypes = {
  versionItem: SWC_PROP_TYPES.versionItem,
};

const StyledTooltip = styled(Tooltip)`
  float: right;
`;
const StyledFileLabel = styled.span`
  white-space: nowrap;
`;

function FileSize({file}) {

  const size = file && file.size;
  const label = size ? prettyBytes(size, {binary: true}) : '';

  return (
    size ? (

      <StyledTooltip>
        <StyledFileLabel>{label}</StyledFileLabel>
      </StyledTooltip>
    ) : null
  );
}

FileSize.propTypes = {
  file: SWC_PROP_TYPES.fileMeta.isRequired,
};

function FileSha256CopyButton({file, disabled}) {
  // state currently not used, but mihgt be changed
  const [showButton, setShowButton] = useState(false);

  function CopyCommandIcon() {
    return <CopyToClipboardButton
      disabled={disabled}
      data={{text: file.sha256, copySuccessText: "Copied!"}}
      btnSize={"small"}
      btnTitle="Copy SHA256 to clipboard"/>
  }

  return file.sha256 ? (
    <div style={{display: "flex", textAlign: "left", alignItems: "flex-end"}}
         onMouseEnter={() => setShowButton(true)}
         onMouseLeave={() => setShowButton(false)}>
      SHA256 {abbreviateString(file.sha256, 12)}
      <CopyCommandIcon/>
    </div>
  ) : null;
}

const abbreviateString = (str, maxLength) => {
  if (str == null || str.length <= maxLength) {
    return str;
  }

  return `${str.substring(0, maxLength - 3)}...`;
};
