import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {useNotifications} from '../contexts/NotificationsContext';
import {usePageMeta} from '../contexts/PageMetaContext';
import {useAuth} from '../react-auth-wrapper';
import FileBreadcrumbs from '../components/files/FileBreadcrumbs';
import FileSize from '../components/files/FileSize';
import FileLastModificationTime from '../components/files/FileLastModificationTime';
import ScrollableList from '../components/ScrollableList';
import ModalDialog from '../components/ModalDialog';
import useModal from '../hooks/useModal';
import useInterval from '../hooks/useInterval';
import FileUploadDialog from '../components/files/dialogs/FileUploadDialog';
import LoadingIcon from '../components/LoadingIcon';
import FileDeleteIcon from "../components/files/FileDeleteIcon";
import FileMoveDialog from "../components/files/dialogs/FileMoveDialog";
import FileMoveIcon from "../components/files/FileMoveIcon";
import ListWrapper from '../components/ListWrapper';
import FileNameDownload from '../components/files/FileNameDownload';
import {useParams} from "react-router-dom";

function escapePath(path) {
  let answer = '';
  if (path) {
    answer = path.replace(/\//g, ':')
    if (answer.endsWith(':')) {
      answer = answer.slice(0, -1);
    }
  }
  console.debug(`[Files] escapePath '${path}' -> '${answer}'`);
  return answer;
}

function unescapePath(path) {
  let answer = '';
  if (path) {
    answer = path.replace(/:/g, '/');
    if (!answer.endsWith('/')) {
      answer = answer + '/';
    }
  }
  console.debug(`[Files] unescapePath '${path}' -> '${answer}'`);
  return answer;
}

export default function Files({readOnly = true, isAdminView = false}) {

  let {bucketId, category, path} = useParams();

  bucketId = bucketId || 'iso.v93000.advantest.cloud';
  path = path || '';

  const {goTo} = usePageMeta();
  console.log('[Files] path', path);

  const parentPath = path ? unescapePath(path) : '';
  const [reloadInterval] = useState(45 * 1000);
  const [bucket, setBucket] = useState(null)
  const [bucketObjects, setBucketObjectsInternal] = useState(null);
  const [uploadEnabled, setUploadEnabled] = useState(true);

  const {callApi} = useAuth();
  const {setTitle} = usePageMeta();
  const {notificator, notify, clearNotificator} = useNotifications();
  const cancel = {};

  const [currentBucketObject, setCurrentBucketObject] = useState();
  const {isShowing: visibleDialogUploadFile, toggle: toggleVisibleDialogUploadFile} = useModal();
  const {isShowing: visibleDialogMove, toggle: toggleVisibleDialogMove} = useModal();

  function setParentPath(bucketId, path) {
    const basePath = isAdminView ? '/admin/cloud-files' : `/download/${category}/files`;
    goTo(`${basePath}/${bucketId}/${escapePath(path)}`)
  }

  function compareFiles(fileA, fileB) {
    if (fileA.name === fileB.name) {
      return 0;
    }
    return fileA.name > fileB.name ? 1 : -1;
  }

  function updateFilesState(bucketObjectListOrNull) {
    console.log('[Files]', 'bucketObjectList', bucketObjectListOrNull);
    setBucketObjects((bucketObjectListOrNull || []).sort(compareFiles));
  }

  const headRow = [
    {
      id: 'name', numeric: false, disablePadding: false, label: 'Name',
    },
    {
      id: 'lastModification',
      date: true,
      disablePadding: false,
      label: 'Last Modification Time',
    },
    {
      id: 'size', numeric: false, disablePadding: false, label: 'Size',
    },
    {
      id: 'actionButtons', numeric: false, disablePadding: false, label: '',
    },
  ];


  const createFileContainer = bucketObject => [
    <FileNameDownload
      fileObject={bucketObject}
      onFolderClick={() => handleNavigateToFolder(bucketObject)}
      onFileClick={() => handleDownload(bucketObject)}
    />,
    <FileLastModificationTime
      bucketObject={bucketObject}/>,
    <FileSize
      bucketObject={bucketObject}/>,
    <React.Fragment>
      <FileDeleteIcon
        bucketObject={bucketObject}
        onFileClick={() => handleDelete(bucketObject)}/>
      <FileMoveIcon
        bucketObject={bucketObject}
        handleClick={() => handleMoveIconClicked(bucketObject)}/>
    </React.Fragment>,
    [bucketObject.name, bucketObject.lastModified, bucketObject.size]
  ];

  function handleNavigateToFolder(bucketObject) {
    const newParentPath = bucketObject.path
    console.log('[Files]', `Altering path to ${newParentPath}`);
    setParentPath(bucketObject.bucketId, newParentPath);
  }

  function setProcessing(bucketObject, bool) {
    setBucketObjectsInternal(prevBucketList => prevBucketList.map(
      prevBucket => {
        if (prevBucket.path === bucketObject.path) {
          return {...prevBucket, processing: bool}
        } else {
          return prevBucket;
        }
      }));
  }

  function handleApiCallError(error) {
    if (error.message !== 'Cancelled') {
      notify('commonNotificator',
        {
          text: "Error while downloading:",
          error: error,
          type: 'error',
          handleClose: () => {
          },
        });
    }
  }

  function handleDownload(/* vararg bucketObject */) {

    function triggerDownload(downloadUrl) {
      console.log(`[Files] downloadUrl=${downloadUrl}`);
      const link = document.createElement('a');
      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (arguments) {
      const bucketObjectList = [...arguments].filter(bo => !bo.folder);

      bucketObjectList.forEach(bo => setProcessing(bo, true));

      bucketObjectList.map(bucketObject => {

        console.log(`[Files] handleDownload ${bucketObject.bucketId}#${bucketObject.path}`)

        callApi(`/swc/api/buckets/${bucketObject.bucketId}/objects/url/GET?path=${encodeURIComponent(
          bucketObject.path)}`, {method: 'GET'}, cancel, false)
          .then((response) => {
            triggerDownload(response.url);
          })
          .catch(handleApiCallError)
          .finally(() => {
            setProcessing(bucketObject, false)
          });
      });
    }
  }

  function handleDelete(/* vararg bucketObject */) {
    if (arguments) {
      const bucketObjectList = Array.from(arguments);
      const bucketObjectPaths = bucketObjectList.map(bo => bo.path);
      const deleteParams = new URLSearchParams(bucketObjectPaths.map(boPath => {
        return ['path', boPath]
      }));

      bucketObjectList.forEach(bo => setProcessing(bo, true));

      callApi(`/swc/api/buckets/${bucketObjectList[0].bucketId}/objects?${deleteParams}`,
        {method: 'DELETE'},
        cancel,
        false)
        .then(() => {
          setBucketObjects((previousList) => previousList.filter(aPrevious => !bucketObjectPaths.includes(
            aPrevious.path)));
          reloadFileList();
        })
        .catch(handleApiCallError)
        .finally(() => {
          bucketObjectList.forEach(bo => setProcessing(bo, false));
        });
    }
  }


  function setBucketObjects(bucketObjects) {
    let bucketObjectList = bucketObjects;
    if (readOnly && bucketObjects) {
      bucketObjectList = bucketObjects.map(bucketObject => ({
        ...bucketObject,
        permissions: ["READ_STORAGE"]
      }));
    }
    setBucketObjectsInternal(bucketObjectList);
  }

  function handleMoveIconClicked(bucketObject) {
    setCurrentBucketObject(bucketObject);
    toggleVisibleDialogMove();
  }

  function handleMovedProcessed() {
    reloadFileList();
    toggleVisibleDialogMove();
  }

  function handleUploadedProcessed() {
    reloadFileList();
    toggleVisibleDialogUploadFile();
  }

  function loadBucketMeta() {
    setBucket(null);
    setBucketObjects(null);

    if (bucketId) {
      console.log('[Files]', `${bucketId}: Fetching bucket meta data...`);

      callApi(`/swc/api/buckets`,
        {method: 'GET'},
        cancel,
        false)
        .then((response) => {
          const selectBucket = response.filter(listItem => listItem.bucketId === bucketId)[0];
          console.log('[Files]', `${bucketId}: bucket`, selectBucket);
          selectBucket === undefined ? goTo("/notFound") : setBucket(selectBucket);
        })
        .catch((error) => {
          if (error.message !== 'Cancelled') {
            updateFilesState([]);
            notify('filesNotificator',
              {
                text: "Error while loading:",
                error: error,
                type: 'error',
                handleClose: () => {
                },
              });
          }
        })
        .finally(() => {

        });
    }
  }

  function reloadFileList() {
    clearNotificator('filesRefreshableNotificator');
    if (bucket && bucket.bucketId === bucketId) {
      console.log('[Files]', `${bucket.bucketId}: Fetching data..`);

      callApi(`/swc/api/buckets/${bucket.bucketId}/objects?parentPath=${encodeURIComponent(
          parentPath)}`,
        {method: 'GET'},
        cancel,
        false)
        .then((response) => {
          const {bucketObjectList} = response;
          console.log('[Files]', `${bucket.bucketId}: bucketObjectList`, bucketObjectList);
          clearNotificator('filesRefreshableNotificator');
          updateFilesState(bucketObjectList);
        })
        .catch((error) => {
          if (error.message !== 'Cancelled') {
            updateFilesState([]);
            notify('filesRefreshableNotificator',
              {
                text: "Error while loading:",
                error: error,
                type: 'error',
                handleClose: () => {
                },
              });
          }
        })
        .finally(() => {

        });
    } else {
      updateFilesState([]);
    }
  }


  function checkUploadEnabled() {
    setUploadEnabled(false);

    if (!readOnly && bucket) {
      callApi(`/swc/api/buckets/${bucket.bucketId}/objects/url/PUT/enabled`,
        {method: 'GET'},
        cancel,
        true)
        .then(() => {
          setUploadEnabled(true);
        }).catch((error) => {
        if (error.message !== 'Cancelled') {
          console.log('[Files] upload disabled');
          setUploadEnabled(false);
        }
      });
    }
  }


  function setPageTitle() {
    setTitle((bucket && bucket.label) ? bucket.label : "");
  }

  useEffect(() => {
    loadBucketMeta();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [bucketId]);
  useEffect(setPageTitle, [bucket]);
  useEffect(checkUploadEnabled, [bucket]);
  useInterval(reloadFileList, reloadInterval);
  useEffect(() => {
    reloadFileList();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [bucket, parentPath]);


  return (
    <ListWrapper>
      {notificator('filesRefreshableNotificator')}
      {notificator('filesNotificator')}
      {bucket && bucketObjects && (
        <div>
          <ScrollableList
            headRow={headRow}
            showHeadRow={true}
            items={bucketObjects}
            scrollableTarget="app-main"
            itemContainer={createFileContainer}
            sortable={true}
            title={(
              <FileBreadcrumbs
                handleBreadcrumbSelected={(selectedPath) => setParentPath(bucketId, selectedPath)}
                folderPath={unescapePath(parentPath)}
                rootFolderName={bucket && bucket.bucketId}
              />
            )}
          />
          {uploadEnabled ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleVisibleDialogUploadFile}
              disabled={!uploadEnabled}
            >
              Upload File
            </Button>
          ) : null
          }
          <ModalDialog
            open={visibleDialogUploadFile}
            toggle={toggleVisibleDialogUploadFile}
            title="Upload File"
          >
            <FileUploadDialog
              bucket={bucket}
              handleUploaded={handleUploadedProcessed}
              hide={toggleVisibleDialogUploadFile}
              folderPath={parentPath}
            />
          </ModalDialog>
          <ModalDialog
            open={visibleDialogMove}
            toggle={toggleVisibleDialogMove}
            title="Move File"
          >
            <FileMoveDialog
              handleFileMoved={handleMovedProcessed}
              hide={toggleVisibleDialogMove}
              bucketObject={currentBucketObject}
            />
          </ModalDialog>
        </div>
      )}
      {!bucketObjects && <LoadingIcon sx={{
        marginTop: (theme) => theme.spacing(20),
        display: 'flex',
        margin: 'auto',
      }} disableShrink/>}
    </ListWrapper>
  );
}
