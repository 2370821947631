import React from 'react';
import {SWC_PROP_TYPES} from "../../../../SwcPropTypes";
import FileMetas from "./FileMetas";
import ErrorDetails from "./ErrorDetails";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import TaskDetails from "./TaskDetails";
import styled from "@emotion/styled";

const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};
  margin: ${({theme}) => theme.spacing(1)};
`;
const StyledList = styled(List)`
  width: 100%;
  padding-bottom: ${({theme}) => theme.spacing(4)};
  background-color: ${({theme}) => theme.palette.background.paper};
`;
export default function NotEditableTaskDetails({taskInfo, productMeta}) {
  return (
    <StyledPaper square>
      <StyledList>
        <TaskDetails
          key={`task_${taskInfo.id}`} task={taskInfo} productMeta={productMeta} moreDetails={true} index={0}/>
        <ErrorDetails error={taskInfo.failure}/>
        <b>Uploaded file(s):</b> <FileMetas fileMetas={taskInfo?.files} removable={false}/>
      </StyledList>
    </StyledPaper>
  );
}

NotEditableTaskDetails.propTypes = {
  taskInfo: SWC_PROP_TYPES.uploadTaskInfo,
  productMeta: SWC_PROP_TYPES.uploadProduct,
};
