// Source from https://github.com/aws-samples/amazon-s3-multipart-upload-transfer-acceleration/blob/main/README.md
// Original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
export class Uploader {
  constructor(options) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    // number of parallel uploads
    options.threadsQuantity = options.threadsQuantity || 0
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15)
    // adjust the timeout value to activate exponential backoff retry strategy
    this.timeout = 20000 // ms
    this.name = undefined
    this.multipartUploadId = undefined
    this.file = options.file
    this.fileName = options.fileName
    this.callApiFn = options.callApi
    this.cancel = options.cancel
    this.aborted = false
    this.uploadedSize = 0
    this.progressCache = {}
    this.activeConnections = {}
    this.parts = []
    this.uploadedParts = []
    this.onProgressFn = () => {
    }
    this.onCompleteFn = () => {
    }
    this.onErrorFn = () => {
    }
    this.baseURL = options.baseURL
  }

  start() {
    this.initialize();
  }

  async initialize() {
    try {
      const initializeResponse = await this.callApiFn(`${this.baseURL}/start`,
        {
          method: 'PUT',
          body: JSON.stringify({name: this.file.name, size: this.file.size})
        }, this.cancel);

      this.name = initializeResponse.name;
      this.multipartUploadId = initializeResponse.multipartUploadId;
      this.parts.push(...initializeResponse.partUrls);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext(retry = 0) {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const chunk = this.file.slice(part.fromByte, part.toByte + 1);

      const sendChunkStarted = () => {
        this.sendNext();
      }

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          if (retry <= 6) {
            retry++;
            const wait = (ms) => new Promise((res) => setTimeout(res, ms));
            //exponential backoff retry before giving up
            console.log(`Part#${part.partNumber} failed to upload, backing off ${2 ** retry * 100} before retrying...`);
            wait(2 ** retry * 100).then(() => {
              this.parts.push(part);
              this.sendNext(retry);
            })
          } else {
            console.log(`Part#${part.partNumber} failed to upload, giving up.`);
            this.complete(error);
          }
        })
    }
  }

  async complete(error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
      this.onCompleteFn();
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  async sendCompleteRequest() {
    if (this.isInitialized()) {
      const finalizationMultiPartInput = {
        name: this.name,
        multipartUploadId: this.multipartUploadId,
        etagForPartNumber: this.uploadedParts,
      };

      await this.callApiFn(`${this.baseURL}/complete`,
        {
          method: 'PUT',
          body: JSON.stringify(finalizationMultiPartInput)
        }, this.cancel);
    }
  }

  async sendAbortRequest() {
    if (this.isInitialized()) {
      const abortMultiPartInput = {
        name: this.name,
        multipartUploadId: this.multipartUploadId,
      }

      await this.callApiFn(`${this.baseURL}/abort`,
        {
          method: 'PUT',
          body: JSON.stringify(abortMultiPartInput)
        }, this.cancel);
    }
  }

  sendChunk(chunk, part, sendChunkStarted) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  handleProgress(part, event) {
    if (this.file) {
      if (event.type === "progress" || event.type === "error" || event.type === "abort") {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      })
    }
  }

  upload(file, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      const throwXHRError = (error, part, abortFx) => {
        delete this.activeConnections[part.partNumber - 1];
        reject(error);
        window.removeEventListener('offline', abortFx);
      }
      if (this.isInitialized()) {
        if (!window.navigator.onLine) {
          reject(new Error("System is offline"));
        }

        const xhr = (this.activeConnections[part.partNumber - 1] = new XMLHttpRequest());
        xhr.timeout = this.timeout;
        sendChunkStarted();

        const progressListener = this.handleProgress.bind(this, part.partNumber - 1);

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.url);
        const abortXHR = () => xhr.abort();
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader("ETag");

            if (ETag) {
              const uploadedPart = {
                partNumber: part.partNumber,
                etag: ETag.replaceAll('"', ""),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.partNumber - 1];
              window.removeEventListener('offline', abortXHR);
            }
          }
        }

        xhr.onerror = (error) => {
          throwXHRError(error, part, abortXHR);
        }
        xhr.ontimeout = (error) => {
          throwXHRError(error, part, abortXHR);
        }
        xhr.onabort = () => {
          throwXHRError(new Error("Upload canceled by user or system"), part);
        }
        window.addEventListener('offline', abortXHR);
        xhr.send(file);
      }
    })
  }

  isInitialized() {
    return this.file && this.multipartUploadId;
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onComplete(onComplete) {
    this.onCompleteFn = onComplete;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      })

    this.aborted = true;
  }
}
