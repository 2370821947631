import React from 'react';
import {TextField} from "@mui/material";
import {StyledSearchFormAutocomplete} from "./SearchForm";

export default function OSSelection({osMetaList, selectedOs, setSelectedOs, disabled}) {

    const sortedOs = osMetaList.map(os => os.name); // already sorted
    const sortedSelectedOs = sortedOs.filter(os => selectedOs.indexOf(os) > -1);

    const osLabel = (os) => osMetaList.find(meta => meta.name === os)?.label || os;

    const handleChange = (event, value) => {
        setSelectedOs((value || []).map(os => os))
    };

    return (

        <StyledSearchFormAutocomplete
            disabled={disabled}
            multiple
            id="search-selected-os"
            options={sortedOs}
            getOptionLabel={(option) => osLabel(option) || ""}
            filterSelectedOptions
            onChange={handleChange}
            value={sortedSelectedOs}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Selected operation systems"
                    placeholder="Operation systems"
                />
            )}
        />
    );
}
