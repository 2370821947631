import React, {useEffect} from 'react';
import {usePageMeta} from '../contexts/PageMetaContext';
import {usePermissions} from '../contexts/PermissionsContext';
import BuildActivitySearch from "./BuildSearch/BuildActivitySearch";
import styled from "@emotion/styled";

const StyledWelcomeContainer = styled.div`
  text-align: center;
`;
export default function Welcome() {

  const {setTitle} = usePageMeta();
  const {hasPermission} = usePermissions();

  useEffect(() => setTitle(''), []);

  if (hasPermission('READ_BUILD')) {
    return <BuildActivitySearch/>
  } else {
    return (<StyledWelcomeContainer>
      <h1>Welcome to the Softwarecenter</h1>
      <p>Please use menu on the left to start</p>
    </StyledWelcomeContainer>);
  }
}
