import React, {useState} from "react";
import {useAuth} from "../../react-auth-wrapper";
import {useNotifications} from "../../contexts/NotificationsContext";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../ConfirmationDialog";
import PropTypes from "prop-types";

export default function NotificationActions({ notification, afterActionHandler, editHandler }) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { callApi } = useAuth();
  const { notify } = useNotifications();
  const cancel = {};

  const handleDelete = () => {
    callApi(`/swc/api/notifications/type/default/${notification.id}`,
      { method: 'DELETE' },
      cancel, true)
      .then((response) => {
        console.log('[NotificationActions] deleted', response);
        afterActionHandler();
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('notificationErrors', {
            text: "Error while notification deleting:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          });
          setConfirmOpen(false);
        }
      });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  };

  return (
    <React.Fragment><Tooltip title="Delete notification" placement="bottom">
      <IconButton
        aria-label="Notifications"
        onClick={() => setConfirmOpen(true)}
        size="large">
        <DeleteIcon/>
      </IconButton>
    </Tooltip>
      <Tooltip title="Edit notification" placement="bottom">
        <IconButton
          aria-label="Notifications"
          onClick={() => editHandler(notification)}
          size="large">
          <EditIcon/>
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        open={confirmOpen}
        handleSubmit={handleDelete}
        handleCancel={() => setConfirmOpen(false)}
        title="Delete notification"
      >
        Do you really want to delete the
        notification <i>{notification.path} / {notification.value}</i>
        {' '}
        ?
      </ConfirmationDialog>
    </React.Fragment>
  );
}

NotificationActions.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  afterActionHandler: PropTypes.func.isRequired,
  editHandler: PropTypes.func.isRequired,
};
