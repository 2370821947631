import SWCTabs from '../../components/SWCTabs';
import React from 'react';
import { ADMIN_DOWNLOADS } from '../../components/PathConstants';

export default function AdminDownloadTabs({ activeTabPath, children }) {
  const tabsInfo = [
    ADMIN_DOWNLOADS.bulkTasks,
    ADMIN_DOWNLOADS.bulkOperationWizard,
  ];

  return (<SWCTabs activeTabPath={activeTabPath} tabsInfo={tabsInfo}>
    {children}
  </SWCTabs>);
}
