import StyledBreadcrumb from './breadcrumbsMenu/StyledBreadcrumb';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {useState} from 'react';
import StyledMenuItem from './breadcrumbsMenu/StyledMenuItem';
import StyledMenu from './breadcrumbsMenu/StyledMenu';
import {useLocation} from "react-router-dom";
import styled from "@emotion/styled";

const UrlsStyledMenu = styled(StyledMenu)`
  margin-top: 30px;
`;

export default function RootNodeUrls({urls}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const currentLocation = useLocation();
  const open = Boolean(anchorEl)

  function handleExpandMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleChipMenuClose() {
    setAnchorEl(null);
  }

  let content = null;

  if (urls && urls.length > 0 && currentLocation && currentLocation.pathname.startsWith('/download/') && currentLocation.pathname.includes('/builds/')) {
    content = <React.Fragment><StyledBreadcrumb
      label="Links to Artifactory"
      onClick={handleExpandMenu}
      deleteIcon={<ExpandMoreIcon onClick={handleExpandMenu}/>}
      onDelete={handleExpandMenu}
    /><UrlsStyledMenu
      id={`menu-appbar-root-node-url`}
      anchorEl={anchorEl}
      open={open}
      onClose={handleChipMenuClose}
    >{
      urls.map((nodeUrl, index) =>
        <StyledMenuItem key={index}><a href={nodeUrl.url}
                                       target="_blank">{nodeUrl.label}</a></StyledMenuItem>)
    }
    </UrlsStyledMenu></React.Fragment>
  }

  return content;
}
