import React, {Fragment, useContext, useEffect, useState} from 'react';
import {amber, green} from '@mui/material/colors';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ErrorMessages from '../components/ErrorMessages';
import ErrorDialog from '../components/ErrorDialog';
import styled from "@emotion/styled";
import {css} from "@emotion/react";

const iconCss = (props) => css`
  font-size: 20px;
  opacity: 0.9;
  margin-right: ${props.theme.spacing(1)};
`;
const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  ${iconCss}
`;
const StyledWarningIcon = styled(WarningIcon)`
  ${iconCss}
`;
const StyledErrorIcon = styled(ErrorIcon)`
  ${iconCss}
`;
const StyledInfoIcon = styled(InfoIcon)`
  ${iconCss}
`;
const variantIcon = {
  success: StyledCheckCircleIcon,
  warning: StyledWarningIcon,
  error: StyledErrorIcon,
  info: StyledInfoIcon,
};
const StyledIconContainer = styled.span`
  display: flex;
`;
const StyledCloseIcon = styled(CloseIcon)`
  ${iconCss}
`;
const StyledSnackbarContent = styled(SnackbarContent)`
  ${props => props.type === 'success' ? `background-color: ${green[600]};` : ''}
  ${props => props.type === 'error' ? `background-color: ${props.theme.palette.error.dark};` : ''}
  ${props => props.type === 'info' ? `background-color: ${props.theme.palette.primary.main};` : ''}
  ${props => props.type === 'warning' ? `background-color: ${amber[700]};` : ''}
  margin: ${({theme}) => theme.spacing(1)};
  margin-left: 0;
  margin-right: 0;
`;

function ActionButton({callback, close, title}) {
  return (
    <Fragment>
      <Button onClick={() => {
        callback();
        close();
      }}>{title}</Button>
    </Fragment>
  );
}

function SnackbarMessage({id, message, index, removeMessageHandler}) {
  const [open, close] = useState(true);
  const {text, type, actions, handleClose, error} = message;
  const Icon = variantIcon[type];

  const handleSnackbarClose = () => {
    close(false);
    handleClose();
  };

  const getActionButtons = () => {
    if (actions) {
      return actions.map((action, index) => (
        <ActionButton callback={action.callback}
                      close={() => close(false)}
                      title={action.title}
                      key={index}/>
      ))
    }
    return undefined;
  };

  useEffect(() => {
    if (!open) {
      removeMessageHandler(id, message);
    }
  }, [open]);

  const closeButton = <IconButton
    key="close"
    aria-label="close"
    color="inherit"
    onClick={handleSnackbarClose}
    size="large">
    <StyledCloseIcon/>
  </IconButton>;

  return (<div id={id}>
      {open &&
        <StyledSnackbarContent
          type={type}
          message={
            <StyledIconContainer id="client-snackbar">
              <Icon/>
              <span><ErrorMessages response={error} message={text}/></span>
            </StyledIconContainer>
          }
          action={[getActionButtons(), handleClose ? closeButton : undefined]}/>
      }
    </div>
  );
}


function NotificatorContainer({id, allMessages, resetMessagesHandler, removeMessageHandler}) {

  const [myMessages, setMyMessages] = useState([]);

  useEffect(() => {
    console.log(`[useNotifications] registered NotificatorContainer[${id}]`)
    resetMessagesHandler(id);
    setMyMessages([]);
  }, []);

  useEffect(() => {
    console.log('New messages', allMessages);
    setMyMessages(allMessages[id]);
  }, [id, allMessages]);

  return (
    <div id={id}>
      {myMessages && (myMessages.map((message, index) => <SnackbarMessage id={id}
                                                                          key={index}
                                                                          message={message}
                                                                          removeMessageHandler={removeMessageHandler}
                                                                          index={index}/>))}
    </div>
  );
}

export const NotificationsContext = React.createContext({});
export const useNotifications = () => useContext(NotificationsContext);
export const NotificationsProvider = ({children}) => {

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [dialogError, setDialogError] = useState(null);
  const [messages, setMessages] = useState({});

  const notify = (key, message) => {
    if (message.error && message.error.message === 'Cancelled') {
      console.log('[useNotifications] skip cancelled message', message, key);
      return;
    }
    const messageStatusCode = (message?.error?.response?.status) || 200;
    if (messageStatusCode >= 400 && messageStatusCode < 500) {
      // show error dialog
      setDialogError({summary: message.text, error: message.error})
      setErrorDialogOpen(true);
      return;
    }
    console.log('[useNotifications] add message', message, key);
    setMessages(prevMessages => getNewMessages(key, message, prevMessages));
  };

  const clearNotificator = (key) => {
    setMessages(prevMessages => {
      if ((prevMessages[key] || []).length === 0) {
        return prevMessages;
      } else {
        return {...prevMessages, [key]: []};
      }
    });
  }

  const getNewMessages = (key, message, oldMessages) => {
    if (oldMessages[key] && !messageExists(oldMessages[key], message)) {
      return ({...oldMessages, [key]: [...oldMessages[key], message]});
    } else {
      return ({...oldMessages, [key]: [message]});
    }
  };

  const messageExists = (currentMessages, newMessage) => {
    return currentMessages.filter(message => (message.text === newMessage.text && message.type === newMessage.type)).length > 0
  };

  const removeMessage = (key, messageToRemove) => {
    const newMessages = messages[key].filter(
      message => (message.text !== messageToRemove.text && message.type !== messageToRemove.type)
    );
    console.log('New messages after remove', key, newMessages);
    setMessages(prevMessages => ({...prevMessages, [key]: newMessages}));
  };

  const resetMessages = (key) => {
    setMessages(prevMessages => ({...prevMessages, [key]: []}));
  };

  const notificator = (key) => {
    return <NotificatorContainer id={key}
                                 allMessages={messages}
                                 resetMessagesHandler={resetMessages}
                                 removeMessageHandler={removeMessage}/>
  };

  return <NotificationsContext.Provider value={{notificator, notify, clearNotificator}}>
    <ErrorDialog open={errorDialogOpen}
                 errorData={dialogError}
                 toggle={setErrorDialogOpen}
                 resetError={setDialogError}/>
    {children}
  </NotificationsContext.Provider>
};

NotificatorContainer.propTypes = {
  id: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
    action: PropTypes.arrayOf(PropTypes.shape({
      callback: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
    })),
    handleClose: PropTypes.func
  }))
};
