import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotifications } from '../../contexts/NotificationsContext';

export default function VmInstanceLimitNotification({ remaining, limit, isAdminView }) {
  const { notify, clearNotificator } = useNotifications();

  const show = remaining === 0 && limit !== 0 && !isAdminView;
  const message = `Personal VM instance limit of ${limit} has been reached. No further instances may be created.`;

  useEffect(() => {
    if (show) {
      notify('instancesLimitNotificator',
        {
          text: message,
          type: 'warning',
        });
    } else {
      clearNotificator('instancesLimitNotificator');
    }
  }, [remaining, limit, isAdminView])

  return <React.Fragment/>;
}

VmInstanceLimitNotification.propTypes = {
  remaining: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  isAdminView: PropTypes.bool
};
