import React, {useState} from 'react';
import {usePageMeta} from '../../contexts/PageMetaContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StyledBreadcrumb from './StyledBreadcrumb';
import StyledMenuItem from './StyledMenuItem';
import StyledMenu from './StyledMenu';
import styled from "@emotion/styled";

const CustomStyledMenu = styled(StyledMenu)`
  margin-top: 30px;
`;

export default function BreadcrumbWithSubMenu({index, breadcrumb}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const {goTo} = usePageMeta();
    const open = Boolean(anchorEl)

    function handleExpandMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleChipMenuClose() {
        setAnchorEl(null);
    }

    function handleMenuItemClick(path) {
        setAnchorEl(null);
        goTo(path);
    }

    const handleClick = breadcrumb => (event) => {
        event.preventDefault();
        goTo(breadcrumb.path)
    }

    const hasClick = (breadcrumb) => {
        return breadcrumb.current !== true && breadcrumb.path
    }

    return (
        <div key={index}>
            <StyledBreadcrumb
                label={breadcrumb.label}
                deleteIcon={<ExpandMoreIcon onClick={handleExpandMenu}/>}
                onClick={hasClick(breadcrumb) ? handleClick(breadcrumb) : undefined}
                onDelete={handleExpandMenu}
            />
            <CustomStyledMenu
                id={`menu-appbar${index}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleChipMenuClose}
            >{
                breadcrumb.items.map((item, secondIndex) =>
                    <StyledMenuItem
                        key={`${index}-${secondIndex}`}
                        onClick={() => handleMenuItemClick(item.path)}>
                        {item.label}
                    </StyledMenuItem>)
            }
            </CustomStyledMenu>
        </div>
    );
}
