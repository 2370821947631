import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {useCopyToClipboard} from "usehooks-ts";
import {Tooltip} from "react-tooltip";
import {IconButton} from "@mui/material";

export default function CopyToClipboardButton({data, btnTitle, disabled = false, btnSize = "large"}) {

  const [_, copyToClipboard] = useCopyToClipboard();
  const [open, setOpen] = useState(false);
  const id = "cp_btn_" + Math.random().toString(20).substring(2, 6);
  let timeout = null;
  const handleCopyToClipboard = () => {
    if (data.text) {
      copyToClipboard(data.text);
      setOpen(true);
      timeout = setTimeout(() => {
        setOpen(false)
      }, 1000);
    }
  }

  useEffect(() => {
    if (timeout != null) {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    if (timeout != null && !open) {
      clearTimeout(timeout);
    }
  }, [open]);

  return (<>
    <IconButton id={id} title={btnTitle ? btnTitle : "Click to copy"} disabled={disabled} onClick={handleCopyToClipboard} edge="end" size="{btnSize}">
      <FontAwesomeIcon icon={faCopy} size="xs"/>
    </IconButton>
    <Tooltip
      isOpen={open}
      anchorId={id}
      closeOnEsc={true}
      place="top"
      content={data.copySuccessText}
    /></>);
}
