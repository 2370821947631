import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import LoadingIcon from '../LoadingIcon';
import {InsertDriveFileOutlined} from '@mui/icons-material';
import {SWC_PROP_TYPES} from "../../SwcPropTypes";
import useDownloader from "./downloader";
import useConfirmation from "./confirmation";
import ConfirmationDialog from "../ConfirmationDialog";
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  position: relative;
  left: 0;
  top: 6px;
  display: inline-block;
`;
const StyledLinkContainer = styled.div`
  display: inline-block;
`;
const StyledFilename = styled.span`
  white-space: nowrap;
  margin-left: 1em;
  width: 100%;
  cursor: pointer;
`;
const StyledLoadingIconContainer = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

const StyledContainerNoWrap = styled.div`
  white-space: nowrap;
  margin-right: 2em;
  width: 100%;
`;

export default function FileListItem({
                                       fileObject, productKey, versionObj, downloadType,
                                     }) {

  const releaseType = versionObj?.type;
  const licenseName = versionObj?.licenseName;
  const {fileName} = fileObject;

  const {handleClick, loading} = useDownloader({
    productKey, versionObj, downloadType, fileName,
  });

  const {
    processing,
    dialogBody,
    dialogTitle,
    dialogOpen,
    handleConfirm,
    handleClose,
    handleShowConfirmationIfRequiredOrSubmit
  } = useConfirmation({releaseType, productKey, licenseName, handleClick});

  const disabled = loading || processing;

  return (<React.Fragment>
    <ConfirmationDialog
      open={dialogOpen}
      handleSubmit={handleConfirm}
      handleCancel={handleClose}
      title={dialogTitle}
      width={'wide'}
    >
      {dialogBody}
    </ConfirmationDialog>
    <StyledContainerNoWrap>
      <StyledContainer>
        <InsertDriveFileOutlined onClick={handleShowConfirmationIfRequiredOrSubmit}/>
      </StyledContainer>
      <StyledLinkContainer>
        <Link
          disabled={disabled}
          color="inherit"
          onClick={handleShowConfirmationIfRequiredOrSubmit}
        >
          <FileName fileObject={fileObject}/>
        </Link>
      </StyledLinkContainer>
    </StyledContainerNoWrap>
    <LoadingIndicator loading={loading}/>
  </React.Fragment>);
}

FileListItem.propTypes = {
  fileObject: SWC_PROP_TYPES.fileMeta.isRequired,
  productKey: PropTypes.string.isRequired,
  downloadType: SWC_PROP_TYPES.downloadType.isRequired,
  versionObj: SWC_PROP_TYPES.version.isRequired,
};


function FileName({fileObject}) {

  const {fileName} = fileObject;
  return (<Tooltip title={fileName}>
    <StyledFilename>{fileName}</StyledFilename>
  </Tooltip>);
}

FileName.propTypes = {
  fileObject: SWC_PROP_TYPES.fileMeta.isRequired,
};


function LoadingIndicator({loading}) {

  const jsx = (<StyledLoadingIconContainer>
    <LoadingIcon
      size={16}
      sx={{
        margin: '2px',
      }}
    />
  </StyledLoadingIconContainer>);
  return loading ? jsx : null;
}

LoadingIndicator.propTypes = {
  loading: PropTypes.bool.isRequired,
}
