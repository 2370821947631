import { usePermissions } from '../contexts/PermissionsContext';

export default function Can({ perform, yes, no }) {

  const { hasPermission } = usePermissions();
  return hasPermission(perform) ? yes() : no();
}

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};
