import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import Grid from '@mui/material/Grid';
import {useAuth} from '../react-auth-wrapper';

const twoVersionLinesCss = {
  textAlign: 'left',
};

export default function VersionBar() {
  const NO_VERSION = 'Not Available';
  const {callApi} = useAuth();
  const cancel = {};
  const [frontendVersion, setFrontendVersion] = useState(NO_VERSION);
  const [backendVersion, setBackendVersion] = useState(NO_VERSION);

  function getBackendVersion() {
    callApi('/swc/internal/info', {method: 'GET'}, cancel)
      .then((response) => {
        console.log(response);
        if (response.app.version) {
          setBackendVersion(response.app.version);
        } else {
          setBackendVersion(NO_VERSION);
        }
      }).catch((reason) => {
      if (reason.message !== 'Cancelled') {
        console.log('Error while getting backend version', reason);
        setBackendVersion(NO_VERSION);
      }
    });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }

  function getFrontendVersion() {
    callApi('/version.json', {method: 'GET'}, cancel)
      .then((response) => {
        console.log(response);
        if (response.version) {
          setFrontendVersion(response.version);
        } else {
          setFrontendVersion(NO_VERSION);
        }
      }).catch((reason) => {
      if (reason.message !== 'Cancelled') {
        console.log('Error while getting frontend version', reason);
        setFrontendVersion(NO_VERSION);
      }
    });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }

  function getVersions() {
    getBackendVersion();
    getFrontendVersion();
  }

  useEffect(getVersions, []);

  const version = frontendVersion === backendVersion ?

    <Typography variant="caption"
                display="block"
                sx={{
                  textAlign: 'center',
                }}
                gutterBottom>
      {`v${frontendVersion}`}
    </Typography>
    :
    <Grid container spacing={1}>
      <Grid item sx={{
        margin: 5,
        marginRight: 0,
        alignSelf: 'center',
      }}>
        <WarningIcon/>
      </Grid>
      <Grid item>
        <Typography variant="caption"
                    display="block"
                    gutterBottom
                    sx={twoVersionLinesCss}>
          {`Frontend v${frontendVersion}`}
        </Typography>
        <Typography variant="caption"
                    display="block"
                    gutterBottom
                    sx={twoVersionLinesCss}>
          {`Backend v${backendVersion}`}
        </Typography>
      </Grid>
    </Grid>;

  return (
    <React.Fragment>{version}</React.Fragment>
  );
}
