import React, {useEffect, useState} from 'react';
import {useAuth} from '../react-auth-wrapper';

import {usePageMeta} from '../contexts/PageMetaContext';
import {useNotifications} from '../contexts/NotificationsContext';
import Typography from '@mui/material/Typography';
import ProductBuildSearch from "../components/BuildSearch/ProductBuildSearch";
import {usePermissions} from "../contexts/PermissionsContext";
import {useParams} from "react-router-dom";


export default function BuildListPage() {

  const {notify} = useNotifications();
  const {callApi} = useAuth();
  const cancel = {};

  const {category: categoryKey, group: groupKey, product: productKey} = useParams();
  const {hasPermission} = usePermissions();
  const [productData, setProductData] = useState(null);
  const [currentProductMeta, setCurrentProductMeta] = useState(null);

  const {setTitle, setRootNodeUrls} = usePageMeta();

  function loadProductData() {
    callApi('/swc/api/products', {method: 'GET'}, cancel).then(setProductData)
      .catch(error => {
          if (error.message !== 'Cancelled') {
            notify('commonNotificator',
              {
                text: 'Error while loading build meta:',
                error: error,
                type: 'error',
                handleClose: () => {
                },
              });
          }
        }
      );

    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }

  function updateRootNodeUrls() {
    setRootNodeUrls(null);

    if (hasPermission && currentProductMeta?.productKey && hasPermission('READ_ROOT_NODE_URLS')) {
      callApi(`/swc/api/products/${currentProductMeta?.productKey}/dataSourceUrls`, {method: 'GET'}, cancel).then(setRootNodeUrls)
        .catch(error => {
            if (error.message !== 'Cancelled') {
              setRootNodeUrls(null)
              notify('commonNotificator',
                {
                  text: 'Error while loading build meta:',
                  error: error,
                  type: 'error',
                  handleClose: () => {
                  },
                });
            }
          }
        );
    }
  }

  function updateCurrentProductMeta() {
    if (productKey == null || productData?.productMetas == null) {
      setCurrentProductMeta(null)
    } else {
      setCurrentProductMeta(productData.productMetas.find(meta =>
        meta.productKey === productKey && meta.groupKey === groupKey && meta.categoryKey === categoryKey
      ));
    }
  }

  function updateTitle() {
    setTitle(currentProductMeta?.productLabel);
  }

  useEffect(loadProductData, []);
  useEffect(updateCurrentProductMeta, [productKey, groupKey, categoryKey, productData?.productMetas]);
  useEffect(updateRootNodeUrls, [hasPermission == null, currentProductMeta]);
  useEffect(updateTitle, [currentProductMeta]);

  return (
    <div>
      {currentProductMeta?.productDescription &&
        <Typography variant="subtitle1" gutterBottom>{currentProductMeta?.productDescription}</Typography>}
      <ProductBuildSearch
        categoryKey={categoryKey}
        groupKey={groupKey}
        productKey={productKey}
      />
    </div>
  );
}

