import React, { useEffect, useState } from 'react';
import OverviewList from '../../components/OverviewList';
import { useAuth } from '../../react-auth-wrapper';
import { useNotifications } from '../../contexts/NotificationsContext';

export default function AdminOverview() {

  const [menu, setMenu] = useState([]);
  const { callApi } = useAuth();
  const { notify } = useNotifications();
  const cancel = {};

  function loadAdminMenu() {
    console.log('[AdminOverview]', 'Fetching admin menu items...');

    callApi('/swc/api/menu/admin', { method: 'GET' }, cancel)
    .then((response) => {
      console.log('[AdminOverview]', 'admin menu items', response);
      const menuItems = getAdminItems(response.menu);
      setMenu(menuItems);
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setMenu(null);
        notify('commonNotificator',
          {
            text: "Error while loading admin menu items:",
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    })
    .finally(() => {
    });
  }

  function getAdminItems(menu) {
    if (menu && menu.items !== null && menu.items.length > 0) {
      const adminMenu = menu.items.filter(menuItem => menuItem.key === "admin")[0]
      return adminMenu && adminMenu.items
    }
    return [];
  }

  useEffect(loadAdminMenu,[])

  return (<OverviewList items={menu}
                        title="Administration Overview"
                        pathPrefix="/admin/"/>);
}
