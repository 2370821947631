import React from 'react';
import {FormControlLabel} from "@mui/material";
import Switch from '@mui/material/Switch';
import styled from "@emotion/styled";

const uniq = list => Array.from(new Set(list));
const StyledContainer = styled.div`
  margin-top: ${({theme}) => theme.spacing(1.5)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

export default function PropertiesSelection({
                                                propertyList,
                                                selectedProperties,
                                                setSelectedProperties,
                                                disabled,
                                            }) {

    const sortedPropertyItems = propertyList.map(propertyItem => ({
        ...propertyItem,
        checked: (selectedProperties || []).includes(propertyItem.key)
    }));

    const handleChange = (property) => (event, value) => {
        if (value) {
            setSelectedProperties(prev => uniq([...prev, property]));
        } else {
            setSelectedProperties(prev => uniq((prev || []).filter(selected => selected !== property)));
        }
    };

    return (
        <StyledContainer>
            {sortedPropertyItems.map(propertyItem => (
                <FormControlLabel
                    disabled={disabled}
                    key={propertyItem.key}
                    control={
                        <Switch
                            size="small"
                            checked={propertyItem.checked}
                            onChange={handleChange(propertyItem.key)}
                            color="primary"
                            name={propertyItem.label}/>
                    }
                    label={propertyItem.label}
                />
            ))}
        </StyledContainer>
    );
}
