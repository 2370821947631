import {styled} from '@mui/material/styles';
import MuiExpansionPanel from '@mui/material/Accordion';

const Accordion = styled(MuiExpansionPanel)(({theme}) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}));

export default Accordion;
