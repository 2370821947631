
export default function (rootFolderName, folderPath) {

  const newBreadcrumbs = [];

  const normalizedFolderPath = folderPath === '' ? rootFolderName : `${rootFolderName}/${folderPath.slice(0,-1)}`;
  const folderPathElements = normalizedFolderPath.split('/');

  for (let i = 1; i <= folderPathElements.length; i += 1) {
    const joinedPath = folderPathElements.slice(1, i).join('/') + '/';
    newBreadcrumbs.push({name: folderPathElements[i - 1], path: joinedPath});
  }

  newBreadcrumbs[0].path = '';

  return newBreadcrumbs;
}
