import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SWCLink from "../../SWCLink";

function removeLeadingSign(str) {
  if (str.length && str[0] === '-') {
    return removeLeadingSign(str.substring(1));
  } else {
    return str;
  }
}

function replaceUnderScores(str) {
  return str.replace(/_/g, ' ').split(' ').map(removeLeadingSign).join(' ');
}

export default function BuildVersionDetailCell({
                                                 item, clickCallback = () => {
  }
                                               }) {

  const mainVersion = item?.version?.mainVersion || '';
  const rawVersion = item?.version?.version || '';

  let labelVersionPart = rawVersion;
  if (labelVersionPart.startsWith(mainVersion)) {
    labelVersionPart = labelVersionPart.substring(mainVersion.length);
  }
  labelVersionPart = replaceUnderScores(labelVersionPart);

  const labelReleaseTypePart = convertToTitleCase(item?.version?.releaseType);

  const label = [labelReleaseTypePart, labelVersionPart].filter(it => it).join(' - ') || '';

  const tooltip = [
    item?.version?.releaseType,
    item?.version?.classifier || rawVersion].filter(it => it).join(' - ') || '';

  return rawVersion ?
    (
      <SWCLink clickCallback={clickCallback}>
        <Tooltip
          title={tooltip}
          data-version={rawVersion}>
          <span>{label}</span>
        </Tooltip>
      </SWCLink>
    ) : null;
}

BuildVersionDetailCell.propTypes = {
  clickCallback: PropTypes.func,
  item: PropTypes.shape({
    version: PropTypes.shape({
      mainVersion: PropTypes.string,
      version: PropTypes.string,
      classifier: PropTypes.string,
    }),
  }).isRequired,
};

const convertToTitleCase = (inputString = '') => {
  return inputString ? inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase() : null;
};
