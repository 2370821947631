/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ProductsSelection from './ProductsSelection';
import OSSelection from './OSSelection';
import BuildTypesSelection from './BuildTypesSelection';
import MainVersionInput from './MainVersionInput';
import PropertiesSelection from './PropertiesSelection';
import {
    FORM_DIRECTIVES,
    PROPERTIES_META,
    QUALIFIED_META_PROPERTY_KEY,
    SUBSCRIPTION_META,
    SUBSCRIPTION_NONE_KEY,
} from '../searchConstants';
import SubscriptionSelection from './SubscriptionSelection';
import AttributeSelection from './AttributeSelection';
import styled from "@emotion/styled";
import {Autocomplete} from "@mui/material";

function hasDifferences(setAsArray1, setAsArray2) {
    const s1 = setAsArray1 || [];
    const s2 = setAsArray2 || [];
    const diff1 = s1.filter((x) => s2.indexOf(x) === -1);
    const diff2 = s2.filter((x) => s1.indexOf(x) === -1);
    return diff1.length > 0 || diff2.length > 0;
}

function updatePropertiesMeta(selectedBuildTypes, setPropertiesMeta, setSelectedProperties) {
    if (selectedBuildTypes.length > 0 && (selectedBuildTypes.includes('BETA') || selectedBuildTypes.includes('CSBETA'))) {
        setPropertiesMeta(PROPERTIES_META);
    } else {
        setSelectedProperties((prevState) => prevState.filter((propertyKey) => propertyKey !== QUALIFIED_META_PROPERTY_KEY));
        setPropertiesMeta(PROPERTIES_META.filter(meta => meta.key !== QUALIFIED_META_PROPERTY_KEY));
    }
}

const StyledContainer = styled.div`
  padding: ${({theme}) => theme.spacing(0)};
  margin: ${({theme}) => theme.spacing(0)};
  margin-bottom: ${({theme}) => theme.spacing(0)};
`;
export const SearchFormInputStyles = (theme) => (`
    margin-left: ${theme.spacing(1.5)};
    margin-right: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
    max-width: ${theme.spacing(100)};
`);
export const StyledSearchFormAutocomplete = styled(Autocomplete)`
  ${props => SearchFormInputStyles(props.theme)}
`;
const StyledButtonContainer = styled.div`
  display: flex;
  padding-top: ${({theme}) => theme.spacing(2)};
  margin: ${({theme}) => theme.spacing(1)};
`;
const StyledButton = styled(Button)`
  display: flex;
  margin-right: ${({theme}) => theme.spacing(1)};
  width: 80px;
  margin-left: 4px;
`;

export function SearchForm({
                               disabled,
                               productData,
                               state,
                               defaultState = null,
                               handleSearch,
                               directives = [FORM_DIRECTIVES.PRODUCT_DIRECTIVE],
                               searchButtonLabel = 'Filter'
                           }) {

    const handleSubscription = directives.indexOf(FORM_DIRECTIVES.SUBSCRIPTION_DIRECTIVE) >= 0;
    const handleProducts = directives.indexOf(FORM_DIRECTIVES.PRODUCT_DIRECTIVE) >= 0;

    const productMetaList = productData?.productMetas;
    const buildTypeList = productData?.buildTypes;
    const osMetaList = productData?.oss;
    const [formDirty, setFormDirty] = useState(false);
    const [selectedProductKeys, setSelectedProductKeys] = useState([]);
    const [selectedBuildTypes, setSelectedBuildTypes] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [selectedOs, setSelectedOs] = useState([]);
    const [mainVersionSubstring, setMainVersionSubstring] = useState('');
    const [propertiesMeta, setPropertiesMeta] = useState(PROPERTIES_META);
    const [selectedSubscription, setSelectedSubscription] = useState(SUBSCRIPTION_NONE_KEY);

    function init(stateParam) {
        setSelectedProductKeys(stateParam?.selectedProductKeys || []);
        setSelectedBuildTypes(stateParam?.selectedBuildTypes || []);
        setSelectedProperties(stateParam?.selectedProperties || []);
        setSelectedAttributes(stateParam?.selectedAttributes || []);
        setSelectedOs(stateParam?.selectedOs || []);
        setMainVersionSubstring(stateParam?.mainVersionSubstring || '');
        setSelectedSubscription(stateParam?.subscription || SUBSCRIPTION_NONE_KEY);
        setFormDirty(false);
    }

    function calculateDirtyFlag() {
        if (defaultState) {
            setFormDirty(() => {
                return state != null && (
                    state.mainVersionSubstring !== defaultState?.mainVersionSubstring
                    || hasDifferences(state?.selectedProductKeys, defaultState?.selectedProductKeys)
                    || hasDifferences(state?.selectedBuildTypes, defaultState?.selectedBuildTypes)
                    || hasDifferences(state?.selectedProperties, defaultState?.selectedProperties)
                    || hasDifferences(state?.selectedAttributes, defaultState?.selectedAttributes)
                    || hasDifferences(state?.selectedOs, defaultState?.selectedOs)
                    || (state?.subscription || SUBSCRIPTION_NONE_KEY) !== (defaultState?.subscription || SUBSCRIPTION_NONE_KEY)
                );
            });
        } else {
            setFormDirty(() => {
                return state != null && (
                    state.mainVersionSubstring !== mainVersionSubstring
                    || hasDifferences(state?.selectedProductKeys, selectedProductKeys)
                    || hasDifferences(state?.selectedBuildTypes, selectedBuildTypes)
                    || hasDifferences(state?.selectedProperties, selectedProperties)
                    || hasDifferences(state?.selectedAttributes, selectedAttributes)
                    || hasDifferences(state?.selectedOs, selectedOs)
                    || (state?.subscription || SUBSCRIPTION_NONE_KEY) !== (selectedSubscription || SUBSCRIPTION_NONE_KEY)
                );
            });
        }
    }

    const handleReset = (event) => {
        event.preventDefault();
        if (defaultState) {
            init(defaultState);
        } else {
            init(state);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        let searchState = {
            ...state,
            selectedBuildTypes: selectedBuildTypes,
            selectedProperties: selectedProperties,
            selectedAttributes: selectedAttributes,
            selectedOs: selectedOs,
            mainVersionSubstring: mainVersionSubstring
        };
        if (handleProducts) {
            searchState = {...searchState, selectedProductKeys: selectedProductKeys};
        }
        if (handleSubscription) {
            searchState = {...searchState, subscription: selectedSubscription};
        }
        handleSearch(searchState);
    };

    useEffect(() => updatePropertiesMeta(selectedBuildTypes, setPropertiesMeta, setSelectedProperties), [selectedBuildTypes]);

    useEffect(() => init(state), [state]);

    useEffect(calculateDirtyFlag, [selectedProductKeys, selectedBuildTypes, selectedOs, selectedProperties, selectedAttributes, mainVersionSubstring, selectedSubscription]);

    return (

        <StyledContainer>
            <form onSubmit={() => false}>
                {handleProducts ? (
                    <div>
                        <ProductsSelection
                            productList={productMetaList}
                            selectedProductKeys={selectedProductKeys}
                            setSelectedProductKeys={setSelectedProductKeys}
                            disabled={disabled}
                        />
                    </div>
                ) : null}
                <div>
                    <MainVersionInput
                        mainVersionSubstring={mainVersionSubstring}
                        setMainVersionSubstring={setMainVersionSubstring}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <OSSelection
                        osMetaList={osMetaList}
                        selectedOs={selectedOs}
                        setSelectedOs={setSelectedOs}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <BuildTypesSelection
                        buildTypesList={buildTypeList}
                        selectedBuildTypes={selectedBuildTypes}
                        setSelectedBuildTypes={setSelectedBuildTypes}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <PropertiesSelection
                        propertyList={propertiesMeta}
                        selectedProperties={selectedProperties}
                        setSelectedProperties={setSelectedProperties}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <AttributeSelection
                        preSelectedProductKeys={handleProducts ? null : (selectedProductKeys || [])}
                        productList={productMetaList}
                        selectedAttributes={selectedAttributes}
                        setSelectedAttributes={setSelectedAttributes}
                        disabled={disabled}
                    />
                </div>
                {handleSubscription ? (
                    <SubscriptionSelection
                        subscriptionMetaList={SUBSCRIPTION_META}
                        subscriptionDefaultKey={SUBSCRIPTION_NONE_KEY}
                        selectedSubscription={selectedSubscription}
                        setSelectedSubscription={setSelectedSubscription}
                        disabled={disabled}
                    />
                ) : null}
                <StyledButtonContainer>
                    <StyledButton
                        variant="outlined"
                        color="primary"
                        type="submit"
                        disabled={disabled}
                        onClick={handleSubmit}
                    >
                        <span>{searchButtonLabel}</span>
                    </StyledButton>
                    <StyledButton
                        disabled={disabled || !formDirty}
                        variant="outlined"
                        type="button"
                        onClick={handleReset}>
                        Reset
                    </StyledButton>
                </StyledButtonContainer>
            </form>
        </StyledContainer>
    );
}

SearchForm.propTypes = {
    disabled: PropTypes.bool,
    productData: PropTypes.shape({
        productMetas: PropTypes.arrayOf(PropTypes.shape({
            productKey: PropTypes.string.isRequired,
            productLabel: PropTypes.string.isRequired,
            productAttributes: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                values: PropTypes.arrayOf(PropTypes.string),
            })),
            groupKey: PropTypes.string.isRequired,
            groupLabel: PropTypes.string.isRequired,
            categoryKey: PropTypes.string.isRequired,
            categoryLabel: PropTypes.string.isRequired,
        })).isRequired,
        buildTypes: PropTypes.array.isRequired,
        oss: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            family: PropTypes.string.isRequired
        })).isRequired,
    }),
    state: PropTypes.shape({
        selectedProductKeys: PropTypes.array,
        selectedBuildTypes: PropTypes.array,
        selectedProperties: PropTypes.array,
        selectedAttributes: PropTypes.array,
        setSelectedOs: PropTypes.array,
        mainVersionSubstring: PropTypes.string,
    }).isRequired,
}
