import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Block';
import WarningIcon from '@mui/icons-material/Warning';
import AceEditor from 'react-ace';
import {useAuth} from '../../../react-auth-wrapper';
import {useNotifications} from '../../../contexts/NotificationsContext';
import useModal from '../../../hooks/useModal';
import {usePageMeta} from '../../../contexts/PageMetaContext';
import ModalDialog from '../../../components/ModalDialog';
import 'ace-builds/src-noconflict/mode-sh';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import useInterval from '../../../hooks/useInterval';
import {ADMIN_SETTINGS} from '../../../components/PathConstants';
import useClouds from '../../../hooks/useClouds';
import Paper from "@mui/material/Paper";
import {useNavigate, useParams} from "react-router-dom";
import styled from "@emotion/styled";

const StyledLineContainer = styled.div`
  padding-left: ${({theme}) => theme.spacing(2)};
  ${props => props.level === 'error' ? 'color: #FF2020;' : ''}
  ${props => props.level === 'warning' ? 'color: #FFFF20;' : ''}
  ${props => props.level === 'info' ? 'color: #20FF20;' : ''}
`;
const StyledScriptContainer = styled.div`
  font-size: 80%;
  color: #C0C0C0;
  font-family: Consolas, "Courier New", Monospace;
  background-color: black;
  height: 500px;
  padding: ${({theme}) => theme.spacing(2)};
  margin-top: ${({theme}) => theme.spacing(2)};
  max-height: 300px;
  overflow-y: auto;
`;
const StyledWarningChip = styled(Chip)`
  color: #ff9800;
  border-color: #ff9800;
`;
const StyledResultContainer = styled.div({
  display: "flex",
  justifyContent: "left",
  flexWrap: "wrap",
  "& > *": {
    margin: ({theme}) => theme.spacing(0.5)
  }
});
const StyledPaper = styled(Paper)`
  margin: 0;
  padding-top: ${({theme}) => theme.spacing(2)};
  padding: ${({theme}) => theme.spacing(1)};
`;
const StyledTypography = styled(Typography)`
  padding: ${({theme}) => theme.spacing(2)};
  padding-left: 8px;
`;
const StyledInputBaseContainer = styled.div`
  overflow-y: auto;
`;
const StyledInputBase = styled(InputBase)`
  font-family: Consolas, Monaco, "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace;
`;
const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-left: 8px;
  margin-right: ${({theme}) => theme.spacing(2)};
`;
let currentInitScript = '';

function ShellCheckLine({item, selectAceLine}) {
  return (
    <div>
      <a href="#" onClick={() => selectAceLine(item.line, item.column)}>
        Line
        {item.line}
        :
      </a>
      <StyledLineContainer level={item.level}>
        <a
          href={`https://github.com/koalaman/shellcheck/wiki/SC${item.code}`}
          target="_blank"
        >
          SC
          {item.code}
        </a>
        :
        {item.message}
      </StyledLineContainer>
    </div>
  );
}

function ShellCheckConsole({items, selectAceLine}) {
  const output = items.map(item => (
    <ShellCheckLine
      item={item}
      selectAceLine={selectAceLine}
    />
  ));

  return (
    <StyledScriptContainer>
      $ shellcheck myscript
      <br/>
      {output}
    </StyledScriptContainer>
  );
}

export default function VmStartupScript() {
  const {cloudKey} = useParams();
  const navigate = useNavigate();
  const [reloadInterval] = useState(3 * 1000);
  const {isShowing, toggle} = useModal();
  const [script, setScript] = useState('');
  const {callApi} = useAuth();
  const {notificator, notify, clearNotificator} = useNotifications();
  const {setTitle, setCloudKey} = usePageMeta();
  const [previewScript, setPreviewScript] = React.useState('');
  const [checkIndicators, setCheckIndicators] = React.useState([]);
  const [shellCheckItems, setShellCheckItems] = useState([]);
  const [scriptEdited, setScriptEdited] = useState(false);
  const cancel = {};
  const {
    clouds,
    validCloudKey
  } = useClouds(cloudKey, 'vmInitScriptApiCallNotificator', cancel, '/swc/api/vm-init-script/meta');

  const aceEditorRef = useRef();

  const handleOnAceChange = (newValue) => {
    setScript(newValue);
    setScriptEdited(true);
  };

  const handleSelectAceLine = (line, column) => {
    if (aceEditorRef.current) {
      aceEditorRef.current.editor.gotoLine(line, column, true);
      aceEditorRef.current.editor.scrollToLine(line, true, true, () => {
      });
      aceEditorRef.current.editor.gotoLine(line, column, true);
    }
  };

  const setAceAnnotations = (items) => {
    const annotations = items.map(item => ({
      row: item.line - 1,
      column: item.column,
      text: `SC${item.code}: ${item.message}`,
      type: item.level,
    }));
    if (aceEditorRef.current) {
      aceEditorRef.current.editor.getSession().setAnnotations(annotations);
    }
  };

  function callShellcheck(scriptToCheck) {
    setScriptEdited(false);
    callApi(`/swc/api/vm-init-script/${validCloudKey}/shellcheck`,
      {method: 'POST', body: scriptToCheck},
      cancel)
      .then((checkResult) => {
        setShellCheckItems(checkResult.results);
        setAceAnnotations(checkResult.results);
      }).catch((error) => {
      if (error.message !== 'Cancelled') {
        console.log('[VmStartupScript] shellcheck call failed', error);
        notify('vmInitScriptApiCallNotificator', {
          text: 'Error while calling shellcheck:',
          error,
          type: 'error',
          handleClose: () => {
          },
        });
      }
    });
  }

  useInterval(() => {
    if (scriptEdited && validCloudKey) {
      callShellcheck(script);
    }
  }, reloadInterval);

  function redirectToValidCloudIfNeeded() {
    if (validCloudKey !== cloudKey && validCloudKey !== null) {
      // CLOUD-1927
      // const target = `${ADMIN_SETTINGS.vmStartupScript.path}/${validCloudKey}`;
      // console.log(`[VmStartupScript] redirecting to ${target}`);
      // navigate(target);
    }
  }

  useEffect(() => {
    clearNotificator('vmInitScriptApiCallNotificator');
    redirectToValidCloudIfNeeded();
    if (validCloudKey) {
      setCloudKey(validCloudKey);
      const validCloudLabel = (clouds || []).find(cloud => cloud.key === validCloudKey)?.label;
      setTitle(`Administration - VM Startup Script - ${validCloudLabel}`);
      callApi(`/swc/api/vm-init-script/${validCloudKey}/latest`, {method: 'GET'}, cancel)
        .then((vmInitScript) => {
          currentInitScript = vmInitScript.content;
          setScript(vmInitScript.content);
          callShellcheck(vmInitScript.content);
        }).catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('vmInitScriptApiCallNotificator',
            {
              text: 'Error while getting startup script:',
              error,
              type: 'warning',
              handleClose: () => {
              },
            });
        }
      });
    }
  }, [validCloudKey]);

  useEffect(() => () => {
    if (cancel && cancel.doCancel) cancel.doCancel();
  }, []);

  function saveVmInitScriptTemplate() {
    if (currentInitScript === script) {
      console.log('Same script, no need to save');
      toggle();
      return;
    }
    callApi(`/swc/api/vm-init-script/${validCloudKey}`,
      {
        method: 'POST',
        body: script,
      }, cancel)
      .then(() => {
        currentInitScript = script;
        toggle();
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('vmInitScriptApiCallNotificator',
            {
              text: 'Error while adding new script:',
              error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });
  }

  function cancelScriptTemplateChanges() {
    setScript(currentInitScript);
    callShellcheck(currentInitScript);
  }

  function validateInitScriptTemplate() {
    const vmInitScriptContent = script;
    setCheckIndicators([]);
    callApi(`/swc/api/vm-init-script/${validCloudKey}/preview`, {
      method: 'POST',
      body: vmInitScriptContent,
    }, cancel)
      .then((response) => {
        setPreviewScript(response.scriptPreview);
        setCheckIndicators(response.checkIndicators);
        toggle();
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setCheckIndicators([]);
          notify('vmInitScriptApiCallNotificator',
            {
              text: 'Error while validating VM startup script template:',
              error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });
  }

  function getIndicator(checkIndicator) {
    if (checkIndicator.state === "ERROR") {
      return <Chip variant="outlined"
                   label={checkIndicator.name}
                   color="secondary"
                   icon={<ErrorIcon/>}/>
    } else if (checkIndicator.state === "WARNING") {
      return <StyledWarningChip variant="outlined"
                                label={checkIndicator.name}
                                color="secondary"
                                icon={<WarningIcon/>}/>
    } else {
      return <Chip variant="outlined"
                   label={checkIndicator.name}
                   color="primary"
                   icon={<DoneIcon/>}/>
    }
  }

  function renderTemplateValidationResult() {
    const checkResult = checkIndicators.map(checkIndicator => getIndicator(checkIndicator));

    return (
      <StyledResultContainer>
        {checkResult}
      </StyledResultContainer>
    );
  }

  function renderDescription() {
    return (
      <Box color="secondary">
        This is the template used for creating the VM init script. It uses
        {' '}
        <a href="https://github.com/mustache/mustache.github.com" target="_blank">mustache</a>
        {' '}
        and the following variables will be replaced:
        <br/>
        {'{{ userName }}'}
        ,
        {' '}
        {'{{{ sshKeys }}}'}
        ,
        {' '}
        {'{{ mustacheCompileTime }}'}
        ,
        {' '}
        {'{{ homeVolumeId }}'}
        ,
        {' '}
        {'{{ cloudId }}'}
        ,
        {' '}
        {'{{ instanceName }}'}
      </Box>
    );
  }

  function scriptSet() {
    return script && script.length > 0 && script.replace(/\s/g, '') !== '';
  }

  return (
    <StyledPaper square>
      {notificator('vmInitScriptApiCallNotificator')}
      <CssBaseline/>
      <Typography component="div">
        <StyledTypography variant="subtitle1">
          {renderDescription()}
        </StyledTypography>
        <ModalDialog
          maxWidth="lg"
          fullWidth
          open={isShowing}
          toggle={toggle}
          title="Validation Result"
        >
          {renderTemplateValidationResult()}
          <hr/>
          <Typography variant="h6">
            Script preview
          </Typography>
          <StyledInputBaseContainer>
            <StyledInputBase
              multiline
              fullWidth
              rowsMax="30"
              value={previewScript}
              inputProps={{
                readOnly: true,
              }}
            />
          </StyledInputBaseContainer>
          <hr/>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={saveVmInitScriptTemplate}
          >
            Save
          </StyledButton>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={toggle}
          >
            Close
          </StyledButton>
        </ModalDialog>
        <AceEditor
          ref={aceEditorRef}
          minLines={30}
          width="100%"
          placeholder="Paste your start up script here"
          mode="sh"
          theme="github"
          name="shellEditor"
          setOptions={{
            showInvisibles: true,
            newLineMode: 'unix',
          }}
          onChange={handleOnAceChange}
          fontSize={14}
          showPrintMargin
          showGutter
          highlightActiveLine
          value={script}
        />
        <ShellCheckConsole items={shellCheckItems} selectAceLine={handleSelectAceLine}/>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <StyledButton
            variant="outlined"
            color="primary"
            disabled={!scriptSet()}
            onClick={validateInitScriptTemplate}
          >
            Validate
          </StyledButton>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={cancelScriptTemplateChanges}
          >
            Cancel
          </StyledButton>
        </Grid>
      </Typography>
    </StyledPaper>
  );
}
