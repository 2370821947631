import React from 'react';
import OverviewList from '../../../../components/OverviewList';


export default function TargetBucketSelection({ buckets, clickCallBack }) {

  return (
    <OverviewList
      items={buckets || []}
      title={null}
      clickCallBack={clickCallBack}
      cardButtonCaption={'Select'}
      buttonCaption="Select"
      pathPrefix="/download/"
      keyPropName="bucketId"
      labelPropName="label"
      descrPropName="description"
      iconPropName="icon"
    />
  );
}
