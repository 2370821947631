import {Fab} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import {usePageMeta} from "../../../../contexts/PageMetaContext";
import React from "react";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TaskDateInfo from "./TaskDateInfo";
import styled from "@emotion/styled";
import FormattedDateTime from "../../../../components/FormattedDateTime";

const StyledFab = styled(Fab)`
  background-color: ${props => props.color};
`;
const StyledPaper = styled(Paper)`
  box-shadow: none;
`;
const StyledListItem = styled(ListItem)`
  margin-top: ${props => props.index === 0 ? '0px' : '20px'};
  cursor: pointer;
`;
const TaskStatus = ({status}) => {
  const getIcon = (color, iconComp) => {
    return <StyledFab size='small' color={color}>{iconComp}</StyledFab>;
  }
  const userInputIcon = getIcon('#f5f5f5', <SettingsIcon fontSize='small'/>);
  const progressIcon = getIcon('#03a9f4', <DonutLargeIcon fontSize='small'/>);
  const completedIcon = getIcon('#4caf50', <CheckCircleOutlineIcon fontSize='small'/>);
  const errorIcon = getIcon('#ff1744', <ErrorOutlineIcon fontSize='small'/>);
  const cancelIcon = getIcon('#757575', <CancelIcon fontSize='small'/>);

  const getState1 = () => {
    switch (status) {
      case 'CONFIRMED':
      case 'PUBLISHING':
      case 'CANCELLED':
      case 'PUBLISHED':
      case 'FAILURE':
        return {icon: completedIcon, name: 'Completed'};
      case 'INITIALIZED':
      case 'PARAMETRIZED':
      case 'UPLOADING':
      default:
        return {icon: userInputIcon, name: 'Editable'};
    }
  }

  const getState2 = () => {
    switch (status) {
      case 'CONFIRMED':
      case 'PUBLISHING':
        return {icon: progressIcon, name: 'In Progress'};
      case 'CANCELLED':
        return {icon: cancelIcon, name: 'Cancelled'};
      case 'PUBLISHED':
        return {icon: completedIcon, name: 'Completed'};
      case 'FAILURE':
        return {icon: errorIcon, name: 'Error'};
      case 'INITIALIZED':
      case 'PARAMETRIZED':
      case 'UPLOADING':
      default:
        return {icon: userInputIcon, name: 'Waiting for user input'};
    }
  }

  const state1 = getState1();
  const state2 = getState2();
  return (
    <div>
      <Tooltip title={`User Input: ${state1.name}`}>{state1.icon}</Tooltip> - <Tooltip
      title={`Final Result: ${state2.name}`}>{state2.icon}</Tooltip>
    </div>
  );
}

export default function TaskDetails({task, productMeta, moreDetails = false, index}) {
  const {goTo} = usePageMeta();

  const Prop = ({children}) => {
    return (<b>{children}</b>);
  }

  const getAttributes = () => {
    if (task?.parameter?.attributes) {
      const attributesMap = new Map(Object.entries(task.parameter.attributes));
      const currentSelectedAttribute = attributesMap.entries().next().value || [];
      const [_, values] = currentSelectedAttribute;
      const value = values && values.length > 0 ? values[0] : null;
      return value;
    }
    return null;
  }

  const showDetailsHandler = () => {
    if (!moreDetails) {
      goTo(`/admin/product-upload/start/${task.id}`);
    }
  }

  return (<React.Fragment key={`fragment-${task.id}`}>
    <StyledListItem
      onClick={showDetailsHandler}
      key={`list-item-${task.id}`}
      divider
      index={index}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid key={`task_status_${task.id}`} xs={2} item>
              <StyledPaper><TaskStatus status={task.state}/></StyledPaper>
            </Grid>
            <Grid key={`task_detail_${task.id}`} xs={10} item>
              <StyledPaper>
                <TaskDateInfo lastModified={task.lastModifiedDate} status={task.state}/>
                <div><Prop>User:</Prop> {task.ownerEmail}</div>
                {moreDetails && <div><Prop>Category:</Prop> {productMeta?.category?.label}</div>}
                <div><Prop>Product:</Prop> {productMeta?.product?.label}</div>
                <div><Prop>Build Type:</Prop> {productMeta?.buildType?.label}</div>
                <div><Prop>Version:</Prop> {productMeta?.version}</div>
                <div><Prop>License:</Prop> {productMeta?.license}</div>
                <div><Prop>Release Date:</Prop>{' '}
                  <Tooltip title={productMeta?.releaseDate || ''}>
                    <span>
                      <FormattedDateTime dateTimeString={productMeta?.releaseDate}/>
                    </span>
                  </Tooltip>
                </div>
                <div><Prop>Build Attribute:</Prop> {getAttributes()}</div>
                {moreDetails && <div><Prop>OS Target:</Prop> {productMeta?.osVersion?.label}</div>}
                {moreDetails && <div><Prop>Customer specific private
                  release:</Prop> {productMeta?.buildAudienceList?.map(ba => ba.label).join(', ')}</div>}
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledListItem>
  </React.Fragment>);
}
