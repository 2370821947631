import React from 'react';
import {Description} from '@mui/icons-material';
import {IconTextButton} from "../IconTextButton";
import useDownloader, {OPEN_WINDOW} from "./downloader";
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import {SWC_PROP_TYPES} from "../../SwcPropTypes";
import MenuItem from "@mui/material/MenuItem";

export default function ReleaseNotesButton({
                                             productMeta, versionObj, disabled = false,
                                           }) {
  let releaseNotesContent = null;

  if (versionObj && !versionObj.betaType && versionObj.releaseNotes && versionObj.releaseNotes.length > 0) {
    if (versionObj.releaseNotes && versionObj.releaseNotes.length === 1) {
      const note = (versionObj.releaseNotes)[0];
      releaseNotesContent = (<SingleReleaseNoteButton
        productMeta={productMeta}
        versionObj={versionObj}
        fileName={note}
        disabled={disabled}
      />);
    } else if (versionObj.releaseNotes && versionObj.releaseNotes.length > 1) {
      releaseNotesContent = <ReleaseNotesPopupMenu
        productMeta={productMeta}
        versionObj={versionObj}
        disabled={disabled}
      />
    }
  }

  return releaseNotesContent;
}

function SingleReleaseNoteButton({
                                   productMeta, versionObj, fileName, label = "Release Notes", closeCallback = () => {
  }
                                 }) {

  const {productKey, downloadType} = productMeta

  const {handleClick, loading} = useDownloader({
    productKey, versionObj, downloadType, fileName, mode: OPEN_WINDOW
  });

  const handleIconTextButtonClick = () => {
    closeCallback();
    handleClick();
  }

  return productKey && downloadType && versionObj ? (<IconTextButton
    label={label}
    tooltip={fileName}
    onClick={handleIconTextButtonClick}
    disabled={loading}
    startIcon={<Description/>}
  />) : null;
}

SingleReleaseNoteButton.propTypes = {
  productMeta: SWC_PROP_TYPES.productMeta.isRequired,
  versionObj: SWC_PROP_TYPES.version.isRequired,
  fileName: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

function ReleaseNotesPopupMenuItem({
                                     productMeta, versionObj, fileName, label = "Release Notes", closeCallback = () => {
  }
                                   }) {

  const {productKey, downloadType} = productMeta

  const {handleClick, loading} = useDownloader({
    productKey, versionObj, downloadType, fileName, mode: OPEN_WINDOW
  });

  const handleIconTextButtonClick = () => {
    closeCallback();
    handleClick();
  }

  return productKey && downloadType && versionObj ? (<MenuItem onClick={handleIconTextButtonClick}>
    <IconTextButton
      label={label}
      tooltip={fileName}
      onClick={handleIconTextButtonClick}
      disabled={loading}
      startIcon={<Description/>}
    />
  </MenuItem>) : null;
}

ReleaseNotesPopupMenuItem.propTypes = {
  productMeta: SWC_PROP_TYPES.productMeta.isRequired,
  versionObj: SWC_PROP_TYPES.version.isRequired,
  fileName: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};


function ReleaseNotesPopupMenu({productMeta, versionObj, disabled = false}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  const popupItems = versionObj.releaseNotes.map((fileName) => <ReleaseNotesPopupMenuItem
    key={fileName}
    label={fileName}
    productMeta={productMeta}
    versionObj={versionObj}
    fileName={fileName}
    closeCallback={handleMenuClose}
    disabled={disabled}
  />);

  const content = (<React.Fragment>
    <IconTextButton
      label={"Release Notes"}
      tooltip={"Release Notes"}
      disabled={disabled}
      onClick={handleMenuClick}
      startIcon={<Description/>}
    />
    <Menu
      sx={{
        marginTop: '160px',
      }}
      id="menu-appbar"
      disabled={disabled}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom', horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom', horizontal: 'right',
      }}
      open={open}
      onClose={handleMenuClose}
    >
      {popupItems}
    </Menu>
  </React.Fragment>);

  return content;
}

ReleaseNotesPopupMenu.propTypes = {
  productMeta: SWC_PROP_TYPES.productMeta.isRequired,
  versionObj: SWC_PROP_TYPES.version.isRequired,
  disabled: PropTypes.bool,
};
