/* eslint-disable max-len */
import React from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Typography from '@mui/material/Typography';
import prettyBytes from 'pretty-bytes';
import Tooltip from '@mui/material/Tooltip';
import ListWrapper from '../../../../components/ListWrapper';
import ScrollableList from '../../../../components/ScrollableList';
import {BuildSelection} from '../../../../components/BuildDetail/BuildSelection';
import OPERATIONS_META, {COPY_FILES, GENERATE_ARCHIVE} from "./operationsMeta";
import styled from "@emotion/styled";

const StyledTypography = styled(Typography)`
  margin-bottom: ${({theme}) => theme.spacing(1)};
`;
const StyledStrongContainer = styled.span`
  font-weight: bold;
`;
const StyledContainer = styled.div`
  margin-left: ${({theme}) => theme.spacing(3)};
  margin-right: ${({theme}) => theme.spacing(3)};
  padding-left: ${({theme}) => theme.spacing(1)};
  padding-right: ${({theme}) => theme.spacing(1)};
`;
const StyledListWrapperContainer = styled.div`
  margin: ${({theme}) => theme.spacing(3)};
  padding: ${({theme}) => theme.spacing(1)};
`;

const headRow = [
  {id: 'data', numeric: false, disablePadding: false, label: ''},
];

const createSelectionContainer = selection => [
  <BuildSelection selection={selection}/>,
];

export default function OperationConfirmation({bucket, productVersionSelections, operationSelections}) {
  const rawProductLabelList = (productVersionSelections || []).map(selection => selection.product.productLabel);
  const productLabelList = [...new Set(rawProductLabelList)].sort();
  const size = (productVersionSelections || []).reduce((acc, selection) => acc + (selection.size || 0), 0);
  const prettySize = prettyBytes(size, {binary: true});

  console.debug('[OperationConfirmation] products', productLabelList, `size ${size}`, operationSelections);

  const selectionsByProductLabelIndex = (productVersionSelections || []).reduce((acc, selection) => {
    const key = selection.product.productLabel;
    if (!acc.get(key)) {
      acc.set(key, []);
    }
    acc.get(key).push(selection);
    return acc;
  }, new Map());

  const operationsList = OPERATIONS_META
    .filter(item => operationSelections.includes(item.operation))
    .map(item => (<li key={item.operation}>{item.label}</li>));

  const bucketInfo = operationSelections.includes(COPY_FILES) || operationSelections.includes(GENERATE_ARCHIVE) ? (
    <React.Fragment>
      <StyledStrongContainer>
        {' '}{bucket?.label}{' '}
      </StyledStrongContainer>
      {'will be used as target bucket.'}
    </React.Fragment>
  ) : null;

  console.debug('[OperationConfirmation] product selections', selectionsByProductLabelIndex);

  return (
    <React.Fragment>
      <StyledContainer>
        <StyledTypography
          component="div"
          variant="h6"
        >
          Confirmation
        </StyledTypography>
        <StyledTypography
          component="div"
          variant="body2"
        >
          Please confirm that the subsequent operation(s) should take place:
          <ul>{operationsList}</ul>
          {' '}
          {bucketInfo}
          {' '}
          Depending on the selected operations, at least
          {' '}
          <Tooltip title={`${size} bytes`}>
            <StyledStrongContainer>
              {prettySize}
            </StyledStrongContainer>
          </Tooltip>
          {' '}
          will be transferred/deleted.
        </StyledTypography>
      </StyledContainer>
      {
        productLabelList.map((productLabel) => {
          const selections = selectionsByProductLabelIndex.get(productLabel);
          return (
            <StyledListWrapperContainer key={productLabel}>
              <ListWrapper>
                <ScrollableList
                  title={productLabel}
                  headRow={headRow}
                  showHeadRow
                  items={selections}
                  itemContainer={createSelectionContainer}/>
              </ListWrapper>
            </StyledListWrapperContainer>
          );
        })
      }
    </React.Fragment>
  );
}
