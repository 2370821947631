import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {formatDate} from "../FormattedDateTime";
import Button from "@mui/material/Button";
import BuildOSCell from "./table/BuildOSCell";
import BuildReleaseDateCell from "./table/BuildReleaseDateCell";
import BuildProductCell from "./table/BuildProductCell";
import BuildMainVersionCell from "./table/BuildMainVersionCell";
import {PROPERTIES_META} from "./searchConstants";
import BuildPropertiesCell from "./table/BuildPropertiesCell";
import BuildAttributesCell from "./table/BuildAttributesCell";
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: ${({theme}) => theme.spacing(1)};
`;
const StyledDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;
const StyledTypography = styled(Typography)`
  display: inline;
`;
const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};
  margin: ${({theme}) => theme.spacing(1)};
  display: flex;
  overflow: auto;
  flex-direction: column;
`;
const StyledList = styled(List)`
  width: 100%;
  padding-bottom: ${({theme}) => theme.spacing(4)};
  background-color: ${({theme}) => theme.palette.background.paper};
`;
const StyledCellContainer = styled.div`
  margin-right: ${({theme}) => theme.spacing(1)};
`;
const StyledListItem = styled(ListItem)`
  margin-top: ${props => props.index === 0 ? '0' : '20px'};
`;

function ListItemProductIcon({item, onClick}) {
  return (
    <ListItemAvatar onClick={event => {
      event.preventDefault();
      onClick();
    }}>
      <ListItemIcon>
        <Tooltip
          title={item.productMeta.productLabel}
          placement="bottom"
        >
          <Icon
            alt={item.productMeta.productLabel}
          >
            {item.productMeta.productIcon || ''}
          </Icon>
        </Tooltip>
      </ListItemIcon>
    </ListItemAvatar>
  );
}

function ListItemProductText({item, onClick}) {
  return <ListItemText
    onClick={event => {
      event.preventDefault();
      onClick();
    }}
    primary={
      <StyledContainer>
        <BuildProductCell item={item} clickCallback={onClick}/>
        <StyledDetails>
          <BuildMainVersionCell item={item} versionAsToolTip={true} clickCallback={onClick}/>
          <BuildPropertiesCell item={item} propertyList={PROPERTIES_META} clickCallback={onClick}/>
          <StyledCellContainer>{item.version.type}</StyledCellContainer>
          <StyledCellContainer><BuildOSCell item={item} clickCallback={onClick}/></StyledCellContainer>
          <BuildAttributesCell item={item} attributesMeta={item?.productMeta?.productAttributes || []}
                               clickCallback={onClick}/>
        </StyledDetails>
      </StyledContainer>
    }
    secondary={
      <StyledTypography
        component="span"
        variant="body2"
        color="textPrimary">
        <div>
          Published {item.version.type} build {item.version.version} for operating
          system <BuildOSCell item={item} clickCallback={onClick}/> at <BuildReleaseDateCell item={item}
                                                                                             clickCallback={onClick}/>
        </div>
      </StyledTypography>
    }
  />;
}

export default function BuildTableActivity({
                                             rows,
                                             disabled = false,
                                             pagination = {page: 0, size: 25, numberOfPages: 0, overallCount: 0},
                                             setPagination = ({page, size}) => {
                                             },
                                             onRowClicked = (row) => {
                                             },
                                           }) {
  const [groupedStreamItems, setGroupedStreamItems] = useState([]);

  useEffect(() => {
    const groupedItems = (rows || []).reduce(
      (acc, currentRow) => {
        const {version: versionObj} = currentRow.item;
        const lastElement = acc.length > 0 ? acc[acc.length - 1] : null;

        if (lastElement == null || lastElement.date !== formatDate(versionObj.releaseDate)) {
          acc.push({date: formatDate(versionObj.releaseDate), bucket: [currentRow]});
        } else {
          lastElement.bucket.push(currentRow)
        }
        return acc;
      },
      []
    );
    setGroupedStreamItems(groupedItems);
  }, [rows]);


  function handleShowMore(event) {
    event.preventDefault()
    setPagination({...pagination, page: 0, size: (pagination.size || 25) + 25});
  }

  const mapDateBucket = (date, bucket, index) => (
    <React.Fragment key={`fragment-${index}-${date}`}>
      <StyledListItem
        key={`list-item-date-${index}-${date}`}
        divider
        index={index}>
        <ListItemText primary={date}/>
      </StyledListItem>
      {
        bucket.map((row, bucketRowIndex) => {
            const handleClicked = () => onRowClicked(row);
            return (
              <React.Fragment key={`list-item-build-${index}-${date}-${bucketRowIndex}`}>

                {bucketRowIndex > 0 ? (<Divider variant="inset" component="li"/>) : null}

                <ListItem
                  alignItems="flex-start"
                  onClick={handleClicked}>
                  <ListItemProductIcon item={row.item} onClick={handleClicked}/>
                  <ListItemProductText item={row.item} onClick={handleClicked}/>
                </ListItem>
              </React.Fragment>
            );
          }
        )
      }
    </React.Fragment>
  );

  let hasMoreButton = null;
  if (rows.length < pagination.overallCount) {
    hasMoreButton = (
      <Button
        variant="outlined"
        onClick={handleShowMore}>
        Show more
      </Button>
    );
  }

  return groupedStreamItems.length > 0 ?
    (
      <StyledPaper>
        <StyledList>
          {groupedStreamItems.map(({date, bucket}, index) => mapDateBucket(date, bucket, index))}
        </StyledList>
        {hasMoreButton}
      </StyledPaper>
    ) : (
      <StyledPaper>
        <Typography variant="subtitle2">
          No data found
        </Typography>
      </StyledPaper>
    );
}

BuildTableActivity.propTypes = {
  disabled: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    overallCount: PropTypes.number.isRequired
  }),
  setPagination: PropTypes.func.isRequired,
  onRowClicked: PropTypes.func.isRequired,
};
