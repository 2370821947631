/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {ChonkyActions, FullFileBrowser} from 'chonky';
import * as PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import {useNotifications} from '../../contexts/NotificationsContext';
import {usePageMeta} from '../../contexts/PageMetaContext';
import {useAuth} from '../../react-auth-wrapper';
import useModal from '../../hooks/useModal';
import './fileExplorer.css';
import Backdrop from '@mui/material/Backdrop';
import LoadingIcon from '../../components/LoadingIcon';
import {Keys} from './FileExplorerPreferences';
import {ADMIN_SETTINGS} from '../../components/PathConstants';
import {useFileExplorerActions} from './FileExplorerActions';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import ModalDialog from '../../components/ModalDialog';
import FileNameDialog from '../../components/files/dialogs/FileNameDialog';
import FileUploadDialog from '../../components/files/dialogs/FileUploadDialog';
import {useParams} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import styled from "@emotion/styled";

const StyledFileBrowserContainer = styled.div({
  "& .chonky-fileListWrapper": {
    minHeight: "50rem"
  }
});

function escapePath(path) {
  let answer = '';
  if (path) {
    answer = path.replace(/\//g, ':');
    if (answer.endsWith(':')) {
      answer = answer.slice(0, -1);
    }
  }
  console.debug(`[Files] escapePath '${path}' -> '${answer}'`);
  return answer;
}

function unescapePath(path) {
  let answer = '';
  if (path) {
    answer = path.replace(/:/g, '/');
    if (!answer.endsWith('/')) {
      answer += '/';
    }
  }
  console.debug(`[Files] unescapePath '${path}' -> '${answer}'`);
  return answer;
}

function asFileData(bucketObject) {
  return !bucketObject ? null : {

    id: `bucket-object-${bucketObject.path}`,
    name: bucketObject.name || 'Home',
    ext: (bucketObject.folder ||
      !!bucketObject.name.split('.')) ? null : bucketObject.name.split('.').pop(),
    isDir: bucketObject.folder,
    selectable: true,
    draggable: true,
    droppable: bucketObject.folder,
    size: bucketObject.folder ? null : bucketObject.size,
    isHidden: bucketObject.name[0] === '.',
    modDate: bucketObject.lastModified,

    bucketObject,
  };
}

export default function FileExplorer({readOnly = false, isAdminView = true, fileExplorerPreferences}) {
  let {bucketId, path} = useParams();
  const theme = useTheme();
  bucketId = bucketId || 'service-tools.v93000.advantest.cloud';
  path = path || '';

  const {goTo} = usePageMeta();
  console.log('[Files] path', path);

  const parentPath = path ? unescapePath(path) : '';
  const [bucket, setBucket] = useState(null);

  const [bucketObjects, setBucketObjects] = useState(null);
  const [ancestorBucketObjects, setAncestorBucketObjects] = useState(null);
  const [files, setFiles] = useState([null]);
  const [folderChain, setFolderChain] = useState([]);
  const [uploadEnabled, setUploadEnabled] = useState(true);

  const [selectedBucketObjectsForPaste, setSelectedBucketObjectsForPaste] = useState([]);
  const [selectedBucketObjectForRename, setSelectedBucketObjectForRename] = useState(null);
  const [selectedBucketObjectForDelete, setSelectedBucketObjectForDelete] = useState([]);
  const [backgroundTaskRunning, setBackgroundTaskRunning] = useState(false);
  const [preferences, setPreferences] = fileExplorerPreferences;

  const {callApi} = useAuth();
  const {setTitle} = usePageMeta();
  const {notificator, notify} = useNotifications();
  const cancel = {};

  const {isShowing: visibleDialogCreateFolder, toggle: toggleVisibleDialogCreateFolder} = useModal();
  const {isShowing: visibleDialogRenameFile, toggle: toggleVisibleDialogRenameFile} = useModal();
  const {isShowing: visibleDialogUploadFile, toggle: toggleVisibleDialogUploadFile} = useModal();
  const {isShowing: visibleDialogDeleteFile, toggle: toggleVisibleDialogDeleteFile} = useModal();

  const currentFolderBucketObject = () => ancestorBucketObjects && ancestorBucketObjects.slice(-1) && ancestorBucketObjects.slice(
    -1)[0];

  const {fileActions} = useFileExplorerActions(
    preferences,
    selectedBucketObjectsForPaste,
    readOnly,
    uploadEnabled
  );

  const handleFileAction = React.useCallback(
    (action /* FileAction + FileActionData */) => {
      console.log(`[Files] event ${action.id}, #elementsForPaste=${selectedBucketObjectsForPaste.length}, ancestorBucketObjects=,`,
        ancestorBucketObjects, ', action=', action);

      const selectedFiles = action.payload ? action.payload.files : action.state.selectedFiles;
      const selectedSortedFiles = selectedFiles ? selectedFiles.sort((f1, f2) => f1.name.localeCompare(f2.name)) : [];
      const selectedBucketObjects = selectedSortedFiles.map(file => file.bucketObject);

      if (action.id === ChonkyActions.OpenFiles.id) {
        const selectedFolder = selectedBucketObjects.find(bo => bo.folder);
        const selectedFiles = selectedBucketObjects.filter(bo => !bo.folder);
        if (selectedFolder) {
          handleNavigateToFolder(selectedFolder);
        } else if (selectedFiles) {
          handleDownload(...selectedFiles);
        }
      }
      if (action.id === ChonkyActions.UploadFiles.id) {
        toggleVisibleDialogUploadFile();
      }
      if (action.id === ChonkyActions.DeleteFiles.id) {
        console.log('[Files] deleting ', selectedBucketObjects.map(bo => bo.path));
        setSelectedBucketObjectForDelete(selectedBucketObjects);
        toggleVisibleDialogDeleteFile();
      }
      if (action.id === ChonkyActions.DownloadFiles.id) {
        const selectedFileBucketObjects = selectedBucketObjects.filter(bo => !bo.folder);
        handleDownload(...selectedFileBucketObjects);
      }
      if (action.id === ChonkyActions.CreateFolder.id) {
        toggleVisibleDialogCreateFolder();
      }
      if (action.id === ChonkyActions.CopyFiles.id) {
        setSelectedBucketObjectsForPaste(selectedBucketObjects);
        console.log('[Files] selected for paste', selectedBucketObjects);
      }
      if (action.id === ChonkyActions.MoveFiles.id) {
        const targetBucket = action.payload.destination.bucketObject;
        handleMove(targetBucket, ...selectedBucketObjects);
      }
      if (action.id === 'adv_paste_selection') {
        console.log('[Files] paste', selectedBucketObjectsForPaste);
        handlePaste();
      }
      if (action.id === 'adv_rename_file') {
        const selected = (selectedBucketObjects && selectedBucketObjects.length > 0) ? selectedBucketObjects[0] : null;
        console.log('[Files] selected for rename', selected);
        setSelectedBucketObjectForRename(selected);
        toggleVisibleDialogRenameFile();
      }
      if (action.id === ChonkyActions.SortFilesBySize.id) {
        setBucketObjects(previousList => [...previousList].sort((bo1, bo2) => {
          const size1 = bo1.size || 0;
          const size2 = bo2.size || 0;
          return size1 - size2;
        }));
      }
      if (action.id === ChonkyActions.SortFilesByName.id) {
        setBucketObjects(previousList => [...previousList].sort((bo1, bo2) => {
          const name1 = bo1.name.toUpperCase();
          const name2 = bo2.name.toUpperCase();
          return name1 === name2 ? 0 : (name1 < name2 ? -1 : 1);
        }));
      }
      if (action.id === ChonkyActions.SortFilesByDate.id) {
        setBucketObjects(previousList => [...previousList].sort((bo1, bo2) => {
          const lastModified1 = bo1.lastModified || '';
          const lastModified2 = bo2.lastModified || '';
          return lastModified1 === lastModified2 ? 0 : (lastModified1 < lastModified2 ? -1 : 1);
        }));
      }
      if (action.id === ChonkyActions.ToggleHiddenFiles.id) {
        setPreferences(prefs => (
          {...prefs, [Keys.SHOW_HIDDEN_FILES]: !prefs[Keys.SHOW_HIDDEN_FILES]}));
      }
      if (action.id === ChonkyActions.ToggleShowFoldersFirst.id) {
        setPreferences(prefs => (
          {...prefs, [Keys.SHOW_FOLDERS_FIRST]: !prefs[Keys.SHOW_FOLDERS_FIRST]}));
      }
      if (action.id === ChonkyActions.EnableGridView.id) {
        setPreferences(prefs => ({...prefs, [Keys.SHOW_LIST_VIEW]: false}));
      }
      if (action.id === ChonkyActions.EnableListView.id) {
        setPreferences(prefs => ({...prefs, [Keys.SHOW_LIST_VIEW]: true}));
      }
      if (action.id === ChonkyActions.OpenParentFolder.id) {
      }
      if (action.id === ChonkyActions.ChangeSelection.id) {
        console.log('[Files] selected', selectedBucketObjects);
      }
    },
    [bucket, bucketObjects, ancestorBucketObjects, selectedBucketObjectsForPaste, selectedBucketObjectForRename, path]);

  function setParentPath(bucketId, path) {
    const basePath = isAdminView ? ADMIN_SETTINGS.buckets.path : '/files';
    goTo(`${basePath}/${bucketId}/${escapePath(path)}`);
  }

  function compareFiles(fileA, fileB) {
    if (fileA.name === fileB.name) {
      return 0;
    }
    return fileA.name > fileB.name ? 1 : -1;
  }

  function handleNavigateToFolder(bucketObject) {
    const newParentPath = bucketObject.path;
    console.log('[Files]', `Altering path to ${newParentPath}`);
    setParentPath(bucketObject.bucketId, newParentPath);
  }

  function handleApiCallError(error) {
    if (error.message !== 'Cancelled') {
      notify('filesNotificator',
        {
          text: 'Error while accessing files:',
          error,
          type: 'error',
          handleClose: () => {
          },
        });
    }
  }

  function handleDownload(...args /* vararg bucketObject */) {
    function triggerDownload(downloadUrl) {
      console.log(`[Files] downloadUrl=${downloadUrl}`);
      const link = document.createElement('a');
      link.href = downloadUrl;
      document.body.appendChild(link);
      console.log(`[Files] downloadUrl=${downloadUrl} click`);
      link.click();
      console.log(`[Files] downloadUrl=${downloadUrl} remove`);
      document.body.removeChild(link);
    }

    if (args) {
      const bucketObjectList = [...args].filter(bo => !bo.folder);

      bucketObjectList.map((bucketObject) => {
        console.log(`[Files] handleDownload ${bucketObject.bucketId}#${bucketObject.path}`);

        callApi(`/swc/api/buckets/${bucketObject.bucketId}/objects/url/GET?path=${encodeURIComponent(
          bucketObject.path,
        )}`, {method: 'GET'}, cancel, false)
          .then((response) => {
            triggerDownload(response.url);
          })
          .catch(handleApiCallError)
          .finally(() => {
          });
      });
    }
  }

  function handleDelete(...args /* vararg bucketObject */) {
    if (args) {
      const bucketObjectList = Array.from(args);
      const bucketObjectPaths = bucketObjectList.map(bo => bo.path);
      const deleteParams = new URLSearchParams(bucketObjectPaths.map(boPath => ['path', boPath]));

      setBackgroundTaskRunning(true);

      callApi(`/swc/api/buckets/${bucketObjectList[0].bucketId}/objects?${deleteParams}`,
        {method: 'DELETE'},
        cancel,
        false)
        .then(() => {
          setBucketObjects(previousList => previousList.filter(aPrevious => !bucketObjectPaths.includes(aPrevious.path)));
          loadFileList();
        })
        .catch(handleApiCallError)
        .finally(() => {
          setBackgroundTaskRunning(() => false);
          setSelectedBucketObjectsForPaste([]);
          setSelectedBucketObjectForDelete([]);
          toggleVisibleDialogDeleteFile();
        });
    }
  }

  function handleRename(bucketObjectToRename, newName) {
    if (bucketObjectToRename && newName) {
      const newAbsolutePath = bucketObjectToRename.parentPath + newName + (bucketObjectToRename.folder ? '/' : '');
      const pathParam = new URLSearchParams([['path', bucketObjectToRename.path], ['newPath', newAbsolutePath]]);
      const urlRenameEndpoint = `/swc/api/buckets/${bucket.bucketId}/objects?${pathParam}`;

      setBackgroundTaskRunning(true);

      callApi(urlRenameEndpoint,
        {method: 'PUT'},
        cancel,
        false)
        .then(() => {
          setBucketObjects(previousList => previousList.map((aPrevious) => {
            if (aPrevious.path === bucketObjectToRename.path) {
              return {...aPrevious, name: newName, path: newAbsolutePath};
            }
            return aPrevious;
          }));
          toggleVisibleDialogRenameFile();
          loadFileList();
        })
        .catch(handleApiCallError)
        .finally(() => {
          setBackgroundTaskRunning(false);
        });
    }
  }

  function handleMove(...args /* vararg bucketObject */) {
    if (args) {
      const bucketObjectList = [...args];
      const [targetBucketObject, ...bucketsObjectsToMove] = bucketObjectList;
      if (targetBucketObject && bucketsObjectsToMove) {
        setBackgroundTaskRunning(true);

        const pathParam = new URLSearchParams(bucketsObjectsToMove.map(bo => ['path', bo.path]));
        const urlMoveEndpoint = `/swc/api/buckets/${bucket.bucketId}/objects?${pathParam}&targetPath=${encodeURIComponent(
          targetBucketObject.path,
        )}`;

        callApi(urlMoveEndpoint,
          {method: 'POST'},
          cancel,
          false)
          .then(() => {
            setBucketObjects(previousList => previousList.filter(aPrevious => !bucketsObjectsToMove.includes(aPrevious.path)));
            loadFileList();
          })
          .catch(handleApiCallError)
          .finally(() => {
            setBackgroundTaskRunning(false);
            setSelectedBucketObjectsForPaste([]);
          });
      }
    }
  }

  function handlePaste() {
    console.log('[Files] handlePaste', currentFolderBucketObject(), selectedBucketObjectsForPaste);
    if (currentFolderBucketObject() && selectedBucketObjectsForPaste) {
      const pathParam = new URLSearchParams(selectedBucketObjectsForPaste.map(bo => ['path', bo.path]));
      const urlPasteEndpoint = `/swc/api/buckets/${bucket.bucketId}/objects?mode=COPY&${pathParam}&targetPath=${encodeURIComponent(
        currentFolderBucketObject().path,
      )}`;

      setBackgroundTaskRunning(true);

      callApi(urlPasteEndpoint,
        {method: 'POST'},
        cancel,
        false)
        .then(loadFileList)
        .catch(handleApiCallError)
        .finally(() => {
          setSelectedBucketObjectsForPaste([]);
          setBackgroundTaskRunning(false);
        });
    }
  }


  function handleCreateFolder(folderName) {
    console.log('[Files]', folderName, ancestorBucketObjects);

    callApi(`/swc/api/buckets/${bucketId}/objects/folder?path=${encodeURIComponent(`${parentPath}${folderName}/`)}`,
      {method: 'POST'},
      cancel,
      false)
      .catch(handleApiCallError)
      .finally(() => {
        toggleVisibleDialogCreateFolder();
        loadFileList();
      });
  }

  function handleUploadedProcessed() {
    loadFileList();
    toggleVisibleDialogUploadFile();
    setBackgroundTaskRunning(false);
  }


  function loadBucketMeta() {
    setBucket(null);
    setBucketObjects(null);

    if (bucketId) {
      console.log('[Files]', `${bucketId}: Fetching bucket meta data...`);
      callApi('/swc/api/buckets',
        {method: 'GET'},
        cancel,
        false)
        .then((response) => {
          const selectBucket = response.filter(listItem => listItem.bucketId === bucketId)[0];
          console.log('[Files]', `${bucketId}: bucket`, selectBucket);
          setBucket(selectBucket);
        })
        .catch((error) => {
          if (error.message !== 'Cancelled') {
            updateFilesState([]);
            notify('filesNotificator',
              {
                text: 'Error while loading:',
                error,
                type: 'error',
                handleClose: () => {
                },
              });
          }
        })
        .finally(() => {
        });
    }
  }

  function loadFileList() {
    if (bucket && bucket.bucketId === bucketId) {
      console.log('[Files]', `${bucket.bucketId}: Fetching data..`);

      callApi(`/swc/api/buckets/${bucket.bucketId}/objects?parentPath=${
          encodeURIComponent(parentPath)}`,
        {method: 'GET'},
        cancel,
        false)
        .then((response) => {
          const {bucketObjectList, ancestorBucketObjects} = response;
          console.log('[Files]', `${bucket.bucketId}: bucketObjectList`, bucketObjectList);
          updateFilesState(bucketObjectList, ancestorBucketObjects);
        })
        .catch((error) => {
          if (error.message !== 'Cancelled') {
            updateFilesState([]);
            notify('filesNotificator',
              {
                text: 'Error while loading:',
                error,
                type: 'error',
                handleClose: () => {
                },
              });
          }
        })
        .finally(() => {

        });
    } else {
      updateFilesState([]);
    }
  }

  function updateFilesState(bucketObjectListOrNull, ancestorBucketObjects) {
    console.log('[Files]', 'bucketObjectList', bucketObjectListOrNull);
    setBucketObjects((bucketObjectListOrNull || []).sort(compareFiles));
    setAncestorBucketObjects(ancestorBucketObjects || []);
  }

  function loadUploadEnabled() {
    setUploadEnabled(false);

    if (!readOnly && bucket) {
      callApi(`/swc/api/buckets/${bucket.bucketId}/objects/url/PUT/enabled`,
        {method: 'GET'},
        cancel,
        true)
        .then(() => {
          setUploadEnabled(true);
        }).catch((error) => {
        if (error.message !== 'Cancelled') {
          console.log('[Files] upload disabled');
          setUploadEnabled(false);
        }
      });
    }
  }

  function setPageTitle() {
    const prefix = isAdminView ? 'Administration - ' : '';
    setTitle(`${prefix}${(bucket && bucket.label) ? bucket.label : ''}`);
  }


  useEffect(() => {
    loadBucketMeta();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [bucketId]);

  useEffect(setPageTitle, [bucket]);
  useEffect(loadUploadEnabled, [bucket]);

  useEffect(() => {
    loadFileList();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [bucket, path]);

  useEffect(() => {
    if (bucketObjects == null) {
      setFiles([null]);
      setFolderChain([]);
    } else {
      const folderChainList = ancestorBucketObjects.map(asFileData);
      const parentFolderBucketObject = folderChainList.slice(-2, -1).map(file => ({
        ...file, name: '..', selectable: false, isHidden: true,
      }));
      const fileList = [...bucketObjects.map(asFileData), ...parentFolderBucketObject];
      console.log('[Files]', 'folderChain=', folderChainList, 'fileList=', fileList);
      setFiles(fileList);
      setFolderChain(folderChainList);
    }
  }, [bucketObjects, ancestorBucketObjects]);

  const defaultFileViewAction = preferences[Keys.SHOW_LIST_VIEW] ? ChonkyActions.EnableListView : ChonkyActions.EnableGridView;
  return (
    <React.Fragment>
      <Backdrop sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }} open={backgroundTaskRunning}>
        <LoadingIcon/>
      </Backdrop>
      <Paper sx={{
        padding: '2rem',
        minHeight: '50rem',
      }}>
        {notificator('filesNotificator')}
        {bucket && bucketObjects && (
          <StyledFileBrowserContainer>
            <FullFileBrowser
              darkMode={theme.palette.mode === 'dark'}
              files={files}
              folderChain={folderChain}
              disableDefaultFileActions
              fileActions={fileActions}
              onFileAction={handleFileAction}
              defaultFileViewActionId={defaultFileViewAction.id}/>
            <ConfirmationDialog
              open={visibleDialogDeleteFile}
              handleSubmit={() => handleDelete(...selectedBucketObjectForDelete)}
              handleCancel={toggleVisibleDialogDeleteFile}
              title="File Deletion"
            >
              <div>
                Do you really want to delete the subsequent files or folders?
                <ul>
                  {selectedBucketObjectForDelete.map(bo => (<li key={bo.name}><i>{bo.name}</i></li>))}
                </ul>
                Please be aware that this operation can not be reverted.
              </div>
            </ConfirmationDialog>
            <ModalDialog
              open={visibleDialogCreateFolder}
              toggle={toggleVisibleDialogCreateFolder}
              title="Create Folder"
            >
              <FileNameDialog
                bucket={bucket}
                backgroundTaskRunning={backgroundTaskRunning}
                handleSubmit={folderName => handleCreateFolder(folderName)}
                handleValidate={folderName => !bucketObjects.map(bo => bo.name)
                  .includes(`${folderName}/`) && !bucketObjects.map(bo => bo.name).includes(folderName)}
                handleClose={toggleVisibleDialogCreateFolder}
              />
            </ModalDialog>
            <ModalDialog
              open={visibleDialogRenameFile}
              toggle={toggleVisibleDialogRenameFile}
              title="Rename"
            >
              <FileNameDialog
                bucket={bucket}
                backgroundTaskRunning={backgroundTaskRunning}
                handleSubmit={newName => handleRename(selectedBucketObjectForRename, newName)}
                handleValidate={folderName => !bucketObjects.map(bo => bo.name)
                  .includes(`${folderName}/`) && !bucketObjects.map(bo => bo.name).includes(folderName)}
                handleClose={toggleVisibleDialogRenameFile}
                defaultName={selectedBucketObjectForRename && selectedBucketObjectForRename.name}
              />
            </ModalDialog>
            <ModalDialog
              open={visibleDialogUploadFile}
              toggle={toggleVisibleDialogUploadFile}
              title="Upload File"
            >
              <FileUploadDialog
                bucket={bucket}
                backgroundTaskRunning={backgroundTaskRunning}
                handleUploaded={handleUploadedProcessed}
                hide={toggleVisibleDialogUploadFile}
                folderPath={parentPath}
              />
            </ModalDialog>
          </StyledFileBrowserContainer>
        )}
      </Paper>
    </React.Fragment>
  );
}

FullFileBrowser.propTypes = {
  isAdminView: PropTypes.bool,
  readOnly: PropTypes.bool,
  fileExplorerPreferences: PropTypes.arrayOf(PropTypes.func).isRequired,
};

FullFileBrowser.defaultProps = {readOnly: false, isAdminView: false};
