import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export function getComplianceLogTypeLabel(type) {
  let label = 'BUILD';
  if (type === 'STORAGE') {
    label = 'S3';
  }
  return label;
}

export default function TypeCell({type}) {

  const label = getComplianceLogTypeLabel(type);
  const tooltip = type || '';

  return label ? (
    <Tooltip title={tooltip}>
      <span>{label}</span>
    </Tooltip>
  ) : null;
}
