import { useEffect, useState } from 'react';
import { ChonkyActions, ChonkyIconName } from 'chonky';
import { Keys } from './FileExplorerPreferences';

const SwcActions = {
  pasteSelected: () => ({
    id: 'adv_paste_selection',
    requiresSelection: false,
    hotkeys: ['ctrl+v'],
    button: {
      name: 'Paste files to folder',
      icon: ChonkyIconName.dndCanDrop,
      toolbar: true,
    },
  }),
  renameFile: () => ({
    id: 'adv_rename_file',
    requiresSelection: true,
    hotkeys: [],
    button: {
      name: 'Rename',
      group: "Actions",
      icon: ChonkyIconName.model,
      toolbar: true,
    },
  }),
  showHiddenFiles: preferences => ({
    id: 'toggle_hidden_files',
    hotkeys: ['ctrl+h'],
    option: {
      id: 'show_hidden_files',
      defaultValue: preferences[Keys.SHOW_HIDDEN_FILES],
    },
    button: {
      name: 'Show hidden files',
      group: 'Options',
      toolbar: true,
    },
  }),
  showFoldersFirst: preferences => ({
    id: 'toggle_show_folders_first',
    option: {
      id: 'show_folders_first',
      defaultValue: preferences[Keys.SHOW_FOLDERS_FIRST],
    },
    button: {
      name: 'Show folders first',
      group: 'Options',
      toolbar: true,
    },
  }),
};

export const useFileExplorerActions = (preferences, selectedBucketObjectsForPaste, readOnly, uploadEnabled) => {
  const [fileActions, setFileActions] = useState([]);

  function calculateAndSetFileActions() {

    const hidePaste = !(selectedBucketObjectsForPaste && selectedBucketObjectsForPaste.length && selectedBucketObjectsForPaste.length > 0);

    const actions = [

      // See https://github.com/TimboKZ/Chonky/blob/master/src/util/file-actions-definitions.ts for available actions

      readOnly || hidePaste ? null : SwcActions.pasteSelected(),
      readOnly ? null : ChonkyActions.CreateFolder,
      readOnly || !uploadEnabled ? null : ChonkyActions.UploadFiles,
      ChonkyActions.DownloadFiles,
      readOnly ? null : ChonkyActions.CopyFiles,
      readOnly ? null : ChonkyActions.MoveFiles,
      readOnly ? null : ChonkyActions.DeleteFiles,
      readOnly ? null : SwcActions.renameFile(),
      ChonkyActions.OpenFiles,
      ChonkyActions.SelectAllFiles,
      ChonkyActions.ChangeSelection,
      ChonkyActions.ClearSelection,

      // Sorting actions
      ChonkyActions.SortFilesByName,
      ChonkyActions.SortFilesBySize,
      ChonkyActions.SortFilesByDate,

      // File views
      ChonkyActions.EnableListView,
      ChonkyActions.EnableGridView,

      // Toggleable options
      SwcActions.showHiddenFiles(preferences),
      SwcActions.showFoldersFirst(preferences),
    ].filter(item => item);

    setFileActions(actions);
  }

  useEffect(calculateAndSetFileActions,
    [
      selectedBucketObjectsForPaste,
      preferences,
      readOnly,
      uploadEnabled]);

  return { fileActions };
};
