import React, { useEffect, useState } from 'react';
import { useNotifications } from '../../contexts/NotificationsContext';
import LoadingIcon from '../../components/LoadingIcon';
import { useAuth } from '../../react-auth-wrapper';

export default function VMOverview({ detailKey }) {

  const [meta, setMeta] = useState({});
  const [overviewData, setOverviewData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { callApi } = useAuth();
  const { notify } = useNotifications();
  const cancel = {};

  function calculateOverviewData(instances) {
    const data = {};
    instances.forEach((vm) => {
      if (!(vm.regionKey in data)) {
        data[vm.regionKey] = 0
      }
      data[vm.regionKey]++
    })
    setOverviewData(data);
  }

  function getRegionName(regionKey) {
    const currentRegion = meta.regions.filter(region => region.key === regionKey)[0];
    return currentRegion ? currentRegion.label : regionKey;
  }

  function loadVmInstances() {
    console.log('[VMOverview]', 'Fetching data...');

    callApi(`/swc/api/clouds/${detailKey}/meta`, { method: 'GET' }, cancel)
    .then((response) => {
      console.log('[VmInstances]', 'meta', response);
      setMeta(response);
      return callApi(`/swc/api/clouds/${detailKey}/vms`,
        { method: 'GET' }, cancel);
    })
    .then((response) => {
      console.log('[VMOverview]', 'instances', response);
      calculateOverviewData(response.instanceList);
    })
    .catch((error) => {
      console.log('[VMOverview] response error', error);
      if (error.message !== 'Cancelled') {
        setMeta({});
        notify('instancesOverviewNotificator',
          {
            text: "Error while loading VM instances:",
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    })
    .finally(() => {
      setDataLoaded(true);
    });
  }

  function vmOverviewInfo(vmsCount, index, regionKey) {
    const suffix = vmsCount > 1 ? 's' : '';
    return <div key={index}>{vmsCount} VM{suffix} in {getRegionName(regionKey)}</div>
  }

  function OverviewData({ data }) {

    let result = <div>No VMs created</div>
    if (Object.keys(data).length > 0) {
      result = Object.keys(data).map((regionKey, index) => vmOverviewInfo(data[regionKey],
        index,
        regionKey))
    }

    return result;
  }

  useEffect(loadVmInstances, [])

  return (<React.Fragment>
    {dataLoaded && overviewData && <OverviewData data={overviewData}/>}
    {!dataLoaded && <LoadingIcon size={16} inline/>}
  </React.Fragment>)
}
