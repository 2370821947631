import AdminCloudSettingsTabs from "../../menu/admin/AdminCloudSettingsTabs";
import ScrollableList from "../../components/ScrollableList";
import Button from "@mui/material/Button";
import ModalDialog from "../../components/ModalDialog";
import React, {useEffect, useState} from "react";
import {useNotifications} from "../../contexts/NotificationsContext";
import useModal from "../../hooks/useModal";
import ManageNotificationDialog from "../../components/notifications/ManageNotificationDialog";
import {usePageMeta} from "../../contexts/PageMetaContext";
import {useAuth} from "../../react-auth-wrapper";
import NotificationActions from "../../components/notifications/NotificationsActions";
import {ADMIN_SETTINGS} from '../../components/PathConstants';
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

const StyledPre = styled.pre`
  margin-left: 5em;
  margin-right: 5em;
  padding: 1em;
  border-style: dotted;
`;

const mustacheTemplate = `{{#ITEMS_INDEXED_BY_DATE}}
==== {{{first}}} ===

{{#second}}
{{{productLabel}}}
{{{mainVersion}}} {{{type}}} {{{os}}}
Published {{{type}}} build {{{classifier}}}{{#os}} for operating system {{{os}}}{{/os}} at {{{releaseDateTimeFormatted}}}
{{{productUrl}}}

{{/second}}

{{/ITEMS_INDEXED_BY_DATE}}`;

const embeddedLinkExample = `
  <a
    class ="signed-url"
    data-bucket-id  ="swc.non-sw-test.t2k.advantest.cloud"
    data-path ="Compatiblity List (mock) EN.pdf"
    target="_blank" href="#">

        link text
  </a>
`

export default function Notifications() {

  const [notifications, setNotifications] = useState([]);
  const [editableNotification, setEditableNotification] = useState(undefined);
  const {notify, notificator} = useNotifications();
  const {isShowing, toggle} = useModal();
  const {setTitle} = usePageMeta();
  const {callApi} = useAuth();
  const cancel = {};

  const headRow = [
    {id: 'path', numeric: false, disablePadding: false, label: 'Path'},
    {id: 'value', numeric: false, disablePadding: false, label: 'Value'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Actions'}
  ];

  const createNotificationsContainer = (notification) => {
    return [
      notification.path,
      <div dangerouslySetInnerHTML={{__html: notification.value}}/>,
      <NotificationActions notification={notification}
                           editHandler={openNotificationDialog}
                           afterActionHandler={getNotifications}
                           key={notification.id}/>
    ]
  };

  const openNotificationDialog = (notification = undefined) => {
    setEditableNotification(notification);
    toggle();
  };

  const getNotifications = () => {
    callApi(`/swc/api/notifications/type/default`, {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[Notifications]', response);
        setNotifications(response.values);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setNotifications([]);
          notify('notificationErrors', {
            text: "Error while loading notifications:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
  };

  const createNotification = (event, notification) => {
    console.log('[Notifications] createNotification called', notification);
    manageNotification(event, notification, 'POST')
  };

  const updateNotification = (event, notification) => {
    console.log('[Notifications] updateNotification called', notification);
    manageNotification(event, notification, 'PUT')
  };

  const manageNotification = (event, notification, method) => {
    event.preventDefault();
    toggle();

    callApi(`/swc/api/notifications/type/default`,
      {method: method, body: JSON.stringify(notification)},
      cancel)
      .then((response) => {
        console.log(`[Notifications] response for ${method}`, response);
        getNotifications();
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('notificationErrors', {
            text: `Error while notifications management (method ${method}):`,
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  };

  useEffect(() => {
    setTitle('Administration - Notifications');
    getNotifications();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  const description = (
    <React.Fragment>
      <p>
        Notifications are linked to browser URL paths. For example, you can create a notification to highlight a new
        feature of Smart-Install by using the path <code>/download</code>.
        The <i>value</i> of a notification must be a properly formatted HTML fragment.
      </p>
      <p>
        Each notification is enclosed in a styled div. There are four styles available: 'info', 'warning', 'error', and
        'success'.
        The default style is 'info'. You can choose a different style by adding a type parameter to the path,
        e.g., <code>/download?type=warning</code>.
      </p>
      <p>
        Additionally, if the URL path begins with the "^" character, it will be treated as a regular expression. In this
        case, the notification will match
        any browser path that satisfies the regex pattern. For example, the value
        <StyledPre><code>^\/download\/t5ksw\/builds\/swc\.systemsoftware-test\.t5k\.advantest\.cloud\/swc\.systemsoftware-test\.t5k\.advantest\.cloud:futuresuitet5801(\/.*)?$?type=warning</code></StyledPre>
        will match exactly the download pages of the <code>futuresuitet5801</code> product.
        Please note that in the example above, <code>?type=warning</code> is not part of the regex. This postfix will be
        stripped away before the regex is applied.
      </p>
      <Typography variant="subtitle2">Confirmation dialogs</Typography>
      <p>
        The notification mechanism can also be used to display a disclaimer before downloading a software artifact. To
        do this, use one of the following paths:
        <ul>
          <li><code>#confirmations/download/product/&lt;PRODUCTKEY&gt;/type/&lt;TYPE&gt;</code></li>
          <li><code>#confirmations/download/product/&lt;PRODUCTKEY&gt;</code></li>
          <li><code>#confirmations/download/type/&lt;TYPE&gt;</code></li>
        </ul>
        as path, where
        <ul>
          <li><i>&lt;PRODUCTKEY&gt;</i> must be a product key, such as <code>smartestaddons:fpga</code> for FPGA, and
          </li>
          <li><i>&lt;TYPE&gt;</i> must be set to one of the following
            values: <i>RELEASE</i>, <i>CS</i>, <i>BETA</i>, <i>CSBETA</i>.
          </li>
        </ul>
        <br/>
        Please keep in mind that the most specific notification will be displayed if multiple confirmation patterns
        match.
        <br/>
        Also note that if a license term is defined for product, <strong>no notification</strong> may be shown on the
        product detail view.
      </p>
      <Typography variant="subtitle2">Embedded Links to Explore Buckets</Typography>
      <p>
        A notification may contain a link to a bucket. To do this, use the following pattern:
        <StyledPre><code>{embeddedLinkExample}</code></StyledPre>
        The anchor tag must have the class <code>signed-url</code> and include the
        attributes <code>data-bucket-id</code> and <code>data-path</code>. These attributes specify the bucket
        and the path within the bucket, respectively.
      </p>
      <Typography variant="subtitle2">Email templates</Typography>
      <p>
        The notification mechanism can also serve as a source for email templates (using Mustache). To do this, you must
        use
        <code>@mail/subscription/&lt;TYPE&gt;.mustache</code> as the path,
        where <i>&lt;TYPE&gt;</i> should be set to one of the following values:
        <i>SUBJECT</i>, <i>BODY_HEADER</i>, <i>BODY</i>, <i>BODY_FOOTER</i>.
        <br/>

        The following variables are available in the Mustache templates:
        <i>USER_EMAIL_ADDRESS</i>, <i>SUBSCRIPTION_TYPE</i> (DAILY, WEEKLY, MONTHLY), <i>SUBSCRIPTION_ID</i>,
        <i>ITEMS_INDEXED_BY_DATE</i>, <i>ITEMS</i>, <i>SWC_BASE_URL</i>, <i>SWC_VERSION</i>, <i>SWC_CONTAINER_ID</i>,
        <i>SWC_MAIL_CREATION_TIMESTAMP_FORMATTED</i>.

        E.g. <code>@mail/subscription/BODY.mustache</code>
        <StyledPre><code>{mustacheTemplate}</code></StyledPre>

      </p>
    </React.Fragment>
  );

  return (<AdminCloudSettingsTabs activeTabPath={ADMIN_SETTINGS.notifications.path}>
    {notificator("notificationErrors")}
    {notifications
      && <ScrollableList headRow={headRow}
                         items={notifications}
                         itemContainer={createNotificationsContainer}
                         showHeadRow={true}
                         title={"Notifications"}
                         description={description}/>}
    <Button
      variant="outlined"
      color="primary"
      type="submit"
      onClick={() => openNotificationDialog()}
    >
      Add notification
    </Button>
    <ModalDialog
      open={isShowing}
      toggle={toggle}
      title={editableNotification !== undefined ? "Update notification" : "Create notification"}
      maxWidth="lg"
      fullWidth="true"
    >
      <ManageNotificationDialog handleCreate={createNotification}
                                handleUpdate={updateNotification}
                                hide={toggle}
                                editableNotification={editableNotification}/>
    </ModalDialog>
  </AdminCloudSettingsTabs>)
}
