import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  StyledFileDialogButton,
  StyledFileDialogButtonContainer,
  StyledFileDialogLoadingIcon,
  StyledFileDialogTextField
} from "./dialogStyles";


const fileNamePattern = "^\.?([a-z,A-Z,0-9,_,-]+)[\\.]{0,1}([a-z,A-Z,0-9,_,-]*)$";

export default function FileNameDialog({
                                         handleValidate,
                                         handleSubmit,
                                         handleClose,
                                         backgroundTaskRunning = false,
                                         buttonLabel = 'OK',
                                         defaultName = ''
                                       }) {
  console.log("[FileNameDialog] backgroundTaskRunning=", backgroundTaskRunning);
  const [fileName, setFileName] = useState(undefined);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    setSubmitButtonEnabled(handleValidate(fileName));
  }, [fileName]);

  const onSubmit = event => {
    event.preventDefault();
    handleSubmit(fileName);
  };

  function renderButton() {
    if (backgroundTaskRunning) {
      return <StyledFileDialogLoadingIcon color="primary" size={24}/>;
    }
    return buttonLabel;
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div>
          <StyledFileDialogTextField
            required
            id="name"
            name="name"
            label="Name"
            inputProps={{pattern: fileNamePattern}}
            margin="normal"
            variant="outlined"
            onChange={event => setFileName(event.target.value)}
            defaultValue={defaultName}
          />
        </div>
        <StyledFileDialogButtonContainer>
          <StyledFileDialogButton
            variant="outlined"
            color="primary"
            type="submit"
            disabled={!submitButtonEnabled || backgroundTaskRunning}
          >
            {renderButton()}
          </StyledFileDialogButton>
          <StyledFileDialogButton
            disabled={backgroundTaskRunning}
            variant="outlined"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </StyledFileDialogButton>
        </StyledFileDialogButtonContainer>
      </form>
    </div>
  );
}

FileNameDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  defaultName: PropTypes.string,
};
