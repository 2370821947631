import React, { useEffect } from 'react';
import VmInstances from '../VmInstances';
import { usePageMeta } from '../../contexts/PageMetaContext';
import CloudTabs from '../../components/CloudTabs';
import { CLOUD_OVERVIEW } from '../../components/PathConstants';

export default function CloudVms() {

  const { setTitle } = usePageMeta();
  const cancel = {};

  useEffect(() => {
    setTitle('Administration - VM Overview');
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  return <CloudTabs firstLevelActiveTabPath={CLOUD_OVERVIEW.vms.path}
                    TabContent={VmInstances}/>
}
