import React from 'react';
import {FilterChip} from "./FilterChip";


export default function FilterChipGroupOS({osMetaList, selectedOs, setSelectedOs, disabled}) {

  const sortedOs = osMetaList.map(os => os.name); // already sorted
  const sortedSelectedOs = sortedOs.filter(os => selectedOs.indexOf(os) > -1);

  const osLabel = (os) => osMetaList.find(meta => meta.name === os)?.label || os;
  const osTooltip = (os) => osMetaList.find(meta => meta.name === os)?.description || osLabel;

  const handleDelete = (osToDelete) => {
    setSelectedOs(selectedOs.filter(os => os !== osToDelete));
  };

  return (
    <React.Fragment>
      {sortedSelectedOs.map(os =>
        <FilterChip
          key={os}
          tooltip={osTooltip(os)}
          disabled={disabled}
          label={osLabel(os)}
          onDelete={() => handleDelete(os)}/>
      )}
    </React.Fragment>
  );
}
