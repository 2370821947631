import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AuthProviderKC} from './react-auth-wrapper';
import {NotificationsProvider} from './contexts/NotificationsContext';
import {getDocumentFragmentContainsPropertyWithValue, getDocumentFragmentProperties} from './util';
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {StyledEngineProvider} from "@mui/material/styles";
import {setChonkyDefaults} from 'chonky';
import {ChonkyIconFA} from 'chonky-icon-fontawesome';
import {SWCThemeProvider} from "./contexts/ThemeContext";

setChonkyDefaults({iconComponent: ChonkyIconFA});

function getBrowser() {
  const ua = navigator.userAgent;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  let tem;

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return {name: 'IE', version: (tem[1] || '')};
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/)
    if (tem != null) {
      return {name: 'Opera', version: tem[1]};
    }
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/\Edge\/(\d+)/)
    if (tem != null) {
      return {name: 'Edge', version: tem[1]};
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: +M[1]
  };
}

function isSupported(browser) {
  let supported = false;
  if (browser.name === "Chrome" && browser.version >= 85) {
    supported = true;
  } else if ((browser.name === "MSIE" || browser.name === "IE") && browser.version >= 10) {
    supported = true;
  } else if (browser.name === "Edge" && browser.version >= 85) {
    supported = true;
  } else if (browser.name === "Firefox" && browser.version >= 77) {
    supported = true;
  } else if (browser.name === "Safari" && browser.version >= 13) {
    supported = true;
  }
  return supported;
}

const browser = getBrowser();
const isBrowserSupported = isSupported(browser);

if (!isBrowserSupported) {
  document.getElementById("unsupported").style.display = 'block';
  const className = '.' + browser.name.toLocaleLowerCase();
  const elements = document.querySelectorAll(className);
  for (let i = 0; i < elements.length; ++i) {
    elements[i].style.display = 'list-item';
  }
} else {
  fetch('/swc/api/oidc/primary')
    .then(response => response.json())
    .then((config) => {
      const children = (
        <DndProvider backend={HTML5Backend}>
          <StyledEngineProvider injectFirst>
            <SWCThemeProvider>
              <NotificationsProvider>
                <App/>
              </NotificationsProvider>
            </SWCThemeProvider>
          </StyledEngineProvider>
        </DndProvider>
      );

      const tryAuth0SSO = !getDocumentFragmentContainsPropertyWithValue(window, 'error', ['login_required', 'interaction_required', 'consent_required', 'account_selection_required']);

      console.log(`OIDC init - parameters: ${JSON.stringify(getDocumentFragmentProperties(window))}, also try Auth0 SSO: ${tryAuth0SSO}`);

      const container = document.getElementById("root");
      const root = createRoot(container);
      root.render(<BrowserRouter>
        <AuthProviderKC
          config={config}
          tryAuth0SSO={tryAuth0SSO}
        >
          {children}
        </AuthProviderKC>
      </BrowserRouter>);
    });
}
serviceWorker.unregister();
