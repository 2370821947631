import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SimpleTableWithPaging from './SimpleTableWithPaging';
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StyledPaper = styled(Paper)`
  padding: 10px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  margin-bottom: 20px;
`;
const StyledTitleContainer = styled.div`
  display: flex;
`;
const StyledDescription = styled.div`
  margin-bottom: 10px;
  margin-right: 2em;
`;

export default class ScrollableList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const rows = this.props.items.map((item, index) => (
      this.props.itemContainer(item, index)
    ));

    return (
      <StyledPaper>
        <StyledTitleContainer>
          <Typography variant="h6">
            {this.props.title}
          </Typography>
        </StyledTitleContainer>
        <StyledDescription>{this.props.description}</StyledDescription>
        <SimpleTableWithPaging
          headRow={this.props.headRow}
          rows={rows}
          showHeadRow={this.props.showHeadRow}
          showTitle={false}
          showPagination={false}
          sortable={this.props.sortable}
        />
      </StyledPaper>
    );
  }
}

ScrollableList.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  description: PropTypes.string,
  items: PropTypes.array,
  itemContainer: PropTypes.func,
  headRow: PropTypes.array,
  showHeadRow: PropTypes.bool,
  scrollableTarget: PropTypes.string,
  sortable: PropTypes.bool,
};
