import React from 'react';
import {usePageMeta} from '../contexts/PageMetaContext';
import styled from "@emotion/styled";

const StyledA = styled.a`
  text-decoration: none;
  color: inherit;
`;

export default function SWCLink({path = "#", clickCallback = null, children, ...props}) {

  const {goTo} = usePageMeta();

  function handleClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    clickCallback === null ? goTo(path) : clickCallback();
  }

  return (<StyledA
    href={path}
    onClick={handleClicked}
    {...props}
  >{children}</StyledA>);
}
