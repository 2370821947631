import React, {useEffect, useState} from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import {usePageMeta} from '../../../contexts/PageMetaContext';
import {useNotifications} from '../../../contexts/NotificationsContext';
import {useAuth} from '../../../react-auth-wrapper';
import OperationSelection from './steps/OperationSelection';
import ProductGroupSelection from './steps/ProductGroupSelection';
import ProductVersionSelection from './steps/ProductVersionSelection';
import TargetBucketSelection from './steps/TargetBucketSelection';
import OperationConfirmation from './steps/OperationConfirmation';
import LoadingIcon from '../../../components/LoadingIcon';
import AdminDownloadTabs from '../../../menu/admin/AdminDownloadTabs';
import {ADMIN_DOWNLOADS} from '../../../components/PathConstants';
import {TASK_TYPE} from '../BuildsBulkTasks/taskFunctions';
import {COPY_FILES, GENERATE_ARCHIVE} from "./steps/operationsMeta";
import WizardButtonContainer from "../../../components/WizardButtonContainer";
import styled from "@emotion/styled";

const StyledTypography = styled(Typography)`
  margin-top: ${({theme}) => theme.spacing(1)};
  margin-bottom: ${({theme}) => theme.spacing(1)};
  padding: ${({theme}) => theme.spacing(1)};
`;
const StyledPaperContent = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
`;
const StyledStepper = styled(Stepper)`
  padding-left: 0;
`;
const StyledBackdrop = styled(Backdrop)`
  z-index: ${({theme}) => theme.zIndex.drawer + 1};
`;
const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(1)};
`;
const Constant = {
  NOTIFICATOR: 'buildBulkNotificator',
};

export default function BuildsBulkOperations() {
  const {setTitle} = usePageMeta();
  const {callApi} = useAuth();
  const {notificator, notify} = useNotifications();

  const cancel = {};

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [writableBuckets, setWritableBuckets] = useState([]);
  const [groups, setGroups] = useState(null);
  const [selectedOperations, setSelectedOperations] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [builds, setBuilds] = useState(null);
  const [selectedProductVersions, setSelectedProductVersions] = useState([]);
  const [selectedBucket, setSelectedBucket] = useState();
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  // ---- STEP CONFIGURATION ----

  class StepData {
    constructor(name, content, onEnterAction = () => {
    }, nextDisabledCondition = () => false, backDisabledCondition = () => false, isOptional = false, isHidden = () => false) {
      this.name = name;
      this.content = content;
      this.onEnterAction = onEnterAction;
      this.nextDisabledCondition = () => loading || nextDisabledCondition();
      this.backDisabledCondition = () => loading || backDisabledCondition();
      this.isOptional = isOptional;
      this.isHidden = isHidden;

      this.index = -1;
      this.isFinalStep = false;
    }
  }

  const stepDataList = [
    new StepData(
      'Select product group',
      () => (products ?
        <ProductGroupSelection groups={groups} loading={loading} clickCallBack={handleGroupSelected}/> : null),
      () => {
        setSelectedGroup(null);
      },
      () => !selectedGroup,
      () => true,
      false,
      () => false,
    ),
    new StepData(
      'Choose product versions',
      () => (builds ? <ProductVersionSelection group={selectedGroup} builds={builds} loading={loading}
                                               handleProjectVersionSelectionChange={handleProductVersionsSelected}/> : null),
      () => {
        setSelectedProductVersions([]);
      },
      () => selectedProductVersions.length === 0,
      () => false,
      false,
      () => false,
    ),
    new StepData(
      'Select operation',
      () => (products ? <OperationSelection selection={selectedOperations} loading={loading}
                                            selectCallBack={handleOperationSelected}/> : null),
      () => {
        setSelectedOperations([]);
      },
      () => selectedOperations.length === 0,
      () => false,
      false,
      () => false,
    ),
    new StepData(
      'Select bucket',
      () => (writableBuckets ? <TargetBucketSelection buckets={writableBuckets} loading={loading}
                                                      clickCallBack={handleBucketSelected}/> : null),
      () => {
        setSelectedBucket(null);
      },
      () => !selectedBucket,
      () => false,
      false,
      () => selectedOperations.length != 0 && selectedOperations.filter(item => item === COPY_FILES || item === GENERATE_ARCHIVE).length === 0,
    ),
    new StepData(
      'Confirm operation',
      () => (selectedProductVersions ?
        <OperationConfirmation bucket={selectedBucket} productVersionSelections={selectedProductVersions}
                               operationSelections={selectedOperations} loading={loading}/> : null),
      () => {
      },
      () => false,
      () => false,
      false,
      () => false,
    ),
  ].filter(stepData => !stepData.isHidden()).map((stepData, index, allStepData) => ({
    ...stepData,
    index,
    isFinalStep: allStepData.length - 1 === index
  }));

  const getStepData = stepIndex => stepDataList[stepIndex] || new StepData('unknown', () => 'Invalid step');
  const isStepSkipped = stepIndex => skipped.has(stepIndex);

  const activeStepData = getStepData(activeStepIndex);

  // ---- NAVIGATION EVENT HANDLERS ----

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStepIndex)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStepIndex);
    }

    setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex - 1);
  };

  const handleSkip = () => {
    if (!getStepData(activeStepIndex).isOptional) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStepIndex);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStepIndex(0);
  };


  // ---- EVENT HANDLERS ----

  const handleOperationSelected = (operations) => {
    console.log('[BulkBuildsOperations]', 'operation selected', operations);
    setSelectedOperations(operations);
  };

  const handleGroupSelected = (group) => {
    console.log('[BulkBuildsOperations]', 'group selected', group);
    setSelectedGroup(group);
  };

  const handleProductVersionsSelected = (productVersionList) => {
    setSelectedProductVersions((prevProductVersions) => {
      console.log('[BulkBuildsOperations]', `#selected changed: ${prevProductVersions.length} -> ${productVersionList.length}`);
      return productVersionList;
    });
  };

  const handleBucketSelected = (bucket) => {
    console.log('[BulkBuildsOperations]', 'bucket selected', bucket);
    setSelectedBucket(bucket);
  };

  const handleFinished = () => {
    const taskType = TASK_TYPE.BUILD_MOVE_TO_CLOUD_BUCKET;
    const payload = {
      bucketId: selectedBucket?.bucketId,
      selectionList: selectedProductVersions.map(selection => selection.toRestData()),
      operations: selectedOperations,
    };

    console.log('[BulkBuildsOperations]', 'hand over job', taskType, payload);

    setLoading(true);
    callApi(`/swc/api/admin/tasks/${taskType}`, {method: 'POST', body: JSON.stringify(payload)}, cancel).then(
      (response) => {
        console.log('[BulkBuildsOperations]', 'response', response);
        setLoading(false);
      },
    )
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setLoading(false);
          signalApiError(error);
        }
      });
  };

  // ---- SERVER API CALLS ----

  function signalApiError(error) {
    notify(Constant.NOTIFICATOR,
      {
        text: 'Error while loading bulk data:',
        error,
        type: 'error',
        handleClose: () => {
        },
      });
  }

  function loadProducts() {
    console.log('[BulkBuildsOperations]', 'Fetching products...');
    setLoading(true);
    callApi('/swc/api/admin/products', {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[BulkBuildsOperations]', 'products', response);
        setProducts(response.productMetas.filter(product => product.downloadType === 'DEFAULT'));
        setLoading(false);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setProducts(null);
          setLoading(false);
          signalApiError(error);
        }
      })
      .finally(() => {
      });
  }

  function loadBuckets() {
    console.log('[BulkBuildsOperations]', 'Fetching buckets...');
    setLoading(true);
    callApi('/swc/api/buckets?filter=WRITABLE', {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[BulkBuildsOperations]', 'buckets', response);
        setWritableBuckets(response);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setWritableBuckets([]);
          setLoading(false);
          signalApiError(error);
        }
      })
      .finally(() => {
      });
  }

  function loadGroups() {
    const rawResult = (products || []).reduce((acc, product) => {
      if (acc.find(g => g.groupKey === product.groupKey)) {
        return acc;
      }
      return [...acc, {
        groupKey: product.groupKey,
        groupLabel: product.groupLabel || product.groupKey,
        groupDescription: product.groupDescription,
        groupIcon: product.groupIcon,
      }];
    }, []);

    const result = rawResult.sort((g1, g2) => g1.groupLabel.localeCompare(g2.groupLabel));

    setGroups(result);
  }

  function loadBuilds() {
    async function fetchBuildsForProduct(product) {
      try {
        console.log('[BulkBuildsOperations]', `loading builds for ${product.productKey}`);
        const response = await callApi(`/swc/api/admin/products/${product.productKey}/any`, {method: 'GET'}, cancel);
        console.log('[BulkBuildsOperations]', `builds for ${product.productKey} loaded`, response);
        return response;
      } catch (error) {
        console.log('[BulkBuildsOperations]', `failed loading builds for ${product.productKey}`);
        if (error.message !== 'Cancelled') {
          signalApiError(error);
          setBuilds(null);
        }
        return error;
      }
    }

    async function fetchBuilds() {
      console.log('[BulkBuildsOperations]', `loading builds for group ${selectedGroup && selectedGroup.groupKey}`);
      let relevantProducts = [];
      if (products && selectedGroup) {
        relevantProducts = products.filter(product => product.groupKey === selectedGroup.groupKey);
      }
      const partialResults = relevantProducts.map(fetchBuildsForProduct);
      const result = [];
      for await (const aPartialResult of partialResults) {
        result.push(aPartialResult);
      }
      if (result.find(item => item.stack && item.message)) {
        console.log('[BulkBuildsOperations]', `failed loading builds for ${selectedGroup && selectedGroup.groupKey}`);
      } else {
        console.log('[BulkBuildsOperations]', `builds for group ${selectedGroup && selectedGroup.groupKey} loaded`, result);
        setBuilds(result);
      }
    }

    (async () => {
      try {
        setLoading(true);
        await fetchBuilds();
      } finally {
        setLoading(false);
      }
    })();
  }

  // ---- LOCAL DATA SYNC ----

  function highlightSelectedGroup() {
    setGroups((prevGroups) => {
      if (prevGroups) {
        return prevGroups.map(g => ({
          ...g,
          highlight: selectedGroup && g.groupKey === selectedGroup.groupKey,
        }));
      }
      return prevGroups;
    });
  }

  function highlightSelectedBucket() {
    setWritableBuckets((prevBucket) => {
      if (prevBucket) {
        return prevBucket.map(b => ({
          ...b,
          highlight: selectedBucket && b.bucketId === selectedBucket.bucketId,
        }));
      }
      return prevBucket;
    });
  }

  useEffect(highlightSelectedGroup, [selectedGroup]);
  useEffect(highlightSelectedBucket, [selectedBucket]);

  // ---- EFFECTS ----

  useEffect(() => {
    setLoading(false);
    setTitle('Builds Bulk Operations');
    loadProducts();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);
  useEffect(() => getStepData(activeStepIndex).onEnterAction(), [activeStepIndex]);
  useEffect(() => {
    if (activeStepIndex === stepDataList.length) handleFinished();
  }, [activeStepIndex]);
  useEffect(loadGroups, [products]);
  useEffect(loadBuckets, [products]);
  useEffect(loadBuilds, [selectedGroup]);

  // ---- UI ----

  const buttons = (
    <WizardButtonContainer
      activeStepData={activeStepData}
      wizardFinished={activeStepIndex === stepDataList.length}
      onNext={handleNext}
      onSkip={handleSkip}
      onBack={handleBack}
      onReset={handleReset}
    />
  );

  return (
    <AdminDownloadTabs activeTabPath={ADMIN_DOWNLOADS.bulkOperationWizard.path}>
      <StyledPaper square>
        {notificator(Constant.NOTIFICATOR)}
        <StyledBackdrop open={loading}>
          <LoadingIcon/>
        </StyledBackdrop>
        <div>
          <StyledStepper activeStep={activeStepIndex}>
            {stepDataList.map((stepDataItem, index) => {
              const stepProps = {};
              const labelProps = {};
              if (stepDataItem.isOptional) {
                labelProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={stepDataItem.name} {...stepProps}>
                  <StepLabel {...labelProps}>{stepDataItem.name}</StepLabel>
                </Step>
              );
            })}
          </StyledStepper>
          {buttons}
          <Paper>
            <StyledPaperContent>
              {activeStepIndex === stepDataList.length ? (
                <StyledTypography>
                  All steps completed - you&apos;re finished
                </StyledTypography>
              ) : (
                <StyledTypography>
                  {activeStepData.content()}
                </StyledTypography>
              )}
            </StyledPaperContent>
          </Paper>
          {buttons}
        </div>
      </StyledPaper>
    </AdminDownloadTabs>
  );
}
