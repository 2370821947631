
const LABEL = {
  ID: 'ID',
  USER: 'Created By',
  PRODUCER: 'Created on Cluster Node',
  PRODUCER_APP_VERSION: 'Producer App Version',
  CONSUMER: 'Processed on Cluster Node',
  CREATED: 'Creation Date',
  UPDATED: 'Last Update',
  STATE: 'State',
  ASSIGNMENT_COUNTER: 'Task (Re)assignments',
  PARAMETER: 'Parameter',
  RESULT: 'Result',
};

export default LABEL;
