import {Autocomplete, Checkbox} from "@mui/material";
import TextField from '@mui/material/TextField';
import React, {useEffect, useMemo, useState} from "react";
import {SWC_PROP_TYPES} from "../../../../SwcPropTypes";
import * as PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import moment from 'moment-timezone';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DesktopDateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";

const StyledRowContainer = styled.div`
  display: flex;
  padding: ${({theme}) => theme.spacing(2)};
`;
const StyledTypographyContainer = styled.div`
  display: table-cell;
  width: 20%;
  text-align: left;
  vertical-align: middle;
`;
const StyledContent = styled.div`
  width: 80%;
`;
const StyledPaper = styled(Paper)`
  margin: auto;
  width: 60%;
  box-shadow: none;
`;
const Row = ({label, children}) => {
  return (<StyledRowContainer>
    <StyledTypographyContainer><Typography variant="h7">{label}:</Typography></StyledTypographyContainer>
    <StyledContent>{children}</StyledContent>
  </StyledRowContainer>);
}

const isoStringToMoment = value => {
  if (value !== null && value !== undefined) {
    const date = new Date(value);
    if (date.toString() !== 'Invalid Date') {
      const zonedDate = moment(date).utc();
      return zonedDate;
    }
  }
  return null;
}

export default function ChooseUploadProduct({
                                              initialUploadProduct = null,
                                              initialBuildAudienceList,
                                              categories,
                                              buildAudiences,
                                              clickCallBack
                                            }) {

  const [uploadProduct, setUploadProduct] = useState(initialUploadProduct);
  const [buildAudienceChecked, setBuildAudienceChecked] = useState(initialUploadProduct?.buildAudienceList?.length > 0);

  const handleCheckboxChanged = (event) => {
    setBuildAudienceChecked(event.target.checked);
    if (!event.target.checked) {
      handleBuildAudienceChange([]);
    }
  }

  const handleCategoryChange = (value) => {
    console.log('[ChooseUploadProduct] selected category', value);
    setUploadProduct({
      category: value,
      buildAudienceList: value == null ? [] : initialBuildAudienceList,
    });
    setBuildAudienceChecked(value && initialBuildAudienceList.length > 0);
  }

  const handleProductChange = (value) => {
    console.log('[ChooseUploadProduct] selected product', value);
    const singleLicense = value.licenses?.values?.length === 1 && value.licenses?.optional === false ? value.licenses?.values[0] : null;
    const singleOS = value?.osList?.length === 1 ? value?.osList[0] : null;
    setUploadProduct(oldUploadProduct => ({
      ...oldUploadProduct,
      product: value,
      version: null,
      osVersion: singleOS,
      buildType: null,
      license: singleLicense,
      buildAttribute: null,
    }));
  }

  const handleBuildTypeChange = (value) => {
    console.log('[ChooseUploadProduct] selected build type', value);
    setUploadProduct(oldUploadProduct => ({...oldUploadProduct, buildType: value, version: null}));
  }

  const handleAttributeChange = (value) => {
    console.log('[ChooseUploadProduct] selected attribute', value);
    setUploadProduct(oldUploadProduct => ({...oldUploadProduct, buildAttribute: value}));
  }

  const handleOSVersionChange = (value) => {
    console.log('[ChooseUploadProduct] selected os version', value);
    setUploadProduct(oldUploadProduct => ({...oldUploadProduct, osVersion: value}));
  }

  const handleBuildAudienceChange = (value) => {
    console.log('[ChooseUploadProduct] selected build audience', value);
    setUploadProduct(oldUploadProduct => ({...oldUploadProduct, buildAudienceList: value}));
  }

  const handleVersionChange = (value) => {
    console.log('[ChooseUploadProduct] version', value);
    setUploadProduct(oldUploadProduct => ({...oldUploadProduct, version: value}));
  }

  const handleLicenseChange = (value) => {
    console.log('[ChooseUploadProduct] selected a license', value);
    setUploadProduct(oldUploadProduct => ({...oldUploadProduct, license: value}));
  }

  const handleReleaseDateChange = (momentDateValue) => {
    console.log('[ChooseUploadProduct] selected a release date', momentDateValue);
    let isoDateString = null;
    if (moment.isMoment(momentDateValue) && momentDateValue.isValid()) {
      const zonedDate = moment(momentDateValue).utc()
      isoDateString = zonedDate.toISOString();
    }
    setUploadProduct(oldUploadProduct => ({...oldUploadProduct, releaseDate: isoDateString}));
  };

  const getPatternsString = (patterns) => {
    return patterns ? patterns.map(versionPattern => versionPattern.pattern).join(', ') : '';
  }

  const getBuildAttributes = () => {
    if (!uploadProduct?.product?.attributeList || uploadProduct?.product?.attributeList.length === 0) {
      return [];
    }
    const attributeOptions = [];
    const attribute = uploadProduct?.product?.attributeList[0];
    (attribute.values || []).forEach(value => {
      attributeOptions.push({key: attribute.key, value: value});
    });
    return attributeOptions;
  }

  useEffect(() => {
    const buildAudienceListLength = uploadProduct?.buildAudienceList?.length || 0;
    if (buildAudienceChecked && buildAudienceListLength === 0) {
      clickCallBack(null);
    }
  }, [buildAudienceChecked]);

  useEffect(() => {
    const attributeOptional = uploadProduct?.product?.attributeList?.length > 0 ? uploadProduct?.product.attributeList[0].optional : true;
    const attributeSet = attributeOptional || uploadProduct?.buildAttribute;
    const licenseOptional = uploadProduct?.product?.licenses?.optional;
    const licenseSet = licenseOptional || uploadProduct?.license
    if (uploadProduct?.buildType && uploadProduct?.osVersion && uploadProduct?.version && attributeSet && licenseSet) {
      clickCallBack(uploadProduct);
    } else {
      clickCallBack(null);
    }
  }, [uploadProduct]);

  useEffect(() => {
    console.log('[ChooseUploadProduct] initialProductMeta', initialUploadProduct);
    if (initialUploadProduct) {
      setUploadProduct(initialUploadProduct);
      setBuildAudienceChecked(initialUploadProduct?.buildAudienceList?.length > 0);
    }
  }, [initialUploadProduct]);

  return (<StyledPaper square>
    <LocalizationProvider dateAdapter={AdapterMoment}>
    <Row label="Category">
      <Autocomplete
        size="small"
        options={categories}
        getOptionLabel={(option) => option.label || ''}
        filterSelectedOptions
        onChange={(event, value) => handleCategoryChange(value)}
        value={uploadProduct?.category || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Category"
            placeholder="Select category"
          />
        )}
      />
    </Row>
    <Row label="Product">
      <Autocomplete
        disabled={!uploadProduct?.category}
        size="small"
        options={uploadProduct?.category?.productList || []}
        getOptionLabel={(option) => option.label || ''}
        filterSelectedOptions
        onChange={(event, value) => handleProductChange(value)}
        value={uploadProduct?.product || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Product"
            placeholder="Select product"
          />
        )}
      />
    </Row>
    <Row label="Build Type">
      <Autocomplete
        disabled={!uploadProduct?.product}
        size="small"
        options={uploadProduct?.product?.buildTypeList || []}
        getOptionLabel={(option) => option.label || ''}
        filterSelectedOptions
        onChange={(event, value) => handleBuildTypeChange(value)}
        value={uploadProduct?.buildType || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Build Type"
            placeholder="Select Build Type"
          />
        )}
      />
    </Row>
    <Row label="Version">
      <TextField
        disabled={!uploadProduct?.buildType}
        helperText={`Use pattern: ${getPatternsString(uploadProduct?.buildType?.versionPatternList)}`}
        size="small"
        name="version"
        label={'Version'}
        margin="normal"
        variant="outlined"
        value={uploadProduct?.version || ''}
        onChange={(event) => handleVersionChange(event.target.value)}
      />
    </Row>
    <Row label="Licenses">
      <Autocomplete
        disabled={(uploadProduct?.product?.licenses?.values || []).length === 0}
        size="small"
        options={uploadProduct?.product?.licenses?.values || []}
        getOptionLabel={(option) => option || ''}
        filterSelectedOptions
        onChange={(event, value) => handleLicenseChange(value)}
        value={uploadProduct?.license || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="License"
            placeholder="Select License"
          />
        )}
      />
    </Row>
    <Row label="Build Attribute">
      <Autocomplete
        disabled={!uploadProduct?.product}
        size="small"
        options={useMemo(() => getBuildAttributes(), [uploadProduct?.product?.attributeList])}
        getOptionLabel={(option) => option.value || ''}
        filterSelectedOptions
        onChange={(event, value) => handleAttributeChange(value)}
        value={uploadProduct?.buildAttribute || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Build Attribute"
            placeholder="Select Build Attribute"
          />
        )}
      />
    </Row>
    <Row label="OS Target">
      <Autocomplete
        disabled={!uploadProduct?.product}
        size="small"
        options={uploadProduct?.product?.osList || []}
        getOptionLabel={(option) => option?.label || ''}
        filterSelectedOptions
        onChange={(event, value) => handleOSVersionChange(value)}
        value={uploadProduct?.osVersion || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="OS Target"
            placeholder="Select OS Target"
          />
        )}
      />
    </Row>
    <Row label="Release Date">
      <DesktopDateTimePicker
        label="Release Date (local timezone)"
        ampm={false}
        disabled={!uploadProduct?.product}
        size="small"
        value={
          isoStringToMoment(uploadProduct?.releaseDate) || moment().hours(12).minutes(0).seconds(0).milliseconds(0)}
        onChange={handleReleaseDateChange}
      />
    </Row>
    <Row label="Customer specific private release">
      <Checkbox disabled={!(uploadProduct?.category)} checked={buildAudienceChecked} onChange={handleCheckboxChanged}
                name="buildAudienceCheck"/>
      <Autocomplete
        multiple
        disabled={!buildAudiences || !buildAudienceChecked}
        id="search-selected-build-audience"
        size="small"
        options={buildAudiences || []}
        getOptionLabel={(option) => option.label || ''}
        filterSelectedOptions
        onChange={(event, value) => handleBuildAudienceChange(value)}
        value={uploadProduct?.buildAudienceList || []}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Build Audience"
            placeholder="Select Build Audience"
          />
        )}
      />
    </Row>
    </LocalizationProvider>
  </StyledPaper>);
}

ChooseUploadProduct.propTypes = {
  categories: SWC_PROP_TYPES.uploadMeta,
  buildAudiences: PropTypes.arrayOf(SWC_PROP_TYPES.buildAudience),
  clickCallBack: PropTypes.func.isRequired,
  initialUploadProduct: SWC_PROP_TYPES.uploadProduct,
};
