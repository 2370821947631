import React from 'react';
import { BuildTypeChip, OSChip } from './TypeChip';

export function BuildSelection({ selection }) {
  return (<React.Fragment>
      <BuildTypeChip version={selection}/>
      {selection.classifier}
      <OSChip version={selection}/>
    </React.Fragment>
  );
}
