import Chip from "@mui/material/Chip";
import React from "react";
import {Tooltip} from "@mui/material";
import styled from "@emotion/styled";

const StyledContainer = styled.span`
  display: inline-block;
  min-width: ${({theme}) => theme.spacing(2)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;
const StyledChip = styled(Chip)`
  font-size: x-small;
`;

export function FilterChip({label, tooltip, disabled, onDelete}) {
  return (
    <StyledContainer>
      <Tooltip
        title={tooltip}>
        <StyledChip
          disabled={disabled}
          size="small"
          label={label}
          onDelete={onDelete}/>
      </Tooltip>
    </StyledContainer>)
}
