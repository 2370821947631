import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SWCLink from '../SWCLink';

export default function VmImage({ instance, path }) {

  const label = instance.label || instance.imageId;
  const tooltip = instance.description || label;

  return (
    <SWCLink path={path}>
      <Tooltip title={tooltip} data-image-id={instance.imageId}>
        <span>{label}</span>
      </Tooltip></SWCLink>
  );
}

VmImage.propTypes = {
  instance: PropTypes.shape({
    imageId: PropTypes.string.isRequired,
    regionKey: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};
