
const createObjectFrom = (objTemplate) => {
  const containerObject = {};
  for (let prop of objTemplate) {
    if (prop.type !== 'Object' && prop.type !== 'Array') {
      // primitive prop
      containerObject[prop.name] = getValueFor(prop.type, prop.defaultValue);
    } else if (prop.type === 'Object') {
      containerObject[prop.name] = createObjectFrom(prop.props);
    } else {
      containerObject[prop.name] = [];
      if (prop.required === true) {
        const defaultElement = createObjectFrom(prop.props);
        containerObject[prop.name].push(defaultElement);
      }
    }
  }
  return containerObject;
};

const getValueFor = (type, defaultValue) => {
  if (type === 'String') {
    return defaultValue !== undefined ? defaultValue : "";
  } else if (type === 'Int') {
    return defaultValue !== undefined ? defaultValue : undefined;
  } else {
    return defaultValue;
  }
};

const useConfigFormCreateObjectFrom = (objTemplate) => {
  return createObjectFrom(objTemplate);
};

export default useConfigFormCreateObjectFrom;
