/* eslint-disable max-len */
import React, {useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import EnhancedTableHeaderWithSort from '../../../components/table/EnhancedTableHeaderWithSort';
import TimestampCell from './table/TimestampCell';
import ProductOrBucketCell from './table/ProductOrBucketCell';
import TypeCell from './table/TypeCell';
import UrlCell from './table/UrlCell';
import MirrorCell from './table/MirrorCell';
import FilenameCell from './table/FilenameCell';
import {StyledTableCell} from "../../../components/SimpleTableWithPaging";
import styled from "@emotion/styled";

const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};
  margin: ${({theme}) => theme.spacing(1)};
  display: flex;
  overflow: auto;
  flex-direction: column;
`;
const StyledContainer = styled.div`
  ${props => props.showTitle ? `
    width: 100%;
    margin-top: ${props.theme.spacing(3)};
    padding-top: ${props.theme.spacing(4)};
    padding-bottom: ${props.theme.spacing(4)};
  ` : ''}
`;
const StyledTableContainer = styled.div`
  overflow-x: auto;
`;
const StyledTable = styled(Table)`
  min-width: 750px;
`;
const noOp = () => {
  // noop
};

const headRowMeta = [
  {
    id: 'timestamp',
    label: 'Timestamp',
    notSortable: false,
    cellFormatter: ({cellData}) => (<TimestampCell timestamp={cellData}/>),
  },
  {id: 'type', label: 'Type', cellFormatter: ({cellData}) => (<TypeCell type={cellData}/>)},
  {id: 'email', label: 'Email', notSortable: false},
  {
    id: 'productKey',
    label: 'Product or S3 Bucket',
    cellFormatter: ({cellData, metaData}) => (<ProductOrBucketCell productOrCloudKey={cellData} metaData={metaData}/>),
    notSortable: false,
  },
  {id: 'fileName', label: 'Filename', cellFormatter: ({cellData}) => (<FilenameCell filename={cellData}/>)},
  {id: 'buildType', label: 'Build Type'},
  {id: 'classifier', label: 'Version'},
  {id: 'os', label: 'OS'},
  {id: 'mirror', label: 'Mirror', cellFormatter: ({cellData}) => (<MirrorCell mirror={cellData}/>)},
  {id: 'url', label: 'URL', cellFormatter: ({cellData}) => (<UrlCell url={cellData}/>)},
  {id: 'dryRun', label: 'Dry run', cellFormatter: ({cellData}) => (cellData ? 'Yes' : '')},
  {id: 'geoLocation', label: 'Geo'},
  {id: 'ipAddress', label: 'IP Address', notSortable: false},
].map((headCell) => ({
  numeric: false, disablePadding: false, notSortable: true, cellFormatter: ({cellData}) => cellData, ...headCell,
}));

function sortList(listToSort, propertyName, direction) {
  if (direction !== 'asc' && direction !== 'desc') {
    throw new Error("Invalid sort direction. Please use 'asc' or 'desc'.");
  }

  const answer = [...listToSort];
  answer.sort((a, b) => {
    const propA = a[propertyName];
    const propB = b[propertyName];

    if (propA < propB) {
      return direction === 'asc' ? -1 : 1;
    }
    if (propA > propB) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return answer;
}

const defaultTableState = {
  page: 0,
  size: 25,
  numberOfPages: 0,
  orderBy: 'timestamp',
  orderDirection: 'desc',
};

export default function ComplianceLogTable({
                                             disabled = false,
                                             showTitle = false,
                                             metaData,
                                             searchResults,
                                             tableState = {...defaultTableState},
                                             setTableState = (newTableState) => {
                                             },
                                           }) {
  const [sanitizedTableState, setSanitizedTableState] = React.useState({});
  const [sanitizedSearchResultItems, setSanitizedSearchResultItems] = React.useState([]);

  function handleChangePage(event, newPage) {
    setTableState({...sanitizedTableState, page: newPage});
  }

  function handleChangeRowsPerPage(event) {
    const newSize = (event.target.value) || 25;
    const currentSize = sanitizedTableState.size || 0;

    if (currentSize !== newSize) {
      setTableState({...sanitizedTableState, page: 0, size: newSize});
    }
  }

  const handleRequestSort = (event, property) => {
    const headCell = headRowMeta.find((cell) => cell.id === property && !cell.notSortable);
    if (headCell) {
      let direction;
      if (sanitizedTableState.orderBy === property) {
        direction = sanitizedTableState.orderDirection === 'asc' ? 'desc' : 'asc';
      } else {
        direction = defaultTableState.orderDirection;
      }
      setTableState({...sanitizedTableState, orderBy: headCell.id, orderDirection: direction});
    }
  };

  useEffect(() => {
    if (sanitizedTableState.orderBy && sanitizedTableState.orderDirection) {
      const unsortedItems = (searchResults?.items) || [];
      const sortedItems = sortList(unsortedItems, sanitizedTableState.orderBy, sanitizedTableState.orderDirection);
      setSanitizedSearchResultItems(sortedItems);
    }
  }, [searchResults, sanitizedTableState]);

  useEffect(() => setSanitizedTableState({
    ...tableState,
    page: tableState?.page || defaultTableState.page,
    size: tableState?.size || defaultTableState.size,
    numberOfPages: tableState?.numberOfPages || defaultTableState.numberOfPages,
    orderBy: tableState?.orderBy || defaultTableState.orderBy,
    orderDirection: tableState?.orderDirection || defaultTableState.orderDirection,
  }), [tableState]);

  const tableHead = (
    <EnhancedTableHeaderWithSort
      headRow={headRowMeta}
      order={sanitizedTableState.orderDirection}
      orderBy={sanitizedTableState.orderBy}
      onRequestSort={disabled ? () => {
      } : handleRequestSort}
    />
  );

  const tableBody = sanitizedSearchResultItems
    .slice(sanitizedTableState.page * sanitizedTableState.size, (sanitizedTableState.page + 1) * sanitizedTableState.size)
    .map((row, resultRowIndex) => (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={`row--${resultRowIndex}`}
      >
        {headRowMeta.map((headRowCell, headRowCellIndex) => (
          <StyledTableCell
            component="td"
            scope="row"
            data-value={row[headRowCell.id]}
            key={`cell-{resultRowIndex-${headRowCell.id}-$}-${headRowCellIndex}`}
            className={headRowCell.cssClass ? headRowCell.cssClass : undefined}
          >
            {headRowCell.cellFormatter({cellData: row[headRowCell.id], metaData, row})}
          </StyledTableCell>
        ))}
      </TableRow>
    ));

  const paginationFooter = (
    <TablePagination
      backIconButtonProps={{'aria-label': 'Previous Page'}}
      nextIconButtonProps={{'aria-label': 'Next Page'}}
      component="div"
      count={sanitizedSearchResultItems.length}
      rowsPerPage={sanitizedTableState.size}
      page={sanitizedTableState.page}
      onPageChange={disabled ? noOp : handleChangePage}
      onRowsPerPageChange={disabled ? noOp : handleChangeRowsPerPage}
    />
  );

  let title = '';
  if (showTitle) {
    title = (
      <Typography variant="h6">
        Log Items
      </Typography>
    );
  }

  let content = (
    <StyledPaper>
      {title}
      <StyledTableContainer>
        <StyledTable
          aria-labelledby="tableTitle"
          size="small"
        >
          {tableHead}
          <TableBody>
            <>
              {tableBody}
            </>
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {paginationFooter}
    </StyledPaper>
  );

  if (sanitizedSearchResultItems.length === 0) {
    if (searchResults) {
      content = (
        <StyledPaper>
          <Typography variant="subtitle2">
            No data found
          </Typography>
        </StyledPaper>
      );
    } else {
      content = null;
    }
  }

  return (
    <StyledContainer showTitle={showTitle}>{content}</StyledContainer>
  );
}
