import React, { useContext, useState } from 'react';

export const CloudConfigFormContext = React.createContext();
export const useConfigForm = () => useContext(CloudConfigFormContext);
export const ConfigFormProvider = ({ children }) => {

  const [pathTemplates, setPathTemplates] = useState({});
  const [config, setConfig] = useState();
  const [template, setTemplate] = useState();

  const initConfig = (value) => {
    setConfig(value)
  };

  const initTemplate = (value) => {
    setTemplate(value)
  };

  const setValue = (path, value) => {
    /*
     * The path should be like:
      * ['key'] -> for cloud config 'key' prop
      * ['regions'][0]['key'] -> for the 'key' prop of the first region
      * ['regions'][0]['images'][0]['machineSizes'][0]['key'] -> for the 'key' prop of the first image of the first region
      *
      * The idea that path contains the full path to the value in the object
     */
    const currentConfig = { ...config };
    const expression = Number.isInteger(value) ? `currentConfig${path} = ${value}` : `currentConfig${path} = '${value}'`;
    eval(expression);
    setConfig(currentConfig);
  };

  const addElement = (path, objectValue) => {
    const currentConfig = { ...config };
    const templateClone = deepObjectClone(objectValue);
    const expression = `const collection = currentConfig${path};
    collection.push(templateClone);`;
    console.log('[ConfigFormProvider] addElement', expression);
    eval(expression);
    setConfig(currentConfig);
  };

  const removeElement = (path, index) => {
    const currentConfig = { ...config };
    console.log('[ConfigFormProvider] delete element with key', path, index);
    const expression = `const collection = currentConfig${path};
    collection.splice(${index}, 1);`;
    console.log('[ConfigFormProvider] removeElement', expression);
    eval(expression);
    setConfig(currentConfig);
  };

  const deepObjectClone = (value) => {
    const newObject = {};

    for (let prop in value) {
      const currentValue = value[prop];
      if (Array.isArray(currentValue)) {
        newObject[prop] = [];
        if (currentValue.length > 0) {
          newObject[prop].push(deepObjectClone(currentValue[0]));
        }
      } else if (Number.isInteger(currentValue)) {
        newObject[prop] = Number(0);
      } else {
        newObject[prop] = '';
      }
    }

    return newObject;
  };

  const addObjectTemplate = (name, value) => {
    if (pathTemplates[name] === undefined) {
      setPathTemplates(prevTemplates => ({ ...prevTemplates, [name]: value }))
    }
  };

  const getObjectTemplate = (name) => {
    return pathTemplates[name];
  };

  /**
   * for example get template for element with path ['key']
   * for paths like ['region'][1]['image'], any index will be replaced with 0,
   * because there is only one element in template for any collection
   * @param path
   * @returns {undefined|*}
   */
  const getTemplateForPath = (path) => {
    path = path.replace(/\[\d+\]/g, '[0]');
    console.log('[CloudConfigFormContext]', 'getTemplateFromTemplateFor', path, template);
    const regex = /\['?(.*?)'?\]/g;

    let match;
    let currentProp;
    let currentTemplate = template;
    do {
      match = regex.exec(path);
      if (match) {
        let name = match[1];
        if (name !== '0') {
          const propTemplate = findPropFor(name, currentTemplate);
          if (propTemplate !== undefined) {
            currentProp = propTemplate;
          } else {
            // try to get sub-property from object
            currentProp = findPropFor(name, currentProp.props);
          }
        } else if (currentProp !== undefined) {
          currentTemplate = currentProp.props;
        } else {
          console.log('[CloudConfigFormContext] getTemplateForPath',
            `Can not find template for ${path}`);
          return undefined;
        }
      }
    }
    while (match);
    console.log('[CloudConfigFormContext] getTemplateForPath',
      'result template for',
      path,
      currentProp);
    return currentProp;
  };

  const findPropFor = (name, objTemplate) => {
    for (let prop of objTemplate) {
      if (prop.name === name) {
        return prop;
      }
    }
    return undefined;
  };

  const getConfigValueByPath = (path) => {
    let value;
    const expression = `value = config${path};`;
    console.log('[ConfigFormProvider] getConfigValueByPath', expression);
    eval(expression);

    return value;
  };

  return <CloudConfigFormContext.Provider value={{
    config,
    addElement,
    removeElement,
    initConfig,
    initTemplate,
    addObjectTemplate,
    getObjectTemplate,
    getTemplateForPath,
    getConfigValueByPath,
    setValue
  }}>
    {children}
  </CloudConfigFormContext.Provider>
};
