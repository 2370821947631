import React from 'react';
import {TextField} from "@mui/material";
import {StyledSearchFormAutocomplete} from "./SearchForm";

export default function ProductsSelection({productList, selectedProductKeys, setSelectedProductKeys, disabled}) {

    const sortedProductList = [...productList].sort((a, b) => a.productLabel.localeCompare(b.productLabel));
    const sortedSelectedProduct = sortedProductList.filter(p => selectedProductKeys.indexOf(p.productKey) > -1);

    const handleChange = (event, value) => {
        setSelectedProductKeys((value || []).map(product => product.productKey))
    };

    return (
        <StyledSearchFormAutocomplete
            disabled={disabled}
            multiple
            id="search-selected-products"
            options={sortedProductList}
            getOptionLabel={(option) => option.productLabel}
            defaultValue={[]}
            filterSelectedOptions
            onChange={handleChange}
            value={sortedSelectedProduct}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Selected products"
                    placeholder="Products"
                />
            )}
        />
    );
}
