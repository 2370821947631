import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';
import {styled} from "@mui/material/styles";

const AccordionDetails = styled(MuiExpansionPanelDetails)(({theme}) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default AccordionDetails;
