import React, {useEffect} from 'react';
import {usePageMeta} from '../contexts/PageMetaContext';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import SWCLink from './SWCLink';
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";

const StyledOverviewContainer = styled.div`
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 85%;
`;
const StyledTitle = styled.div`
  position: relative;
  top: -5px;
  padding-left: ${({theme}) => theme.spacing(1)};
  ${props => props.highlighted ? 'font-weight: bold;' : ''};
`;

function OverviewCard({
                        meta,
                        pathPrefix,
                        clickCallBack = null,
                        DetailComponent,
                        keyPropName,
                        labelPropName,
                        descrPropName,
                        iconPropName,
                        highlightPropName = "highlight",
                        buttonCaption = 'Show'
                      }) {

  let path = pathPrefix.startsWith('/download') ? meta.clickActionPath : `${pathPrefix}${meta[keyPropName]}`

  const highlighted = meta[highlightPropName];
  let buttonDisabled = false;
  let action = clickCallBack;

  if (highlighted) {
    buttonDisabled = true;
    action = () => {
    };
    path = null;
  }
  return (
    <Grid item>
      <Card sx={{
        maxWidth: 350,
        minWidth: 350,
        maxHeight: 300,
        minHeight: 300,
        position: 'relative',
        marginBottom: (theme) => theme.spacing(5),
        marginRight: (theme) => theme.spacing(5),
      }} variant={'outlined'}>
        <SWCLink path={path} clickCallback={action}>
          <CardContent>
            <Typography variant="h5" component="h2" sx={{
              paddingBottom: (theme) => theme.spacing(1),
              display: 'flex',
            }}>
              {meta[iconPropName] && <Icon>{meta[iconPropName]}</Icon>}
              <StyledTitle highlighted={highlighted}>{meta[labelPropName]}</StyledTitle>
            </Typography>
            <Typography variant="subtitle1"
                        color="textSecondary"
                        component="div"
                        sx={{
                          marginBottom: (theme) => theme.spacing(2),
                        }}>
              {DetailComponent && <DetailComponent detailKey={meta[keyPropName]}/>}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              {meta[descrPropName]}
            </Typography>
          </CardContent>
          <CardActions sx={{
            bottom: (theme) => theme.spacing(2),
            position: 'absolute',
          }}>
            <Button disabled={buttonDisabled} size="small">{buttonCaption}</Button>
          </CardActions>
        </SWCLink>
      </Card>
    </Grid>
  );
}

export default function OverviewList({
                                       items,
                                       title = null,
                                       pathPrefix,
                                       clickCallBack = null,
                                       DetailComponent = null,
                                       keyPropName = 'key',
                                       labelPropName = 'label',
                                       descrPropName = 'description',
                                       iconPropName = 'icon',
                                       highlightPropName = "highlight",
                                       cardButtonCaption = 'Show'
                                     }) {

  const {setTitle} = usePageMeta();

  useEffect(() => {
    if (title) setTitle(title);
  }, [title]);

  return (<Paper sx={{
      padding: (theme) => theme.spacing(1),
      marginBottom: (theme) => theme.spacing(3),
    }} square>
      <StyledOverviewContainer><Grid container
                                     item
                                     xs={12}
                                     spacing={1}
                                     direction="row"
                                     justifyContent="flex-start"
      >{items && items.map((meta, index) =>
        <OverviewCard
          key={index}
          meta={meta}
          clickCallBack={clickCallBack ? () => clickCallBack(meta) : null}
          buttonCaption={cardButtonCaption}
          pathPrefix={pathPrefix}
          DetailComponent={DetailComponent}
          keyPropName={keyPropName}
          labelPropName={labelPropName}
          descrPropName={descrPropName}
          iconPropName={iconPropName}
          highlightPropName={highlightPropName}
        />)}</Grid></StyledOverviewContainer></Paper>
  );
}
