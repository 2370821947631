import React from 'react';
import logo from '../images/advantest-logo.gif';
import logoDark from '../images/advantest-logo-dark.png';
import {useTheme} from "@mui/material/styles";

export default function AdvantestIcon() {

  const theme = useTheme();

  return (
    <p><img src={theme.palette.mode === 'dark' ? logoDark : logo} width="152" height="19" alt="Advantest"/></p>
  );
}
