import React, {useEffect, useState} from 'react';
import {useAuth} from "../../../../react-auth-wrapper";
import {useNotifications} from "../../../../contexts/NotificationsContext";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import {Fab} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styled from "@emotion/styled";
import {Uploader} from "../../../../components/chunksUpload";
import axios from "axios";

const StyledFab = styled(Fab)`
  background-color: #ff1744;
`;
const StyledContainer = styled.div`
  display: flex;
  padding: ${({theme}) => theme.spacing(2)};
`;
const StyledFilename = styled.span`
  padding-left: 10px;
  padding-top: 10px;
`;
const NOTIFICATOR_NAME = 'adminProductUploadStartNotificator';
export default function FileUploadInfo({taskId, fileMeta, handleUploaded}) {
  const [progress, setProgress] = useState(null);
  const [hasError, setHasError] = useState(false);
  const {notify} = useNotifications();
  const {callApi} = useAuth();
  const cancel = {};

  async function sendFileToS3_axios(url) {
    console.log('[FileUploadInfo] call axios', url, 'fileMeta', fileMeta);
    await axios.put(url, fileMeta, {
      headers: {
        "Content-Type": fileMeta.type,
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress || 0);
      },
    })
      .catch((error) => {
        console.log('[FileUploadInfo] error', error)
        if (error.message !== 'Cancelled') {
          const message = `Error while uploading file ${fileMeta.name}: ${error}`;
          notify(NOTIFICATOR_NAME,
            {
              text: message,
              error: error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });
    handleUploaded(false);
  }

  async function uploadFileChunksToS3() {
    setProgress(0);
    const uploaderOptions = {
      file: fileMeta,
      baseURL: `/swc/api/admin/builds/upload/my/tasks/${taskId}/multipart`,
      threadsQuantity: 1,
      callApi: callApi,
      cancel: cancel
    };

    const uploader = new Uploader(uploaderOptions);
    uploader
      .onProgress(({percentage: newPercentage}) => {
        if (newPercentage !== progress) {
          setProgress(newPercentage);
        }
      })
      .onComplete(() => {
        handleUploaded(false);
      })
      .onError((error) => {
        if (error.message !== 'Cancelled') {
          uploader.sendAbortRequest();
          handleUploaded(true);
          const message = `Error while chunk-uploading for file ${fileMeta.name}: ${error.type === 'timeout' ? "Timeout exceeded" : error}`;
          setHasError(true);
          notify(NOTIFICATOR_NAME,
            {
              text: message,
              error: error.type === 'timeout' ? "Timeout exceeded" : error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      })

    uploader.start();
  }

  async function uploadFileToS3() {
    setProgress(0);
    const urlCalculationEndpoint = `/swc/api/admin/builds/upload/my/tasks/${taskId}/files`;

    callApi(urlCalculationEndpoint,
      {
        method: 'PUT',
        body: JSON.stringify({name: fileMeta.name, size: fileMeta.size})
      }, cancel)
      .then((response) => {
        console.log('[FileUploadInfo] upload url', response);
        sendFileToS3_axios(response.url);
      })
      .catch((error) => {
        console.log('[FileUploadInfo] error', error);
        if (error.message !== 'Cancelled') {
          const message = `Error while uploading file ${fileMeta.name}: ${error}`;
          setHasError(true);
          notify(NOTIFICATOR_NAME,
            {
              text: message,
              error: error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
        handleUploaded(true);
      });
  }

  const showError = () => {
    return <StyledFab size='small'><ErrorOutlineIcon fontSize='small'/></StyledFab>
  }

  useEffect(() => {
    if (cancel.resetCancel) cancel.resetCancel();
    if (fileMeta.size <= 5000000) { // 5 Mebibyte = 5,24288 Megabyte
      uploadFileToS3();
    } else {
      uploadFileChunksToS3();
    }
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  return (
    <StyledContainer>{hasError ? showError() :
      <CircularProgressWithLabel value={progress}/>}<StyledFilename>{`Upload file ${fileMeta.name}`}</StyledFilename>
    </StyledContainer>);
}
