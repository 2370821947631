import React from 'react';
import { useAuth } from '../react-auth-wrapper';

export default function ErrorMessages({ response = {}, message = "Some error happened:" }) {

  const { errorCodeMap } = useAuth();
  const statusMessage = errorCodeMap[response.status];

  const getDetailedMessage = () => {
    if (response.fieldErrorMessages !== undefined) {
      return response.fieldErrorMessages.map((error, index) => <div key={index}>{error}</div>)
    } else {
      return getMessageOrDetail()
    }
  };

  const getMessageOrDetail = () => {
    if (response.title && response.detail) {
      return `${response.title}. ${response.detail}`
    } else if (response.title) {
      return response.title
    } else if (response.detail) {
      return response.detail
    } else if (response.message) {
      return response.message
    }
    return null
  }

  const detailedMessage = getDetailedMessage();
  const statusErrorMessage = statusMessage ? errorCodeMap[response.status] : null;

  return (<React.Fragment>{message} {statusErrorMessage ? statusErrorMessage : detailedMessage}
    {statusErrorMessage && detailedMessage ? <div>Cause: {detailedMessage}</div> : null}
  </React.Fragment>);
}
