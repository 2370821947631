import PropTypes, { string } from "prop-types";

const propTypeDownloadType = PropTypes.oneOf(['DEFAULT', 'SIGNED']);

const propTypeFileMeta = PropTypes.shape({
  fileName: PropTypes.string.isRequired,
  size: PropTypes.number,
  sha256: PropTypes.string,
  flags: PropTypes.arrayOf(PropTypes.string),
});

const propTypeVersion = PropTypes.shape({
  mainVersion: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  classifier: PropTypes.string.isRequired,
  size: PropTypes.number,
  releaseDate: PropTypes.string,
  releaseNotes: PropTypes.arrayOf(PropTypes.string),
  os: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  betaType: PropTypes.bool.isRequired,
  installCommand: PropTypes.shape({
    command: PropTypes.string.isRequired,
  }),
  files: PropTypes.arrayOf(propTypeFileMeta),
  baseline: PropTypes.string,
  gitHash: PropTypes.string,
  buildVersionType: PropTypes.oneOf(['REFERENCE', 'INTEGRATION']),
  properties: PropTypes.shape({
    qualified: PropTypes.bool
  })
});

const propTypeProductMeta = PropTypes.shape({
  productKey: PropTypes.string.isRequired,
  productLabel: PropTypes.string.isRequired,
  productDescription: PropTypes.string,
  productIcon: PropTypes.string,
  productAttributes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.string,
    optional: PropTypes.bool.isRequired,
    values: PropTypes.arrayOf(PropTypes.string),
  })),
  groupKey: PropTypes.string.isRequired,
  groupLabel: PropTypes.string.isRequired,
  groupDescription: PropTypes.string,
  groupIcon: PropTypes.string,
  categoryKey: PropTypes.string.isRequired,
  categoryLabel: PropTypes.string.isRequired,
  categoryDescription: PropTypes.string,
  categoryIcon: PropTypes.string,
  rootNodeUrls: PropTypes.arrayOf(PropTypes.shape({})),
  downloadType: propTypeDownloadType,
});
const uploadVersionPatternMeta = PropTypes.shape({
  pattern: PropTypes.string.isRequired,
  regex: PropTypes.string.isRequired,
});
const uploadBuildTypeMeta = PropTypes.shape({
  buildType: PropTypes.string.isRequired,
  versionPatternList: PropTypes.arrayOf(uploadVersionPatternMeta),
});
const uploadOsMeta = PropTypes.shape({
  os: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string,
});
const uploadFileMeta = PropTypes.shape({
  extension: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
});
const propUploadProductMeta = PropTypes.shape({
  productKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  fileTypeList: PropTypes.arrayOf(uploadFileMeta),
  buildTypeList: PropTypes.arrayOf(uploadBuildTypeMeta),
  osList: PropTypes.arrayOf(uploadOsMeta),
  licenses: PropTypes.shape({
    optional: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
});

const propUploadSectionMeta = PropTypes.shape({
  name: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(propUploadProductMeta)
});
const propUploadTaskParameter = PropTypes.shape({
  productKey: PropTypes.string,
  os: PropTypes.string,
  buildType: PropTypes.oneOf(['RELEASE', 'CS', 'BETA', 'CSBETA']),
  version: PropTypes.string,
  releaseType: PropTypes.string,
  buildAudienceList: PropTypes.arrayOf(PropTypes.string),
});
const propUploadFile = PropTypes.shape({
  name: PropTypes.string,
  size: PropTypes.number,
  sha256: PropTypes.string,
});
const propUploadTaskInfo = PropTypes.shape({
  id: PropTypes.string,
  state: PropTypes.oneOf(['INITIALIZED', 'PARAMETRIZED', 'UPLOADING', 'CONFIRMED', 'PUBLISHING', 'CANCELLED', 'PUBLISHED', 'FAILURE']),
  parameter: propUploadTaskParameter,
  files: PropTypes.arrayOf(propUploadFile),
  ownerEmail: PropTypes.string,
  failure: PropTypes.string,
  lastModifiedDate: PropTypes.string,
});
const propUploadProduct = PropTypes.shape({
  section: propUploadSectionMeta,
  product: propUploadProductMeta,
  version: PropTypes.string,
  osVersion: uploadOsMeta,
  releaseType: PropTypes.string
});

export const SWC_PROP_TYPES = {
  downloadType: propTypeDownloadType,
  fileMeta: propTypeFileMeta,
  version: propTypeVersion,
  productMeta: propTypeProductMeta,
  versionItem: PropTypes.shape({
    version: propTypeVersion.isRequired,
    productMeta: propTypeProductMeta.isRequired
  }),
  uploadProduct: propUploadProduct,
  uploadMeta: PropTypes.arrayOf(propUploadSectionMeta),
  buildAudience: PropTypes.shape({
    buildAudience: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string
  }),
  uploadTaskInfo: propUploadTaskInfo,
  uploadFileMeta: propUploadFile,
}

export const BuildAudience = PropTypes.shape({
  description: PropTypes.string.isRequired,
  buildAudience: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
});
