import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationDialog from '../ConfirmationDialog';
import { useAuth } from '../../react-auth-wrapper';
import { useNotifications } from '../../contexts/NotificationsContext';

export default function TranslationActions({ property, afterActionHandler, editTranslationHandler }) {

  const [confirmOpen, setConfirmOpen] = useState(false);
  const { callApi } = useAuth();
  const { notify } = useNotifications();
  const cancel = {};

  const handleDelete = () => {
    callApi(`/swc/api/properties/propertyType/${property.propertyType}/keyType/${property.keyType}/key/${property.key}`,
      { method: 'DELETE' },
      cancel, true)
    .then((response) => {
      console.log('[TranslationActions] deleted', response);
      afterActionHandler();
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        notify('translationErrors', {
          text: "Error while translation deleting:",
          error: error,
          type: 'error',
          handleClose: () => {
          }
        });
        setConfirmOpen(false);
      }
    });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  };

  return (
    <React.Fragment><Tooltip title="Delete translation" placement="bottom">
      <IconButton
        aria-label="Translations"
        onClick={() => setConfirmOpen(true)}
        size="large">
        <DeleteIcon/>
      </IconButton>
    </Tooltip>
      <Tooltip title="Edit translation" placement="bottom">
        <IconButton
          aria-label="Translations"
          onClick={() => editTranslationHandler(property)}
          size="large">
          <EditIcon/>
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        open={confirmOpen}
        handleSubmit={handleDelete}
        handleCancel={() => setConfirmOpen(false)}
        title="Delete translation"
      >
        Do you really want to delete the
        translation <i>{property.propertyType} / {property.key} / {property.keyType} / {property.value}</i>
        {' '}
        ?
      </ConfirmationDialog>
    </React.Fragment>
  );
}

TranslationActions.propTypes = {
  property: PropTypes.shape({
    propertyType: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    keyType: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  afterActionHandler: PropTypes.func.isRequired,
  editTranslationHandler: PropTypes.func.isRequired,
};
