import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useNotifications} from '../../../contexts/NotificationsContext';
import {useAuth} from '../../../react-auth-wrapper';
import {
  StyledFileDialogButton,
  StyledFileDialogButtonContainer,
  StyledFileDialogInput,
  StyledFileDialogLoadingIcon
} from "./dialogStyles";


export default function FileUploadDialog({bucket, handleUploaded, hide, folderPath}) {
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const [backgroundTaskRunning, setBackgroundTaskRunning] = useState(false);
  const [fileMeta, setFileMeta] = useState(undefined);

  const inputFileRef = useRef();

  const cancel = {};

  async function handleFormSubmit(event) {
    event.preventDefault();
    setBackgroundTaskRunning(true);

    const absolutePath = folderPath ? `${folderPath}${fileMeta.name}` : fileMeta.name;
    const urlCalculationEndpoint = `/swc/api/buckets/${bucket.bucketId}/objects/url/PUT?path=${encodeURIComponent(
      absolutePath)}&contentType=${encodeURIComponent(fileMeta.type)}`;

    try {
      const {url} = await callApi(urlCalculationEndpoint,
        {method: 'GET'},
        cancel,
        false);

      const {files} = inputFileRef.current;
      const file = files[0];
      const options = {
        headers: {'Content-Type': file.type},
        method: 'PUT',
        body: file,
      };
      await callApi(url, options, cancel, true);
      setBackgroundTaskRunning(false);
      handleUploaded();
    } catch (error) {
      if (error.message !== 'Cancelled') {
        setBackgroundTaskRunning(false);
        const message = `Error while uploading file: ${error}`;
        notify('commonNotificator',
          {
            text: message,
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    }
  }

  function handleFileChanged() {
    const {files} = inputFileRef.current;

    let newFileMeta;

    if (files && files[0]) {
      newFileMeta = {
        name: files[0].name,
        size: files[0].size,
        type: files[0].type,
      };
    }

    setFileMeta(newFileMeta);
  }

  function renderUploadButton() {
    if (backgroundTaskRunning) {
      return <StyledFileDialogLoadingIcon color="primary" size={24}/>;
    }
    return 'Upload';
  }

  const submitButtonDisabled = !fileMeta;

  return (
    <div>
      <form>
        <div>
          <label className="mdl-button mdl-js-button mdl-button--icon mdl-button--file">
            <i className="material-icons">attach_file</i>
            {fileMeta ? fileMeta.name : (<i>No file chosen</i>)}
            <StyledFileDialogInput required
                                   onChange={handleFileChanged}
                                   ref={inputFileRef}
                                   type="file"/>
          </label>
        </div>
        <StyledFileDialogButtonContainer>
          <StyledFileDialogButton
            variant="outlined"
            color="primary"
            type="submit"
            onClick={handleFormSubmit}
            disabled={submitButtonDisabled || backgroundTaskRunning}
          >
            {renderUploadButton()}
          </StyledFileDialogButton>
          <StyledFileDialogButton
            disabled={backgroundTaskRunning}
            variant="outlined"
            type="button"
            onClick={hide}
          >
            Cancel
          </StyledFileDialogButton>
        </StyledFileDialogButtonContainer>
      </form>
    </div>
  );
}

FileUploadDialog.propTypes = {
  bucket: PropTypes.shape({
    cloudKey: PropTypes.string.isRequired,
    regionKey: PropTypes.string.isRequired,
    bucketId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  handleUploaded: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  folderPath: PropTypes.string.isRequired,
};
