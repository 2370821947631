import React, {useEffect, useRef, useState} from "react";
import {useNotifications} from "../contexts/NotificationsContext";
import {useAuth} from "../react-auth-wrapper";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {useLocation} from 'react-router-dom';
import styled from "@emotion/styled";

const StyledNotification = styled.div`
  padding-bottom: ${({theme}) => theme.spacing(2)};
`;

const StyledNotifications = styled.div`
  padding-bottom: ${({theme}) => theme.spacing(3)};
`;

const HiddenStack = styled(Stack)`
  display: none;
`;

const HiddenAlert = styled(Alert)`
  display: none;
`;

function useStorageDownloader() {
  const {callApi} = useAuth();
  const [loading, setLoading] = useState(false);
  const {notify} = useNotifications();

  const invoke = (bucketId, path) => {

    async function triggerDownload(downloadUrl) {

      console.log(`[storageDownloader] downloadUrl=${downloadUrl}`);

      function htmlEncode(str) {
        var temp = document.createElement('div');
        if (temp.textContent !== undefined) {
          temp.textContent = str;
        } else {
          temp.innerText = str;
        }
        return temp.innerHTML;
      }

      function showNotEntitledToOpenWindowError() {
        notify("commonNotificator",
          {
            text: 'Please allow popup opening to see downloaded data.',
            type: "error",
            handleClose: () => {
            }
          });
      }

      async function convertToBase64Src(documentUrl) {
        const documentResponse = await fetch(documentUrl);
        const contentTypeRaw = documentResponse.headers.get('Content-Type')
        const contentType = contentTypeRaw || "text/plain";
        console.log(`[storageDownloader] contentType=${contentTypeRaw}`);
        const arrayBuffer = await documentResponse.arrayBuffer();
        const typedArray = new Uint8Array(arrayBuffer);
        let binary = '';
        const len = typedArray.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(typedArray[i]);
        }
        const base64 = btoa(binary);
        return `"data:${contentType};base64,${base64}`;
      }

      const wnd = window.open('about:blank', '');
      if (!wnd) {
        showNotEntitledToOpenWindowError();
      } else {
        try {
          const base64SrcString = await convertToBase64Src(downloadUrl);
          wnd.document.write(`<html><head><title>${htmlEncode(path)}</title></head><body><iframe style="border: 0; width: 100%; height: 100%;" src=${base64SrcString}"/></body></html>`);
        } catch (error) {
          console.log(`[storageDownloader] bucketId=${bucketId}, path=${path} `, error);
          notify("commonNotificator",
            {
              text: 'Could not download the request data.',
              type: "error",
              handleClose: () => {
              }
            });
        }
      }
    }

    function handleError(error) {
      if (error.message !== 'Cancelled') {
        notify(
          'commonNotificator',
          {
            text: 'Error while downloading:',
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    }

    console.log(`[storageDownloader] invoked with bucketId=${bucketId}, path=${path}`);

    if (bucketId === null || bucketId === undefined) {
      console.error('[storageDownloader] parameter bucketId or path maynot be null');
      return
    }

    let queryParameter = '';
    if (path) {
      queryParameter = `${queryParameter}&path=${encodeURIComponent(path)}`;
    }

    setLoading(true);
    callApi(`/swc/api/buckets/${encodeURIComponent(bucketId)}/objects/url/GET?${queryParameter}`)
      .then(response => triggerDownload(response.url))
      .catch(handleError)
      .finally(() => setLoading(false));

  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return {
    invoke,
    loading,
  };
}

const StyledNotificationOnClickWrapper = ({id, htmlFragment}) => {
  const containerRef = useRef(null);
  const downloader = useStorageDownloader();

  useEffect(() => {
    if (containerRef.current && htmlFragment) {
      const elementList = containerRef.current.querySelectorAll('.signed-url');
      for (const element of elementList) {
        element.addEventListener('click', handleClick);
      }
      return () => {
        for (const element of elementList) {
          element.removeEventListener('click', handleClick);
        }
      };
    }
  }, [htmlFragment]);

  console.log("[StyledNotificationOnClickWrapper] ref=", containerRef.current)

  const handleClick = (event) => {

    if (event && event.target) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent?.stopImmediatePropagation();
      const element = event.target;
      const bucketId = element.getAttribute('data-bucket-id');
      const path = element.getAttribute('data-path');

      downloader.invoke(bucketId, path);
    }
  };

  return (
    <StyledNotification id={id} key={id} ref={containerRef}
                        dangerouslySetInnerHTML={{__html: htmlFragment}}/>
  );
};

export default function AdminNotifications() {
  const [notifications, setNotifications] = useState(null);
  const {notify} = useNotifications();
  const {callApi} = useAuth();
  const currentLocation = useLocation();
  const cancel = {};

  function loadAdminNotifications() {
    callApi(`/swc/api/notifications`, {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[AdminNotifications]', response);
        setNotifications(response.values);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setNotifications([]);
          notify('commonNotificator', {
            text: "Error while loading admin notifications:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
  }

  function getAlertHtml(type, contentId, content) {
    const alertId = `mui-${type}-html`;
    const alertHtml = document.getElementById(alertId).innerHTML;
    return alertHtml.replace("content", content);
  }

  function getValidType(type) {
    if (type && ["info", "success", "warning", "error"].includes(type)) {
      return type;
    }
    return "info";
  }

  function extractNotificationPathWithoutType(notification) {
    const indexOf = notification.path.lastIndexOf("?type=");
    if (indexOf === -1) {
      return notification.path;
    }
    return notification.path.substring(0, indexOf);
  }

  function showAdminNotifications() {

    const matches = (notification) => {
      const sanitizedPattern = extractNotificationPathWithoutType(notification);
      const pathToMatch = currentLocation?.pathname ?? '';

      // If sanitizedPattern starts with "^", treat it as a regex
      if (sanitizedPattern.startsWith("^")) {
        try {
          return new RegExp(sanitizedPattern).test(pathToMatch);
        } catch {
          return false;
        }
      }

      return pathToMatch.startsWith(sanitizedPattern);
    };

    const result = notifications && notifications.filter(notification => notification.path === currentLocation.pathname || matches(notification) || notification.path === '/')
      .map(notification => {
        const type = new URLSearchParams(notification.path.substring(notification.path.indexOf("?"))).get('type');
        return <StyledNotificationOnClickWrapper id={notification.id} key={notification.id}
                                                 htmlFragment={getAlertHtml(getValidType(type), notification.id, notification.value)}/>;
      })
    return <StyledNotifications>{result}</StyledNotifications>;
  }

  useEffect(() => {
    loadAdminNotifications();
  }, [currentLocation.pathname])

  return (<React.Fragment>
    <HiddenStack sx={{width: '100%'}} spacing={2}>
      <div id="mui-error-html"><Alert severity="error">content</Alert></div>
      <div id="mui-warning-html"><Alert severity="warning" id="mui-warning-html">content</Alert></div>
      <div id="mui-info-html"><Alert severity="info" id="mui-info-html">content</Alert></div>
      <div id="mui-success-html"><Alert severity="success" id="mui-success-html">content</Alert></div>
    </HiddenStack>
    {notifications && showAdminNotifications()}
    <HiddenAlert/>
  </React.Fragment>)
}
