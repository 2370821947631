import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import {styled} from "@mui/material/styles";

const AccordionSummary = styled(MuiExpansionPanelSummary)(({theme}) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}));

export default AccordionSummary;
