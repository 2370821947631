import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React from 'react';
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import LoadingIcon from './LoadingIcon';

const costCss = {
  borderRight: '1px solid rgba(224, 224, 224, 1)',
};
const secondaryTextCss = {
 typography: 'button'
};

export default function LastAndThisMonthCosts({ lastMonthCost, thisMonthCost, centDigitLength = 2 }) {

  const show = () => {
    return lastMonthCost !== null && lastMonthCost >= 0 && thisMonthCost !== null && thisMonthCost >= 0
  };

  return (
    <React.Fragment>
      <Paper sx={{
        marginBottom: '20px',
      }}>
        <List component="nav" aria-label="user cost" sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
        }}>
          <ListItem sx={costCss}>
            <ListItemText align="center" sx={{ secondary: secondaryTextCss }}
                          primary={
                            <Typography variant="h6">{show() ? `$${lastMonthCost.toFixed(
                              centDigitLength)}` : <LoadingIcon size={16}/>}</Typography>}
                          secondary="Last month costs"/>
          </ListItem>
          <ListItem sx={costCss}>
            <ListItemText align="center" sx={{ secondary: secondaryTextCss }}
                          primary={
                            <Typography variant="h6">{show() ? `$${thisMonthCost.toFixed(
                              centDigitLength)}` : <LoadingIcon size={16}/>}</Typography>}
                          secondary="This month costs"/>
          </ListItem>
          <Grid sx={{
            width: "50px",
            paddingLeft: "3%",
            paddingRight: "2%"
          }}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
          >
            <Tooltip title="Pricing Information">
              <Button
                aria-label="Infolink"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
                href="https://weshare.advantest.com/vs/display/SWC/Virtual+Machine+instance+pricing"
                startIcon={<InfoIcon/>}/>
            </Tooltip>
          </Grid>
        </List>
      </Paper>
    </React.Fragment>
  );
}

LastAndThisMonthCosts.propTypes = {
  lastMonthCost: PropTypes.number,
  thisMonthCost: PropTypes.number,
  centDigitLength: PropTypes.number,
};
