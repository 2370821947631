import React from 'react';
import {FilterChip} from "./FilterChip";


export default function FilterChipGroupAttribute({
                                                 productList,
                                                 selectedAttributes,
                                                 setSelectedAttributes,
                                                 disabled
                                               }) {

  const productAttributeMappings = [];

  for (const productMeta of (productList || [])) {
    for (const productAttribute of (productMeta.productAttributes || [])) {
      for (const productAttributeValue of (productAttribute.values || [])) {
        productAttributeMappings.push(
          {
            key: productAttribute.key,
            value: productAttributeValue,
            label: `${productMeta.productLabel}:${productAttribute.label}:${productAttributeValue}`,
            selection: `${productAttribute.key}=${productAttributeValue}`,
            tooltip: `${productAttribute.label}=${productAttributeValue}`,
          }
        )
      }
    }
  }

  const sortedProductAttributeMappings = [...productAttributeMappings].sort((a, b) => a.label.localeCompare(b.label));
  const selectedProductAttributeMappings = sortedProductAttributeMappings.filter(item => (selectedAttributes || []).indexOf(item.selection) > -1)

  const handleDelete = (item) => {
    setSelectedAttributes(selectedAttributes.filter(current => current !== item.selection));
  };

  return (
    <React.Fragment>
      {selectedProductAttributeMappings.map(item =>
        <FilterChip
          key={item.selection}
          tooltip={item.tooltip}
          disabled={disabled}
          label={item.label}
          onDelete={() => handleDelete(item)}/>
      )}
    </React.Fragment>
  );
}
