import React, {useEffect, useState} from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faFile,
  faFolder,
  faFolderOpen,
  faMinusSquare,
  faPlusSquare,
  faSquareFull,
} from '@fortawesome/free-solid-svg-icons';

import {BuildTypeChip, OSChip} from '../../../../components/BuildDetail/TypeChip';
import Selection from '../selection';
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  padding-left: 40px;
  padding-bottom: 20px;
`;
export default function ProductVersionSelection({group, builds, handleProjectVersionSelectionChange}) {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [nodeSelectionMapping, setModeSelectionMapping] = useState({});

  const loadNodes = () => {
    const mapping = {};

    function mapLeaf(level1, level2, level3) {
      const selectionKey = `l3:${level1.productMeta.productKey}/${level2.version}/${level3.os}/${level3.type}/${level3.classifier}`;
      const selection = new Selection(
        level1.productMeta,
        level3.os,
        level3.type,
        level3.version,
        level3.classifier,
        level3.betaType,
        level3.size,
      );
      mapping[selectionKey] = selection;

      return {
        label: (
          <React.Fragment>
            <BuildTypeChip version={level3}/>
            {level3.version}
            <OSChip version={level3}/>
          </React.Fragment>
        ),
        value: selectionKey,
      };
    }

    function mapLevel2(level1, level2) {
      return {
        label: (<React.Fragment>{level2.version}</React.Fragment>),
        value: `l2:${level1.productMeta.productKey}/${level2.version}`,
        children: level2.versionList.map(v => mapLeaf(level1, level2, v)),
      };
    }

    function mapLevel1(level1) {
      return {
        label: (<React.Fragment>{level1.productMeta.productLabel}</React.Fragment>),
        value: `l1:${level1.productMeta.productKey}`,
        children: level1.groupedVersionsList.map(c => mapLevel2(level1, c)),
      };
    }

    if (builds && builds.length) {
      console.log('[ProjectVersionSelection]', 'Loading nodes ...', group, builds);
      const listOfTrees = builds.map(mapLevel1);

      setNodes(listOfTrees);
      setModeSelectionMapping(mapping);
      setExpanded(listOfTrees.map(tree => tree.value));
      console.log('[ProjectVersionSelection]', '... nodes loaded.');
    } else {
      console.log('[ProjectVersionSelection]', 'Clearing nodes.');
      setNodes([]);
      setModeSelectionMapping({});
    }
  };

  const handleChange = () => {
    const selections = checked.map(selectionKey => nodeSelectionMapping[selectionKey]).filter(
      selection => selection);
    console.log('[ProjectVersionSelection]', `#selectedLeafs=${selections.length}`);
    handleProjectVersionSelectionChange(selections);
  };

  useEffect(() => {
    setChecked([]);
    setExpanded([]);
    loadNodes();
  }, [builds]);

  useEffect(handleChange, [checked]);

  return (
    <StyledContainer>
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={setChecked}
        onExpand={setExpanded}
        icons={{
          check:
            <FontAwesomeIcon
              className="rct-icon rct-icon-check"
              icon={faCheckSquare}
            />,
          uncheck:
            <FontAwesomeIcon
              className="rct-icon rct-icon-uncheck"
              icon={faSquareFull}
            />,
          halfCheck:
            <FontAwesomeIcon
              className="rct-icon rct-icon-half-check"
              icon={faCheckSquare}
            />,
          expandClose:
            <FontAwesomeIcon
              className="rct-icon rct-icon-expand-close"
              icon={faChevronRight}
            />,
          expandOpen:
            <FontAwesomeIcon
              className="rct-icon rct-icon-expand-open"
              icon={faChevronDown}
            />,
          expandAll:
            <FontAwesomeIcon
              className="rct-icon rct-icon-expand-all"
              icon={faPlusSquare}
            />,
          collapseAll:
            <FontAwesomeIcon
              className="rct-icon rct-icon-collapse-all"
              icon={faMinusSquare}
            />,
          parentClose:
            <FontAwesomeIcon
              className="rct-icon rct-icon-parent-close"
              icon={faFolder}
            />,
          parentOpen:
            <FontAwesomeIcon
              className="rct-icon rct-icon-parent-open"
              icon={faFolderOpen}
            />,
          leaf:
            <FontAwesomeIcon
              className="rct-icon rct-icon-leaf-close"
              icon={faFile}
            />,
        }}
      />
    </StyledContainer>
  );
}
