import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SWCLink from "../../SWCLink";
import styled from "@emotion/styled";

const StyledCell = styled.span`
  margin-right: ${({theme}) => theme.spacing(1)};
`;
export default function BuildMainVersionCell({
                                               item, clickCallback = () => {
  }, versionAsToolTip = false
                                             }) {
  const rawVersion = item?.version?.mainVersion;
  let tooltip = item?.version?.mainVersion || rawVersion
  if (versionAsToolTip) {
    tooltip = item?.version?.version || rawVersion
  }

  return rawVersion ? (<StyledCell>
    <SWCLink clickCallback={clickCallback}>
      <Tooltip
        title={tooltip}
        data-main-version={rawVersion}>
        <span>{rawVersion}</span>
      </Tooltip></SWCLink>
  </StyledCell>) : null;
}

BuildMainVersionCell.propTypes = {
  clickCallback: PropTypes.func,
  versionAsToolTip: PropTypes.bool,
  item: PropTypes.shape({
    version: PropTypes.shape({
      mainVersion: PropTypes.string,
    }),
  }).isRequired,
};
