import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';
import styled from "@emotion/styled";

const StyledLabelContainer = styled.span`
  white-space: nowrap;
`;
export default function FileSize({bucketObject}) {

  const size = bucketObject && !bucketObject.folder && bucketObject.size;
  const label = size ? prettyBytes(size, {binary: true}) : '';
  const tooltip = bucketObject && bucketObject.size;

  return (
    size ? (
      <Tooltip title={tooltip} data-bucket-object-size={tooltip}>
        <StyledLabelContainer>{label}</StyledLabelContainer>
      </Tooltip>
    ) : null
  );
}

FileSize.propTypes = {
  fileObject: PropTypes.shape({
    size: PropTypes.number,
    folder: PropTypes.bool,
  }).isRequired,
};
