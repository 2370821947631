import React from 'react';
import {TextField} from "@mui/material";
import {StyledSearchFormAutocomplete} from "./SearchForm";

export default function SubscriptionSelection({
                                                  subscriptionMetaList,
                                                  subscriptionDefaultKey,
                                                  selectedSubscription,
                                                  setSelectedSubscription,
                                                  disabled,
                                              }) {

    const selectedSubscriptionMeta = subscriptionMetaList.find(subscriptionMeta => subscriptionMeta.key === selectedSubscription) ||
        subscriptionMetaList.find(subscriptionMeta => subscriptionMeta.key === subscriptionDefaultKey);

    const handleChange = (event, value) => {
        setSelectedSubscription(value?.key || subscriptionDefaultKey)
    };

    return (
        <StyledSearchFormAutocomplete
            disabled={disabled}
            id="search-selected-subscription"
            options={[...subscriptionMetaList]}
            getOptionLabel={(subscriptionMeta) => subscriptionMeta.label || ""}
            onChange={handleChange}
            value={selectedSubscriptionMeta}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Email subscription"
                    placeholder="Subscription"
                />
            )}
        />
    );
}
