import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import FormattedDateTime from '../FormattedDateTime';

export default function FileLastModificationTime({bucketObject}) {
  const tooltip = bucketObject.lastModified;
  const label = bucketObject.lastModified
    ? <FormattedDateTime dateTimeString={bucketObject.lastModified}/> : null;

  return bucketObject.lastModified ?
    (
      <Tooltip
        title={tooltip}
        data-bucket-last-modificatio-time={bucketObject.lastModified}>
        <span>{label}</span>
      </Tooltip>
    ) : null;
}

FileLastModificationTime.propTypes = {
  bucketObject: PropTypes.shape({
    lastModified: PropTypes.string,
  }).isRequired,
};
