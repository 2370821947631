import React, {useEffect, useState} from 'react';
import {Button, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import Card from '@mui/material/Card';
import useInterval from '../../../hooks/useInterval';
import {useAuth} from '../../../react-auth-wrapper';
import {usePageMeta} from '../../../contexts/PageMetaContext';
import {useNotifications} from '../../../contexts/NotificationsContext';
import ScrollableList from '../../../components/ScrollableList';
import TimeStamp from '../../../components/TimeStamp';
import {postProcessTask, TASK_STATE, TASK_TYPE} from './taskFunctions';
import LABEL from './labels';
import TaskState from './TaskState';
import AdminDownloadTabs from '../../../menu/admin/AdminDownloadTabs';
import {ADMIN_DOWNLOADS} from '../../../components/PathConstants';
import ListWrapper from '../../../components/ListWrapper';
import {BuildSelection} from '../../../components/BuildDetail/BuildSelection';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import LoadingIcon from '../../../components/LoadingIcon';
import OPERATIONS_META from '../BuildsBulkOperations/steps/operationsMeta';
import {useParams} from "react-router-dom";
import styled from "@emotion/styled";

const StyledBucketDetailContainer = styled.div`
  margin-top: ${({theme}) => theme.spacing(2)};
  margin-bottom: ${({theme}) => theme.spacing(3)};
`;
const StyledBucketDetailTypography = styled(Typography)`
  padding-bottom: ${({theme}) => theme.spacing(1)};
`;
const StyledLoadingIcon = styled(LoadingIcon)`
  margin-top: ${({theme}) => theme.spacing(20)};
  margin: auto;
`;
const StyledIcon = styled(Icon)`
  margin-right: ${({theme}) => theme.spacing(2) / 4};
  padding-top: ${({theme}) => theme.spacing(2) / 4};
`;
const StyledButton = styled(Button)`
  margin-top: ${({theme}) => theme.spacing(2)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;
const StyledScrollableListContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(1)};
`;

const detailsHeadRow = [
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Detail',
  },
  {
    id: 'value', numeric: false, disablePadding: false, label: 'Value',
  },
];

const valuesContainer = detail => detail;
const NOTIFICATOR_NAME = 'buildsBulkTaskDetailNotificator';

export default function BuildsBulkTaskDetail({
                                               taskType = TASK_TYPE.BUILD_MOVE_TO_CLOUD_BUCKET,
                                               headerTitle = 'Administration - Build Bulk Tasks'
                                             }) {
  const {taskId} = useParams();

  const [reloadInterval] = useState(60 * 1000);
  const [dataLoading, setDataLoading] = useState(false);
  const [task, setTask] = useState(null);
  const [buckets, setBuckets] = useState(null);
  const [parameterByProductLabel, setParameterByProductLabel] = useState([]);
  const [products, setProducts] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const {callApi} = useAuth();
  const {setTitle, goTo} = usePageMeta();
  const {notificator, notify, clearNotificator} = useNotifications();
  const cancel = {};


  function signalApiError(error) {
    notify(NOTIFICATOR_NAME,
      {
        text: 'Error while loading task details:',
        error,
        type: 'error',
        handleClose: () => {
        },
      });
  }

  function loadBucket() {
    console.log('[BulkBuildsOperations]', 'Fetching buckets...');
    setDataLoading(true);
    callApi('/swc/api/buckets', {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[BulkBuildsOperations]', 'buckets', response);
        setBuckets(response);
        setDataLoading(false);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setBuckets(null);
          setDataLoading(false);
          signalApiError(error);
        }
      })
      .finally(() => {
      });
  }

  function loadProducts() {
    setDataLoading(true);
    console.log('[BuildsBulkTaskDetail] Loading products...');
    callApi('/swc/api/admin/products', {method: 'GET'}, cancel).then((response) => {
      console.log('[BuildsBulkTaskDetail]', response);
      setProducts(response.productMetas);
      setDataLoading(false);
    }).catch((error) => {
      if (error.message !== 'Cancelled') {
        setDataLoading(false);
        setProducts(null);
        signalApiError(error);
      }
    });
  }

  function loadTask() {
    const postProcessSingleTask = postProcessTask(taskType)(products);

    if (products) {
      setDataLoading(true);
      clearNotificator(NOTIFICATOR_NAME);

      callApi(`/swc/api/admin/tasks/${taskType}/${taskId}`, {method: 'GET'}, cancel)
        .then((response) => {
          console.log('[BuildsBulkTaskDetail]', response);
          const postProcessedTask = postProcessSingleTask(response);
          setTask(postProcessedTask);
          setDataLoading(false);
        })
        .catch((error) => {
          if (error.message !== 'Cancelled') {
            setDataLoading(false);
            if (error.status === 404) {
              console.log(`[BuildsBulkTaskDetail] ${taskId} does not exists, redirecting`, error.status);
              goTo(`${ADMIN_DOWNLOADS.bulkTasks.path}/`);
            } else {
              signalApiError(error);
            }
          }
        });
    }
  }

  function loadParameterByProductLabel() {
    let productSelectionsTupleList = [];

    if (task && taskType === TASK_TYPE.BUILD_MOVE_TO_CLOUD_BUCKET) {
      const calculateSelectionByProductLabelIndex = () => {
        const selectionList = (task.parameter && task.parameter.selectionList) || [];
        return selectionList.reduce((acc, selection) => {
          const key = selection.product.productLabel;
          if (!acc.get(key)) {
            acc.set(key, []);
          }
          acc.get(key).push(selection);
          return acc;
        }, new Map());
      };

      const toSortedPairs = (selectionByProductLabelIndex) => {
        const tupleList = [];

        const selectionList = (task.parameter && task.parameter.selectionList) || [];
        const rawProductLabelList = selectionList.map(selection => selection.product.productLabel);
        const productLabelList = [...new Set(rawProductLabelList)].sort();

        productLabelList.forEach((productLabel) => {
          tupleList.push({
            productLabel,
            selectionList: selectionByProductLabelIndex.get(productLabel),
          });
        });

        return tupleList;
      };

      const selectionByProductLabelIndex = calculateSelectionByProductLabelIndex();
      productSelectionsTupleList = toSortedPairs(selectionByProductLabelIndex);
    }

    setParameterByProductLabel(productSelectionsTupleList);
  }

  function cancelTask() {
    setConfirmOpen(false);

    setDataLoading(true);
    clearNotificator(NOTIFICATOR_NAME);

    callApi(`/swc/api/admin/tasks/${taskType}/${taskId}/cancel`, {method: 'PUT'}, cancel)
      .then((response) => {
        console.log('[BuildsBulkTaskDetail]', response);
        setTask((prev) => {
          const newTask = {...prev, state: TASK_STATE.CANCELLED};
          return postProcessTask(taskType)(products)(newTask);
        });
        setDataLoading(false);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setDataLoading(false);
          if (error.status === 404) {
            console.log(`[BuildsBulkTaskDetail] ${taskId} does not exists, redirecting`, error.status);
            goTo(`${ADMIN_DOWNLOADS.bulkTasks.path}/`);
          } else {
            signalApiError(error);
          }
        }
      });
  }

  function setPageTitle() {
    let title = headerTitle;
    if (task && task.id) {
      title = `${headerTitle} - ${task.id}`;
    }
    setTitle(title);
  }

  useEffect(() => {
    loadProducts();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);
  useEffect(loadTask, [products]);
  useEffect(loadBucket, [task]);
  useEffect(loadParameterByProductLabel, [task]);
  useEffect(setPageTitle, [headerTitle, task]);

  useInterval(loadProducts, reloadInterval);


  function bucketDetail() {
    let detail = null;

    if (taskType === TASK_TYPE.BUILD_MOVE_TO_CLOUD_BUCKET) {
      if (task.parameter && task.parameter.bucketId) {
        const bucket = (buckets || []).find(bucketItem => bucketItem.bucketId === task.parameter.bucketId) || {};

        detail = (
          <StyledBucketDetailContainer>
            <Card>
              <CardContent>
                <StyledBucketDetailTypography variant="h5" component="h2">
                  {bucket.icon && <StyledIcon>{bucket.icon}</StyledIcon>}
                  <span>{bucket.label || task.parameter.bucketId}</span>
                </StyledBucketDetailTypography>
                <Typography variant="body2" color="textSecondary" component="div">
                  {bucket.description}
                </Typography>
              </CardContent>
            </Card>
          </StyledBucketDetailContainer>
        );
      }
    }

    return detail;
  }

  function operationsDetail() {
    let detail = null;

    if (taskType === TASK_TYPE.BUILD_MOVE_TO_CLOUD_BUCKET) {
      if (task.parameter && task.parameter.operations) {

        const operations = task.parameter.operations || [];
        const operationsList = OPERATIONS_META
          .filter(item => operations.includes(item.operation))
          .map(item => item.label);

        const headRow = [
          {
            id: 'data', numeric: false, disablePadding: false, label: '',
          },
        ];

        detail = (
          <StyledScrollableListContainer>
            <ScrollableList
              title={<Typography variant="subtitle1">Operations</Typography>}
              headRow={headRow}
              showHeadRow
              items={operationsList}
              itemContainer={operation => [(<span>{operation}</span>)]}
            />
          </StyledScrollableListContainer>
        );
      }
    }

    return detail;
  }


  function taskDetail() {
    let selectionParameter = null;

    if (taskType === TASK_TYPE.BUILD_MOVE_TO_CLOUD_BUCKET) {
      const rawSelectionParameter = (parameterByProductLabel || []).map((pair) => {
        const {productLabel, selectionList} = pair;
        const headRow = [
          {
            id: 'data', numeric: false, disablePadding: false, label: '',
          },
        ];

        return (
          <StyledScrollableListContainer key={productLabel}>
            <ScrollableList
              title={<Typography variant="subtitle1">{productLabel}</Typography>}
              headRow={headRow}
              showHeadRow
              items={selectionList}
              itemContainer={selection => [<BuildSelection selection={selection}/>]}
            />
          </StyledScrollableListContainer>
        );
      });

      selectionParameter = (
        <ListWrapper>
          {bucketDetail()}
          {rawSelectionParameter}
          {operationsDetail()}
        </ListWrapper>
      );
    }

    return [
      [LABEL.ID, task.id],
      [LABEL.USER, task.user],
      [LABEL.CREATED, <TimeStamp dateTimeString={task.created}/>],
      [LABEL.UPDATED, <TimeStamp dateTimeString={task.updated}/>],
      [LABEL.STATE, <TaskState task={task}/>],
      [LABEL.PRODUCER, task.producer],
      [LABEL.PRODUCER_APP_VERSION, task.producerAppVersion],
      [LABEL.CONSUMER, task.consumer],
      [LABEL.ASSIGNMENT_COUNTER, task.assignmentCounter],
      [LABEL.PARAMETER, selectionParameter],
      [LABEL.RESULT, task.result ? (<div>
        <pre>{task.result.value}</pre>
      </div>) : null],
    ];
  }

  return (
    <AdminDownloadTabs activeTabPath={ADMIN_DOWNLOADS.bulkTasks.path}>
      <Paper sx={{
        padding: (theme) => theme.spacing(2),
      }} square>
        {notificator(NOTIFICATOR_NAME)}
        {task !== null && (
          <div>
            <ScrollableList
              title={`Build Task ${task.id}`}
              headRow={detailsHeadRow}
              showHeadRow
              items={taskDetail()}
              itemContainer={valuesContainer}
            />
            <ConfirmationDialog
              open={confirmOpen}
              handleSubmit={cancelTask}
              handleCancel={() => setConfirmOpen(false)}
              title="Cancel Task"
            >
              Do you really want to cancel this task?
            </ConfirmationDialog>
          </div>
        )}
        {task !== null && (
          <div>
            {(task.state === TASK_STATE.OPEN || task.state === TASK_STATE.RUNNING)
              ? (
                <StyledButton
                  variant="outlined"
                  color="primary"
                  disabled={dataLoading}
                  onClick={() => setConfirmOpen(true)}
                >
                  Cancel
                </StyledButton>
              ) : null
            }
            <StyledButton
              variant="outlined"
              color="primary"
              disabled={dataLoading}
              onClick={() => goTo(ADMIN_DOWNLOADS.bulkTasks.path)}
            >
              Task Overview
            </StyledButton>
          </div>
        )}
        {dataLoading && <StyledLoadingIcon/>}
      </Paper>
    </AdminDownloadTabs>
  );
}
