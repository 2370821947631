import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotifications } from '../contexts/NotificationsContext';
import { useAuth } from '../react-auth-wrapper';
import LastAndThisMonthCosts from './LastAndThisMonthCosts';

export default function UserCloudCost({ cloudKey }) {

  const [lastMonthCost, setLastMonthCost] = useState(null);
  const [thisMonthCost, setThisMonthCost] = useState(null);
  const [costVisible, setCostVisible] = useState(false);
  const { callApi } = useAuth();
  const { notify } = useNotifications();
  const cancel = {};

  function getUserCloudCost() {
    function checkAWSAccountCostPermissionAndHideCostIfNeeded(reason) {
      const hasNoPermission = reason.response && reason.response.status === 400 && reason.response.type && reason.response.type.endsWith(
        "/cost-explorer-bad-configuration-problem");
      if (hasNoPermission) {
        setCostVisible(false);
      }
      return hasNoPermission;
    }

    if (cloudKey) {
      console.log('[UserCloudCost]', 'Fetching data ...');
      callApi(`/swc/api/clouds/${cloudKey}/user/cost`, { method: 'GET' }, cancel)
      .then((response) => {
        console.log('[UserCloudCost] response', response);
        setLastMonthCost(response.lastMonthCost);
        setThisMonthCost(response.thisMonthCost);
        setCostVisible(true);
      }).catch((reason) => {
        const cloudHasCostPermission = checkAWSAccountCostPermissionAndHideCostIfNeeded(reason);
        if (reason.message !== 'Cancelled' && !cloudHasCostPermission) {
          setLastMonthCost(null);
          setThisMonthCost(null);
          notify('instancesNotificator',
            {
              text: "Error while loading user cloud cost:",
              error: reason,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });
    }
  }

  useEffect(() => {
    setCostVisible(false);
    setLastMonthCost(null);
    setThisMonthCost(null);
    getUserCloudCost();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, [cloudKey]);

  return (costVisible ? <LastAndThisMonthCosts lastMonthCost={lastMonthCost}
                                               thisMonthCost={thisMonthCost}/> : null);
}

UserCloudCost.propTypes = {
  cloudKey: PropTypes.string.isRequired,
};
