import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNotifications} from '../../../contexts/NotificationsContext';
import {useAuth} from '../../../react-auth-wrapper';
import {
  StyledFileDialogButton,
  StyledFileDialogButtonContainer,
  StyledFileDialogLoadingIcon,
  StyledFileDialogTextField
} from "./dialogStyles";

export default function FileMoveDialog({bucketObject, handleFileMoved, hide}) {
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const [backgroundTaskRunning, setBackgroundTaskRunning] = useState(false);
  const cancel = {};

  async function submit(event) {
    console.log('[FileMoveDialog] move called');
    event.preventDefault();

    setBackgroundTaskRunning(true);

    const newPath = event.target.newPath.value

    const urlCalculationEndpoint = `/swc/api/buckets/${bucketObject.bucketId}/objects?path=${encodeURIComponent(
      bucketObject.path)}&newPath=${encodeURIComponent(newPath)}`;

    try {
      await callApi(urlCalculationEndpoint, {method: 'PUT'}, cancel, false);
      setBackgroundTaskRunning(false);
      handleFileMoved(newPath);
    } catch (error) {
      if (error.message !== 'Cancelled') {
        setBackgroundTaskRunning(false);
        const message = `Error while moving file: ${error}`;
        notify('commonNotificator',
          {
            text: message,
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    }
  }

  const submitButtonDisabled = false;

  function renderMoveButton() {
    if (backgroundTaskRunning) {
      return <StyledFileDialogLoadingIcon color="primary" size={24}/>;
    }
    return 'Move';
  }

  const fileNamePattern = "^(([a-z,A-Z,0-9,_,-]+)\\/)*([a-z,A-Z,0-9,_,-]+)[\\.]{0,1}([a-z,A-Z,0-9,_,-]*)$";

  return bucketObject ? (
    <div>
      <form onSubmit={submit}>
        <div>
          <StyledFileDialogTextField
            required
            id="newPath"
            name="newPath"
            label="New file name"
            inputProps={{pattern: fileNamePattern}}
            margin="normal"
            variant="outlined"
            defaultValue={bucketObject.path}
          />
        </div>
        <StyledFileDialogButtonContainer>
          <StyledFileDialogButton
            variant="outlined"
            color="primary"
            type="submit"
            disabled={submitButtonDisabled || backgroundTaskRunning}
          >
            {renderMoveButton()}
          </StyledFileDialogButton>
          <StyledFileDialogButton
            disabled={backgroundTaskRunning}
            variant="outlined"
            type="button"
            onClick={hide}
          >
            Cancel
          </StyledFileDialogButton>
        </StyledFileDialogButtonContainer>
      </form>
    </div>
  ) : null;
}

FileMoveDialog.propTypes = {
  handleFileMoved: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  bucketObject: PropTypes.any,
};
