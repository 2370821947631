import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import FormattedDateTime from './FormattedDateTime';

export default function TimeStamp({ dateTimeString }) {
  const tooltip = dateTimeString;
  const label = dateTimeString ? <FormattedDateTime dateTimeString={dateTimeString} /> : null;

  return dateTimeString ?
    (
      <Tooltip
        title={tooltip}
        data-datetimestring={dateTimeString}>
        <span>{label}</span>
      </Tooltip>
    ) : null;
}

TimeStamp.propTypes = {
  dateTimeString: PropTypes.string,
};
