import React from 'react';
import BuildDetail from "../components/BuildDetail/BuildDetail";
import {useParams} from "react-router-dom";

export default function BuildDetailPage() {
  const {category, group, product, mainVersion, os, version, type} = useParams();

  return (
    <BuildDetail
      categoryKey={category}
      groupKey={group}
      productKey={product}
      mainVersion={mainVersion}
      version={version}
      os={os}
      buildType={type}
    />);
}

