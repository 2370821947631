import Button from "@mui/material/Button";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "@emotion/styled";

const buttonCss = {
  marginRight: (theme) => theme.spacing(2),
};
const StyledButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
export default function WizardButtonContainer({
                                                activeStepData, wizardFinished, onNext, onSkip = () => {
  }, onBack, onReset = () => {
  }, onCancel = null
                                              }) {
  return (
    <StyledButtonContainer>
      {wizardFinished ? (
        <div>
          <Button variant="outlined" onClick={onReset} sx={buttonCss}>
            Reset
          </Button>
        </div>
      ) : (
        <div>
          <Button
            variant="outlined"
            disabled={activeStepData.backDisabledCondition()}
            onClick={onBack}
            sx={buttonCss}
          >
            Back
          </Button>
          {activeStepData.isOptional && (
            <Button
              variant="outlined"
              color="primary"
              onClick={onSkip}
              sx={buttonCss}
            >
              Skip
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            disabled={activeStepData.nextDisabledCondition()}
            onClick={onNext}
            sx={buttonCss}
          >
            {activeStepData.isFinalStep ? 'Finish' : 'Next'}
          </Button>

          {onCancel && <Button
            variant="outlined"
            onClick={onCancel}
            sx={buttonCss}
          >
            Delete
          </Button>}
        </div>
      )}
    </StyledButtonContainer>
  );
}

WizardButtonContainer.propTypes = {
  activeStepData: PropTypes.any.isRequired,
  onReset: PropTypes.func,
  onBack: PropTypes.func.isRequired,
  onSkip: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  wizardFinished: PropTypes.bool.isRequired,
};
