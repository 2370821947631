import React, {useEffect, useMemo, useState} from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import AutoSuggestion from '../AutoSuggestion';
import {useAuth} from '../../react-auth-wrapper';
import {useNotifications} from '../../contexts/NotificationsContext';
import styled from "@emotion/styled";

const StyledButton = styled(Button)`
  display: flex;
  margin-right: ${({theme}) => theme.spacing(1)};
  width: 80px;
  margin-left: 4px;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;

export default function ImpersonationDialog({
                                              email = '',
                                              setEmail,
                                              emailValid,
                                              hide,
                                            }) {
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const [processing, setProcessing] = useState(false);
  const [selection, setSelection] = useState(email);
  const [selectionValid, setSelectionValid] = useState(false);
  const cancel = {};

  const handleApiCallError = (error) => {
    if (error.message !== 'Cancelled') {
      setProcessing(false);
      notify('commonNotificator',
        {
          text: 'Error while searching user:',
          error,
          type: 'error',
          handleClose: () => {
          },
        });
    }
  };

  const searchByEmailSubstring = useMemo(
    () => (request, callback) => {
      setProcessing(true);
      callApi(`/swc/api/admin/impersonation/search?term=${encodeURIComponent(request.input)}`,
        {method: 'GET'},
        cancel,
        false)
        .then((response) => {
          const options = response.users.map(user => user.emailAddress);
          setProcessing(false);
          callback(options);
        })
        .catch(handleApiCallError);
    },
    [],
  );

  const handleButtonClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setEmail(selection);
    hide();
  };

  const handleCancelClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    hide();
  };

  useEffect(() => {
    setSelectionValid(emailValid(selection));
  }, [selection]);

  useEffect(() => () => {
    if (cancel && cancel.doCancel) cancel.doCancel();
  }, []);

  return (
    <React.Fragment>
      <AutoSuggestion
        fetch={searchByEmailSubstring}
        value={selection}
        setValue={setSelection}
        label="Enter Email Address"
      />
      <StyledButtonContainer>
        <StyledButton
          variant="outlined"
          color="primary"
          type="submit"
          disabled={!selectionValid && !processing}
          onClick={handleButtonClicked}
        >
          OK
        </StyledButton>
        <StyledButton
          disabled={processing}
          variant="outlined"
          type="button"
          onClick={handleCancelClicked}
        >
          Cancel
        </StyledButton>
      </StyledButtonContainer>
    </React.Fragment>
  );
}


ImpersonationDialog.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  emailValid: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};


ImpersonationDialog.defaultProps = {
  email: '',
};
