import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import {usePageMeta} from '../../../contexts/PageMetaContext';
import {useNotifications} from '../../../contexts/NotificationsContext';
import {useAuth} from '../../../react-auth-wrapper';
import LoadingIcon from '../../../components/LoadingIcon';
import ComplianceLogSearchForm from './ComplianceLogSearchForm';
import ComplianceLogTable from './ComplianceLogTable';
import styled from "@emotion/styled";
import SaveIcon from "@mui/icons-material/Save";
import {IconTextButton} from "../../../components/IconTextButton";
import {download, generateCsv, mkConfig} from "export-to-csv";
import {formatDateTime} from "../../../components/FormattedDateTime";
import {getComplianceLogTypeLabel} from "./table/TypeCell";
import {getProductOrBucketLabel} from "./table/ProductOrBucketCell";

const StyledLoadingContainer = styled.span`
  margin-top: ${({theme}) => theme.spacing(1)};
`;
const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};
`;
const StyledSearchFormPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};
  margin: ${({theme}) => theme.spacing(1)};
`;
const NOTIFICATOR_NAME = 'adminComplianceLogNotificator';
export default function ComplianceLog() {
  const [meta, setMeta] = useState(null);
  const [searchState, setSearchState] = useState(null);
  const [tableState, _setTableState] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  const {setTitle} = usePageMeta();
  const {notificator, notify, clearNotificator} = useNotifications();
  const {callApi} = useAuth();
  const cancel = {};

  function setTableState(newTableState) {
    _setTableState(newTableState);
    console.log('[ComplianceLog] tableState', newTableState);
  }

  function loadMetaData() {
    setDataLoading(true);
    setMeta(null);
    console.debug('[ComplianceLog] Loading meta data...');
    callApi('/swc/api/compliance/meta', {method: 'GET'}, cancel).then((response) => {
      console.debug('[ComplianceLog]', response);
      setMeta(response);
      setDataLoading(false);
      clearNotificator(NOTIFICATOR_NAME);
    }).catch((error) => {
      if (error.message !== 'Cancelled') {
        setDataLoading(false);
        setMeta(null);
        notify(NOTIFICATOR_NAME,
          {
            text: 'Error while compliance log meta data',
            error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
  }

  function loadComplianceLogs() {
    if (searchState !== null) {
      setDataLoading(true);
      setSearchResults({});
      setTableState(null);
      console.debug('[ComplianceLog] Loading logs...');
      callApi('/swc/api/compliance/search', {
        method: 'POST',
        body: JSON.stringify(searchState),
      }, cancel).then((response) => {
        console.debug('[ComplianceLog]', response);
        setSearchResults(response || {});
        setDataLoading(false);
        clearNotificator(NOTIFICATOR_NAME);
      }).catch((error) => {
        if (error.message !== 'Cancelled') {
          setDataLoading(false);
          setSearchResults({});
          notify(NOTIFICATOR_NAME,
            {
              text: 'Error while compliance logs:',
              error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });
    }
  }

  function handleExportComplianceLogInCSV() {
    const options = {
      filename: 'ComplianceLog',
      fieldSeparator: ';',
      quoteStrings: true,
      decimalSeparator: '.',
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      showColumnHeaders: true,
      columnHeaders: [{key: 'timestamp', displayLabel: 'Timestamp'},
        {key: 'type', displayLabel: 'Type'},
        {key: 'email', displayLabel: 'Email'},
        {key: 'productKey', displayLabel: 'Product or S3 Bucket'},
        {key: 'fileName', displayLabel: 'Filename'},
        {key: 'buildType', displayLabel: 'Build Type'},
        {key: 'classifier', displayLabel: 'Version'},
        {key: 'os', displayLabel: 'OS'},
        {key: 'mirror', displayLabel: 'Mirror'},
        {key: 'url', displayLabel: 'URL'},
        {key: 'dryRun', displayLabel: 'Dry run'},
        {key: 'geoLocation', displayLabel: 'Geo'},
        {key: 'ipAddress', displayLabel: 'IP Address'}
      ]
    };
    if (!searchResults?.items || searchResults.items.length === 0) {
      notify(NOTIFICATOR_NAME,
        {
          text: 'No data for export in CSV!',
          type: 'error',
          handleClose: () => {
          },
        });
      return;
    }
    const formattedItems = searchResults.items.map(item => ({
      ...item,
      'timestamp': formatDateTime(item.timestamp),
      'type': getComplianceLogTypeLabel(item.type),
      'productKey': getProductOrBucketLabel(item.productKey, meta)?.label,
      'dryRun': item.dryRun ? 'Yes' : '',
      'geoLocation': item.geoLocation ?? '',
    }));
    const csvConfig = mkConfig(options);
    const report = generateCsv(csvConfig)(formattedItems);
    download(csvConfig)(report);
  }

  function getCSVButton() {
    return (
      <IconTextButton
        tooltip={'Download CSV'}
        label={'Download CSV'}
        onClick={handleExportComplianceLogInCSV}
        disabled={dataLoading || !searchResults?.items || searchResults?.items?.length === 0}
        startIcon={<SaveIcon/>}
      />
    );
  }

  useEffect(loadComplianceLogs, [searchState]);

  useEffect(() => {
    setTitle('Administration - Compliance Log');
    loadMetaData();
    return () => {
      if (cancel?.doCancel) cancel.doCancel();
    };
  }, []);

  return (
    <>
      {notificator(NOTIFICATOR_NAME)}
      <StyledPaper>
        <StyledSearchFormPaper>
          <ComplianceLogSearchForm
            disabled={dataLoading}
            metaData={meta}
            searchState={searchState}
            onChange={setSearchState}
          />
        </StyledSearchFormPaper>
        {dataLoading && (<StyledLoadingContainer><LoadingIcon/></StyledLoadingContainer>)}
        {!dataLoading && (
          <ComplianceLogTable
            disabled={dataLoading}
            metaData={meta}
            searchResults={searchResults}
            searchState={searchState}
            tableState={tableState}
            setTableState={setTableState}
          />
        )}
        {getCSVButton()}
      </StyledPaper>
    </>
  );
}
