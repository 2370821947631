import React from 'react';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GenericProfile from '../components/GenericProfile';
import UserRoles from '../components/UserRoles';
import {usePageMeta} from '../contexts/PageMetaContext';
import ThemeSwitcher from '../components/ThemeSwitcher';
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import ListWrapper from "../components/ListWrapper";

const StyledPaper = styled(Paper)`
  width: 100%;
  padding-bottom: ${({theme}) => theme.spacing(2)};
`;
const StyledFlexContainer = styled.div`
  display: flex;
`;
const StyledTypography = styled(Typography)`
  padding-bottom: ${({theme}) => theme.spacing(2)};
  padding-top: ${({theme}) => theme.spacing(2)};
  padding-left: ${({theme}) => theme.spacing(3)};
  width: 90%;
`;

const StyledRoles = styled(Typography)`
  padding-left: ${({theme}) => theme.spacing(3)};
  padding-right: ${({theme}) => theme.spacing(3)};
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

const StyledProfileContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(7)};
  margin-top: 32px;
  padding-left: ${({theme}) => theme.spacing(3)};
`;
export default function Profile() {
  const theme = useTheme();
  const {setTitle, goTo} = usePageMeta();

  setTitle('User profile');

  return (
    <StyledPaper>
      <StyledFlexContainer>
        <StyledTypography variant="h6">
          <Box color="primary.main">Your Profile</Box>
        </StyledTypography>
        <ThemeSwitcher/>
      </StyledFlexContainer>
      <StyledProfileContainer>
        <GenericProfile/>
      </StyledProfileContainer>
      <StyledRoles>
        <ListWrapper>
          <UserRoles/>
        </ListWrapper>
      </StyledRoles>
    </StyledPaper>
  );
}
