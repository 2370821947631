import React from 'react';
import {ADMIN_SETTINGS} from '../../components/PathConstants';
import {BuildActivitySearchAdmin} from "../../components/BuildSearch/BuildActivitySearch";
import AdminCloudSettingsTabs from "../../menu/admin/AdminCloudSettingsTabs";

export default function BuildActivitySearchAdminTab() {

  return (<AdminCloudSettingsTabs activeTabPath={ADMIN_SETTINGS.activityStream.path}>
    <BuildActivitySearchAdmin/>
  </AdminCloudSettingsTabs>);
}
