import Chip from '@mui/material/Chip';
import React from 'react';
import styled from "@emotion/styled";

const StyledChip = styled(Chip)`
  margin-right: ${({theme}) => theme.spacing(1)};
  font-size: x-small;
  ${props => props.isBeta === true ? '' : 'background-color: #cee874'};
  ${props => props.isOs === true ? `margin-left: ${props.theme.spacing(1)};` : ''}
`;

const StyledChipContainer = styled.span`
  display: inline-block;
  min-width: ${({theme}) => theme.spacing(8)};
`;

export function BuildTypeChip({version}) {
  return ('betaType' in version) ? (
    <StyledChipContainer>
      <StyledChip size="small"
                  label={version.type}
                  isBeta={version.betaType}/>
    </StyledChipContainer>) : null
}

export function OSChip({version}) {
  return (
    <StyledChipContainer>
      <StyledChip size="small"
                  label={version.os}
                  isBeta={true}
                  isOs={true}/>
    </StyledChipContainer>)
}

