import React from 'react';
import {ATTRIBUTES, PROPERTIES_META, SEARCHABLE_ATTRIBUTES} from "../searchConstants";
import FilterChipGroupBuildType from "./FilterChipGroupBuildType";
import FilterChipGroupMainVersion from "./FilterChipGroupMainVersion";
import FilterChipGroupOS from "./FilterChipGroupOS";
import FilterChipGroupProduct from "./FilterChipGroupProduct";
import FilterChipGroupProperty from "./FilterChipGroupProperty";
import {FilterList, SettingsOutlined} from "@mui/icons-material";
import {IconTextButton} from "../../IconTextButton";
import FilterChipGroupAttribute from "./FilterChipGroupAttribute";
import styled from "@emotion/styled";

const StyledBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: ${({theme}) => theme.spacing(2)};
`;
const StyledButtonContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(4)};
`;
export const createFilterChipContainer = ({searchState, productData, headRow, disabled, toggle}) => {
  return (
    <StyledBoxContainer>
      <StyledButtonContainer>
        <IconTextButton
          tooltip={'Filter'}
          label={'Filter'}
          onClick={toggle}
          disabled={disabled}
          startIcon={<FilterList/>}
        />
      </StyledButtonContainer>
      <FilterChipContainer
        searchState={searchState}
        headRow={headRow}
        disabled={disabled}
        productData={productData}
      />
    </StyledBoxContainer>
  );
}

export const createSettingsChipContainer = ({disabled, toggle}) => {
  return (
    <StyledBoxContainer>
      <StyledButtonContainer>
        <IconTextButton
          tooltip={'Configuration'}
          label={'Configuration'}
          onClick={toggle}
          disabled={disabled}
          startIcon={<SettingsOutlined/>}
        />
      </StyledButtonContainer>
    </StyledBoxContainer>
  );
}

function FilterChipContainer({searchState, headRow, disabled, productData}) {

  const [state, setState] = [...searchState];

  const productMetaList = productData?.productMetas || [];
  const buildTypeList = productData?.buildTypes || [];
  const osMetaList = productData?.oss || [];

  const filterableCells = headRow.filter(headCell => SEARCHABLE_ATTRIBUTES.indexOf(headCell.id) > -1);

  const toChips = headCell => {
    if (headCell.id === ATTRIBUTES.BUILD_TYPE) {
      return (
        <React.Fragment key={`chips-${ATTRIBUTES.BUILD_TYPE}-${ATTRIBUTES.PROPERTIES}`}>
          <FilterChipGroupBuildType
            key={`chips-${ATTRIBUTES.BUILD_TYPE}`}
            buildTypesList={buildTypeList}
            selectedBuildTypes={state?.selectedBuildTypes || []}
            setSelectedBuildTypes={buildTypes => setState({
              ...state,
              pagination: {...state.pagination, page: 0},
              selectedBuildTypes: buildTypes
            })}
            disabled={disabled}/>
          <FilterChipGroupProperty
            key={`chips-${ATTRIBUTES.PROPERTIES}`}
            propertyList={PROPERTIES_META}
            selectedProperties={state?.selectedProperties || []}
            setSelectedProperties={selectedProperties => setState({
              ...state,
              pagination: {...state.pagination, page: 0},
              selectedProperties: (selectedProperties || [])
            })}
            disabled={disabled}/>
          <FilterChipGroupAttribute
            key={`chips-${ATTRIBUTES.ATTRIBUTES}`}
            productList={productMetaList}
            selectedAttributes={state?.selectedAttributes || []}
            setSelectedAttributes={selectedAttributes => setState({
              ...state,
              pagination: {...state.pagination, page: 0},
              selectedAttributes: (selectedAttributes || [])
            })}
            disabled={disabled}/>
        </React.Fragment>
      );
    } else if (headCell.id === ATTRIBUTES.MAIN_VERSION) {
      return (
        <FilterChipGroupMainVersion
          key={`chips-${ATTRIBUTES.MAIN_VERSION}`}
          mainVersionSubstring={state.mainVersionSubstring || ""}
          setMainVersionSubstring={mainVersionSubstring => setState({
            ...state,
            pagination: {...state.pagination, page: 0},
            mainVersionSubstring: (mainVersionSubstring || "")
          })}
          disabled={disabled}
        />
      );

    } else if (headCell.id === ATTRIBUTES.OS) {
      return (
        <FilterChipGroupOS
          key={`chips-${ATTRIBUTES.OS}`}
          osMetaList={osMetaList}
          selectedOs={state?.selectedOs || []}
          setSelectedOs={selectedOs => setState({
            ...state,
            pagination: {...state.pagination, page: 0},
            selectedOs: (selectedOs || [])
          })}
          disabled={disabled}/>
      );

    } else if (headCell.id === ATTRIBUTES.PRODUCT) {
      return (
        <FilterChipGroupProduct
          key={`chips-${ATTRIBUTES.PRODUCT}`}
          productList={productMetaList}
          selectedProductKeys={state?.selectedProductKeys || []}
          setSelectedProductKeys={selectedProductKeys => setState({
            ...state,
            pagination: {...state.pagination, page: 0},
            selectedProductKeys: (selectedProductKeys || [])
          })}
          disabled={disabled}/>
      );
    } else {
      return null;
    }
  };

  const chips = filterableCells.map(toChips).filter(it => it);

  return (<div>{chips}</div>);
}
