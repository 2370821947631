import React from 'react';
import {FilterChip} from "./FilterChip";


export default function FilterChipGroupProduct({
                                                 productList,
                                                 selectedProductKeys,
                                                 setSelectedProductKeys,
                                                 disabled
                                               }) {

  const sortedProductList = [...productList].sort((a, b) => a.productLabel.localeCompare(b.productLabel));
  const sortedSelectedProduct = sortedProductList.filter(p => selectedProductKeys.indexOf(p.productKey) > -1);

  const handleDelete = (productKeyToDelete) => {
    setSelectedProductKeys(selectedProductKeys.filter(productKey => productKey !== productKeyToDelete));
  };

  return (
    <React.Fragment>
      {sortedSelectedProduct.map(product =>
        <FilterChip
          key={product.productKey}
          tooltip={"Product"}
          disabled={disabled}
          label={product.productLabel}
          onDelete={() => handleDelete(product.productKey)}/>
      )}
    </React.Fragment>
  );
}
