import { useAuth } from "../react-auth-wrapper";
import { useEffect, useState } from "react";

const useBuildUploadMeta = (signalApiErrorCallback) => {

  const [categories, setCategories] = useState(null);
  const [buildAudiences, setBuildAudiences] = useState(null);
  const {callApi} = useAuth();
  const cancel = {};

  function loadProductMeta() {
    console.log('[userBuildUploadMeta]', 'Fetching product upload meta...');
    callApi('/swc/api/admin/builds/upload/my/metaData', {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[userBuildUploadMeta]', 'product upload meta', response);
        setCategories(response.categoryList);
        setBuildAudiences(response.buildAudienceList);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setCategories(null);
          setBuildAudiences(null);
          signalApiErrorCallback('Error while loading product upload metadata', error);
        }
      })
      .finally(() => {
      });
  }

  useEffect(() => {
    loadProductMeta();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  const adminBuildAudience = buildAudiences?.find(item => item.buildAudience = "ADMIN");

  return {categories, buildAudiences, adminBuildAudience};
}

export default useBuildUploadMeta;
