import React, {useEffect, useState} from 'react';
import OverviewList from '../components/OverviewList';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import {usePageMeta} from "../contexts/PageMetaContext";
import {useLocation, useParams} from "react-router-dom";

export default function ProductsOverview() {

  const {category, group, product} = useParams();
  const [menu, setMenu] = useState(null);
  const [label, setLabel] = useState(null);
  const [overviewItems, setOverviewItems] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const currentLocation = useLocation();
  const { goTo } = usePageMeta();
  const cancel = {};

  function loadMenu() {
    console.log('[ProductsOverview]', 'Fetching menu...');

    callApi('/swc/api/menu', {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[ProductsOverview]', 'menu', response);
        const downloadsMenu = response.menu.filter(menuItem => menuItem.key === 'downloads');
        const menuItems = downloadsMenu.length > 0 ? downloadsMenu[0].items : [];
        setMenu(menuItems);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setMenu([]);
          notify('commonNotificator',
            {
              text: 'Error while loading menu:',
              error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      })
      .finally(() => {
      });
  }

  function buildOverviewFromPath() {
    for (const menuItem of menu) {
      if (menuItem.key === category) {
        if (group) {
          const subMenuItemsSortedDescByClickActionPathLength = [...menuItem.items].sort((item0, item1) => item1.clickActionPath.length - item0.clickActionPath.length);
          for (const subMenuItem of subMenuItemsSortedDescByClickActionPathLength) {
            if (currentLocation.pathname.includes(subMenuItem.clickActionPath)) {
              setLabel(subMenuItem.label);
              setOverviewItems(subMenuItem.items);
              return;
            }
          }
        }
        setLabel(menuItem.label);
        setOverviewItems(menuItem.items);
        return;
      }
    }
    setLabel(menu[0].label);
    setOverviewItems(menu[0].items);
  }

  function buildOverview() {
    if (menu) {
      buildOverviewFromPath();
    }
  }

  useEffect(() => {
    loadMenu();
  }, []);

  useEffect(() => {
    buildOverview();
  }, [menu, category, group, product]);

  useEffect(() => {
    // open next level in case of a single menu item
    if (overviewItems && overviewItems.length === 1) {
      goTo(overviewItems[0].clickActionPath);
    } else {
      setShowCard(true);
    }
  }, [overviewItems]);

  let card = null;
  if (overviewItems && showCard) {
    card = (
      <React.Fragment>
        <OverviewList
          items={overviewItems}
          title={`${label} Overview`}
          pathPrefix="/download/"
        />
      </React.Fragment>
    );
  }

  return card;
}
