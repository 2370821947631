/* eslint-disable camelcase */
import React, {useContext, useEffect, useState} from 'react';
import Keycloak from 'keycloak-js';

const errorCodeMap = {
  400: 'Sorry, your input data is invalid.',
  401: 'Sorry, you do not have access to this resource. Please log in first and try again.',
  403: 'Sorry, you do not have the required permissions. Please contact your administrator.',
  404: 'The URL you requested does not exist. Please contact your administrator.',
  500: 'Sorry, an unexpected error occurs. Please try again later - if the error still occurs, please file a bug report.',
  502: 'Sorry, an unexpected error occurs. This is probably due to a network error, try to reload the page - if it still occurs, please file a bug report.',
  503: 'Sorry, the service is currently unavailable. Please try again later - if the error still occurs, please file a bug report.',
  504: 'Sorry, the server took too long to answer. Please try again later - if the error still occurs, please file a bug report.',
};

class CancelledError extends Error {
  message = 'Cancelled';
}

class SWCError extends Error {
}

class UnknownError extends Error {
  message = errorCodeMap[502];
}

const parseError = (json) => {
  const error = new SWCError();
  Object.assign(error, json);
  return error;
};

const rejectWithError = (response) => {
  const errorMsg = errorCodeMap[response.status];
  let err = new UnknownError();
  err.response = response;
  if (errorMsg) {
    err = new SWCError();
    err.status = response.status;
    err.response = response;
    err.message = errorMsg;
  }
  return Promise.reject(err);
};

const handleError = (cancelled, error, inputData) => {
  if (cancelled) {
    return Promise.reject(new CancelledError());
  }
  console.log('[error]', error);
  error.inputData = inputData;
  error.time = Date();
  return Promise.reject(error);
};

const handleResponse = (notJson, cancelled, response) => {
  if (cancelled) {
    return Promise.reject(new CancelledError());
  }
  console.log('[response]', response);
  if (notJson || response.status === 204) {
    return response.ok ? response : Promise.reject(new UnknownError());
  } else {
    return response.json().then(json => {
      if (json.type && json.type.startsWith(
        'https://www.advantest.com/swc/api/problem/')) {
        const error = parseError(json);
        error.response = parseError(json);
        return Promise.reject(error);
      } else if (!response.ok) {
        return rejectWithError(response);
      }
      return json;
    }, () => {
      return rejectWithError(response);
    });
  }
};

const getApiUrlWithToken = async (url, accessToken) => {
  const queryParameter = 'access_token';

  const urlContainsQuery = url.indexOf('?') !== -1;

  if (accessToken) {
    if (urlContainsQuery) {
      return `${url}&${queryParameter}=${accessToken}`;
    } else {
      return `${url}?${queryParameter}=${accessToken}`;
    }
  } else {
    return url;
  }
};

const callApiWithToken = async (url, options, cancel, notJson, accessToken) => {
  let cancelled = false;

  if (!!cancel) {
    cancel.doCancel = () => {
      cancelled = true;
      if (cancel.cleanUpFn) {
        cancel.cleanUpFn();
      }
      console.log('[callApi] doCancel called...');
    };

    cancel.resetCancel = () => {
      cancelled = false;
    }

  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  } else {
    console.log('No authentication token in request!');
  }

  const inputData = { url: url, options: options };
  return fetch(url, { headers, ...options })
  .then(response => handleResponse(notJson, cancelled, response))
  .catch(error => handleError(cancelled, error, inputData));
};

export const AuthContext = React.createContext({});
export const useAuth = () => useContext(AuthContext);


export const AuthProviderKC = ({
                                  config,
                                  tryAuth0SSO,
                                  children,
                                }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [kcClient, setKcClient] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initKc = async () => {
      const kc = new Keycloak(config);
      const authenticationResult = await kc.init(
        {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
          pkceMethod: 'S256',
        },
      );
      setKcClient(kc);
      setIsAuthenticated(authenticationResult);

      console.log(`KeyCloak init with auth result ${authenticationResult}`)

      if (authenticationResult) {
        console.log("KeyCloak init - setting user")
        setUser({ nickname: kc.tokenParsed?.preferred_username, email: kc.tokenParsed?.email });
      } else if (tryAuth0SSO) {
        console.log("KeyCloak init - trying SSO login with Auth0")
        await kc.login({
          prompt: 'none',
          idpHint: 'auth0'
        });
      }

      setLoading(false);
    };
    initKc();
  }, []);


  const getAccessToken = async () => {
    let accessToken = '';
    if (isAuthenticated) {
      try {
        await kcClient.updateToken(5);
        accessToken = kcClient.token;
      }
      catch {
        kcClient.login();
      }
    }
    return accessToken;
  };


  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        errorCodeMap,
        callApi: async (url, options, cancel, notJson) => {
          const accessToken = await getAccessToken();
          return callApiWithToken(url, options, cancel, notJson, accessToken);
        },
        getApiUrlWithToken: async (url) => {
          const accessToken = await getAccessToken();
          return getApiUrlWithToken(url, accessToken);
        },
        loginWithRedirect: () => kcClient.login(),
        logout: (options) => kcClient.logout({
          redirectUri: options.returnTo
        }),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
