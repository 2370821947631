import React, {useEffect, useState} from 'react';
import {Icon} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import useInterval from '../../../hooks/useInterval';
import {useAuth} from '../../../react-auth-wrapper';
import {usePageMeta} from '../../../contexts/PageMetaContext';
import {useNotifications} from '../../../contexts/NotificationsContext';
import ScrollableList from '../../../components/ScrollableList';
import LoadingIcon from '../../../components/LoadingIcon';
import TimeStamp from '../../../components/TimeStamp';
import {postProcessTask, STATE_GROUPS, TASK_TYPE} from './taskFunctions';
import LABEL from './labels';
import TaskState from './TaskState';
import AdminDownloadTabs from '../../../menu/admin/AdminDownloadTabs';
import {ADMIN_DOWNLOADS} from '../../../components/PathConstants';
import SWCLink from '../../../components/SWCLink';
import {useTheme} from "@mui/material/styles";
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(1)};
  display: flex;
  align-items: center;
`;
const StyledListContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(3)};
`;
const StyledIcon = styled(Icon)`
  margin-right: ${({theme}) => theme.spacing(1)};
`;
const StyledLoadingIcon = styled(LoadingIcon)`
  margin-top: ${({theme}) => theme.spacing(20)};
  margin: auto;
`;
const headRow = [
  {
    id: 'id', numeric: false, disablePadding: false, label: LABEL.ID,
  },
  {
    id: 'user', numeric: false, disablePadding: false, label: LABEL.USER,
  },
  {
    id: 'created', numeric: false, disablePadding: false, label: LABEL.CREATED,
  },
  {
    id: 'updated', numeric: false, disablePadding: false, label: LABEL.UPDATED,
  },
  {
    id: 'state', numeric: true, disablePadding: false, label: LABEL.STATE,
  },
  // { id: 'producer', numeric: false, disablePadding: false, label: LABEL.PRODUCER },
  // { id: 'producerAppVersion', numeric: false, disablePadding: false, label: LABEL.PRODUCER_APP_VERSION },
  // { id: 'consumer', numeric: false, disablePadding: false, label: LABEL.CONSUMER },
  // { id: 'assignmentCounter', numeric: true, disablePadding: false, label: LABEL.ASSIGNMENT_COUNTER },
];

function createTaskContainer(task) {
  return [
    task.id,
    task.user,
    <TimeStamp dateTimeString={task.created}/>,
    <TimeStamp dateTimeString={task.updated}/>,
    <TaskState task={task}/>,
    // task.producer,
    // task.producerAppVersion,
    // task.consumer,
    // task.assignmentCounter,
  ].map(
    cell => (<SWCLink path={`${ADMIN_DOWNLOADS.bulkTasks.path}/${task.id}`}>{cell}</SWCLink>),
  );
}

function TaskGroup({groupMeta, tasks}) {
  const theme = useTheme();
  console.log('[BuildsBulkTasks] Group', groupMeta, tasks);

  const title = (
    <StyledContainer>
      <StyledIcon>{groupMeta.icon}</StyledIcon>
      <Tooltip
        title={groupMeta.description}
      >
        <span>{groupMeta.label}</span>
      </Tooltip>
    </StyledContainer>
  );

  return (
    <StyledListContainer>
      <ScrollableList
        title={title}
        headRow={headRow}
        showHeadRow
        items={tasks}
        itemContainer={createTaskContainer}
      />
    </StyledListContainer>
  );
}

const NOTIFICATOR_NAME = 'buildsBulkTasksNotificator';
export default function BuildsBulkTasks({
                                          taskType = TASK_TYPE.BUILD_MOVE_TO_CLOUD_BUCKET,
                                          headerTitle = 'Administration - Build Bulk Tasks'
                                        }) {
  const [reloadInterval] = useState(60 * 1000);
  const [dataLoading, setDataLoading] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [groupedTaskList, setGroupedTaskList] = useState([]);
  const [products, setProducts] = useState(null);

  const {callApi} = useAuth();
  const {setTitle, goTo} = usePageMeta();
  const {notificator, notify, clearNotificator} = useNotifications();
  const cancel = {};

  function handleClicked(task) {
    goTo(`${ADMIN_DOWNLOADS.bulkTasks.path}/${task.id}`);
  }

  function loadProducts() {
    setDataLoading(true);
    console.log('[BuildsBulkTasks] Loading products...');
    callApi('/swc/api/admin/products', {method: 'GET'}, cancel).then((response) => {
      console.log('[BuildsBulkTasks] products', response);
      setProducts(response.productMetas);
      setDataLoading(false);
    }).catch((error) => {
      if (error.message !== 'Cancelled') {
        setDataLoading(false);
        setProducts(null);
        notify(NOTIFICATOR_NAME,
          {
            text: 'Error while trying to load product list:',
            error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
  }

  function loadTasks() {
    const postProcessTaskList = taskList => taskList.map(postProcessTask(taskType)(products)).reverse();

    if (products) {
      setDataLoading(true);
      clearNotificator(NOTIFICATOR_NAME);

      callApi(`/swc/api/admin/tasks/${taskType}`, {method: 'GET'}, cancel).then((response) => {
        console.log('[BuildsBulkTasks] tasks', response);
        const taskList = postProcessTaskList(response);
        setTasks(taskList);
        setDataLoading(false);
      }).catch((error) => {
        if (error.message !== 'Cancelled') {
          setTasks([]);
          setDataLoading(false);
          notify(NOTIFICATOR_NAME,
            {
              text: 'Error while trying to load task list:',
              error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });
    }
  }

  function loadGroupedTasks() {
    const grouped = STATE_GROUPS.map(groupMeta => (
      {
        groupMeta,
        tasks: (tasks || []).filter(task => task.groupMeta && task.groupMeta.name === groupMeta.name),
      })).filter(groupedItem => groupedItem.tasks.length > 0);

    console.log('[BuildsBulkTasks] grouped', grouped);

    setGroupedTaskList(grouped);
  }


  function setPageTitle() {
    setTitle(headerTitle);
  }

  useEffect(loadProducts, []);
  useEffect(loadTasks, [products]);
  useEffect(loadGroupedTasks, [tasks]);
  useEffect(setPageTitle, [headerTitle]);

  useInterval(loadProducts, reloadInterval);

  return (
    <AdminDownloadTabs activeTabPath={ADMIN_DOWNLOADS.bulkTasks.path}>
      <Paper sx={{
        padding: (theme) => theme.spacing(2),
      }} square>
        {notificator(NOTIFICATOR_NAME)}
        {groupedTaskList.length > 0 && (groupedTaskList.map(groupedTask => (
          <TaskGroup
            key={groupedTask.groupMeta.name}
            groupMeta={groupedTask.groupMeta}
            tasks={groupedTask.tasks}
            handleClicked={handleClicked}
          />
        )))}
        {dataLoading && <StyledLoadingIcon/>}
      </Paper>
    </AdminDownloadTabs>
  );
}
