import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {SelectValidator, TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import styled from "@emotion/styled";

const validatorStyles = (theme) => `
  margin-left: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
  min-width: 250px;
`;
const StyledTextValidator = styled(TextValidator)`
  ${({theme}) => validatorStyles(theme)}
`;
const StyledSelectValidator = styled(SelectValidator)`
  ${({theme}) => validatorStyles(theme)}
`;
const StyledButtonContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;
const StyledButton = styled(Button)`
  margin-right: ${({theme}) => theme.spacing(1)};
`;

const initialTranslationState = {propertyType: '', key: '', keyType: '', value: ''};

export default function ManageTranslationDialog({handleCreate, handleUpdate, hide, property, meta}) {
  const [translation, setTranslation] = useState(initialTranslationState);
  const isEdit = property !== undefined;

  const handleChange = (event) => {
    setTranslation({...translation, [event.target.name]: event.target.value});
  };

  useEffect(() => {
    if (isEdit) {
      setTranslation(property);
    }
  }, []);

  ValidatorForm.addValidationRule('nonEmptyString',
    value => value !== undefined && value.length > 0 && value.replace(/\s/g, '') !== '');

  return (
    <React.Fragment>
      <CssBaseline/>
      <Container maxWidth="lg">
        <Typography component="div">
          <ValidatorForm onSubmit={(event) => isEdit ? handleUpdate(event,
            translation) : handleCreate(event, translation)}>
            <div>
              <StyledSelectValidator
                disabled={isEdit}
                required
                label="Type"
                onChange={handleChange}
                value={translation.propertyType}
                name="propertyType"
                validators={['required']}
                errorMessages={['Please select Type']}
                margin="normal"
                variant="outlined"
              >
                {meta.propertyTypes ? meta.propertyTypes.map(option =>
                    <MenuItem value={option} key={option}>{option}</MenuItem>) :
                  <MenuItem key="" value=""/>}
              </StyledSelectValidator>
            </div>
            <div>
              <StyledTextValidator
                disabled={isEdit}
                label="UUID"
                onChange={handleChange}
                value={translation.key}
                name="key"
                validators={['required', 'nonEmptyString']}
                errorMessages={['Please set not empty UUID']}
                helperText="Not empty string"
                margin="normal"
                variant="outlined"
              />
            </div>
            <div>
              <StyledSelectValidator
                required
                disabled={isEdit}
                label="Key"
                onChange={handleChange}
                value={translation.keyType}
                name="keyType"
                validators={['required']}
                errorMessages={['Please select Key']}
                margin="normal"
                variant="outlined"
              >
                {meta.keyTypes ? meta.keyTypes.map(option =>
                    <MenuItem value={option} key={option}>{option}</MenuItem>) :
                  <MenuItem key="" value=""/>}
              </StyledSelectValidator>
            </div>
            <div>
              <StyledTextValidator
                label="Value"
                onChange={handleChange}
                value={translation.value}
                name="value"
                validators={['required', 'nonEmptyString']}
                errorMessages={['Please set not empty Value']}
                helperText="Not empty string"
                margin="normal"
                variant="outlined"
              />
            </div>
            <StyledButtonContainer>
              <StyledButton
                variant="outlined"
                color="primary"
                type="submit"
              >
                {isEdit ? 'Save' : 'Create'}
              </StyledButton>
              <Button variant="outlined" type="button" onClick={hide}>Cancel</Button>
            </StyledButtonContainer>
          </ValidatorForm>
        </Typography>
      </Container>
    </React.Fragment>
  );
}

ManageTranslationDialog.ptopTypes = {
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  property: PropTypes.shape({
    propertyType: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    keyType: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  meta: PropTypes.shape({
    propertyTypes: PropTypes.array.isRequired,
    keyTypes: PropTypes.array.isRequired,
  }).isRequired
};
