import React from 'react';
import {usePageMeta} from '../contexts/PageMetaContext';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import styled from "@emotion/styled";

const StyledCenterContainer = styled.div`
  text-align: center;
`;
const StyledButton = styled(Button)`
  margin: ${({theme}) => theme.spacing(1)};
`;
export default function NotFound() {
  const navigate = useNavigate();
  const {setTitle} = usePageMeta();

  setTitle('');

  return (<StyledCenterContainer>
    <h1>Not Found</h1>
    <p>{'The page was not found. Please use menu on the left or back button to continue.'}</p>
    <StyledButton
      variant="outlined"
      color="primary"
      type="submit"
      onClick={() => navigate(-1)}
    >
      Back to previous page
    </StyledButton>
  </StyledCenterContainer>);
}
