import {usePageMeta} from "../../contexts/PageMetaContext";
import {useEffect, useMemo, useState} from "react";
import {ORDERABLE_ATTRIBUTES, QUERY_PARAM, SUBSCRIPTION_NONE_KEY} from "./searchConstants";
import {useNotifications} from "../../contexts/NotificationsContext";
import {useAuth} from "../../react-auth-wrapper";
import {useLocation} from "react-router-dom";

const initialSelectionState = {
  selectedProductKeys: [],
  selectedBuildTypes: [],
  selectedOs: [],
  selectedProperties: [],
  selectedAttributes: [],
  subscription: SUBSCRIPTION_NONE_KEY,
  mainVersionSubstring: "",
  pagination: {page: 0, size: 25},
  order: {by: ORDERABLE_ATTRIBUTES.BY.DATE, direction: ORDERABLE_ATTRIBUTES.DIRECTION.DESC},
};

export function useSimpleSearchState() {

  const {goTo} = usePageMeta();
  const currentLocation = useLocation();

  const [internalState, setInternalInternalState] = useState({...initialSelectionState});
  const parameters = useMemo(() => new URLSearchParams(currentLocation.search), [currentLocation.search]);

  function updateState({
                         selectedProductKeys,
                         selectedBuildTypes,
                         selectedOs,
                         selectedProperties,
                         selectedAttributes,
                         mainVersionSubstring,
                         pagination,
                         order,
                       }) {


    const paramSelectedProductKeys = (selectedProductKeys || []).map(key => key.split(':').map(encodeURIComponent).join(':')).join(',');
    const paramSelectedBuildTypes = (selectedBuildTypes || []).map(encodeURIComponent).join(',');
    const paramSelectedOs = (selectedOs || []).map(encodeURIComponent).join(',');
    const paramSelectedProperties = (selectedProperties || []).map(encodeURIComponent).join(',');
    const paramSelectedAttributes = (selectedAttributes || []).map(encodeURIComponent).join(',');
    const paramMainVersionSubstring = encodeURIComponent((mainVersionSubstring || "").trim());

    const newParams = [
      [QUERY_PARAM.SELECTED_PRODUCT_KEYS, paramSelectedProductKeys],
      [QUERY_PARAM.SELECTED_BUILD_TYPES, paramSelectedBuildTypes],
      [QUERY_PARAM.SELECTED_OS, paramSelectedOs],
      [QUERY_PARAM.SELECTED_PROPERTIES, paramSelectedProperties],
      [QUERY_PARAM.SELECTED_ATTRIBUTES, paramSelectedAttributes],
      [QUERY_PARAM.MAIN_VERSION_SUBSTRING, paramMainVersionSubstring],
      [QUERY_PARAM.ORDER_BY, encodeURIComponent(order.by)],
      [QUERY_PARAM.ORDER_DIRECTION, encodeURIComponent(order.direction)],
      [QUERY_PARAM.PAGE_NUMBER, encodeURIComponent(pagination.page)],
      [QUERY_PARAM.PAGE_SIZE, encodeURIComponent(pagination.size)],
    ]

    const queryString = newParams.map(pair => `${pair[0]}=${pair[1]}`).join('&');
    const replaceBrowserHistory = true;

    goTo(`/search?${queryString}`, replaceBrowserHistory);
  }

  function resetState() {
    setInternalInternalState({...initialSelectionState})
  }

  useEffect(() => {

    const paramSelectedProductKeysRaw = parameters.get(QUERY_PARAM.SELECTED_PRODUCT_KEYS);
    const paramSelectedBuildTypesRaw = parameters.get(QUERY_PARAM.SELECTED_BUILD_TYPES);
    const paramSelectedOsRaw = parameters.get(QUERY_PARAM.SELECTED_OS);
    const paramSelectedProperties = parameters.get(QUERY_PARAM.SELECTED_PROPERTIES);
    const paramSelectedAttributes = parameters.get(QUERY_PARAM.SELECTED_ATTRIBUTES);
    const paramMainVersionSubstringRaw = parameters.get(QUERY_PARAM.MAIN_VERSION_SUBSTRING);

    const paramOrderBy = extractOrderBy(parameters);
    const paramOrderDirection = extractOrderDirection(parameters);
    const paramPageNumber = extractPageNumber(parameters);
    const paramPageSize = extractPageSize(parameters);

    setInternalInternalState(prevState => {
      return {
        ...prevState,
        selectedProductKeys: convertParamToArray(paramSelectedProductKeysRaw),
        selectedBuildTypes: convertParamToArray(paramSelectedBuildTypesRaw),
        selectedOs: convertParamToArray(paramSelectedOsRaw),
        selectedProperties: convertParamToArray(paramSelectedProperties),
        selectedAttributes: convertParamToArray(paramSelectedAttributes),
        mainVersionSubstring: (paramMainVersionSubstringRaw || "").trim(),
        pagination: {...prevState.pagination, page: paramPageNumber, size: paramPageSize},
        order: {...prevState.order, by: paramOrderBy, direction: paramOrderDirection},
      };
    })
  }, [parameters]);

  return [internalState, updateState, resetState];
}


function useLoadSearchStateFromBackendFn({path = "/swc/api/builds/searchpreferences"}) {

  const {callApi} = useAuth();
  const {notify} = useNotifications();

  return async (cancel, setStateFn, mode) => {

    try {
      const preferences = await callApi(`${path}/${mode}`, cancel);
      const orderFromPreferences = preferences?.orderList?.find(() => true);
      const selectedAttributesFromPreferences = (preferences?.attributeAndValueInCriteria?.selectedValues || []).map(attr => `${attr.key}=${attr.value}`);

      setStateFn(prevState => ({
          ...prevState,
          selectedProductKeys: (preferences?.productInCriteria?.selectedValues || []),
          selectedBuildTypes: (preferences?.buildTypeInCriteria?.selectedValues || []),
          selectedOs: (preferences?.osCriteria?.selectedValues || []),
          selectedProperties: (preferences?.propertyInCriteria?.selectedValues || []),
          selectedAttributes: selectedAttributesFromPreferences,
          mainVersionSubstring: (preferences?.mainVersionCriteria?.startsWith || ''),
          order: {
            by: (orderFromPreferences?.orderBy || prevState?.order?.by || initialSelectionState.order.by),
            direction: (orderFromPreferences?.direction || prevState?.order?.direction || initialSelectionState.order.direction).toLowerCase(),
          },
          pagination: {
            ...initialSelectionState.pagination
          },
          subscription: (preferences?.subscription || initialSelectionState.subscription),
        })
      );

    } catch (error) {
      if (error.message !== 'Cancelled') {
        notify('commonNotificator',
          {
            text: "Error while loading build search preferences:",
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    }
  }
}


function useUpdateSearchStateInBackendFn({path = "/swc/api/builds/searchpreferences"}) {

  const {callApi} = useAuth();
  const {notify} = useNotifications();

  return async (cancel, setStateFn, mode, {
    selectedProductKeys,
    selectedBuildTypes,
    selectedOs,
    selectedProperties,
    selectedAttributes,
    mainVersionSubstring,
    pagination,
    order,
    subscription,
  }) => {

    const selectedAttributePairs = (selectedAttributes || []).map(
      keyAndValue => {
        const pair = keyAndValue.split("=")
        return ({
          key: pair.shift(),
          value: pair.shift()
        })
      }
    );

    const newPreferences = {
      "productInCriteria": {
        "selectedValues": selectedProductKeys || []
      },
      "buildTypeInCriteria": {
        "selectedValues": selectedBuildTypes || []
      },
      "osCriteria": {
        "selectedValues": selectedOs || []
      },
      "propertyInCriteria": {
        "selectedValues": selectedProperties || []
      },
      "attributeAndValueInCriteria": {
        "selectedValues": selectedAttributePairs
      },
      "mainVersionCriteria": {
        "startsWith": mainVersionSubstring?.trim() || ''
      },
      "orderList": [
        {
          "orderBy": order?.by || initialSelectionState.order.by,
          "direction": (order?.direction || initialSelectionState.order.direction).toUpperCase()
        }
      ],
      "subscription": subscription || SUBSCRIPTION_NONE_KEY,
    }

    const requestBody = JSON.stringify(newPreferences);

    callApi(`${path}/${mode}`, {
      method: 'POST',
      body: requestBody
    }, cancel).catch(
      error => {
        if (error.message !== 'Cancelled') {
          notify('commonNotificator',
            {
              text: "Error while updating search state:",
              error: error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });

    setStateFn(prevState => (
      {
        ...prevState,
        selectedProductKeys: [...selectedProductKeys],
        selectedBuildTypes: [...selectedBuildTypes],
        selectedOs: [...selectedOs],
        selectedProperties: [...selectedProperties],
        selectedAttributes: [...selectedAttributes],
        mainVersionSubstring: mainVersionSubstring,
        pagination: {...pagination},
        order: {...order},
        subscription: subscription || SUBSCRIPTION_NONE_KEY,
      }
    ));
  };
}

const usePreferenceSearchStateParam = {path: "/swc/api/builds/searchpreferences"}

export function usePreferenceSearchState() {

  const cancel = {};

  const [internalState, setInternalInternalState] = useState();
  const [defaultState, setDefaultState] = useState();

  const updatePreferenceSearchState = useUpdateSearchStateInBackendFn(usePreferenceSearchStateParam);
  const loadPreferenceSearchState = useLoadSearchStateFromBackendFn(usePreferenceSearchStateParam);

  const updateState = (newState) => updatePreferenceSearchState(cancel, setInternalInternalState, 'currentuser', newState);

  function resetState() {
    setInternalInternalState(defaultState);
  }

  async function loadPreferences() {
    await loadPreferenceSearchState(cancel, setInternalInternalState, 'currentuser');
  }

  async function loadDefaultPreferences() {
    await loadPreferenceSearchState(cancel, setDefaultState, 'default');
  }

  useEffect(() => {
    loadDefaultPreferences().then(() => loadPreferences())
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  return [internalState, updateState, resetState, defaultState];
}


export function usePreferenceSearchStateAdmin() {

  const cancel = {};

  const [internalState, setInternalState] = useState();
  const [defaultState, setDefaultState] = useState();

  const updatePreferenceSearchState = useUpdateSearchStateInBackendFn(usePreferenceSearchStateParam);
  const loadPreferenceSearchState = useLoadSearchStateFromBackendFn(usePreferenceSearchStateParam);

  const updateState = (newState) => updatePreferenceSearchState(cancel, setInternalState, 'default', newState);

  function resetState() {
    setInternalState(defaultState);
  }

  useEffect(() => {
    (() => loadPreferenceSearchState(cancel, setInternalState, 'default'))();
    (() => loadPreferenceSearchState(cancel, setDefaultState, 'default'))();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  return [internalState, updateState, resetState, defaultState];
}


const useSearchStateParam = {path: "/swc/api/builds/searchpreferences/currentuser/product"}

export function useSearchState({productKey, baseUrl}) {

  const cancel = {};

  const {goTo} = usePageMeta();
  const currentLocation = useLocation();
  const paramUseQueryParameter = "useQueryParameter";

  const [internalState, setInternalInternalState] = useState({...initialSelectionState});
  const parameters = useMemo(() => new URLSearchParams(currentLocation.search), [currentLocation.search, productKey, baseUrl]);

  const updatePersistedSearchState = useUpdateSearchStateInBackendFn(useSearchStateParam);
  const loadPersistedSearchState = useLoadSearchStateFromBackendFn(useSearchStateParam);

  async function updateState({
                               selectedBuildTypes,
                               selectedOs,
                               selectedProperties,
                               selectedAttributes,
                               mainVersionSubstring,
                               pagination,
                               order,
                             }) {

    await updatePersistedSearchState(cancel, function () {}, productKey, {
      selectedBuildTypes,
      selectedOs,
      selectedProperties,
      selectedAttributes,
      mainVersionSubstring,
      pagination,
      order,
    })

    const newParams = [
      [QUERY_PARAM.SELECTED_BUILD_TYPES, (selectedBuildTypes || []).map(encodeURIComponent).join(',')],
      [QUERY_PARAM.SELECTED_OS, (selectedOs || []).map(encodeURIComponent).join(',')],
      [QUERY_PARAM.SELECTED_PROPERTIES, (selectedProperties || []).map(encodeURIComponent).join(',')],
      [QUERY_PARAM.MAIN_VERSION_SUBSTRING, encodeURIComponent((mainVersionSubstring || "").trim())],
      [QUERY_PARAM.SELECTED_ATTRIBUTES, (selectedAttributes || []).map(encodeURIComponent).join(',')],
      [QUERY_PARAM.ORDER_BY, encodeURIComponent(order.by)],
      [QUERY_PARAM.ORDER_DIRECTION, encodeURIComponent(order.direction)],
      [QUERY_PARAM.PAGE_NUMBER, encodeURIComponent(pagination.page)],
      [QUERY_PARAM.PAGE_SIZE, encodeURIComponent(pagination.size)],
      [paramUseQueryParameter, true],
    ]

    const queryString = newParams.map(pair => `${pair[0]}=${pair[1]}`).join('&');
    const replaceBrowserHistory = true;

    goTo(`${baseUrl}?${queryString}`, replaceBrowserHistory);
  }

  async function resetState() {
    await updatePersistedSearchState(cancel, setInternalInternalState, productKey, {...initialSelectionState});
  }

  useEffect(() => {
    if (parameters.get(paramUseQueryParameter) !== "true") {

      function redirect(stateCalcFn) {
        const newState = stateCalcFn({...initialSelectionState});
        const newParams = [
          [QUERY_PARAM.SELECTED_BUILD_TYPES, (newState.selectedBuildTypes || []).map(encodeURIComponent).join(',')],
          [QUERY_PARAM.SELECTED_OS, (newState.selectedOs || []).map(encodeURIComponent).join(',')],
          [QUERY_PARAM.SELECTED_PROPERTIES, (newState.selectedProperties || []).map(encodeURIComponent).join(',')],
          [QUERY_PARAM.MAIN_VERSION_SUBSTRING, encodeURIComponent((newState.mainVersionSubstring || "").trim())],
          [QUERY_PARAM.SELECTED_ATTRIBUTES, (newState.selectedAttributes || []).map(encodeURIComponent).join(',')],
          [QUERY_PARAM.ORDER_BY, encodeURIComponent(newState.order.by)],
          [QUERY_PARAM.ORDER_DIRECTION, encodeURIComponent(newState.order.direction)],
          [QUERY_PARAM.PAGE_NUMBER, encodeURIComponent(newState.pagination.page)],
          [QUERY_PARAM.PAGE_SIZE, encodeURIComponent(newState.pagination.size)],
          [paramUseQueryParameter, true],
        ]
        const queryString = newParams.map(pair => `${pair[0]}=${pair[1]}`).join('&');
        const replaceBrowserHistory = true;
        goTo(`${baseUrl}?${queryString}`, replaceBrowserHistory);
      }

      (async function () {
        await loadPersistedSearchState(cancel, redirect, productKey);
      })();
    } else {
      const paramSelectedBuildTypesRaw = parameters.get(QUERY_PARAM.SELECTED_BUILD_TYPES);
      const paramSelectedOsRaw = parameters.get(QUERY_PARAM.SELECTED_OS);
      const paramSelectedProperties = parameters.get(QUERY_PARAM.SELECTED_PROPERTIES);
      const paramSelectedAttributes = parameters.get(QUERY_PARAM.SELECTED_ATTRIBUTES);
      const paramMainVersionSubstringRaw = parameters.get(QUERY_PARAM.MAIN_VERSION_SUBSTRING);

      const paramOrderBy = extractOrderBy(parameters);
      const paramOrderDirection = extractOrderDirection(parameters);
      const paramPageNumber = extractPageNumber(parameters);
      const paramPageSize = extractPageSize(parameters);

      setInternalInternalState(prevState => {
          return {
            ...prevState,
            selectedProductKeys: [productKey],
            selectedBuildTypes: convertParamToArray(paramSelectedBuildTypesRaw),
            selectedOs: convertParamToArray(paramSelectedOsRaw),
            selectedProperties: convertParamToArray(paramSelectedProperties),
            selectedAttributes: convertParamToArray(paramSelectedAttributes),
            mainVersionSubstring: (paramMainVersionSubstringRaw || "").trim(),
            pagination: {...prevState.pagination, page: paramPageNumber, size: paramPageSize},
            order: {...prevState.order, by: paramOrderBy, direction: paramOrderDirection},
          };
        }
      )
    }
  }, [parameters]);

  useEffect(()=>{
    console.log("[useSearchState]", `internal state changed`);
  }, [internalState]);

  return [internalState, updateState, resetState];
}

const convertParamToArray = paramString => (paramString || "").split(',').map(param => param.trim()).filter(it => it);

const extractOrderBy = parameters => {
  let answer = initialSelectionState.order.by;
  const rawParameterValue = parameters.get(QUERY_PARAM.ORDER_BY)?.trim();
  if ([ORDERABLE_ATTRIBUTES.BY.PRODUCT, ORDERABLE_ATTRIBUTES.BY.BUILD_TYPE, ORDERABLE_ATTRIBUTES.BY.MAIN_VERSION, ORDERABLE_ATTRIBUTES.BY.OS, ORDERABLE_ATTRIBUTES.BY.DATE].indexOf(rawParameterValue) > -1) {
    answer = rawParameterValue;
  }
  return answer;
};

const extractOrderDirection = parameters => {
  let answer = initialSelectionState.order.direction
  const rawParameterValue = parameters.get(QUERY_PARAM.ORDER_DIRECTION)?.trim();
  if ([ORDERABLE_ATTRIBUTES.DIRECTION.DESC, ORDERABLE_ATTRIBUTES.DIRECTION.ASC].indexOf(rawParameterValue) > -1) {
    answer = rawParameterValue;
  }
  return answer;
};

const extractPageNumber = parameters => parseInt(parameters.get(QUERY_PARAM.PAGE_NUMBER)) || initialSelectionState.pagination.page;

const extractPageSize = parameters => parseInt(parameters.get(QUERY_PARAM.PAGE_SIZE)) || initialSelectionState.pagination.size;

