/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import LogDatePicker from './form/LogDatePicker';
import ProductsOrBucketSelection from './form/ProductsOrBucketSelection';
import styled from "@emotion/styled";

const StyledFormContainer = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 0;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  padding-top: ${({theme}) => theme.spacing(2)};
  margin: ${({theme}) => theme.spacing(1)};
`;
const StyledButton = styled(Button)`
  display: flex;
  margin-right: ${({theme}) => theme.spacing(1)};
  width: 80px;
  margin-left: 4px;
`;
export const ComplianceLogInputFieldStyle = (theme) => `
  margin-left: ${theme.spacing(1.5)};
  margin-right: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};
  max-width: ${theme.spacing(100)};
`;

function hasDeltas(setAsArray1, setAsArray2) {
  const s1 = setAsArray1 || [];
  const s2 = setAsArray2 || [];
  const diff1 = s1.filter((x) => s2.indexOf(x) === -1);
  const diff2 = s2.filter((x) => s1.indexOf(x) === -1);
  return diff1.length > 0 || diff2.length > 0;
}

const defaultYearAndMonth = () => new Date().toISOString().substring(0, 7);

const defaultSearchState = {
  yearAndMonth: defaultYearAndMonth(),
  email: null,
  keys: [],
};

export default function ComplianceLogSearchForm({
                                                  disabled,
                                                  metaData,
                                                  searchState = defaultSearchState,
                                                  onChange = (newSearchState) => {
                                                  },
                                                }) {
  const [formDirty, setFormDirty] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(defaultSearchState.keys);
  const [selectedEmail, setSelectedEmail] = useState(defaultSearchState.email);
  const [selectedYearAndMonth, setSelectedYearAndMonth] = useState(defaultSearchState.yearAndMonth);

  const sanitizedMetaData = metaData || [];
  const sanitizedSearchState = {
    ...searchState,
    yearAndMonth: (searchState?.yearAndMonth || defaultSearchState.yearAndMonth),
    email: (searchState?.email || defaultSearchState.email),
    keys: (searchState?.keys || defaultSearchState.keys),
  };

  function init() {
    setSelectedYearAndMonth(sanitizedSearchState.yearAndMonth);
    setSelectedEmail(sanitizedSearchState.email);
    setSelectedKeys(sanitizedSearchState.keys);
    setFormDirty(false);
  }

  function calculateDirtyFlag() {
    setFormDirty(() => sanitizedSearchState.yearAndMonth !== selectedYearAndMonth
      || sanitizedSearchState.email !== selectedEmail
      || hasDeltas(sanitizedSearchState.keys, selectedKeys));
  }

  const handleReset = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    init();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    const newSearchState = {
      ...searchState,
      yearAndMonth: selectedYearAndMonth,
      email: selectedEmail,
      keys: selectedKeys.length ? selectedKeys : null,
    };
    onChange(newSearchState);
  };

  useEffect(calculateDirtyFlag, [selectedEmail, selectedYearAndMonth, selectedKeys, metaData, searchState]);
  useEffect(init, [searchState, metaData]);

  return (

    <StyledFormContainer>
      <form onSubmit={() => false}>
        <div>
          <LogDatePicker
            disabled={disabled}
            yearAndMonth={selectedYearAndMonth}
            onChange={(newYearAndMonth) => setSelectedYearAndMonth(newYearAndMonth || defaultYearAndMonth())}
          />
        </div>
        <div>
          <ProductsOrBucketSelection
            disabled={disabled}
            allItems={sanitizedMetaData}
            selectedItemKeys={selectedKeys}
            onChange={setSelectedKeys}
          />
        </div>
        <StyledButtonContainer>
          <StyledButton
            variant="outlined"
            color="primary"
            type="submit"
            disabled={disabled}
            onClick={handleSubmit}
          >
            Search
          </StyledButton>
          <StyledButton
            disabled={disabled || !formDirty}
            variant="outlined"
            type="button"
            onClick={handleReset}
          >
            Reset
          </StyledButton>
        </StyledButtonContainer>
      </form>
    </StyledFormContainer>
  );
}
