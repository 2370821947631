import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import FormattedDateTime from '../FormattedDateTime';
import SWCLink from '../SWCLink';

export default function VmLaunchTime({ instance, path }) {
  const tooltip = instance.launchTime;
  const label = instance.launchTime ?
    <FormattedDateTime dateTimeString={instance.launchTime}/> : null;

  return instance.launchTime ?
    (
      <SWCLink path={path}>
        <Tooltip
          title={tooltip}
          data-launch-time={instance.launchTime}>
          <span>{label}</span>
        </Tooltip></SWCLink>
    ) : null;
}

VmLaunchTime.propTypes = {
  instance: PropTypes.shape({
    launchTime: PropTypes.string,
  }).isRequired,
};
