import React, {Fragment, useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import VmInstanceAction from './VmInstanceAction';
import ConfirmationDialog from '../ConfirmationDialog';
import {AWS_COMMAND, awsCommandLabel, awsCommands, awsStateCommands,} from './vmConstants';
import PropTypes from 'prop-types';
import VmStateIcon from "./VmStateIcon";
import VmState from "./VmState";
import {useAuth} from '../../react-auth-wrapper';
import {useNotifications} from '../../contexts/NotificationsContext';
import styled from "@emotion/styled";

const initialDialogState = {
  open: false,
  title: '',
  body: '',
  handleSubmit: () => {
  },
};

function DialogBody({children}) {
  return (<div>{children}</div>);
}

DialogBody.propTypes = {
  children: PropTypes.node.isRequired,
};
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;


export default function VmInstanceStateWithActionMenu({instance, isAdminView, alterStateCallback, ...props}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [dialogState, setDialogState] = useState(initialDialogState);

  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const cancel = {};

  function handleMenuItemClicked(event) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function closeDialog() {
    setDialogState({...dialogState, open: false});
  }


  function wrapHandlerInConfirmationDialogIfRequired(command, handleSubmit) {
    if (command !== AWS_COMMAND.TERMINATE) {
      return handleSubmit;
    }

    function triggerActionAndClose() {
      closeMenu();
      closeDialog();
      handleSubmit();
    }

    return () => {
      closeMenu();
      setDialogState({
        open: true,
        title: 'VM Instance Deletion',
        body: (
          <DialogBody>
            Do you really want to delete the vm instance <i>{instance.name}</i>
            {' '}
            ?
          </DialogBody>
        ),
        handleSubmit: triggerActionAndClose,
      });
    };
  }

  function handleAlterState(command) {

    const url = isAdminView ? `/swc/api/admin/clouds/${instance.cloudKey}/regions/${instance.regionKey}/vms/${instance.instanceId}/commands/${command}` :
      `/swc/api/clouds/${instance.cloudKey}/regions/${instance.regionKey}/vms/${instance.instanceId}/commands/${command}`;

    callApi(url, {method: 'PUT'}, cancel)
      .then((response) => {
        console.log('[VmInstanceStateWithActionMenu]', response);
        if (alterStateCallback !== null) {
          alterStateCallback(response.instance);
        }
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('commonNotificator',
            {
              text: `Error while trying to change state of ${instance.name}:`,
              error: error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      })
      .finally(() => {
      });
  }

  function createMenuItem(command) {
    const enabledCommandList = awsStateCommands(instance.state);
    const label = awsCommandLabel(command);
    const isDisabled = disabled || !enabledCommandList.includes(command);

    function handleActionTriggered() {
      setDisabled(true);
      try {
        closeMenu();
        handleAlterState(command);
      } finally {
        setDisabled(false);
      }
    }

    const wrappedHandler = wrapHandlerInConfirmationDialogIfRequired(command,
      handleActionTriggered);

    return (
      <VmInstanceAction
        clicked={wrappedHandler}
        disabled={isDisabled}
        key={`${instance.instanceId}-${command}`}
      >
        <StyledContainer>
          <VmStateIcon state={command}/>
          {label}
        </StyledContainer>
      </VmInstanceAction>
    );
  }

  const menuItems = awsCommands().map(createMenuItem);

  return (
    <Fragment>
      <ConfirmationDialog
        open={dialogState.open}
        handleSubmit={dialogState.handleSubmit}
        handleCancel={closeDialog}
        title={dialogState.title}
      >
        {dialogState.body}
      </ConfirmationDialog>
      <div>
        <Button variant="outlined" size="small" aria-controls="simple-menu" aria-haspopup="true"
                onClick={handleMenuItemClicked} sx={{
          label: {
            justifyContent: "left",
          }
        }} fullWidth {...props}>
          <VmState instance={instance}/>
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          {menuItems}
        </Menu>
      </div>
    </Fragment>
  );
}

VmInstanceStateWithActionMenu.propTypes = {
  instance: PropTypes.shape({
    instanceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }).isRequired,
  alterStateHandler: PropTypes.func.isRequired,
};
