import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import React from "react";

export function IconTextButton({tooltip, label, onClick, startIcon, className, disabled = false}) {

  function getRandomId() {
    return Math.random().toString(36).substring(2, 15);
  }

  const doHandleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(event);
  };

  const btn = <Button
    disabled={disabled}
    aria-label={label}
    aria-haspopup="true"
    aria-controls={`buildDetailPanelButton-${getRandomId()}`}
    color="inherit"
    onClick={doHandleClick}
    startIcon={startIcon}
  >
    {label}
  </Button>;

  return disabled ? btn : (
    <Tooltip title={tooltip} className={className} placement={'bottom'}>
      {btn}
    </Tooltip>
  )
}

IconTextButton.prototype = {
  tooltip: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  startIcon: PropTypes.element,
}
