import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import TabPanel from './TabPanel';
import AdminCloudOverviewTabs from '../menu/admin/AdminCloudOverviewTabs';
import SWCTabs from './SWCTabs';
import useClouds from '../hooks/useClouds';
import {useParams} from "react-router-dom";

export default function CloudTabs({ firstLevelActiveTabPath, TabContent }) {

  const [secondLevelCurrentTabPath, setSecondLevelCurrentTabPath] = useState(null);
  let { cloudKey } = useParams();
  const cancel = {};
  const { clouds, validCloudKey } = useClouds(cloudKey, "commonNotificator", cancel);

  const calculateSecondLevelCurrentTabPath = () => {
    if (validCloudKey !== null) {
      setSecondLevelCurrentTabPath(getTabPath(validCloudKey));
    }
  }

  const getTabPath = (cloudKey) => {
    return `${firstLevelActiveTabPath}/${cloudKey}`;
  }

  const getTabNames = () => {
    return clouds.map((cloud) => ({
      name: cloud.label,
      path: getTabPath(cloud.key)
    }));
  };

  useEffect(() => {
    calculateSecondLevelCurrentTabPath();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, [validCloudKey, clouds])

  let content = null;
  let tabs = null;

  if (clouds && clouds.length > 0) {
    const tabsInfo = getTabNames();
    content = (<React.Fragment>
      {tabsInfo.map((tabInfo, index) => <TabPanel selectedTabPath={secondLevelCurrentTabPath}
                                                  tabPath={tabInfo.path}
                                                  key={index}><TabContent visible={secondLevelCurrentTabPath === tabInfo.path}
                                                                          isAdminView={true}
                                                                          key={index}/></TabPanel>)}
    </React.Fragment>);

    tabs = (<SWCTabs activeTabPath={secondLevelCurrentTabPath}
                     tabsInfo={tabsInfo}>{content}</SWCTabs>)
  }

  return <AdminCloudOverviewTabs activeTabPath={firstLevelActiveTabPath}>
    {tabs}
  </AdminCloudOverviewTabs>;
}

CloudTabs.propTypes = {
  TabContent: PropTypes.func.isRequired,
};
