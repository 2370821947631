import React from 'react';
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-start;
  align-items: baseline;
  margin-bottom: ${({theme}) => theme.spacing(1)};
`;
const StyledTitle = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({theme}) => theme.spacing(1)};
`;
const StyledActions = styled.div`
  align-self: flex-start;
`;
export default function PanelHeaderBox({title, actions, children}) {
  return (
    <div>
      <StyledHeader>
        <StyledTitle>{title}</StyledTitle>
        {(actions || children) ? (
          <StyledActions>
            {actions}
            {children}
          </StyledActions>
        ) : null
        }
      </StyledHeader>
    </div>
  );
}

PanelHeaderBox.prototype = {
  title: PropTypes.element.isRequired,
  actions: PropTypes.element,
  children: PropTypes.element,
  className: PropTypes.string,
}

