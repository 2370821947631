import React from "react";
import {SWC_PROP_TYPES} from "../../SwcPropTypes";
import {Tooltip, Typography} from "@mui/material";
import FormattedDateTime from "../FormattedDateTime";
import prettyBytes from "pretty-bytes";
import SimpleTableWithPaging from "../SimpleTableWithPaging";
import BuildDetailPanel from "./BuildDetailPanel";
import {buildVersionLabel} from "./buildVersionLabel";
import PanelHeaderBox from "./PanelHeaderBox";
import DownloadButton from "./DownloadButton";
import ReleaseNotesButton from "./ReleaseNotesButton";
import {IconTextButton} from "../IconTextButton";
import {Delete, Settings} from "@mui/icons-material";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";

const headRow = [
  {
    id: 'key', cssClass: 'x', disablePadding: false, label: 'Key',
  },
  {
    id: 'value', cssClass: 'y', disablePadding: false, label: 'Value',
  },
];


export default function BuildDetailPanelMain({versionItem, buildAudiencesForVersion, toggleRestrictionDialog, togglePurgeDialog}) {

  const {version: versionObj, productMeta} = versionItem;
  const releaseNotes = versionObj?.releaseNotes;

  const titleComponent = (
    <PanelHeaderBox title={
      <Typography variant={'h6'}>Details</Typography>
    }>
      <React.Fragment>
        {versionObj && !versionObj?.betaType && releaseNotes && releaseNotes.length > 0 ? (
          <ReleaseNotesButton
            productMeta={productMeta}
            versionObj={versionObj}
          />
        ) : null}
        {versionObj && productMeta ? (
          <DownloadButton
            productMeta={productMeta}
            versionObj={versionObj}
          />
        ) : null}
        {toggleRestrictionDialog ? (
          <IconTextButton
            label={"Restrictions"}
            tooltip={"Visibility Restrictions"}
            onClick={toggleRestrictionDialog}
            startIcon={<Settings/>}
          />
        ) : null}
        {togglePurgeDialog ? (
            <IconTextButton
                label={"Purge Build"}
                tooltip={"Completly Delete Build"}
                onClick={togglePurgeDialog}
                startIcon={<Delete/>}
            />
        ) : null}
      </React.Fragment>
    </PanelHeaderBox>
  );

    const buildAudienceChips = (buildAudiencesForVersion != null && buildAudiencesForVersion.length > 0) ?
        buildAudiencesForVersion.map(audience =>
            <Chip
                key={audience.buildAudience}
                label={audience.buildAudience}
                size="small"
                sx={{
                    marginRight: (theme) => theme.spacing(1),
                    fontSize: 'x-small',
                    backgroundColor: 'rgb(226,226,226)',
                }}
            />
        ) : null;

    const rows = [
    ['Restricted Build', buildAudienceChips],
    ['Version', versionObj.classifier],
    ['OS', buildOs(versionItem)],
    ['Build', buildVersionType(versionItem)],
    ['Build Date', buildReleaseDate(versionItem)],
    ['Baseline', versionObj.baseline],
    ['Git-sha', versionObj.gitHash],
    ['Release Type', convertToTitleCase(versionObj.releaseType)],
    ['Size', buildArchiveSize(versionItem)],
    ['Product', wrapWithTooltip(productMeta.productLabel, productMeta.productDescription)],
    ['Product Group', wrapWithTooltip(productMeta.groupLabel, productMeta.groupDescription)],
    ['Product Category', wrapWithTooltip(productMeta.categoryLabel, productMeta.categoryDescription)],
  ].filter(cells => cells[1]);

  return (
    <BuildDetailPanel
      title={titleComponent}
    >
      <SimpleTableWithPaging
        headRow={headRow}
        rows={rows}
        showHeadRow={false}
        showTitle={false}
        showPagination={false}
        sortable={false}
      />
    </BuildDetailPanel>
  );
}

BuildDetailPanelMain.prototype = {
  versionItem: SWC_PROP_TYPES.versionItem.isRequired,
  toggleRestrictionDialog: PropTypes.func,
}


const wrapWithTooltip = (value, tooltip) => {
  return value ? (
    <Tooltip title={tooltip || value}>
      <span>{value}</span>
    </Tooltip>
  ) : null;
};


const buildOs = versionItem => {
  const osMeta = versionItem?.osMeta;
  if (osMeta?.label) {
    return wrapWithTooltip(osMeta.label, osMeta.description || osMeta.label)
  } else
    return versionItem?.version?.os
};


const buildVersionType = versionItem => {
  const buildVersionType = versionItem?.version?.buildVersionType;
  const label = buildVersionLabel(buildVersionType);

  return wrapWithTooltip(label, buildVersionType);
};

const buildReleaseDate = versionItem => {
  const releaseDate = versionItem?.version?.releaseDate;

  return releaseDate ? (
    <Tooltip title={releaseDate}>
      <span>
        <FormattedDateTime dateTimeString={releaseDate}/>
      </span>
    </Tooltip>
  ) : null
};

const buildArchiveSize = versionItem => {
  const size = versionItem?.version?.size;
  const label = size ? prettyBytes(size,{binary:true}) : null;

  return wrapWithTooltip(label, size);
};

const convertToTitleCase = (inputString = '') => {
  return inputString ? inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase() : null;
};
