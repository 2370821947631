import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useAuth} from '../../react-auth-wrapper';
import {useNotifications} from '../../contexts/NotificationsContext';
import {SWC_PROP_TYPES} from "../../SwcPropTypes";

export const OPEN_WINDOW = 'OPEN_WINDOW'

export default function useDownloader({
                                        productKey,
                                        versionObj,
                                        downloadType,
                                        fileName = null,
                                        mode = null,
                                      }) {
  const {getApiUrlWithToken, callApi} = useAuth();
  const [loading, setLoading] = useState(false);
  const {notify} = useNotifications();

  const {mainVersion, os, type, classifier} = versionObj;

  let queryParameter = '';
  if (mode === OPEN_WINDOW) {
    queryParameter = `${queryParameter}&contentDispositionInline=true`;
  }
  if (fileName) {
    queryParameter = `${queryParameter}&filename=${encodeURIComponent(fileName)}`;
  }

  const handleClick = (event) => {

    async function triggerDownload(downloadUrl) {

      function showNotEntitledToOpenWindowError() {
        notify("commonNotificator",
          {
            text: 'Please allow popup opening to see downloaded data.',
            type: "error",
            handleClose: () => {
            }
          });
      }

      async function convertToBase64Src(documentUrl) {
        const documentResponse = await fetch(documentUrl);
        const contentTypeRaw = documentResponse.headers.get('Content-Type')
        const contentType = contentTypeRaw || "text/plain";
        console.log(`[downloader] contentType=${contentTypeRaw}`);
        const arrayBuffer = await documentResponse.arrayBuffer();
        const typedArray = new Uint8Array(arrayBuffer);
        let binary = '';
        const len = typedArray.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(typedArray[i]);
        }
        const base64 = btoa(binary);
        return `"data:${contentType};base64,${base64}`;
      }

      function triggerStdDownload() {
        console.log(`[downloader] type=${downloadType} url=${downloadUrl}`);
        const link = document.createElement('a');
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      const showInBrowserWindow = mode === OPEN_WINDOW;

      if (showInBrowserWindow) {
        if (downloadType === 'SIGNED') {
          const wnd = window.open(downloadUrl, '');
          if (!wnd) {
            showNotEntitledToOpenWindowError();
          }
        } else {
          const wnd = window.open('about:blank', '');
          if (!wnd) {
            showNotEntitledToOpenWindowError();
          } else {
            try {
              const base64SrcString = await convertToBase64Src(downloadUrl);
              wnd.document.write(`<iframe style="border: 0; width: 100%; height: 100%;" src=${base64SrcString}" />`);
            } catch (error) {
              console.log(`[downloader] showInBrowserWindow=${showInBrowserWindow}`, error);
              notify("commonNotificator",
                {
                  text: 'Could not download the request data.',
                  type: "error",
                  handleClose: () => {
                  }
                });
            }
          }
        }
      } else {
        triggerStdDownload();
      }
    }

    async function downloadMirrors() {
      const mirrorList = await callApi('/swc/api/mirrors?filtered=true');
      const mirrorKeys = mirrorList.map(mirror => mirror.key); // last list element is always 'no-mirror'
      console.log(`[downloader] mirrors=${mirrorKeys}, mirrors.length=${mirrorKeys.length}`);
      return mirrorKeys;
    }

    async function downloadFromMirrors(mirrorKeyList) {
      const [mirrorKey, ...remaining] = mirrorKeyList;
      try {
        console.log(`[downloader] mirror=${mirrorKey}: Trying dry run...`);
        const downloadEndpoint = `/swc/api/mirrors/${mirrorKey}/products/${productKey}/${type}/${mainVersion}/classifiers/${classifier}/os/${os}/download?${queryParameter}`;
        await callApi(`${downloadEndpoint}&dryRun=true`);
        console.log(`[downloader] mirror=${mirrorKey}: ... dry run succeeded.`);
        console.log(`[downloader] mirror=${mirrorKey}: Starting actual download...`);
        const urlWithAccessToken = await getApiUrlWithToken(downloadEndpoint);
        await triggerDownload(urlWithAccessToken);
        console.log(`[downloader] mirror=${mirrorKey}: ... downloaded.`);
      } catch (error) {
        if (error.message !== 'Cancelled') {
          if (remaining && remaining.length) {
            console.log(`[downloader] Could not download from mirror ${mirrorKey}, trying ${remaining}.`);
            await downloadFromMirrors(remaining);
          } else {
            throw error;
          }
        }
      }
    }

    function handleError(error) {
      if (error.message !== 'Cancelled') {
        notify(
          'commonNotificator',
          {
            text: 'Error while downloading:',
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    }

    console.log('[downloader] ...');

    if (event) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }

    setLoading(true);
    if (downloadType === 'SIGNED') {
      callApi(`/swc/api/products/${productKey}/${type}/${mainVersion}/classifiers/${classifier}/os/${os}/downloadUrl?${queryParameter}`)
        .then(response => triggerDownload(response.url))
        .catch(handleError)
        .finally(() => setLoading(false));
    } else {
      downloadMirrors()
        .then(downloadFromMirrors)
        .catch(handleError)
        .finally(() => setLoading(false));
    }
  };

  useEffect( () => {
    setLoading(false);
  }, [productKey, versionObj, downloadType, fileName]);

  return {
    handleClick,
    loading,
  };
}

useDownloader.propTypes = {
  cancel: PropTypes.shape({}).isRequired,
  productKey: PropTypes.string.isRequired,
  downloadType: SWC_PROP_TYPES.downloadType.isRequired,
  versionObj: SWC_PROP_TYPES.version.isRequired,
  fileName: PropTypes.string.isRequired,
};
