import React, {useEffect} from 'react';
import {usePageMeta} from '../../../contexts/PageMetaContext';
import FilesTabs from './FilesTabs';
import {ADMIN_SETTINGS} from '../../../components/PathConstants';
import FileExplorer from '../../FileExplorer/FileExplorer';

export default function CloudFiles() {

  const {setTitle} = usePageMeta();
  const cancel = {};

  useEffect(() => {
    setTitle('Administration - Bucket Overview');
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  return <FilesTabs firstLevelActiveTabPath={ADMIN_SETTINGS.buckets.path}
                    TabContent={FileExplorer}/>
}
