import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import FormattedDateTime from '../../../../components/FormattedDateTime';


export default function TimestampCell({ timestamp }) {
  const tooltip = timestamp;
  const label = (timestamp) ? <FormattedDateTime dateTimeString={timestamp}/> : null;

  return timestamp ? (
    <Tooltip
      title={tooltip}
      data-timestamp={timestamp}>
      <span>{label}</span>
    </Tooltip>
  ) : null;
}

TimestampCell.propTypes = {
  timestamp: PropTypes.string.isRequired,
};
