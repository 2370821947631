import React from 'react';
import Avatar from '@mui/material/Avatar'
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuth } from "../react-auth-wrapper";

const MD5 = require("react-native-crypto-js").MD5;

export default function UserAvatar(props) {

  const { user } = useAuth();

  const generateGravatar = (email) => {
    const lowercaseEmail = email.trim().toLowerCase();
    const hash = MD5(lowercaseEmail);
    const gravatarMysteryPerson = "mp";
    const defaultIcon = lowercaseEmail.length < 2 ? gravatarMysteryPerson : `https://cdn.auth0.com/avatars/${lowercaseEmail.substr(0, 2)}.png`;
    return `https://www.gravatar.com/avatar/${hash}?s=200&d=${defaultIcon}`;
  }

  const getAvatar = () => {
    if (user && user.picture) {
      return <Avatar title={user.nickname} src={user.picture} {...props}/>;
    } else if (user && user.email) {
      return <Avatar title={user.nickname} src={generateGravatar(user.email)} {...props}/>;
    } else {
      return <AccountCircle {...props}/>;
    }
  }

  return (
    <div>{getAvatar()}</div>
  );
}
