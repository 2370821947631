import { reactLocalStorage } from 'reactjs-localstorage';
import { useEffect, useState } from 'react';


export const Keys = {
  SHOW_HIDDEN_FILES: 'showHiddenFiles',
  SHOW_FOLDERS_FIRST: 'showFoldersFirst',
  SHOW_LIST_VIEW: 'showListView',
};

const defaultPreferences = {};
defaultPreferences[Keys.SHOW_HIDDEN_FILES] = true;
defaultPreferences[Keys.SHOW_FOLDERS_FIRST] = true;
defaultPreferences[Keys.SHOW_LIST_VIEW] = true;

const localStorageKey = 'file_explorer_preferences';

export function useFileExplorerPreferences() {
  const [preferences, setPreferences] = useState(
    { ...defaultPreferences, ...reactLocalStorage.getObject(localStorageKey) },
  );

  useEffect(() => {
    reactLocalStorage.setObject(localStorageKey, { ...defaultPreferences, ...preferences });
  }, [preferences]);

  return [preferences, setPreferences];
}
