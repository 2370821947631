import PropTypes from 'prop-types';
import React from 'react';
import styled from "@emotion/styled";

const StyledWrapper = styled.div`
  width: 100%;
  margin: auto;
`;

export default function ListWrapper({children}) {

  return (<StyledWrapper>{children}</StyledWrapper>);
}

ListWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
