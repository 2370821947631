import React, {useEffect, useState} from 'react';
import {useAuth} from '../../react-auth-wrapper';
import {useNotifications} from '../../contexts/NotificationsContext';
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import LoadingIcon from "../LoadingIcon";
import {ORDERABLE_ATTRIBUTES} from "../BuildSearch/searchConstants";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import BuildDetailPanelFileList from "./BuildDetailPanelFileList";
import BuildDetailPanelInstallCommand from "./BuildDetailPanelInstallCommand";
import BuildDetailPanelMain from "./BuildDetailPanelMain";
import {buildVersionLabel} from "./buildVersionLabel";
import {usePageMeta} from "../../contexts/PageMetaContext";
import PanelHeaderBox from "./PanelHeaderBox";
import useModal from "../../hooks/useModal";
import ModalDialog from "../ModalDialog";
import Restrictions from "./Restrictions";
import styled from "@emotion/styled";
import ConfirmationDialog from "../ConfirmationDialog";

const StyledSeparator = styled.span`
  margin-right: ${({theme}) => theme.spacing(2)};
`;
const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledChip = styled(Chip)`
  margin-right: ${({theme}) => theme.spacing(1)};
  font-size: x-small;
  ${props => props.isBeta ? '' : 'background-color: #cee874;'};
`;

export default function BuildDetail({categoryKey, groupKey, productKey, mainVersion, os, version, buildType}) {
  const cancel = {};
  const {isShowing: isShowingRestrictionsDialog, toggle: toggleRestrictionsDialog} = useModal();
  const {isShowing: isShowingPurgeDialog, toggle: togglePurgeDialog} = useModal();

  const {goTo} = usePageMeta();
  const [loading, setLoading] = useState(false);
  const [versionItem, setVersionItem] = useState(null);
  const [headFragments, setHeadFragments] = useState([]);
  const [buildAudiences, setBuildAudiences] = useState(null);
  const [allowedToChangeRestrictions, setAllowedToChangeRestrictions] = useState(false);

  const versionLabel = versionItem?.osMeta?.label || versionItem?.version?.os;
  const buildAudiencesForVersion = versionItem?.version?.buildAudiences
  const {notify} = useNotifications();
  const {setTitle} = usePageMeta();
  const {callApi} = useAuth();

  function updateTitle() {
    const newTitle = [versionItem?.productMeta.productLabel, versionLabel].filter(it => it).join(' ');
    setTitle(newTitle);
  }

  function calculateHeadFragments() {
    const versionObj = versionItem?.version;
    const productMeta = versionItem?.productMeta;
    const attributeSpec = productMeta?.productAttributes || [];

    if (versionObj && productMeta) {
      const buildVersionTypeLabel = buildVersionLabel(versionObj.buildVersionType);
      const qualifiedBuild = versionObj?.properties?.qualified === 'true';

      let fragments = [(<StyledSeparator>{versionObj.version}</StyledSeparator>)];
      if (productMeta.productKey.startsWith('platformdeltabundle')) {
        fragments = [`${versionObj.version} (${versionLabel})`];
      } else if (versionObj.baseline || versionObj.gitHash || qualifiedBuild || versionObj.attributes) {
        if (buildVersionTypeLabel) {
          fragments.push(<StyledChip
            size="small"
            label={buildVersionTypeLabel}
            isBeta={versionObj.buildVersionType === 'REFERENCE'}/>);
        }
        if (qualifiedBuild) {
          fragments.push(<Chip
            size="small"
            label={"qualified"}
            sx={{
              marginRight: (theme) => theme.spacing(1),
              fontSize: 'x-small',
              backgroundColor: '#2fb641',
            }}
          />);
        }
        attributeSpec.forEach(spec => {
          const {key, label, values = []} = spec;
          const selectedValues = ((versionObj?.attributes || {})[key]) || [];
          values
            .filter(value => selectedValues.includes(value))
            .forEach(value => {
              const chip = (<Chip
                size="small"
                label={`${productMeta.productLabel}:${label}:${value}`}
                sx={{
                  marginRight: (theme) => theme.spacing(1),
                  fontSize: 'x-small',
                  color: 'white',
                  backgroundColor: '#9b0744',
                }}/>);
              fragments.push(chip);
            })
        })
        fragments
          .filter(item => item)
          .map((item, index) => (<StyledSeparator key={index}>{item}</StyledSeparator>));
      }
      setHeadFragments(fragments);
    } else {
      setHeadFragments([]);
    }
  }

  function lookup() {

    if (categoryKey && groupKey && productKey && mainVersion && os && version && buildType) {

      const queryParameter = [
        ['buildType', buildType],
        ['os', os],
        ['product', productKey],
        ['mainVersion', mainVersion],
        ['version', version],
        ['releaseDateCriteria', ""],
        ['releaseDateDayCriteria', ""],
        ['orderBy', ORDERABLE_ATTRIBUTES.BY.DATE],
        ['orderDirection', ORDERABLE_ATTRIBUTES.DIRECTION.DESC],
        ['expands', 'FILE_LIST,RELEASE_NOTES,INSTALL_COMMAND,BUILD_AUDIENCE'],
      ];
      const queryString = new URLSearchParams(queryParameter).toString();
      console.log("[BuildDetail] searching", queryString);
      setLoading(true);

      callApi('/swc/api/builds/search?' + queryString, cancel).then((response) => {
        if (response?.pagination?.overallCount > 0) {
          setVersionItem(response.items[0]);
          setLoading(false);
        } else {
          setVersionItem(null);
          setLoading(false);

          const encodedOS = encodeURIComponent(os || '');
          const encodedMainVersion = encodeURIComponent(mainVersion || '');
          goTo(`/download/${categoryKey}/builds/${groupKey}/${productKey}?os=${encodedOS}&mainVersionSubstring=${encodedMainVersion}`);
        }
      }).catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('commonNotificator', {
            text: "Error while loading build:", error: error, type: 'error', handleClose: () => {
            },
          });
          setVersionItem(null);
          setLoading(false);
        }
      });
    } else {
      setVersionItem(null);
    }
  }

  const loadBuildAudienceList = () => {
    callApi('/swc/api/admin/builds/upload/my/metaData/buildAudienceList', {method: 'GET'}, cancel).then((response) => {
      setBuildAudiences(response);
    }).catch((error) => {
      if (error.message !== 'Cancelled' && error.status !== 403) {
        notify('commonNotificator', {
          text: "Error while loading build audiences", error: error, type: 'error', handleClose: () => {
          },
        });
        setBuildAudiences(null);
      }
    });
  }

  const updateBuildAudiences = (buildAudienceList) => {
    const buildAudienceStringList = (buildAudienceList || []).map(buildAudienceObj => buildAudienceObj.buildAudience);
    const restEndpoint = `/swc/api/admin/builds/upload/${productKey}/${buildType}/${mainVersion}/classifiers/${version}/os/${os}/buildAudience`;
    const restBody = JSON.stringify(buildAudienceStringList)

    callApi(restEndpoint, {method: 'PUT', body: restBody}, cancel)
      .then(lookup)
      .catch((error) => {
        if (error.message !== 'Cancelled' && error.status !== 403) {
          notify('commonNotificator', {
            text: "Error while updating build audiences", error: error, type: 'error', handleClose: () => {
            },
          });
        }
      });
  }

  const purgeBuild = async () => {
    const restEndpoint = `/swc/api/admin/builds/upload/${productKey}/${buildType}/${mainVersion}/classifiers/${version}/os/${os}`;
    try {
      togglePurgeDialog();
      await callApi(restEndpoint, {method: 'DELETE'}, cancel);
      goTo(`/download/${categoryKey}/builds/${groupKey}/${productKey}?os=${os}`);
    } catch (error) {
      if (error.message !== 'Cancelled' && error.status !== 403) {
        notify('commonNotificator', {
          text: "Error while purging build", error: error, type: 'error', handleClose: () => {
          },
        });
      }
    }
  }

  const checkIfAllowedChangeRestrictions = async () => {
    try {
      const productKeyResponse = await callApi('/swc/api/admin/builds/upload/my/metaData/productKeys', {method: 'GET'}, cancel);
      const productPermissionResponse = await callApi('/swc/api/admin/builds/upload/my/metaData/permissions', {method: 'GET'}, cancel);
      const checkIfAllowedChangeRestrictions = (productKeyResponse || []).includes(productKey) && (productPermissionResponse || []).includes('ALTER_BUILD_AUDIENCE');
      setAllowedToChangeRestrictions(checkIfAllowedChangeRestrictions);
    } catch(error) {
      if (error.message !== 'Cancelled') {
        setAllowedToChangeRestrictions(false);
        notify('commonNotificator', {
          text: "Error while loading user product keys", error: error, type: 'error', handleClose: () => {
          },
        });
      }
    }
  }

  useEffect(updateTitle, [versionItem]);
  useEffect(calculateHeadFragments, [versionItem]);
  useEffect(() => console.trace("[BuildDetail]", versionItem), [versionItem]);

  useEffect(() => {
    lookup();
    loadBuildAudienceList();
    checkIfAllowedChangeRestrictions();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [categoryKey, groupKey, productKey, mainVersion, os, version, buildType]);

  return (<StyledRoot>

    <Backdrop sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }} open={loading}>
      <LoadingIcon/>
    </Backdrop>

    {versionItem != null && <StyledRoot>
      <Paper sx={{
        padding: (theme) => theme.spacing(2),
        marginBottom: (theme) => theme.spacing(3),
      }} square>
        <PanelHeaderBox
          title={<Typography variant={'h6'} gutterBottom>
            <StyledChip
              size="small"
              label={versionItem?.version?.type}
              isBeta={versionItem.version?.betaType}/>
            <span>{headFragments.map(value => value)}</span>
          </Typography>}/>
        {versionItem.version ? (<React.Fragment>
          <BuildDetailPanelMain
              versionItem={versionItem}
              buildAudiencesForVersion={allowedToChangeRestrictions ? buildAudiencesForVersion : []}
              toggleRestrictionDialog={(buildAudiences && allowedToChangeRestrictions) ? toggleRestrictionsDialog : null}
              togglePurgeDialog={allowedToChangeRestrictions ? togglePurgeDialog : null}
          />
          <BuildDetailPanelInstallCommand versionItem={versionItem}/>
          <BuildDetailPanelFileList versionItem={versionItem}/>
        </React.Fragment>) : null}
      </Paper>
      <ModalDialog
        open={isShowingRestrictionsDialog}
        toggle={toggleRestrictionsDialog}
        title="Build Access Restriction"
        maxWidth='lg'
        fullWidth={true}
      >
        <Restrictions initialAudiences={buildAudiencesForVersion} allAudiences={buildAudiences}
                      updateBuildAudiences={updateBuildAudiences}
                      hide={toggleRestrictionsDialog}/>
      </ModalDialog>
      <ConfirmationDialog
          open={isShowingPurgeDialog}
          title="Do you really want to delete this build data? This action cannot be undone."
          handleCancel={togglePurgeDialog}
          handleSubmit={purgeBuild}
          width = 'wide'
      >
        <span>The purging process is being handled by a background job. Please be aware that this might take some time, so your patience is appreciated.</span>
      </ConfirmationDialog>
    </StyledRoot>
    }
  </StyledRoot>);
}

