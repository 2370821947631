import React, {useState} from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {getThemeMode, useThemeContext} from "../contexts/ThemeContext";
import styled from "@emotion/styled";

const StyledButton = styled(Button)`
  ${props => props.selected ? `
    background-color: ${props.theme.palette.primary.main};
    color: white;
  ` : ''}
`;

export default function ThemeSwitcher() {

  const [currentThemeMode, setCurrentThemeMode] = useState(localStorage.getItem("themeMode") || 'light');
  const {theme, handleThemeChange} = useThemeContext();

  const handleMode = (newMode) => {
    localStorage.setItem("themeMode", newMode);
    const newThemeMode = getThemeMode();
    if (theme.palette.mode !== newThemeMode) {
      handleThemeChange();
    }
    setCurrentThemeMode(newMode);
  }

  return (
    <>
      <ButtonGroup color="primary"
                   size="small"
                   aria-label="theme button group"
                   sx={{
                     maxHeight: '40px',
                     paddingTop: '15px',
                     paddingRight: (theme) => theme.spacing(1),
                   }}>
        <StyledButton selected={currentThemeMode === 'light'} onClick={() => handleMode("light")}>Light</StyledButton>
        <StyledButton selected={currentThemeMode === 'dark'} onClick={() => handleMode("dark")}>Dark</StyledButton>
        <StyledButton selected={currentThemeMode === 'auto'} onClick={() => handleMode("auto")}>Auto</StyledButton>
      </ButtonGroup>
    </>
  );
}
