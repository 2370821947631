import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

export default function VmInstanceAction({ disabled = false, clicked: handleClicked, children }) {

  return (
    <MenuItem
      disabled={disabled}
      onClick={handleClicked}
    >
      {children}
    </MenuItem>
  );
}

VmInstanceAction.propTypes = {
  clicked: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
