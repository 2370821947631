import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SWCLink from "../../SWCLink";

export default function BuildOSCell({ item, clickCallback = () => {}, osMetaList = []}) {
  const rawOs = item?.version?.os;

  let tooltip = (osMetaList.find(meta=>meta.name === rawOs)?.description) || rawOs;
  let label = (osMetaList.find(meta=>meta.name === rawOs)?.label) || rawOs;
  if (item?.osMeta) {
      tooltip = item.osMeta.description
      label = item.osMeta.label
  }

  return rawOs ?
    (
      <SWCLink clickCallback={clickCallback}>
        <Tooltip
          title={tooltip}
          data-os={rawOs}>
          <span>{label}</span>
        </Tooltip>
      </SWCLink>
    ) : null;
}

BuildOSCell.propTypes = {
  clickCallback: PropTypes.func,
  item: PropTypes.shape({
    version: PropTypes.shape({
        os: PropTypes.string,
    }),
    osMeta : PropTypes.shape({
      name: PropTypes.string,
      family: PropTypes.string,
      label: PropTypes.string,
      description: PropTypes.string,
    })
  }).isRequired,
  osMetaList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      family: PropTypes.string,
      label: PropTypes.string,
      description: PropTypes.string,
  })),
};
