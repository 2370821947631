import React from 'react';
import {TextField} from "@mui/material";
import {StyledSearchFormAutocomplete} from "./SearchForm";

export default function AttributeSelection({
                                               preSelectedProductKeys,
                                               productList,
                                               selectedAttributes,
                                               setSelectedAttributes,
                                               disabled,
                                           }) {

    const productAttributeMappings = [];

    for (const productMeta of (productList || [])) {
        if (preSelectedProductKeys == null || preSelectedProductKeys.includes(productMeta.productKey)) {
            for (const productAttribute of (productMeta.productAttributes || [])) {
                for (const productAttributeValue of (productAttribute.values || [])) {
                    productAttributeMappings.push(
                        {
                            key: productAttribute.key,
                            value: productAttributeValue,
                            label: `${productMeta.productLabel}:${productAttribute.label}:${productAttributeValue}`,
                            selection: `${productAttribute.key}=${productAttributeValue}`
                        }
                    )
                }
            }
        }
    }

    const sortedProductAttributeMappings = [...productAttributeMappings].sort((a, b) => a.label.localeCompare(b.label));
    const selectedProductAttributeMappings = sortedProductAttributeMappings.filter(item => (selectedAttributes || []).indexOf(item.selection) > -1)

    const handleChange = (event, value) => {
        const newSelectedAttributes = (value || []).map(item => item.selection);
        setSelectedAttributes(newSelectedAttributes)
    };

    return productAttributeMappings.length === 0 ? null : (
        <StyledSearchFormAutocomplete
            disabled={disabled}
            multiple
            id="search-selected-attributes"
            options={sortedProductAttributeMappings}
            getOptionLabel={(option) => option.label}
            defaultValue={[]}
            filterSelectedOptions
            onChange={handleChange}
            value={selectedProductAttributeMappings}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Selected attributes"
                    placeholder="Product attributes"
                />
            )}
        />
    );
}
