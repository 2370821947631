import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SWCLink from "../../SWCLink";
import Chip from "@mui/material/Chip";

const chipStyles = {
  chip_qualified: {
    marginRight: (theme) => theme.spacing(1),
    fontSize: 'x-small',
    backgroundColor: '#39ca4b',
  }
};
export default function BuildPropertiesCell({
                                              item, clickCallback = () => {
  }, propertyList
                                            }) {

  const propertyMap = item?.version?.properties || ({});
  const sortedPropertyItems = propertyList.map(propertyItem => ({
    ...propertyItem,
    selected: (propertyMap[propertyItem.key]?.toLowerCase() === 'true'),
  }));

  return (
    <React.Fragment>
      {
        sortedPropertyItems.filter(propertyItem => propertyItem.selected).map(propertyItem => (
          <SWCLink clickCallback={clickCallback} key={propertyItem.key}>
            <Tooltip
              title={propertyItem.key}>
              <Chip
                size="small"
                label={propertyItem.label}
                className={chipStyles[`chip_${propertyItem.key}`]}
              />
            </Tooltip>
          </SWCLink>
        ))
      }
    </React.Fragment>
  );
}

BuildPropertiesCell.propTypes = {
  clickCallback: PropTypes.func,
  item: PropTypes.shape({
    properties: PropTypes.shape({}),
  }).isRequired,
  propertyList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
};
