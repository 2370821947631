import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import IconButton from "@mui/material/IconButton";
import ConfirmationDialog from "../ConfirmationDialog";

const initialDialogState = {
  open: false,
  body: '',
  handleSubmit: () => {
  },
};

function DialogBody({ children }) {
  return (<div>{children}</div>);
}

export default function FileDeleteIcon({ bucketObject, onFileClick }) {

  const [dialogState, setDialogState] = useState(initialDialogState);

  const processing = !!bucketObject.processing;

  function closeDialog() {
    setDialogState({ ...dialogState, open: false });
  }

  function handleClicked() {

    function triggerActionAndClose() {
      closeDialog();
      onFileClick();
    }

    setDialogState({
      open: true,
      body: (
        <DialogBody>
          Do you really want to delete the file <i>{bucketObject.name}</i>
          {' '}
          ?
        </DialogBody>
      ),
      handleSubmit: triggerActionAndClose,
    });
  }


  const hasPermission = !bucketObject.folder && bucketObject.permissions && bucketObject.permissions.find(
    perm => perm === 'DELETE_STORAGE');

  return hasPermission ? (
    <React.Fragment>
      <ConfirmationDialog
        open={dialogState.open}
        handleSubmit={dialogState.handleSubmit}
        handleCancel={closeDialog}
        title="File Deletion"
      >
        {dialogState.body}
      </ConfirmationDialog>
      <Tooltip title="Delete">
        <IconButton
          edge="end"
          aria-label="Delete File"
          onClick={handleClicked}
          disabled={processing}
          size="large">
          <DeleteIcon/>
        </IconButton>
      </Tooltip>
    </React.Fragment>
  ) : null;
}


FileDeleteIcon.propTypes = {
  bucketObject: PropTypes.shape({
    bucketId: PropTypes.string,
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    folder: PropTypes.bool.isRequired,
    size: PropTypes.number,
    lastModified: PropTypes.string,
    processing: PropTypes.bool,
    permissions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFileClick: PropTypes.func.isRequired
};
