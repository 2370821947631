import React, {useEffect} from 'react';
import BuildReleaseDateCell from "./table/BuildReleaseDateCell";
import BuildOSCell from "./table/BuildOSCell";
import BuildProductCell from "./table/BuildProductCell";
import BuildMainVersionCell from "./table/BuildMainVersionCell";
import {ATTRIBUTE_LABELS, ATTRIBUTES, ORDERABLE_ATTRIBUTES, PROPERTIES_META} from "./searchConstants";
import {useSimpleSearchState} from "./searchState";
import BuildSearchComponent from "./BuildSearchComponent";
import {usePageMeta} from "../../contexts/PageMetaContext";
import BuildPropertiesCell from "./table/BuildPropertiesCell";
import {SearchForm} from "./form/SearchForm";
import {Paper} from "@mui/material";
import BuildAttributesCell from "./table/BuildAttributesCell";

const headRow = [
  {
    id: ATTRIBUTES.PRODUCT,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.ASC,
  },
  {
    id: ATTRIBUTES.MAIN_VERSION,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.DESC,
  },
  {
    id: ATTRIBUTES.DATE,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.DESC,
  },
  {
    id: ATTRIBUTES.OS,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.ASC,
  },
  {
    id: ATTRIBUTES.BUILD_TYPE,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.ASC,
  },
  {
    id: ATTRIBUTES.ATTRIBUTES,
    cssClass: '',
    notSortable: true,
    defaultOrderDirection: null,
  },

].map(headCell => ({...headCell, label: ATTRIBUTE_LABELS[headCell.id],}));


export default function BuildSearch() {

  const {goTo, setTitle} = usePageMeta();
  const searchState = useSimpleSearchState();

  const createSearchForm = ({state, handleSearch, productData, loading, isShowing, toggle}) => (
    <Paper sx={{
      padding: (theme) => theme.spacing(2),
      margin: (theme) => theme.spacing(1),
      marginBottom: (theme) => theme.spacing(3),
    }}>
      <SearchForm
        productData={productData}
        disabled={loading}
        state={state}
        handleSearch={handleSearch}
        isShowing={isShowing}
        toggle={toggle}
      />
    </Paper>
  );

  const createFilterChipContainer = () => null;

  const createInstanceContainer = (item, productData) => {


    const container = [
      <BuildProductCell item={item} clickCallback={() => handleItemClicked(item)}/>,
      <React.Fragment>
        <BuildMainVersionCell item={item} clickCallback={() => handleItemClicked(item)} versionAsToolTip={true}/>
        <BuildPropertiesCell item={item} propertyList={PROPERTIES_META} clickCallback={() => handleItemClicked(item)}/>
      </React.Fragment>,
      // <BuildVersionDetailCell item={item} clickCallback={() => handleItemClicked(item)}/>,
      <BuildReleaseDateCell item={item} clickCallback={() => handleItemClicked(item)}/>,
      <BuildOSCell item={item} osMetaList={productData.oss} clickCallback={() => handleItemClicked(item)}/>,
      item.version.type,
      <BuildAttributesCell item={item} attributesMeta={item?.productMeta?.productAttributes || []}
                           clickCallback={() => handleItemClicked(item)}/>,
    ];
    return {...container, item: item};
  };

  function handleItemClicked(item) {
    const categoryKey = item?.productMeta?.categoryKey || "unknown";
    const groupKey = item?.productMeta?.groupKey || "unknown";
    const productKey = item?.productMeta?.productKey || "unknown";
    const version = encodeURIComponent(item?.version?.version || 'unknown');
    const os = encodeURIComponent(item?.version?.os || 'unknown');
    const mainVersion = encodeURIComponent(item?.version?.mainVersion || 'unknown');
    const type = encodeURIComponent(item?.version?.type || 'unknown')
    const target = `/download/${categoryKey}/builds/${groupKey}/${productKey}/details/mainversion/${mainVersion}/os/${os}/version/${version}/type/${type}`;
    goTo(target);
  }

  function handleRowClicked({item}) {
    handleItemClicked(item);
  }

  useEffect(() => {
    setTitle("Build Search");
  }, [setTitle]);

  return (<BuildSearchComponent
    searchState={searchState}
    createSearchForm={createSearchForm}
    createFilterChipContainer={createFilterChipContainer}
    createInstanceContainer={createInstanceContainer}
    onRowClicked={handleRowClicked}
    headRow={headRow}/>);
}
