import React from 'react';
import OverviewList from '../../../../components/OverviewList';

export default function ProductGroupSelection({ groups, clickCallBack }) {

  return (
    <OverviewList
      items={groups || []}
      title={null}
      clickCallBack={clickCallBack}
      cardButtonCaption={'Select'}
      buttonCaption="Select"
      pathPrefix="/download/"
      keyPropName="groupKey"
      labelPropName="groupLabel"
      descrPropName="groupDescription"
      iconPropName="groupIcon"
    />
  );
}
