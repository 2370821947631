import React from 'react';
import Tooltip from '@mui/material/Tooltip';


const abbreviateString = (str, maxLength) => {
  if (str == null || str.length <= maxLength) {
    return str;
  }

  return `${str.substring(0, maxLength - 3)}...`;
};

export default function MirrorCell({ mirror }) {

  const label = abbreviateString(mirror, 12);
  const tooltip = mirror || '';

  return label ? (
    <Tooltip title={tooltip}>
      <span>{label}</span>
    </Tooltip>
  ) : null;
}
