import React from 'react';
import {FilterChip} from "./FilterChip";


export default function FilterChipGroupProperty({
                                                 propertyList,
                                                 selectedProperties,
                                                 setSelectedProperties,
                                                 disabled
                                               }) {

  const sortedPropertyItems = propertyList.map(propertyItem => ({
    ...propertyItem,
    selected: (selectedProperties || []).includes(propertyItem.key)
  }));

  const handleDelete = (propertyToDelete) => () => {
    setSelectedProperties(selectedProperties.filter(property => property !== propertyToDelete));
  };

  return (
    <React.Fragment>
      {sortedPropertyItems.filter(item=>item.selected).map(selectedItem =>
        <FilterChip
          key={selectedItem.key}
          tooltip={selectedItem.key}
          disabled={disabled}
          label={selectedItem.label}
          onDelete={handleDelete(selectedItem.key)}/>
      )}
    </React.Fragment>
  );
}
