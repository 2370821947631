import React, {useCallback} from 'react';
import BuildReleaseDateCell from "./table/BuildReleaseDateCell";
import BuildOSCell from "./table/BuildOSCell";
import BuildVersionDetailCell from "./table/BuildVersionDetailCell";
import BuildMainVersionCell from "./table/BuildMainVersionCell";
import {ATTRIBUTE_LABELS, ATTRIBUTES, ORDERABLE_ATTRIBUTES, PROPERTIES_META} from "./searchConstants";
import {useSearchState} from "./searchState";
import BuildSearchComponent from "./BuildSearchComponent";
import {usePageMeta} from "../../contexts/PageMetaContext";
import PropTypes from "prop-types";
import BuildPropertiesCell from "./table/BuildPropertiesCell";
import {SearchDialog} from "./form/SearchDialog";
import {createFilterChipContainer} from "./chip/FilterChipContainer";
import {useAuth} from "../../react-auth-wrapper";
import BuildAttributesCell from "./table/BuildAttributesCell";


const headRow = [
  {
    id: ATTRIBUTES.MAIN_VERSION,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.DESC,
  },
  {
    id: ATTRIBUTES.VERSION,
    cssClass: '',
    notSortable: true,
    defaultOrderDirection: null,
  },
  {
    id: ATTRIBUTES.DATE,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.DESC,
  },
  {
    id: ATTRIBUTES.OS,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.ASC,
  },
  {
    id: ATTRIBUTES.BUILD_TYPE,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.ASC,
  },
  {
    id: ATTRIBUTES.ATTRIBUTES,
    cssClass: '',
    notSortable: true,
    defaultOrderDirection: null,
  },


].map(headCell => ({...headCell, label: ATTRIBUTE_LABELS[headCell.id],}));

export default function ProductBuildSearch({categoryKey, groupKey, productKey}) {

  const {callApi} = useAuth();
  const {goTo} = usePageMeta();
  const keysAvailable = categoryKey && groupKey && productKey;

  const searchState = useSearchState({
    productKey,
    baseUrl: `/download/${categoryKey}/builds/${groupKey}/${productKey}`
  });

  const lookupProductOSs  = useCallback(
    (cancel) => callApi(`/swc/api/products/${encodeURIComponent(productKey)}/os`, {method: 'GET'}, cancel),
    [productKey]);

  const createSearchForm = ({state, handleSearch, productData, loading, isShowing, toggle }) => (
    <SearchDialog
      productData={productData}
      disabled={loading}
      state={state}
      handleSearch={handleSearch}
      isShowing={isShowing}
      toggle={toggle}
      directives={[]}
    />
  );

  const createInstanceContainer = (item, productData) => {
    const container = [
      <React.Fragment>
        <BuildMainVersionCell item={item} clickCallback={() => handleItemClicked(item)}/>
        <BuildPropertiesCell item={item} propertyList={PROPERTIES_META} clickCallback={() => handleItemClicked(item)}/>
      </React.Fragment>,
      <BuildVersionDetailCell item={item} clickCallback={() => handleItemClicked(item)}/>,
      <BuildReleaseDateCell item={item} clickCallback={() => handleItemClicked(item)}/>,
      <BuildOSCell item={item} osMetaList={productData.oss} clickCallback={() => handleItemClicked(item)}/>,
      item.version.type,
      <BuildAttributesCell item={item} attributesMeta={item?.productMeta?.productAttributes || []} clickCallback={() => handleItemClicked(item)}/>,
    ];
    return {...container, item: item};
  };

  function handleItemClicked(item) {
    const categoryKey = item?.productMeta?.categoryKey || "unknown";
    const groupKey = item?.productMeta?.groupKey || "unknown";
    const productKey = item?.productMeta?.productKey || "unknown";
    const version = encodeURIComponent(item?.version?.version || 'unknown');
    const os = encodeURIComponent(item?.version?.os || 'unknown');
    const mainVersion = encodeURIComponent(item?.version?.mainVersion || 'unknown');
    const type = encodeURIComponent(item?.version?.type || 'unknown')
    const target = `/download/${categoryKey}/builds/${groupKey}/${productKey}/details/mainversion/${mainVersion}/os/${os}/version/${version}/type/${type}`;

    goTo(target);
  }

  function handleRowClicked({item}) {
    handleItemClicked(item);
  }

  return keysAvailable ? (
    <BuildSearchComponent
      searchState={searchState}
      createInstanceContainer={createInstanceContainer}
      createSearchForm={createSearchForm}
      createFilterChipContainer={param=>createFilterChipContainer({...param})}
      lookupProductOSs={lookupProductOSs}
      onRowClicked={handleRowClicked}
      headRow={headRow}/>
  ) : null;
}

ProductBuildSearch.prototype = {
  categoryKey : PropTypes.string,
  groupKey: PropTypes.string,
  productKey: PropTypes.string,
}
