import React, {useEffect, useState} from 'react';
import {usePageMeta} from "../../../../contexts/PageMetaContext";
import LoadingIcon from "../../../../components/LoadingIcon";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Paper from "@mui/material/Paper";
import {useNotifications} from "../../../../contexts/NotificationsContext";
import ChooseUploadProduct from "./ChooseUploadProduct";
import ChooseUploadFiles from "./ChooseUploadFiles";
import NotEditableTaskDetails from "./NotEditableTaskDetails";
import {useAuth} from "../../../../react-auth-wrapper";
import WizardButtonContainer from "../../../../components/WizardButtonContainer";
import UploadTaskRegistration from "./UploadTaskRegistration";
import useBuildUploadMeta from "../../../../hooks/useBuildUploadMeta";
import {initBuildProduct} from "../../../../util";
import {useParams} from "react-router-dom";
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  position: relative;
`;
const StyledTypography = styled(Typography)`
  margin-top: ${({theme}) => theme.spacing(1)};
  margin-bottom: ${({theme}) => theme.spacing(1)};
  padding: ${({theme}) => theme.spacing(1)};
`;
const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(1)};
`;
const StyledStepper = styled(Stepper)`
  padding-left: 0;
`;
const NOTIFICATOR_NAME = 'adminProductUploadStartNotificator';

export default function StartProductUpload() {
  const {taskId} = useParams();
  const {setTitle} = usePageMeta();
  const {notificator, notify} = useNotifications();
  const {categories, buildAudiences, adminBuildAudience} = useBuildUploadMeta(signalApiError);
  const {callApi} = useAuth();
  const {goTo} = usePageMeta();
  const cancel = {};

  const [currentTaskInfo, setCurrentTaskInfo] = useState(null);
  const [uploadProduct, setUploadProduct] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [parameterConfirmed, setParameterConfirmed] = useState(true);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const handleUploadProductChanged = (data) => {
    console.log('[StartProductUpload] uploadProduct changed to', uploadProduct);
    setUploadProduct(data);
    setParameterConfirmed(true);
  }

  const handleFilesSelected = (filesMeta) => {
    console.log('handleFilesSelected', filesMeta);
    if (filesMeta?.length > 0) {
      console.log('[StartProductUpload] selected files', filesMeta);
      setSelectedFiles([...filesMeta]);
    } else {
      setSelectedFiles(null);
    }
  };

  const handlePublish = (value) => {
    console.log('[StartProductUpload] publish upload value = ', value);
    setUploadCompleted(value);
  }

  function updateTaskParameters() {
    console.log('[StartProductUpload]', 'Update upload task parameters...', uploadProduct);
    const attributes = uploadProduct?.buildAttribute ? new Map([[uploadProduct.buildAttribute.key, [uploadProduct.buildAttribute.value]]]) : null;
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        productKey: uploadProduct.product.productKey,
        os: uploadProduct.osVersion.os,
        buildType: uploadProduct.buildType.label,
        version: uploadProduct.version,
        license: uploadProduct.license,
        buildAudienceList: uploadProduct.buildAudienceList?.map(value => value.buildAudience) || [],
        attributes: Object.fromEntries(attributes || []),
        releaseDate: uploadProduct.releaseDate,
      })
    };
    setParameterConfirmed(false);

    callApi(`/swc/api/admin/builds/upload/my/tasks/${currentTaskInfo.id}/parameter`, options, cancel)
      .then((response) => {
        console.log('[StartProductUpload]', `Upload task ${currentTaskInfo.id} parameters changed`, response);
        setParameterConfirmed(true);
        setCurrentTaskInfo(oldTaskInfo => {
          oldTaskInfo.state = response.state;
          oldTaskInfo.parameter = response.parameter;
          oldTaskInfo.files = response.files;
          oldTaskInfo.lastModifiedDate = response.lastModifiedDate;
          oldTaskInfo.releaseDate = response.releaseDate;
          return oldTaskInfo;
        });
        setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex + 1);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          signalApiError(`Error while updating upload task ${currentTaskInfo.id} parameters`, error);
          setParameterConfirmed(false);
        }
      })
      .finally(() => {
      });
  }

  function initProduct() {
    if (!currentTaskInfo.parameter) {
      return;
    }
    const initProduct = initBuildProduct(categories, buildAudiences, currentTaskInfo);
    console.log('[StartProductUpload] initProduct', initProduct);
    setUploadProduct(initProduct);
  }

  function removeUploadedFile(filename) {
    console.log('[StartProductUpload]', `Delete uploaded file ${filename}`);
    callApi(`/swc/api/admin/builds/upload/my/tasks/${currentTaskInfo.id}/files?fileName=${filename}`, {method: 'DELETE'}, cancel)
      .then((response) => {
        console.log('[StartProductUpload]', `Deleted file ${filename} from task`, response);
        setCurrentTaskInfo(oldTaskInfo => ({
          ...oldTaskInfo,
          files: oldTaskInfo?.files.filter(file => file.name !== filename)
        }));
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          signalApiError(`Error while deleting uploaded file ${filename}`, error);
        }
      })
      .finally(() => {
      });
  }

  function initNewTask() {
    console.log('[StartProductUpload]', 'Init new upload task...');
    callApi('/swc/api/admin/builds/upload/my/tasks', {method: 'POST'}, cancel)
      .then((response) => {
        console.log('[StartProductUpload]', `New upload task ${response.id} initialized`, response);
        setCurrentTaskInfo(response);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setCurrentTaskInfo(null);
          signalApiError('Error while initializing new upload task', error);
        }
      })
      .finally(() => {
      });
  }

  function loadTaskInfo(onlyFiles = false) {
    const resultTaskId = taskId ? taskId : currentTaskInfo?.id;
    console.log('[StartProductUpload]', `Loading ${resultTaskId} info...`);
    setDataLoading(true);
    callApi(`/swc/api/admin/builds/upload/my/tasks/${resultTaskId}?expands=FILES`, {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[StartProductUpload]', `Task ${resultTaskId} info`, response, 'onlyFiles', onlyFiles);
        if (onlyFiles) {
          setCurrentTaskInfo(oldTask => {
            oldTask.files = response.files;
            return oldTask;
          });
        } else {
          setCurrentTaskInfo(response);
        }
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setCurrentTaskInfo(null);
          signalApiError(`Error while loading task ${resultTaskId} info.`, error);
        }
      })
      .finally(() => {
        setDataLoading(false);
      });
  }

  const confirmUpload = () => {
    console.log('[StartProductUpload]', 'Call confirm upload...');
    callApi(`/swc/api/admin/builds/upload/my/tasks/${currentTaskInfo.id}/state/CONFIRMED`, {method: 'PUT'}, cancel)
      .then((response) => {
        console.log('[StartProductUpload]', `Product upload for task ${currentTaskInfo.id} confirmed`, response);
        goTo("/admin/product-upload");
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          signalApiError(`Upload confirmation for task ${currentTaskInfo.id} failed`, error);
        }
      })
      .finally(() => {
      });
  }

  const noSelectedFiles = () => {
    return (selectedFiles || []).length === 0 && (currentTaskInfo?.files || []).length === 0;
  }

  class StepData {
    constructor(name, content, onEnterAction = () => {
    }, nextDisabledCondition = () => false, backDisabledCondition = () => false, isOptional = false, isHidden = () => false) {
      this.name = name;
      this.content = content;
      this.onEnterAction = onEnterAction;
      this.nextDisabledCondition = () => dataLoading || nextDisabledCondition();
      this.backDisabledCondition = () => dataLoading || backDisabledCondition();
      this.isOptional = isOptional;
      this.isHidden = isHidden;

      this.index = -1;
      this.isFinalStep = false;
    }
  }

  const stepDataList = [
    new StepData(
      'Choose product',
      () => (categories && buildAudiences ?
        <ChooseUploadProduct initialUploadProduct={uploadProduct}
                             initialBuildAudienceList={adminBuildAudience ? [adminBuildAudience] : []}
                             categories={categories}
                             buildAudiences={buildAudiences}
                             clickCallBack={handleUploadProductChanged}/> : null),
      () => {
        setSelectedFiles(null);
      },
      () => !parameterConfirmed || uploadProduct == null,
      () => true,
      false,
      () => false,
    ),
    new StepData(
      'Choose files/folders',
      () => (uploadProduct ?
        <ChooseUploadFiles productMeta={uploadProduct} taskInfo={currentTaskInfo}
                           removeFileCallback={removeUploadedFile}
                           clickCallBack={handleFilesSelected}/> : null),
      () => {
        setSelectedFiles(null);
      },
      () => noSelectedFiles(),
      () => false,
      false,
      () => false,
    ),
    new StepData(
      'Publish upload task',
      () => (!noSelectedFiles() ?
        <UploadTaskRegistration productMeta={uploadProduct} taskInfo={currentTaskInfo} selectedFiles={selectedFiles}
                                clickCallBack={handlePublish}/> : null),
      () => {
        setUploadCompleted(false);
      },
      () => uploadCompleted === false,
      () => false,
      false,
      () => false,
    ),
  ].filter(stepData => !stepData.isHidden()).map((stepData, index, allStepData) => ({
    ...stepData,
    index,
    isFinalStep: allStepData.length - 1 === index
  }));

  const getStepData = stepIndex => stepDataList[stepIndex] || new StepData('unknown', () => 'Invalid step');
  const activeStepData = getStepData(activeStepIndex);

  const handleNext = () => {
    if (activeStepIndex === 0) {
      updateTaskParameters();
    } else {
      setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex + 1);
    }
  };

  const handleBack = () => {
    setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex - 1);
  };

  const handleCancel = (taskId) => {
    console.log('[StartProductUpload]', `Cancel task ${taskId}...`);
    setDataLoading(true);
    callApi(`/swc/api/admin/builds/upload/my/tasks/${taskId}/state/CANCELLED`, {method: 'PUT'}, cancel)
      .then((response) => {
        console.log('[StartProductUpload]', `Task ${taskId} cancelled.`, response);
        goTo('/admin/product-upload');
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          notify(NOTIFICATOR_NAME,
            {
              text: `Error while cancel task ${taskId}:`,
              error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      })
      .finally(() => {
        setDataLoading(false);
      });
  }

  const getCurrentStep = () => {
    if (['UPLOADING'].includes(currentTaskInfo?.state)) {
      return 1;
    }
    return 0;
  }

  const isEditable = () => {
    return currentTaskInfo && ['INITIALIZED', 'PARAMETRIZED', 'UPLOADING'].includes(currentTaskInfo?.state);
  }

  function signalApiError(message, error) {
    notify(NOTIFICATOR_NAME,
      {
        text: message,
        error,
        type: 'error',
        handleClose: () => {
        },
      });
  }

  useEffect(() => {
    if (activeStepIndex === stepDataList.length && uploadCompleted) {
      confirmUpload();
    }
    if (uploadCompleted && activeStepIndex === 1) {
      loadTaskInfo(true);
    }
  }, [activeStepIndex, uploadCompleted]);

  useEffect(() => {
    if (currentTaskInfo && categories) {
      initProduct();
      const currentStep = getCurrentStep();
      console.log('[StartProductUpload] currentTaskInfo changed', currentTaskInfo, 'set ActiveStepIndex=', currentStep, 'categories', categories);
      setActiveStepIndex(currentStep);
    }
  }, [currentTaskInfo, categories]);

  useEffect(() => {
    setTitle('Administration - Product Upload - Start');
    if (!taskId) {
      initNewTask();
    } else {
      loadTaskInfo();
    }
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  const buttons = (
    <WizardButtonContainer
      activeStepData={activeStepData}
      wizardFinished={activeStepIndex === stepDataList.length}
      onNext={handleNext}
      onBack={handleBack}
      onCancel={() => handleCancel(currentTaskInfo?.id)}
    />
  );

  let content = null;
  if (isEditable() && categories) {
    content = <div>
      {notificator(NOTIFICATOR_NAME)}
      <StyledStepper activeStep={activeStepIndex}>
        {stepDataList.map((stepDataItem, index) => {
          const stepProps = {};
          const labelProps = {};
          if (stepDataItem.isOptional) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          return (
            <Step key={stepDataItem.name} {...stepProps}>
              <StepLabel {...labelProps}>{stepDataItem.name}</StepLabel>
            </Step>
          );
        })}
      </StyledStepper>
      <Paper>
        <StyledContainer>
          {activeStepIndex === stepDataList.length ? (
            <StyledTypography>
              All steps completed - you&apos;re finished
            </StyledTypography>
          ) : (
            <StyledTypography>
              {activeStepData.content()}
            </StyledTypography>
          )}
        </StyledContainer>
      </Paper>
      {buttons}
    </div>;
  } else if (currentTaskInfo != null) {
    content =
      <NotEditableTaskDetails taskInfo={currentTaskInfo} productMeta={uploadProduct}/>;
  }

  return <StyledPaper square>
    {notificator(NOTIFICATOR_NAME)}
    {(content === null || dataLoading) && <LoadingIcon/>}
    {content}
  </StyledPaper>;
}
