import React from 'react';
import TextField from '@mui/material/TextField';
import styled from "@emotion/styled";
import {ComplianceLogInputFieldStyle} from "../ComplianceLogSearchForm";

const StyledTextField = styled(TextField)`
  ${props => ComplianceLogInputFieldStyle(props.theme)}
`;
export default function LogDatePicker({
                                          style,
                                          disabled,
                                          yearAndMonth,
                                          onChange = (newYearAndMonth) => {
                                          },
                                      }) {

    const handleChangeEvent = (e) => {
        e.preventDefault();
        onChange(e.target.value);
    };

    return (
        <StyledTextField
            id="log-date-picker"
            style={style}
            disabled={disabled}
            label="Log Date"
            type="month"
            defaultValue={new Date().toISOString().substring(0, 7)}
            onChange={handleChangeEvent}
            value={yearAndMonth || new Date().toISOString().substring(0, 7)}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}
