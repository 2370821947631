import React, {useEffect} from 'react';
import {usePreferenceSearchState, usePreferenceSearchStateAdmin} from "./searchState";
import BuildSearchComponent from "./BuildSearchComponent";
import {usePageMeta} from "../../contexts/PageMetaContext";
import {SearchDialog} from "./form/SearchDialog";
import {createSettingsChipContainer} from "./chip/FilterChipContainer";
import BuildTableActivity from "./BuildTableActivity";
import {ATTRIBUTE_LABELS, ATTRIBUTES, FORM_DIRECTIVES, ORDERABLE_ATTRIBUTES} from "./searchConstants";


const headRow = [
  {
    id: ATTRIBUTES.PRODUCT,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.ASC,
  },
  {
    id: ATTRIBUTES.MAIN_VERSION,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.DESC,
  },
  // {
  //   id: ATTRIBUTES.VERSION,
  //   cssClass: '',
  //   notSortable: true,
  //   defaultOrderDirection: null,
  // },
  {
    id: ATTRIBUTES.DATE,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.DESC,
  },
  {
    id: ATTRIBUTES.OS,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.ASC,
  },
  {
    id: ATTRIBUTES.BUILD_TYPE,
    cssClass: '',
    defaultOrderDirection: ORDERABLE_ATTRIBUTES.DIRECTION.ASC,
  },
  {
    id: ATTRIBUTES.ATTRIBUTES,
    cssClass: '',
    defaultOrderDirection: null,
  },

].map(headCell => ({...headCell, label: ATTRIBUTE_LABELS[headCell.id],}));


const createBuildTable = ({tableRows, tableData, loading, setPagination, onRowClicked}) => (
  <BuildTableActivity
    rows={tableRows}
    pagination={tableData?.pagination || {page: 0, size: 25, numberOfPages: 0, overallCount: 0}}
    setPagination={setPagination}
    disabled={loading}
    onRowClicked={onRowClicked}
  />
);

export default function BuildActivitySearch() {

  const {setTitle} = usePageMeta();
  const searchState = usePreferenceSearchState();
  const [state, updateState, resetState, defaultState] = searchState;
  const [handleRowClicked, createInstanceContainer] = useUtils();

  const createSearchForm = ({state, handleSearch, productData, loading, isShowing, toggle}) => (
    <SearchDialog
      productData={productData}
      disabled={loading}
      state={state}
      handleSearch={handleSearch}
      isShowing={isShowing}
      toggle={toggle}
      searchButtonLabel={'OK'}
      searchDialogTitle={'Activity Stream Settings'}
      directives={[FORM_DIRECTIVES.PRODUCT_DIRECTIVE, FORM_DIRECTIVES.SUBSCRIPTION_DIRECTIVE]}
    />
  );


  useEffect(() => {
    setTitle("Activity Stream");
  }, [setTitle]);

  if (state) {
    return (
      <BuildSearchComponent
        searchState={searchState}
        createSearchForm={createSearchForm}
        createFilterChipContainer={param => createSettingsChipContainer({...param})}
        createBuildTable={createBuildTable}
        createInstanceContainer={createInstanceContainer}
        onRowClicked={handleRowClicked}
        headRow={headRow}/>
    )
  } else {
    return (<span/>)
  }
}

export function BuildActivitySearchAdmin() {

  const {setTitle} = usePageMeta();
  const searchState = usePreferenceSearchStateAdmin();

  const [state] = searchState;
  const [handleRowClicked, createInstanceContainer] = useUtils();

  const createSearchForm = ({state, handleSearch, productData, loading, isShowing, toggle}) => (
    <SearchDialog
      productData={productData}
      disabled={loading}
      state={state}
      handleSearch={handleSearch}
      isShowing={isShowing}
      toggle={toggle}
      searchButtonLabel={'OK'}
      searchDialogTitle={'Default Activity Stream Settings'}
      directives={[FORM_DIRECTIVES.PRODUCT_DIRECTIVE]}
    />
  );

  useEffect(() => {
    setTitle("Administration - Activity Stream");
  }, [setTitle]);

  if (state) {
    return (
      <BuildSearchComponent
        searchState={searchState}
        createSearchForm={createSearchForm}
        createFilterChipContainer={param => createSettingsChipContainer({...param})}
        createBuildTable={createBuildTable}
        createInstanceContainer={createInstanceContainer}
        onRowClicked={handleRowClicked}
        headRow={headRow}/>
    )
  } else {
    return (<span></span>)
  }
}


function useUtils() {

  const {goTo} = usePageMeta();

  const createInstanceContainer = (item, productData) => {
    return {
      container: [
        productData,
        item?.version,
        item?.version?.releaseDate,
        item?.version?.os,
        item?.version?.type
      ], item: item
    };
  };

  function handleItemClicked(item) {
    const categoryKey = item?.productMeta?.categoryKey || "unknown";
    const groupKey = item?.productMeta?.groupKey || "unknown";
    const productKey = item?.productMeta?.productKey || "unknown";
    const version = encodeURIComponent(item?.version?.version || 'unknown');
    const os = encodeURIComponent(item?.version?.os || 'unknown');
    const mainVersion = encodeURIComponent(item?.version?.mainVersion || 'unknown');
    const type = encodeURIComponent(item?.version?.type || 'unknown')
    const target = `/download/${categoryKey}/builds/${groupKey}/${productKey}/details/mainversion/${mainVersion}/os/${os}/version/${version}/type/${type}`;
    goTo(target);
  }

  function handleRowClicked({item}) {
    handleItemClicked(item);
  }

  return [handleRowClicked, createInstanceContainer];
}
