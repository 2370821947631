import React, {useEffect, useState} from 'react';
import * as PropTypes from "prop-types";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {SWC_PROP_TYPES} from "../../../../SwcPropTypes";
import FileMetas from "./FileMetas";
import Button from "@mui/material/Button";
import TaskDetails from "./TaskDetails";
import {useTheme} from "@mui/material/styles";
import styled from "@emotion/styled";

const StyledInputContainer = styled.div`
  padding: ${({theme}) => theme.spacing(2)};
`;
const StyledInput = styled.input`
  display: none;
`;
const StyledFileInfoContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  padding-left: ${({theme}) => theme.spacing(3)};
`;
const StyledList = styled(List)`
  width: 100%;
`;
const StyledListItemText = styled(ListItemText)`
  padding: ${({theme}) => theme.spacing(2)};
`;

function FileSelect({title, formats, extensions, releaseNotesPatterns, uploadedFiles, fileChangedCallback}) {
  const id = `${title}-button-file`;
  const [fileMeta, setFileMeta] = useState(null);

  const getUniqueAndNotEmptyFiles = (selectedFiles) => {
    const uploadedFileNames = uploadedFiles.map(file => file.name);
    const selectedFilesMap = new Map();
    for (let file of (selectedFiles || [])) {
      if (!uploadedFileNames.includes(file.name) && file.size > 0) {
        selectedFilesMap.set(file.name, file);
      }
    }
    return selectedFilesMap.values();
  }

  const handleFileChanged = (event) => {
    console.log('[FileSelect] fileChanged', event.target.files);
    const notSelected = (oldMeta, file) => {
      return oldMeta === null || oldMeta.filter(oldFile => oldFile.name === file.name).length === 0;
    }
    if (event.target.files && event.target.files.length > 0) {
      const uniqueFiles = getUniqueAndNotEmptyFiles(event.target.files);
      setFileMeta(oldMeta => {
        const files = Array.from(uniqueFiles).filter(file => notSelected(oldMeta, file));
        if (oldMeta === null) {
          return [...files];
        }
        return oldMeta.concat(files);
      });
    }
    //reset field
    event.target.value = null;
  };

  const removeFile = (file) => {
    setFileMeta(oldMeta => {
      const newMeta = oldMeta.filter(oldFile => oldFile.name !== file.name);
      return [...newMeta];
    });
  }

  const printSelectedFiles = () => {
    if (!fileMeta || fileMeta?.length === 0) {
      return <div><i>No {title} chosen</i></div>;
    }
    return <StyledFileInfoContainer><b>{`Chosen ${title}:`}</b>
      <FileMetas fileMetas={fileMeta} handleRemoveFile={removeFile}/>
    </StyledFileInfoContainer>
  }

  useEffect(() => {
    fileChangedCallback(fileMeta);
  }, [fileMeta]);

  return (<StyledInputContainer>
    <StyledInput
      id={id}
      required
      key={`file_${title}`}
      onChange={handleFileChanged}
      multiple
      type="file"
      accept={formats}
    />
    <label htmlFor={id}>
      <StyledList>
        <ListItem>
          <ListItemAvatar>
            <Button variant="contained" color="primary" component="span">
              Select {title}
            </Button>
          </ListItemAvatar>
          <StyledListItemText primary={<i>Supported file extensions: {extensions}</i>}
                              secondary={<i>Release notes patterns: {releaseNotesPatterns}</i>}/>
        </ListItem>
      </StyledList>
    </label>
    {printSelectedFiles()}
  </StyledInputContainer>);
}

export default function ChooseUploadFiles({productMeta, taskInfo, clickCallBack, removeFileCallback}) {
  const theme = useTheme();
  const [filesMeta, setFilesMeta] = useState(null);

  const fileChangedCallback = (newFilesMeta) => {
    console.log('[ChooseUploadFiles] newFilesMeta', newFilesMeta);
    setFilesMeta(newFilesMeta);
  }

  const getFileFormats = () => {
    const mediaTypes = productMeta.product.fileTypeList.map(fileType => fileType.mediaType);
    const extensions = productMeta.product.fileTypeList.map(fileType => fileType.extension);
    return [...mediaTypes, extensions].join(',');
  }

  const getFileExtensions = () => {
    return productMeta.product.fileTypeList.map(fileType => fileType.extension).join(', ');
  }

  const getReleaseNotesPatterns = () => {
    return productMeta.product.releaseNoteFileTypeList.map(fileType => fileType.pattern).join(', ');
  }

  useEffect(() => {
    clickCallBack(filesMeta);
  }, [filesMeta]);

  return (<div>
    <TaskDetails
      key={`task_${taskInfo.id}`} task={taskInfo} productMeta={productMeta} moreDetails={true} index={0}/>
    {taskInfo?.files?.length > 0 && <StyledFileInfoContainer><b>{`Uploaded files:`}</b>
      <FileMetas fileMetas={taskInfo?.files} handleRemoveFile={(file) => removeFileCallback(file.name)}/>
    </StyledFileInfoContainer>}
    <FileSelect key="uploadFilesSelect" title="Select File(s)"
                uploadedFiles={taskInfo?.files || []}
                formats={getFileFormats()}
                extensions={getFileExtensions()}
                releaseNotesPatterns={getReleaseNotesPatterns()}
                fileChangedCallback={fileChangedCallback}/>
  </div>);
}

ChooseUploadFiles.propTypes = {
  productMeta: SWC_PROP_TYPES.uploadProduct,
  clickCallBack: PropTypes.func.isRequired,
};
