import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingIcon from '../LoadingIcon';
import useDownloader from './downloader';
import useConfirmation from "./confirmation";
import ConfirmationDialog from '../ConfirmationDialog';
import {SWC_PROP_TYPES} from "../../SwcPropTypes";
import {IconTextButton} from "../IconTextButton";
import styled from "@emotion/styled";

const StyledContainer = styled.span`
  position: relative;
  display: inline-block;
`;
const StyledLoadingIcon = styled(LoadingIcon)`
  margin: 2px;
`;
export default function DownloadButton({
                                         productMeta, versionObj
                                       }) {
  const releaseType = versionObj?.type;
  const productKey = productMeta?.productKey;

  const downloadType = productMeta?.downloadType;
  const files = versionObj?.files || [];
  const licenseName = versionObj?.licenseName;

  let archiveDownloadable;
  if (downloadType === 'DEFAULT') {
    archiveDownloadable = true;
  } else {
    archiveDownloadable = files.find(file => (file.flags || []).includes("BUILD_ARCHIVE"));
  }

  const {handleClick, loading} = useDownloader({
    productKey, versionObj, downloadType,
  });

  const {
    processing,
    dialogBody,
    dialogTitle,
    dialogOpen,
    handleConfirm,
    handleClose,
    handleShowConfirmationIfRequiredOrSubmit
  } = useConfirmation({releaseType, productKey, licenseName, handleClick});

  const disabled = loading || processing;

  return archiveDownloadable ? (<>
    <ConfirmationDialog
      open={dialogOpen}
      handleSubmit={handleConfirm}
      handleCancel={handleClose}
      title={dialogTitle}
      width={'wide'}
    >
      {dialogBody}
    </ConfirmationDialog>
    <span>
        <IconTextButton
          tooltip={'Download archive'}
          label={'Download archive'}
          onClick={handleShowConfirmationIfRequiredOrSubmit}
          disabled={disabled}
          startIcon={<SaveIcon/>}
        />
      {disabled ? (<StyledContainer>
        <StyledLoadingIcon size={16}/>
      </StyledContainer>) : null}
      </span>
  </>) : null;
}

DownloadButton.propTypes = {
  productMeta: SWC_PROP_TYPES.productMeta, versionObj: SWC_PROP_TYPES.version,
};
