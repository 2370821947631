import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import HowToVoteIcon from "@mui/icons-material/HowToVote";

export default function FileMoveIcon({bucketObject, handleClick}) {

    const hasPermission = !bucketObject.folder && bucketObject.permissions && bucketObject.permissions.find(perm => perm === 'WRITE_STORAGE');

    return hasPermission ? (
        <React.Fragment>
            <Tooltip title="Move">
                <IconButton edge="end" onClick={handleClick} size="large">
                    <HowToVoteIcon />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    ) : null;
}

FileMoveIcon.propTypes = {
    bucketObject: PropTypes.shape({
        bucketId: PropTypes.string,
        path: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        folder: PropTypes.bool.isRequired,
        size: PropTypes.number,
        lastModified: PropTypes.string,
        permissions: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};
