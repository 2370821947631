import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import LoadingIcon from "../../LoadingIcon";

export const StyledFileDialogButtonContainer = styled.div`
  display: flex;
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;
export const StyledFileDialogButton = styled(Button)`
  display: flex;
  margin-right: ${({theme}) => theme.spacing(1)};
  width: 80px;
  margin-left: 4px;
`;
export const StyledFileDialogTextField = styled(TextField)`
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
  width: 400px;
`;
export const StyledFileDialogInput = styled.input`
  display: none;
`;
export const StyledFileDialogLoadingIcon = styled(LoadingIcon)`
  position: absolute;
  left: 25%;
  top: 20%;
  width: 100%;
  height: 100%;
  text-align: center;
`;
