import React from 'react';

export default function FormattedDateTime({dateTimeString}) {
  return formatDateTime(dateTimeString);
}

export function FormattedDate({dateTimeString}) {
  return formatDate(dateTimeString);
}

export function formatDate(dateTimeString) {
  if (dateTimeString) {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric', month: '2-digit', day: '2-digit'
    }).format(new Date(dateTimeString));
  } else {
    return null;
  }
}

export function formatDateTime(dateTimeString) {
  if (dateTimeString) {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
    }).format(new Date(dateTimeString));
  } else {
    return null;
  }
}
