import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import LoadingIcon from '../LoadingIcon';
import {FolderOpenOutlined, InsertDriveFileOutlined} from '@mui/icons-material';
import styled from "@emotion/styled";

const StyledIconContainer = styled.div`
  position: relative;
  left: 0;
  top: 6px;
  display: inline-block;
`;
const StyledLinkContainer = styled.div`
  display: inline-block;
`;
const StyledLabelContainer = styled.span`
  white-space: nowrap;
  margin-left: 2em;
  cursor: pointer;
`;
const StyledLoadingIcon = styled(LoadingIcon)`
  margin: 2px;
`;
const StyledLoadingIconContainer = styled.div`
  margin-left: 10px;
  display: inline-block;
`;
export default function FileNameDownload({
                                           fileObject, onFolderClick = () => {
  }, onFileClick
                                         }) {
  const label = fileObject.name;
  const tooltip = fileObject.name;
  const processing = !!fileObject.processing;
  const {folder} = fileObject;

  function clickHandler() {
    console.log('[FileNameDownload] processing=', processing, 'folder=', folder);
    if (!processing) {
      if (folder) onFolderClick(); else onFileClick();
    }
  }

  const icon = folder ? (<FolderOpenOutlined onClick={clickHandler}/>) : (
    <InsertDriveFileOutlined onClick={clickHandler}/>);

  return (
    <React.Fragment>
      <StyledIconContainer>{icon}</StyledIconContainer>
      <StyledLinkContainer>
        <Link
          disabled={processing}
          color="inherit"
          onClick={clickHandler}
        >
          <Tooltip title={tooltip} data-bucket-object-name={tooltip}>
            <StyledLabelContainer>{label}</StyledLabelContainer>
          </Tooltip>
        </Link>
      </StyledLinkContainer>
      {processing ? (
        <StyledLoadingIconContainer>
          <StyledLoadingIcon size={16}/>
        </StyledLoadingIconContainer>) : null}
    </React.Fragment>
  );
}

FileNameDownload.propTypes = {
  fileObject: PropTypes.shape({
    folder: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    processing: PropTypes.bool,
  }).isRequired,
  onFolderClick: PropTypes.func,
  onFileClick: PropTypes.func.isRequired,
};

FileNameDownload.defaultProps = {
  onFolderClick: () => {
  },
};
