import React, { useEffect } from 'react';
import { usePageMeta } from '../../contexts/PageMetaContext';
import SWCDetails from '../../pages/admin/SWCDetails';
import AdminCloudSettingsTabs from './AdminCloudSettingsTabs';
import { ADMIN_SETTINGS } from '../../components/PathConstants';

export default function SWCDetailsTab() {
  const { setTitle } = usePageMeta();
  const cancel = {};

  useEffect(() => {
    setTitle('Administration - SWC Details');
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  return <AdminCloudSettingsTabs activeTabPath={ADMIN_SETTINGS.swcDetails.path}><SWCDetails/></AdminCloudSettingsTabs>
}
