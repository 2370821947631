import React, {useEffect, useState} from 'react';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import VmInstanceName from '../components/vmInstances/VmInstanceName';
import VmImage from '../components/vmInstances/VmImage';
import VmRegion from '../components/vmInstances/VmRegion';
import VmMachineType from '../components/vmInstances/VmMachineType';
import VmLaunchTime from '../components/vmInstances/VmLaunchTime';
import VmInstanceStateWithActionMenu from '../components/vmInstances/VmInstanceStateWithActionMenu';
import VmInstanceSshTunnelFile from '../components/vmInstances/VmInstanceSshTunnelFile';
import useModal from '../hooks/useModal';
import VmCreateOrRenameDialog from '../components/vmInstances/VmCreateOrRenameDialog';
import ModalDialog from '../components/ModalDialog';
import useInterval from '../hooks/useInterval';
import LoadingIcon from '../components/LoadingIcon';
import ScrollableList from '../components/ScrollableList';
import ListWrapper from '../components/ListWrapper';
import {usePageMeta} from '../contexts/PageMetaContext';
import {useParams} from "react-router-dom";

export default function VmDetail({ isAdminView = false }) {

  let { cloudKey, regionKey, instanceId } = useParams();
  const [reloadInterval] = useState(45 * 1000);
  const [meta, setMeta] = useState(null);
  const [instance, setInstance] = useState(null);
  const [details, setDetails] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { isShowing, toggle } = useModal();
  const { callApi } = useAuth();
  const { notify, notificator } = useNotifications();
  const { setTitle } = usePageMeta();
  const cancel = {};

  const detailsHeadRow = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Detail' },
    { id: 'value', numeric: false, disablePadding: false, label: 'Value' },
  ];

  const valuesContainer = (detail) => {
    return [
      detail.name,
      detail.value
    ]
  };

  function generateDetails() {
    if (instance === null) {
      return;
    }
    const newDetails = [
      { name: "Image", value: <VmImage instance={instance}/> },
      { name: "Region", value: <VmRegion instance={instance} meta={meta}/> },
      {
        name: "Type", value: <VmMachineType instance={instance}
                                            meta={meta}/>
      },
      {
        name: "Launch Time", value: <VmLaunchTime instance={instance}
                                                  meta={meta}/>
      },
      {
        name: isAdminView ? "Owner" : "Linux User",
        value: isAdminView ? instance.owner : instance.linuxUser
      },
      { name: "IP Address", value: instance.publicIpAddress },
      {
        name: "State", value: <VmInstanceStateWithActionMenu isAdminView={isAdminView}
                                                             instance={instance}
                                                             alterStateCallback={updateInstancesState}
                                                             fullWidth={false}/>
      }
    ];
    if (!isAdminView) {
      newDetails.push({
        name: "SSH Tunnel",
        value: <VmInstanceSshTunnelFile instance={instance}/>
      });
    }
    setDetails(newDetails);
  }

  function loadVMInfo() {
    console.log('[VMDetail]', `${instanceId}: Fetching vm data...`);

    const vmUrl = isAdminView ? `/swc/api/admin/clouds/${cloudKey}/regions/${regionKey}/vms/${instanceId}` : `/swc/api/clouds/${cloudKey}/regions/${regionKey}/vms/${instanceId}`;
    callApi(`/swc/api/clouds/${cloudKey}/meta`, { method: 'GET' }, cancel)
    .then((response) => {
      console.log('[VMDetail]', 'meta', response);
      setMeta(response);
      return callApi(vmUrl,
        { method: 'GET' },
        cancel,
        false)
    })
    .then((response) => {
      console.log('[VMDetail]', "response", response);
      setInstance(response.instance);
      if (response.instance === null) {
        notify('vmDetailsNotificator',
          {
            text: "Sorry, vm not found.",
            error: "",
            type: 'error',
            handleClose: () => {
            },
          });
      }
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setInstance(null);
        setDetails(null);
        notify('vmDetailsNotificator',
          {
            text: "Error while loading vm info:",
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    })
    .finally(() => {
      setDataLoaded(true);
    });
  }

  function updateInstancesState(alteredInstance) {
    if (alteredInstance) {
      setInstance(alteredInstance)
    }
  }

  function renameVM(event) {
    console.log('[VMDetail] renameVM called');
    event.preventDefault();

    const instanceId = event.target.instanceId.value;
    const region = event.target.region.value;
    const newName = event.target.name.value;

    callApi(`/swc/api/clouds/${cloudKey}/regions/${region}/vms/${instanceId}`, {
      method: 'PUT', body: JSON.stringify({ name: newName }),
    }, cancel)
    .then((response) => {
      console.log('[VMDetail] renameVM', response);
      toggle();
      updateInstancesState(response.instance);
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        notify('vmDetailsNotificator',
          {
            text: "Error while VM renaming:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          });
      }
    }).finally();
  }

  function updatePageTitle() {
    const cloudLabel = meta && meta.label ? meta.label : cloudKey;
    if(isAdminView) {
      setTitle(`Administration - VM Overview - ${cloudLabel} - ${instanceId}`)
    }
    else {
      setTitle(`${cloudLabel} - ${instanceId}`)
    }
  }

  useInterval(loadVMInfo, reloadInterval);
  useEffect(() => {
    loadVMInfo();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [cloudKey, regionKey, instanceId]);
  useEffect(generateDetails, [instance]);
  useEffect(updatePageTitle, [meta]);

  return (<ListWrapper>
    {notificator("vmDetailsNotificator")}
    {meta && details && (
      <React.Fragment>
        <ScrollableList headRow={detailsHeadRow}
                        showHeadRow
                        items={details}
                        itemContainer={valuesContainer}
                        title={<VmInstanceName instance={instance}
                                               showRenameDialog={toggle}
                                               showRename={!isAdminView}/>}/>
        <ModalDialog
          open={isShowing}
          toggle={toggle}
          title={'Edit VM'}
        >
          <VmCreateOrRenameDialog handleSubmit={renameVM}
                                  hide={toggle}
                                  meta={meta}
                                  editableInstance={instance}
                                  dialogMode={'edit'}
                                  instanceCreationTaskRunning={false}/>
        </ModalDialog>
      </React.Fragment>
    )}
    {!dataLoaded && <LoadingIcon/>}
  </ListWrapper>);
}
