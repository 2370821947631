import React, { useEffect, useState } from 'react'
import { useNotifications } from '../../contexts/NotificationsContext';
import ScrollableList from '../../components/ScrollableList';
import { useAuth } from '../../react-auth-wrapper';
import { usePageMeta } from '../../contexts/PageMetaContext';
import AdminCloudSettingsTabs from '../../menu/admin/AdminCloudSettingsTabs';
import Button from '@mui/material/Button';
import ModalDialog from '../../components/ModalDialog';
import useModal from '../../hooks/useModal';
import ManageTranslationDialog from '../../components/translations/ManageTranslationDialog';
import TranslationActions from '../../components/translations/TranslationActions';
import { ADMIN_SETTINGS } from '../../components/PathConstants';

export default function Translations() {

  const [properties, setProperties] = useState(null);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [keyTypes, setKeyTypes] = useState([]);
  const [editableProperty, setEditableProperty] = useState(undefined);
  const { notify, notificator } = useNotifications();
  const { isShowing, toggle } = useModal();
  const { setTitle } = usePageMeta();
  const { callApi } = useAuth();
  const cancel = {};

  const headRow = [
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'uuid', numeric: false, disablePadding: false, label: 'UUID' },
    { id: 'key', numeric: false, disablePadding: false, label: 'Key' },
    { id: 'value', numeric: false, disablePadding: false, label: 'Value' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Actions' }
  ];

  const createPropertiesContainer = (property) => {
    return [
      property.propertyType,
      property.key,
      property.keyType,
      property.value,
      <TranslationActions property={property}
                          editTranslationHandler={openTranslationDialog}
                          afterActionHandler={getTranslations}
                          key={property.propertyType + property.key + property.keyType}/>
    ]
  };

  const openTranslationDialog = (property = undefined) => {
    setEditableProperty(property);
    toggle();
  };

  const getPropertyTypes = () => {
    callApi(`/swc/api/properties/propertyTypes`, { method: 'GET' }, cancel)
    .then((response) => {
      console.log('[Translations] propertyTypes', response);
      setPropertyTypes(response);
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setPropertyTypes([]);
        notify('translationErrors', {
          text: "Error while loading translation types:",
          error: error,
          type: 'error',
          handleClose: () => {
          }
        })
      }
    });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  };

  const getKeyTypes = () => {
    callApi(`/swc/api/properties/keyTypes`, { method: 'GET' }, cancel)
    .then((response) => {
      console.log('[Translations] keyTypes', response);
      setKeyTypes(response);
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setKeyTypes([]);
        notify('translationErrors', {
          text: "Error while loading key types:",
          error: error,
          type: 'error',
          handleClose: () => {
          }
        })
      }
    });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  };

  const getTranslations = () => {
    callApi(`/swc/api/properties`, { method: 'GET' }, cancel)
    .then((response) => {
      console.log('[Translations]', response);
      setProperties(response.values);
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        setProperties([]);
        notify('translationErrors', {
          text: "Error while loading properties:",
          error: error,
          type: 'error',
          handleClose: () => {
          }
        })
      }
    });
  };

  useEffect(() => {
    setTitle('Administration - Translations');
    getTranslations();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  useEffect(getPropertyTypes, []);
  useEffect(getKeyTypes, []);

  const createTranslation = (event, translation) => {
    console.log('[Translations] createTranslation called', translation);
    manageTranslation(event, translation, 'POST')
  };

  const updateTranslation = (event, translation) => {
    console.log('[Translations] updateTranslation called', translation);
    manageTranslation(event, translation, 'PUT')
  };

  const manageTranslation = (event, translation, method) => {
    event.preventDefault();
    toggle();

    callApi(`/swc/api/properties`,
      { method: method, body: JSON.stringify(translation) },
      cancel)
    .then((response) => {
      console.log(`[Translations] response for ${method}`, response);
      getTranslations();
    })
    .catch((error) => {
      if (error.message !== 'Cancelled') {
        notify('translationErrors', {
          text: `Error while translations management (method ${method}):`,
          error: error,
          type: 'error',
          handleClose: () => {
          }
        })
      }
    });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  };

  return (<AdminCloudSettingsTabs activeTabPath={ADMIN_SETTINGS.translationTable.path}>
    {notificator("translationErrors")}
    {properties
    && <ScrollableList headRow={headRow}
                       items={properties}
                       itemContainer={createPropertiesContainer}
                       showHeadRow={true}
                       title={"Translations"}/>}
    <Button
      variant="outlined"
      color="primary"
      type="submit"
      onClick={() => openTranslationDialog()}
    >
      Add translation
    </Button>
    <ModalDialog
      open={isShowing}
      toggle={toggle}
      title={editableProperty !== undefined ? "Update translation" : "Create Translation"}
    >
      <ManageTranslationDialog handleCreate={createTranslation}
                               handleUpdate={updateTranslation}
                               hide={toggle}
                               property={editableProperty}
                               meta={{ propertyTypes: propertyTypes, keyTypes: keyTypes }}/>
    </ModalDialog>
  </AdminCloudSettingsTabs>)
}
