import React, {useEffect, useState} from 'react';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import styled from "@emotion/styled";

const StyledFooter = styled.div`
  text-align: center;
  height: 40px;
`;

const StyledFooterContent = styled.div`
  padding-top: ${({theme}) => theme.spacing(1)};
`;

export default function Footer() {
  const cancel = {};
  const {notify} = useNotifications();
  const {callApi} = useAuth();
  const [legacyLinks, setLegacyLinks] = useState(null);
  const [legacyLabels, setLegacyLabels] = useState(null);

  const getLegacyLinks = () => {
    callApi(`/swc/api/properties/propertyType/LEGAL_LINK/keyType/LINK`,
      {method: 'GET'},
      cancel)
      .then((response) => {
        console.log('[Footer] legacy links', response);
        setLegacyLinks(response.values)
      })
      .catch((error) => {
        notify('commonNotificator', {
          text: "Error while loading legacy links:",
          error: error,
          type: 'error',
          handleClose: () => {
          }
        })
      });
  };

  const getLegacyLabels = () => {
    callApi(`/swc/api/properties/propertyType/LEGAL_LINK/keyType/LABEL`,
      {method: 'GET'},
      cancel)
      .then((response) => {
        console.log('[Footer] legacy labels', response);
        setLegacyLabels(response.values)
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('commonNotificator', {
            text: "Error while loading legacy labels:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
  };

  const getLinkFor = (key) => {
    const link = legacyLinks.filter(value => value.key === key);
    return link && link.length > 0 ? link[0].value : null;
  };

  const getLabelFor = (key) => {
    const label = legacyLabels.filter(value => value.key === key);
    return label && label.length > 0 ? label[0].value : null;
  };

  useEffect(() => {
    getLegacyLinks();
    getLegacyLabels();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  return (
    <StyledFooter>
      <Divider/>
      <StyledFooterContent>
        <Typography variant="caption">
          {legacyLinks && legacyLabels && legacyLabels.map((linkData, index) => {
            const link = getLinkFor(linkData.key);
            const label = getLabelFor(linkData.key);
            return <Link key={index} sx={{
              padding: (theme) => theme.spacing(1),
            }} color="inherit" href={link} target="_blank">
              {label}
            </Link>
          })}
        </Typography>
      </StyledFooterContent>
    </StyledFooter>
  );
}
