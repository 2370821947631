import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {useAuth} from '../../react-auth-wrapper';
import CloudConfig from '../../components/CloudConfig';
import {useNotifications} from '../../contexts/NotificationsContext';
import {ConfigFormProvider} from '../../contexts/CloudConfigFormContext';
import AdminCloudSettingsTabs from '../../menu/admin/AdminCloudSettingsTabs';
import {usePageMeta} from '../../contexts/PageMetaContext';
import useConfigFormCreateObjectFrom from '../../hooks/useConfigFormCreateObjectFrom';
import TabPanel from '../../components/TabPanel';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import SWCTabs from '../../components/SWCTabs';
import {ADMIN_SETTINGS} from '../../components/PathConstants';
import {useCallbackPrompt} from "../../hooks/prompt/useCallbackPrompt";
import ConfirmationDialog from "../../components/ConfirmationDialog";

function DirtyIndicator() {
  return (<Tooltip title="Has hot saved changes"><EditIcon/></Tooltip>);
}

export default function AdminClouds() {

  const [cloudConfigs, setCloudConfigs] = useState([]);
  const [tabsInfo, setTabsInfo] = useState([]);
  const [template, setTemplate] = useState([]);
  const [currentConfigTabIndex, setCurrentConfigTabIndex] = useState(0);
  const [cloudNames, setCloudNames] = useState([]);
  const [tabDirtyIndicators, setTabDirtyIndicators] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isBlocked);
  const {setTitle} = usePageMeta();
  const {callApi} = useAuth();
  const {notificator, notify} = useNotifications();
  const newConfig = useConfigFormCreateObjectFrom(template);
  const cancel = {};

  setTitle('Administration - Cloud accounts');

  const addNewConfig = () => {
    console.log('[AdminClouds]', 'new config', newConfig);
    setCloudConfigs([...cloudConfigs, newConfig]);
    setTabDirtyIndicators([...tabDirtyIndicators, <DirtyIndicator/>]);
    setIsBlocked(true);
    setCurrentConfigTabIndex(cloudConfigs.length);
  };

  const getTemplate = () => {
    callApi(`/swc/api/clouds/configs/template`, {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[AdminClouds] cloud config template', response);
        setTemplate(response.cloudConfigTemplate);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setTemplate([]);
          notify('cloudConfigs', {
            text: "Error while loading cloud config template:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
  };

  const getCloudNameForKeyOrDefault = (cloudKey) => {
    const cloudName = cloudNames[cloudKey];
    if (cloudName === undefined) {
      return cloudKey !== '' ? cloudKey : '--No name--'
    } else {
      return cloudName;
    }
  };

  const handleDeleted = (configIndex) => {
    const clouds = [...cloudConfigs];
    clouds.splice(configIndex, 1);
    setCloudConfigs(clouds);
    deleteTabDirtyIndicator(configIndex);
    setCurrentConfigTabIndex(0);
  };

  const updateTabNames = () => {
    console.log('[AdminClouds] cloud configs changed, update tab names.');
    const newTabsInfo = cloudConfigs.map((cloud, index) => ({
      name: getCloudNameForKeyOrDefault(cloud.key),
      path: index
    }));
    setTabsInfo(newTabsInfo);
  };

  const handleUpdateCloud = (config, configIndex) => {
    const clouds = [...cloudConfigs];
    clouds[configIndex] = config;
    setCloudConfigs(clouds);
  };

  const getCloudNames = () => {
    callApi(`/swc/api/properties/propertyType/CLOUD/keyType/LABEL`,
      {method: 'GET'},
      cancel)
      .then((response) => {
        console.log('[CloudConfig] cloud names', response);
        const cloudNamesMap = response.values.reduce(function (map, prop) {
          map[prop.key] = prop.value;
          return map;
        }, {});
        console.log('[AdminClouds] cloud names', cloudNamesMap);
        setCloudNames(cloudNamesMap);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setCloudNames([]);
          notify('cloudConfigs', {
            text: "Error while loading translations:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
  };

  const updateTabDirtyIndicator = (index, isDirty) => {
    const dirtyIndicators = [...tabDirtyIndicators];
    dirtyIndicators[index] = isDirty ? <DirtyIndicator/> : null;
    setTabDirtyIndicators(dirtyIndicators);
    setIsBlocked(dirtyIndicators.filter(indicator => indicator !== null).length > 0);
  };

  const deleteTabDirtyIndicator = (index) => {
    const dirtyIndicators = [...tabDirtyIndicators];
    dirtyIndicators.splice(index, 1);
    setTabDirtyIndicators(dirtyIndicators);
    setIsBlocked(dirtyIndicators.filter(indicator => indicator !== null).length > 0);
  };

  const getClouds = () => {
    callApi(`/swc/api/clouds/configs`, {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[AdminClouds] cloud configs', response);
        setCloudConfigs(response.cloudConfigs);
        setTabDirtyIndicators(response.cloudConfigs.map(() => null));
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setCloudConfigs([]);
          notify('cloudConfigs', {
            text: "Error while loading clouds:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
  };

  useEffect(() => {
    getClouds();
    getTemplate();
    getCloudNames();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  useEffect(() => {
    updateTabNames();
  }, [cloudConfigs, cloudNames]);

  let content = null;
  let tabs = null;

  if (cloudConfigs && cloudConfigs.length > 0 && template && template.length > 0) {
    content = (<React.Fragment>{cloudConfigs.map(
      (config, index) =>
        <TabPanel key={index} selectedTabPath={currentConfigTabIndex}
                  tabPath={index}><ConfigFormProvider key={index}><CloudConfig
          cloudConfig={config}
          template={template}
          configIndex={index}
          handleUpdateTabDirtyIndicator={updateTabDirtyIndicator}
          handleUpdateCloud={handleUpdateCloud}
          handleDeleted={handleDeleted}/></ConfigFormProvider></TabPanel>)}</React.Fragment>);

    tabs = (<SWCTabs activeTabPath={currentConfigTabIndex}
                     tabsInfo={tabsInfo}
                     tabIcons={tabDirtyIndicators}
                     changeCallback={setCurrentConfigTabIndex}>{content}</SWCTabs>);
  }

  return (<AdminCloudSettingsTabs activeTabPath={ADMIN_SETTINGS.cloudAccounts.path}>
    {notificator('cloudConfigs')}
    {tabs}
    {template && template.length > 0 &&
      <Button
        variant="outlined"
        color="primary"
        type="submit"
        sx={{
          marginTop: (theme) => theme.spacing(2),
        }}
        onClick={() => addNewConfig()}
      >
        Add new cloud config
      </Button>
    }
    <ConfirmationDialog
      open={showPrompt}
      handleSubmit={confirmNavigation}
      handleCancel={cancelNavigation}
      title="Warning"
    >
      Are you sure you want to leave? You have unsaved data.
    </ConfirmationDialog>
  </AdminCloudSettingsTabs>);
}
