import prettyBytes from "pretty-bytes";
import React from "react";
import PropTypes from "prop-types";
import {SWC_PROP_TYPES} from "../../../../SwcPropTypes";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

const RemoveButton = ({file, clickCallback}) => {
  return (
    <Tooltip
      title="Remove file"
      leaveDelay={600}
      placement="bottom"
    ><IconButton aria-label="delete" onClick={() => clickCallback(file)} size="large">
      <DeleteIcon fontSize="small" />
    </IconButton></Tooltip>
  );
}

export default function FileMetas({fileMetas, handleRemoveFile = () => {}, removable = true}) {
  return (<ul>
    {(fileMetas||[]).map((file, index) => <li key={`remove_button_${index}`}>{`${file.name} (${prettyBytes(file.size)})`}{removable && <RemoveButton file={file} clickCallback={handleRemoveFile}/>}</li>)}
  </ul>);
}

FileMetas.propTypes = {
  fileMetas: PropTypes.arrayOf(SWC_PROP_TYPES.uploadFileMeta),
};
