import React, {useEffect, useState} from 'react';
import {useNotifications} from '../../contexts/NotificationsContext';
import {useAuth} from '../../react-auth-wrapper';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from "@emotion/styled";

const initialDialogState = {
  open: false, title: 'Hint', body: '', handleSubmit: () => {
  },
};

export default function useConfirmation({
                                          releaseType, productKey, licenseName, handleClick,
                                        }) {

  const cancel = {};
  const [dialogState, setDialogState] = useState(initialDialogState);
  const [processing, setProcessing] = useState(false);

  const {callApi} = useAuth();
  const {notify} = useNotifications();

  function closeDialog() {
    setDialogState(initialDialogState);
  }

  function showConfirmationDialogIfRequiredOrHandleEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    function handleError(error) {
      if (error.message !== 'Cancelled') {
        notify('commonNotificator', {
          text: 'Error while loading confirmation message:', error, type: 'error', handleClose: () => {
          },
        },);
      }
    }

    function convertLicenseTermsToNotifications(licenseTerms) {
      const locales = licenseTerms?.locales || [];
      const licenseTermsMap = (licenseTerms?.localeMap) || {};
      return locales.map(locale =>
        ({path: locale, value: licenseTermsMap[locale]})
      );
    }

    function process(rawNotifications, licenseTerms) {

      const licenseTermsNotifications = convertLicenseTermsToNotifications(licenseTerms);
      const productAndTypeNotifications = rawNotifications.filter(notificationItem => notificationItem.path === `#confirmations/download/product/${productKey}/type/${releaseType}`);
      const productNotifications = rawNotifications.filter(notificationItem => notificationItem.path === `#confirmations/download/product/${productKey}`);
      const typeNotifications = rawNotifications.filter(notificationItem => notificationItem.path === `#confirmations/download/type/${releaseType}`);

      let title = 'Hint'
      let notifications = [];
      if (licenseTermsNotifications.length > 0) {
        notifications = licenseTermsNotifications;
        title = 'License Agreement';
      } else if (productAndTypeNotifications.length > 0) {
        notifications = [productAndTypeNotifications[0]];
      } else if (productNotifications.length > 0) {
        notifications = [productNotifications[0]];
      } else if (typeNotifications.length > 0) {
        notifications = [typeNotifications[0]];
      }

      console.log('[useConfirmation] notifications=', notifications);
      if (notifications.length > 0) {
        let body = (<div dangerouslySetInnerHTML={{__html: notifications[0].value}}/>)
        if (notifications.length > 1) {
          body = (<NotificationTabs notifications={notifications}/>)
        }
        setDialogState(dialogStatePrev => ({
          ...dialogStatePrev,
          body: body,
          title: title,
          open: true,
          handleSubmit: (confirmationEvent) => {
            closeDialog();
            handleClick(confirmationEvent);
          },
        }));
      } else {
        setDialogState(initialDialogState);
        handleClick();
      }
    }

    (async function () {
      try {
        setProcessing(true);
        let licenseTerms = {};
        if (licenseName) {
          const queryString = new URLSearchParams([['licenseName', licenseName],]).toString();
          licenseTerms = await callApi(`/swc/api/builds/licenseterms?${queryString}`, {method: 'GET'}, cancel);
        }
        const notifications = await callApi('/swc/api/notifications', {method: 'GET'}, cancel)
        process(notifications?.values || [], licenseTerms?.value);
      } catch (e) {
        handleError(e)
      } finally {
        setProcessing(false);
      }
    })();
  }


  useEffect(() => () => {
    if (cancel && cancel.doCancel) cancel.doCancel();
  }, []);

  return {
    processing,
    dialogBody: dialogState.body,
    dialogTitle: dialogState.title,
    dialogOpen: dialogState.open,
    handleConfirm: dialogState.handleSubmit,
    handleClose: closeDialog,
    handleShowConfirmationIfRequiredOrSubmit: showConfirmationDialogIfRequiredOrHandleEvent,
  };
}


function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const StyledTabsContainer = styled.div`
  flex-grow: 1;
`;

function NotificationTabs({notifications}) {
  const [value, setValue] = React.useState("en");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (<StyledTabsContainer>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        aria-label="language-selection">
        {notifications.map(notification => (<Tab
          value={notification.path}
          key={notification.path}
          label={notification.path}
          wrapped
          {...a11yProps(notification.path)}
        />))}
      </Tabs>
      {notifications.map(notification => (<TabPanel value={value}
                                                    index={notification.path}
                                                    key={notification.path}>
        <div dangerouslySetInnerHTML={{__html: notification.value}}/>
      </TabPanel>))}
    </StyledTabsContainer>
  );
}
