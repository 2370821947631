import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useConfigForm} from '../contexts/CloudConfigFormContext';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from './ConfirmationDialog';
import useConfigFormCreateObjectFrom from '../hooks/useConfigFormCreateObjectFrom';
import Accordion from './Accordion/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from './Accordion/AccordionSummary';
import AccordionDetails from './Accordion/AccordionDetails';
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import {useTheme} from "@mui/material/styles";
import styled from "@emotion/styled";

const StyledTextAndIcon = styled.span`
  display: inline-flex;
  align-items: center;
`;

const StyledArrayContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

const StyledConfig = styled.div`
  padding-left: ${({theme}) => theme.spacing(4)};
  margin-bottom: 10px;
`;

const ExpandedFormContext = React.createContext();
const useFormExpanded = () => React.useContext(ExpandedFormContext);

function AddElementButton({name, title, value, parentKey}) {

  const {addObjectTemplate, getObjectTemplate, getTemplateForPath, addElement} = useConfigForm();
  const templateForPath = getTemplateForPath(parentKey);
  const obj = useConfigFormCreateObjectFrom(templateForPath.props);

  const handleAddClick = () => {
    const template = getObjectTemplate(name);
    console.log('[CloudConfig.AddElementButton] add element', parentKey, template);
    addElement(parentKey, template);
  };

  useEffect(() => {
    if (!value && getObjectTemplate(name) === undefined) {
      addObjectTemplate(name, obj);
      return;
    }
    addObjectTemplate(name, value);
  }, []);

  return (
    <Tooltip
      title={`Add ${title}`}
      placement="bottom"
    >
      <Button sx={{
        margin: (theme) => theme.spacing(1)
      }}
              variant="outlined"
              id={`add-${parentKey}`}
              color={'primary'}
              onClick={handleAddClick}>
        Add new {title}
      </Button></Tooltip>
  );
}

function NotPrimitiveElement({name, value, parentId, emptyInitialIdentifier, template}) {
  const theme = useTheme();
  console.log('[NotPrimitiveElement]', parentId, name, value);
  let {expanded} = useFormExpanded();
  const [ownExpanded, toggleExpanded] = useState(false);

  function getNewParentId(newPostfix) {
    const newParentId = parentId === '' ? `${newPostfix}` : `${parentId}${newPostfix}`;
    return newParentId;
  }

  function renderObject() {
    return (
      <React.Fragment>
        <Typography component="span" variant="subtitle2">{template.title}</Typography>
        <ConfigElement value={value}
                       parentId={getNewParentId(`['${name}']`)}
                       emptyInitialIdentifier={emptyInitialIdentifier}/>
      </React.Fragment>
    );
  }

  function renderArray() {

    const addButton = <AddElementButton name={name}
                                        title={template.title}
                                        value={value[0]}
                                        parentKey={getNewParentId(`['${name}']`)}/>;
    const text = <Typography component="span" variant="subtitle2">{template.title}</Typography>;
    return (<StyledArrayContainer>
        <Accordion expanded={ownExpanded} onChange={() => toggleExpanded(!ownExpanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <StyledTextAndIcon>{text}</StyledTextAndIcon>
          </AccordionSummary>
          <AccordionDetails sx={{
            display: 'block',
          }}>
            {
              value.map((element, index) => <React.Fragment key={index}><ConfigElement value={element}
                                                                                       key={index}
                                                                                       parentName={name}
                                                                                       parentId={getNewParentId(
                                                                                         `['${name}'][${index}]`)}
                                                                                       removeId={getNewParentId(
                                                                                         `['${name}']`)}
                                                                                       removeIndex={index}
                                                                                       emptyInitialIdentifier={emptyInitialIdentifier}
                                                                                       removable/>{index + 1 < value.length ?
                <Divider variant="middle"/> : null}</React.Fragment>)
            }
          </AccordionDetails>
          {addButton}
        </Accordion>
      </StyledArrayContainer>
    );
  }

  useEffect(() => {
    toggleExpanded(expanded);
  }, [expanded]);

  return (
    <div>
      {template.type === 'Object' ? renderObject() : renderArray()}
    </div>
  );
}

function ConfigElement({
                         value,
                         removable = false,
                         parentName,
                         parentId = '',
                         removeId = null,
                         removeIndex = null,
                         emptyInitialIdentifier
                       }) {
  const {getTemplateForPath, getConfigValueByPath, removeElement} = useConfigForm();
  const props = Object.keys(value);

  const isDeletable = () => {
    const templatePath = parentId.substring(0, parentId.lastIndexOf('['));
    const template = getTemplateForPath(templatePath);
    const configValue = getConfigValueByPath(templatePath);
    console.log('[cloudConfig] isDeletable', 'templatePath', templatePath, 'template', template);
    return template.required === false || (template.required === true && configValue !== undefined && configValue.length > 1);
  };

  const getValidators = (template, propName) => {
    const validatorSetup = {validators: [], errorMessages: []};
    if (template && template.required) {
      validatorSetup['validators'].push('required');
      validatorSetup['errorMessages'].push(`${propName} field is required.`);
    }
    if (template && template.required && template.type === 'String') {
      validatorSetup['validators'].push('nonEmptyScript');
      validatorSetup['errorMessages'].push(`${propName} have to be set.`);
    }
    if (template && template.type === 'Int') {
      validatorSetup['validators'].push('isNumber');
      validatorSetup['errorMessages'].push(`${propName} is a number.`);
    }
    console.log('[CloudConfig] getValidators for ', propName, validatorSetup);
    return validatorSetup;
  };

  function renderProp(prop, index) {
    const currentValue = value[prop];
    console.log('[ConfigElement] currentValue', currentValue);
    const id = `${parentId}['${prop}']`;
    const template = getTemplateForPath(id);
    if (template.type === 'String' || template.type === 'Int') {
      console.log('[ConfigElement] textField with id', id);
      console.log('[ConfigElement] currentValue isString || isNumber');

      const currentTemplate = getTemplateForPath(id);
      const editable = !currentTemplate.identifier || currentTemplate.identifier === false || (currentTemplate.identifier === true && emptyInitialIdentifier);
      const validatorSetup = getValidators(currentTemplate, prop);
      return (
        <React.Fragment key={index}>
          <StyledTextAndIcon>
            <TextValidator
              id={id}
              validators={validatorSetup['validators']}
              label={template.title}
              placeholder={prop}
              disabled={!editable}
              variant="outlined"
              sx={template.longField ? {
                marginLeft: (theme) => theme.spacing(1),
                marginRight: (theme) => theme.spacing(1),
                width: 300,
              } : {
                marginLeft: (theme) => theme.spacing(1),
                marginRight: (theme) => theme.spacing(1),
                width: 200,
              }}
              inputProps={template.type === 'Int' ? {
                style: {textAlign: "right"}
              } : {}}
              value={currentValue}
              margin="normal"
              errorMessages={validatorSetup['errorMessages']}
            />
            {removable &&
              <IconButton
                id={`delete-${removeId}[${removeIndex}]`}
                aria-label={`Delete current of ${parentName}`}
                disabled={!isDeletable()}
                xs={{
                  height: (theme) => theme.typography.pxToRem(48),
                }}
                onClick={() => removeElement(removeId, removeIndex)}
                size="large">
                <DeleteOutlineIcon titleAccess={`Delete one of the ${parentName}`}/>
              </IconButton>}
          </StyledTextAndIcon>
        </React.Fragment>
      );
    } else {
      return (
        <NotPrimitiveElement name={prop}
                             value={value[prop]}
                             key={index}
                             parentId={parentId}
                             template={template}
                             emptyInitialIdentifier={emptyInitialIdentifier}/>
      );
    }
  }

  return (
    <StyledConfig>
      {props && props.length > 0 && props.map((prop, index) => renderProp(prop, index))}
    </StyledConfig>
  );
}

export default function CloudConfig({
                                      cloudConfig,
                                      template,
                                      configIndex,
                                      handleUpdateTabDirtyIndicator,
                                      handleUpdateCloud,
                                      handleDeleted
                                    }) {

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(cloudConfig);
  const [initialKey, setInitialKey] = useState(cloudConfig.key);
  const [expanded, setExpanded] = useState(false);
  const {config, initConfig, initTemplate, setValue} = useConfigForm();
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const cancel = {};

  const handleChange = (e) => {
    console.log('[CloudConfig] handleChange', e.target.id, e.target.value);
    setValue(e.target.id, e.target.value);
    handleUpdateTabDirtyIndicator(configIndex, true);
  };

  const createCloud = (cloud) => {
    callApi(`/swc/api/clouds/configs`,
      {method: 'POST', body: JSON.stringify(cloud)},
      cancel)
      .then((response) => {
        console.log('[AdminClouds] created cloud', response);
        setInitialKey(cloud.key);
        setCurrentConfig(cloud);
        handleUpdateCloud(config, configIndex);
        handleUpdateTabDirtyIndicator(configIndex, false);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          console.log('ERROR', error);
          notify('cloudConfigs', {
            text: "Error while creating cloud:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
  };

  const updateCloud = (cloud) => {
    callApi(`/swc/api/clouds/configs`,
      {method: 'PUT', body: JSON.stringify(cloud)},
      cancel)
      .then((response) => {
        console.log('[AdminClouds] update cloud', response);
        handleUpdateTabDirtyIndicator(configIndex, false);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('cloudConfigs', {
            text: "Error while updating cloud:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
  };

  const askDeleteConfirmation = () => {
    setDeleteConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setDeleteConfirmationDialogOpen(false);
  };

  const handleDeleteConfig = () => {
    if (initialKey !== '') {
      callApi(`/swc/api/clouds/configs/${config.key}`,
        {method: 'DELETE'},
        cancel,
        true)
        .then((response) => {
          console.log('[AdminClouds] delete cloud', response);
        })
        .catch((error) => {
          if (error.message !== 'Cancelled') {
            notify('cloudConfigs', {
              text: "Error while deleting cloud:",
              error: error,
              type: 'error',
              handleClose: () => {
              }
            })
          }
        });
    }
    handleDeleted(configIndex);
    closeConfirmationDialog();
  };

  const handleSubmit = () => {
    if (initialKey === '') {
      // create
      console.log('[CloudConfig] create config', config);
      createCloud(config);
    } else {
      // update
      console.log('[CloudConfig] update config', config);
      updateCloud(config);
    }
  };

  useEffect(() => {
    initConfig(currentConfig);
    initTemplate(template);
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, [currentConfig]);

  ValidatorForm.addValidationRule('nonEmptyScript',
    value => value !== undefined && value.length > 0 && value.replace(/\s/g, '') !== '');

  ValidatorForm.addValidationRule('isNumber',
    value => value !== undefined && !isNaN(value));

  const SubmitButton = (props) => (<button {...props} type="submit"/>);

  return (
    <Paper sx={{
      paddingRight: (theme) => theme.spacing(2),
      paddingBottom: (theme) => theme.spacing(2),
      paddingLeft: (theme) => theme.spacing(2),
    }} square>
      <ConfirmationDialog
        open={deleteConfirmationDialogOpen}
        handleSubmit={handleDeleteConfig}
        handleCancel={closeConfirmationDialog}
        title={"Delete cloud config"}
      >
        Do you really want to delete this cloud config
        {' '}
        ?
      </ConfirmationDialog>
      {config &&
        <Card>
          <ValidatorForm
            onChange={handleChange}
            onSubmit={handleSubmit}
            onError={() => setExpanded(true)}
            noValidate
            autoComplete="off">
            <CardHeader
              sx={{
                paddingBottom: '0',
              }}
              action={
                <React.Fragment>
                  <IconButton aria-label="Save cloud config" component={SubmitButton} size="large">
                    <SaveIcon titleAccess="Save cloud config"/>
                  </IconButton>
                  <IconButton
                    aria-label="Delete cloud config"
                    onClick={() => askDeleteConfirmation()}
                    size="large">
                    <DeleteIcon titleAccess="Delete cloud config"/>
                  </IconButton>
                </React.Fragment>
              }
            />
            <CardContent>
              <ExpandedFormContext.Provider value={{expanded}}>
                <ConfigElement value={config}
                               emptyInitialIdentifier={initialKey === ''}/>
              </ExpandedFormContext.Provider>
            </CardContent>
          </ValidatorForm>
        </Card>
      }
    </Paper>
  );
}
