import React from 'react';
import Button from '@mui/material/Button';
import ModalDialog from './ModalDialog';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

const StyledButtonContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;

export default function ConfirmationDialog(
  {
    title,
    btnSubmitLabel = ConfirmationDialog.defaultProps.btnSubmitLabel,
    btnCancelLabel = ConfirmationDialog.defaultProps.btnCancelLabel,
    open,
    handleSubmit,
    handleCancel,
    children,
    width = 'default'
  },
) {
  let maxWidth = null;
  let fullWidth = false;
  if (width === 'wide') {
    maxWidth = 'lg';
    fullWidth = true;
  }

  return (
    <ModalDialog
      open={open}
      toggle={handleCancel}
      title={title}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <div>
        {children}
      </div>
      <StyledButtonContainer>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          sx={{
            marginRight: (theme) => theme.spacing(1),
          }}
          onClick={handleSubmit}
        >
          {btnSubmitLabel}
        </Button>
        <Button variant="outlined" type="button" onClick={handleCancel}>{btnCancelLabel}</Button>
      </StyledButtonContainer>
    </ModalDialog>
  );
}

ConfirmationDialog.defaultProps = {
  btnSubmitLabel: 'Ok',
  btnCancelLabel: 'Cancel',
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  btnSubmitLabel: PropTypes.string,
  btnCancelLabel: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOf(['default', 'wide']),
};
