import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import {saveAs} from "file-saver";
import PropTypes from 'prop-types';
import {AWS_STATE} from './vmConstants';
import {useAuth} from '../../react-auth-wrapper';
import {useNotifications} from '../../contexts/NotificationsContext';
import styled from "@emotion/styled";

const StyledIconButton = styled(IconButton)`
  margin-left: -8px;
  padding: 8px;
`;
export default function VmInstanceSshTunnelFile({instance}) {
    const {notify} = useNotifications();
    const {callApi} = useAuth();
    const cancel = {};

    function tunnelEnabled() {
        return instance.state === AWS_STATE.RUNNING || instance.state === AWS_STATE.INITIALIZING
    }

    function getSshTunnelRunningFile(instance) {
        const cloudKey = instance.cloudKey;
        const regionKey = instance.regionKey;
        const instanceId = instance.instanceId;

        const url = `/swc/api/ssh-tunnel-file?cloudKey=${cloudKey}&regionKey=${regionKey}&instanceId=${instanceId}`;
        callApi(url, {method: 'GET'}, cancel, true)
            .then((response) => {
                const fileName = response.headers.get('fileName');
                response.blob()
                    .then((blob) => {
                        console.log(`Download ${fileName}`, blob);
                        saveAs(blob, fileName);
                    });
            })
            .catch((error) => {
                if (error.message !== 'Cancelled') {
                    notify('instancesNotificator',
                        {
                            text: "Error while generating ssh-tunnel running file:",
                            error: error,
                            type: 'error',
                            handleClose: () => {
                            },
                        });
                }
            });
    }

    return (
        <Tooltip
            title={tunnelEnabled() ? 'ssh-tunnel' : 'no ssh-tunnel possible'}
        >
      <span>
        { /* add a span to make tooltip work when button is disable (guide from Material UI) */}
          <StyledIconButton
              aria-label="view"
              disabled={!tunnelEnabled()}
              onClick={() => getSshTunnelRunningFile(instance)}
              size="large">
          <ScreenShareOutlinedIcon/>
        </StyledIconButton>
      </span>
        </Tooltip>
    );
}

VmInstanceSshTunnelFile.propTypes = {
    instance: PropTypes.shape({
        linuxUser: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
    }).isRequired,
    getSshTunnelRunningFile: PropTypes.func.isRequired,
};
