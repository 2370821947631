export const ATTRIBUTES = {
  PRODUCT: 'PRODUCT',
  BUILD_TYPE: 'BUILD_TYPE',
  MAIN_VERSION: 'MAIN_VERSION',
  VERSION: 'VERSION',
  OS: 'OS',
  DATE: 'DATE',
  PROPERTIES: 'PROPERTIES',
  ATTRIBUTES: 'ATTRIBUTES',
}

export const SEARCHABLE_ATTRIBUTES = [ATTRIBUTES.BUILD_TYPE, ATTRIBUTES.MAIN_VERSION, ATTRIBUTES.OS, ATTRIBUTES.PRODUCT, ATTRIBUTES.PROPERTIES];

export const ORDERABLE_ATTRIBUTES = {
    BY: {
        PRODUCT: ATTRIBUTES.PRODUCT,
        BUILD_TYPE: ATTRIBUTES.BUILD_TYPE,
        MAIN_VERSION: ATTRIBUTES.MAIN_VERSION,
        OS: ATTRIBUTES.OS,
        DATE: ATTRIBUTES.DATE,
    },
    DIRECTION: {
        ASC: 'asc',
        DESC: 'desc',
    },
};

export const ATTRIBUTE_LABELS = {
  [ATTRIBUTES.PRODUCT]: 'Product',
  [ATTRIBUTES.BUILD_TYPE]: 'Type',
  [ATTRIBUTES.MAIN_VERSION]: 'Version',
  [ATTRIBUTES.VERSION]: 'Version Detail',
  [ATTRIBUTES.OS]: 'OS',
  [ATTRIBUTES.DATE]: 'Release Date',
  [ATTRIBUTES.PROPERTIES]: 'Qualified',
  [ATTRIBUTES.ATTRIBUTES]: 'Attributes'
}


export const QUERY_PARAM = {
  SELECTED_PRODUCT_KEYS : 'productKeys',
  SELECTED_BUILD_TYPES : 'buildTypes',
  SELECTED_OS : 'os',
  SELECTED_PROPERTIES : 'properties',
  SELECTED_ATTRIBUTES : 'attributes',
  MAIN_VERSION_SUBSTRING : 'mainVersionSubstring',
  ORDER_BY: "orderBy",
  ORDER_DIRECTION: "orderDirection",
  PAGE_NUMBER: "pageNumber",
  PAGE_SIZE: "pageSize",
};

export const QUALIFIED_META_PROPERTY_KEY = "qualified";

export const PROPERTIES_META = [
  {
    label : "Qualified",
    key: QUALIFIED_META_PROPERTY_KEY,
  }
]

export const SUBSCRIPTION_NONE_KEY = "NONE";

export const SUBSCRIPTION_META = [
  { label : "None", key : SUBSCRIPTION_NONE_KEY},
  { label : "Daily", key : "DAILY"},
  { label : "Weekly", key : "WEEKLY"},
  { label : "Monthly", key : "MONTHLY"},
];

export const FORM_DIRECTIVES = {
  SUBSCRIPTION_DIRECTIVE : "HANDLE_SUBSCRIPTION_DIRECTIVE",
  PRODUCT_DIRECTIVE : "HANDLE_PRODUCT_DIRECTIVE",
}

