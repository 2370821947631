import React from "react";
import {Paper} from "@mui/material";
import PropTypes from "prop-types";
import {useTheme} from "@mui/material/styles";
import styled from "@emotion/styled";

const StyledContent = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

export default function BuildDetailPanel({title, children}) {
  const theme = useTheme();
  return (
    <Paper sx={{
      padding: (theme) => theme.spacing(2),
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      marginBottom: '20px',
    }}>
      <StyledContent>{title}</StyledContent>
      <StyledContent>{children}</StyledContent>
    </Paper>
  )
}

BuildDetailPanel.prototype = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.element.isRequired,
}
