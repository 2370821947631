import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SWCLink from '../SWCLink';

export default function VmMachineType({ instance, meta, path }) {
  const currentRegion = (meta.regions || []).filter(region => region.key === instance.regionKey)[0];
  const currentMachineSize = currentRegion && currentRegion.machineSizes
    ? currentRegion.machineSizes.filter(machineSize => machineSize.key === instance.type)[0]
    : null;

  const label = currentMachineSize ? currentMachineSize.label : instance.type;
  const tooltip = currentMachineSize ? currentMachineSize.description : instance.type;

  return (
    <SWCLink path={path}>
      <Tooltip title={tooltip} data-machine-size={instance.type}>
        <span>{label}</span>
      </Tooltip>
    </SWCLink>
  );
}

VmMachineType.propTypes = {
  instance: PropTypes.shape({
    imageId: PropTypes.string.isRequired,
    regionKey: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    regions: PropTypes.arrayOf(PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    })).isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};
