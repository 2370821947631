import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SWCLink from "../../SWCLink";

export default function BuildProductCell({ item,  clickCallback = () => {}}) {
  const rawName = item?.productMeta?.productLabel;
  const tooltip = `${item?.productMeta?.productDescription || ""} (${item?.productMeta?.categoryLabel}/${item?.productMeta?.groupLabel})`;
  const label = rawName;

  return rawName ?
    (
      <SWCLink clickCallback={clickCallback}>
        <Tooltip
          title={tooltip}
          data-version={item?.productMeta?.productKey}>
          <span>{label}</span>
        </Tooltip></SWCLink>
    ) : null;
}

BuildProductCell.propTypes = {
  clickCallback: PropTypes.func,
  item: PropTypes.shape({
     productMeta: PropTypes.shape({
        productKey: PropTypes.string.isRequired,
        productLabel: PropTypes.string.isRequired,
        productDescription: PropTypes.string,
        productIcon: PropTypes.string,
        groupKey: PropTypes.string.isRequired,
        groupLabel: PropTypes.string.isRequired,
        groupDescription: PropTypes.string,
        groupIcon: PropTypes.string,
        categoryKey: PropTypes.string.isRequired,
        categoryLabel: PropTypes.string.isRequired,
        categoryDescription: PropTypes.string,
        categoryIcon: PropTypes.string,
    }),
  }).isRequired,
};
