import React from 'react';
import ReactDOM from 'react-dom';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {amber, green} from '@mui/material/colors';
import styled from "@emotion/styled";

const StyledDialogTitle = styled(DialogTitle)`
  margin: 0;
  padding: ${({theme}) => theme.spacing(2)};
  ${props => (props.type === 'success' ? `background-color: ${green[600]};` : '')}
  ${props => (props.type === 'error' ? `background-color: ${props.theme.palette.error.dark};` : '')}
  ${props => (props.type === 'error' ? `background-color: ${props.theme.palette.primary.main};` : '')}
  ${props => (props.type === 'warning' ? `background-color: ${amber[700]};` : '')}
`;
const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  right: ${({theme}) => theme.spacing(1)};
  top: ${({theme}) => theme.spacing(1)};
  color: ${({theme}) => theme.palette.grey[500]};
`;

const StyledDialogContentBody = styled.div`
  padding-left: ${({theme}) => theme.spacing(2)};
  padding-right: ${({theme}) => theme.spacing(2)};
  padding-bottom: ${({theme}) => theme.spacing(2)};
`;

const CustomDialogTitle = ({children, onClose, type = null}) => (
  <StyledDialogTitle type={type}>
    {children}
    {onClose ? (
      <StyledIconButton
        aria-label="close"
        onClick={onClose}
        size="large">
        <CloseIcon/>
      </StyledIconButton>
    ) : null}
  </StyledDialogTitle>
);

const ModalDialog = ({
                       open, toggle, title, children, maxWidth, fullWidth = false, fullScreen = false, type
                     }) => {
  return open ? ReactDOM.createPortal(
    <Dialog onClose={toggle}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}>
      <CustomDialogTitle id="customized-dialog-title" onClose={toggle} type={type}>
        {title}
      </CustomDialogTitle>
      <DialogContent dividers>
        <StyledDialogContentBody>
          {children}
        </StyledDialogContentBody>
      </DialogContent>

    </Dialog>, document.body,
  ) : null;
};

export default ModalDialog;
