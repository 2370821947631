import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import SWCLink from "../../SWCLink";
import Chip from "@mui/material/Chip";

export default function BuildAttributesCell({
                                              item, clickCallback = () => {}, attributesMeta = []
                                            }) {
  let fragments = [];

  attributesMeta.forEach(spec => {
    const {key, label, values = []} = spec;
    const selectedValues = ((item?.version?.attributes || {})[key]) || [];
    values
      .filter(value => selectedValues.includes(value))
      .forEach(value => {
        const chip = (<Chip
          key={value.key}
          size="small"
          label={`${label}:${value}`}
          // label={`${item?.productMeta?.productLabel}:${label}:${value}`}
          className={"A"}/>);
        fragments.push(chip);
      })
  })

  return fragments.length ?
    (<SWCLink clickCallback={clickCallback}>
        <span>{fragments}</span>
      </SWCLink>
    ) : null;
}

BuildAttributesCell.propTypes = {
  clickCallback: PropTypes.func,
  item: PropTypes.shape({
    version: PropTypes.shape({
      os: PropTypes.string,
    }),
  }).isRequired,
  attributesMeta: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.string,
    optional: PropTypes.bool.isRequired,
    values: PropTypes.arrayOf(PropTypes.string),
  })),
};
