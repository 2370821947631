import React from "react";
import ModalDialog from "../../ModalDialog";
import {SearchForm} from "./SearchForm";
import {FORM_DIRECTIVES} from "../searchConstants";

export function SearchDialog({state, handleSearch, productData, loading, toggle, isShowing, directives = [FORM_DIRECTIVES.PRODUCT_DIRECTIVE], searchButtonLabel = 'Filter', searchDialogTitle = 'Filter'}) {

  const doHandleSearch = state => {
    toggle();
    handleSearch(state);
  }

  return <ModalDialog
    toggle={toggle}
    open={isShowing}
    fullWidth={true}
    title={searchDialogTitle}
  >
    <SearchForm
      disabled={loading}
      state={state}
      handleSearch={doHandleSearch}
      productData={productData}
      toggle={toggle}
      directives={directives}
      searchButtonLabel={searchButtonLabel}
    />
  </ModalDialog>;
}
