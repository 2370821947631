import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from "@emotion/styled";

const StyledProgress = styled.div`
  text-align: center;
  ${props => props.inline ? `display: inline-block;
    position: relative;
    margin-right: 2px;
    width: 24px;
    height: 24px;
    top: -3px;` : ''};
`;
const StyledProgressWithColor = styled(CircularProgress)`
  color: ${props => props.customColor};
`;

const LoadingIcon = ({color, ...other}) => {

  return (<StyledProgress inline={other.inline}>
    <StyledProgressWithColor
      disableShrink {...other} customColor={color}/>
  </StyledProgress>);
};

export default LoadingIcon;
