import React, {useEffect} from 'react';
import {usePageMeta} from '../../../contexts/PageMetaContext';
import CloudTabs from '../../../components/CloudTabs';
import Billing from './Billing';
import { CLOUD_OVERVIEW } from '../../../components/PathConstants';

export default function CloudBilling() {

  const { setTitle } = usePageMeta();
  const cancel = {};

  useEffect(() => {
    setTitle('Administration - Cloud Billing Overview');
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  return <CloudTabs firstLevelActiveTabPath={CLOUD_OVERVIEW.billing.path} TabContent={Billing}/>
}
