import React, {useEffect, useState} from 'react';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import ScrollableList from "./ScrollableList";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";


const StyledLabel = styled.span`
  padding-bottom: ${({theme}) => theme.spacing(1)};
  padding-top: ${({theme}) => theme.spacing(1)};
`;

const headRow = [
  {id: 'name', numeric: false, disablePadding: false, label: 'Role'},
  {id: 'description', numeric: false, disablePadding: false, label: 'Description'}
];

const valuesContainer = (row) => {
  return [
    <StyledLabel>{row.name}</StyledLabel>,
    row.description
  ]
};

export default function UserRoles() {

  const [roles, setRoles] = useState([]);
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const cancel = {};

  useEffect(() => {
    console.log('Loading roles...');
    if (cancel.resetCancel) cancel.resetCancel();
    callApi('/swc/api/profiles/my/roles', {method: 'GET'}, cancel)
      .then((response) => {
        const roles = response.values || [];
        console.log('[UserRoles] roles=', roles);
        setRoles(roles);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setRoles([]);
          notify('commonNotificator', {
            text: "Error while loading user roles:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  return roles && (
    <ScrollableList
      headRow={headRow}
      showHeadRow
      items={roles || []}
      itemContainer={valuesContainer}
      title="Roles"
    />
  );
}
