import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import styled from "@emotion/styled";

const StyledButtonContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;

export default function ProfileDefaultMirrorEdit({handleSubmit, hide, mirrors}) {
  ProfileDefaultMirrorEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    mirrors: PropTypes.arrayOf(PropTypes.shape(
      {
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      },
    )).isRequired,
  };
  const [selectedMirror, setSelectedMirror] = useState(null);

  const doHandleSubmit = (event) => {
    event.preventDefault();
    handleSubmit(selectedMirror);
  };

  const calculateSelectedMirror = () => {
    const selected = (mirrors || []).find(mirror => mirror.matches && mirror.matches.find(match => match === 'PREFERRED'));
    setSelectedMirror(selected?.key || 'no-preference');
  };

  useEffect(calculateSelectedMirror, mirrors);

  return (
    <React.Fragment>
      <CssBaseline/>
      <Container maxWidth="lg">
        <Typography component="div">
          <form onSubmit={doHandleSubmit}>
            <TextField
              required
              helperText="Please select your preferred mirror for downloading artifacts."
              id="select-mirror-key"
              name="mirror"
              onChange={event => setSelectedMirror(event.target.value)}
              select
              label="Preferred download mirror"
              sx={{
                marginLeft: (theme) => theme.spacing(1),
                marginRight: (theme) => theme.spacing(1),
                minWidth: 250,
              }}
              value={selectedMirror || ''}
              margin="normal"
              variant="outlined"
              disabled={!mirrors}
            >
              <MenuItem
                key="no-preference"
                value="no-preference"
                selected={selectedMirror === 'no-preference'}
              >
                <i>no preference</i>
              </MenuItem>
              {mirrors && mirrors.map(option => {
                let tooltip = option.description || option.label;
                if (option.matches) {
                  tooltip += ` (${option.matches.join(', ')})`;
                }
                return (
                  <MenuItem
                    key={option.key}
                    value={option.key}
                    selected={option.key === selectedMirror}
                  >
                    <Tooltip
                      title={tooltip}
                    >
                      <span>
                        {option.label}
                      </span>
                    </Tooltip>
                  </MenuItem>
                );
              })}
            </TextField>
            <StyledButtonContainer>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                sx={{
                  marginRight: (theme) => theme.spacing(1),
                }}
              >
                Apply
              </Button>
              <Button variant="outlined" type="button" onClick={hide}>Cancel</Button>
            </StyledButtonContainer>
          </form>
        </Typography>
      </Container>
    </React.Fragment>
  );
}
