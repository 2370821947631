import React, {useEffect, useState} from 'react';
import {useAuth} from '../../react-auth-wrapper';
import {useNotifications} from '../../contexts/NotificationsContext';
import BuildTable from "./BuildTable";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import LoadingIcon from "../LoadingIcon";
import useModal from "../../hooks/useModal";
import {brandColor} from "../../contexts/ThemeContext";
import {useTheme} from "@mui/material/styles";

const initialProductData = {
  productMetas: [],
  buildTypes: [],
  oss: [],
};

function createBuildTableDefault({
                                   state,
                                   headRow,
                                   tableRows,
                                   tableData,
                                   loading,
                                   setOrder,
                                   setPagination,
                                   onRowClicked
                                 }) {
  return <BuildTable
    headRow={headRow}
    rows={tableRows}
    order={state.order}
    setOrder={setOrder}
    pagination={tableData?.pagination || {page: 0, size: 25, numberOfPages: 0, overallCount: 0}}
    setPagination={setPagination}
    showHeadRow={true}
    showTitle={false}
    showPagination={true}
    disabled={loading}
    onRowClicked={onRowClicked}
  />;
}

export default function BuildSearchComponent({
                                               searchState,
                                               headRow,
                                               createInstanceContainer,
                                               createSearchForm,
                                               createFilterChipContainer,
                                               createBuildTable = createBuildTableDefault,
                                               lookupProductOSs = null,
                                               onRowClicked
                                             }) {

  const cancel = {};
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const {isShowing, toggle} = useModal();
  const [showResults, setShowResults] = useState(false);
  const [productDataLoaded, setProductDataLoaded] = useState(false);
  const [productData, setProductData] = useState({...initialProductData});
  const [state, updateState, resetState] = [...searchState];

  const [tableData, setTableData] = useState({});
  const [tableRows, setTableRows] = useState([]);

  const {notify} = useNotifications();
  const {callApi} = useAuth();

  const setOrder = (order) => updateState({...state, order: {...state.order, ...order}});
  const setPagination = (pagination) => {
    const newState = {...state, pagination: {...state.pagination, ...pagination}};
    return updateState(newState);
  };

  async function queryData() {

    const queryParameter = [
      ['page', state.pagination.page],
      ['pageSize', state.pagination.size],
      ['buildType', state.selectedBuildTypes.join(',')],
      ['property', state.selectedProperties.join(',')],
      ['os', state.selectedOs.join(",")],
      ['product', state.selectedProductKeys.join(",")],
      ['mainVersion', state.mainVersionSubstring?.trim() || ""],
      ['releaseDateCriteria', ""],
      ['releaseDateDayCriteria', ""],
      ['attributeAndValue', state.selectedAttributes.join(',')],
      ['orderBy', state.order.by],
      ['orderDirection', state.order.direction],
    ];

    try {
      setLoading(true);
      const queryString = new URLSearchParams(queryParameter).toString();
      console.log("[BuildSearchComponent] request", queryString);
      const tableData = await callApi('/swc/api/builds/search?' + queryString, cancel);
      console.log("[BuildSearchComponent] response", tableData);
      setTableData(tableData);
      setShowResults(true);
      setLoading(false);
    } catch (error) {
      if (error.message !== 'Cancelled') {
        notify('commonNotificator',
          {
            text: "Error while loading builds:",
            error: error,
            type: 'error',
            handleClose: () => {
            },
          });
        setTableData({});
        setShowResults(false);
        resetState();
        setLoading(false);
      }
    }
  }

  async function loadProductData() {
    try {
      setLoading(true);
      const productDataResp = await callApi('/swc/api/products', {method: 'GET'}, cancel)
      const productDataOSSResp = await (lookupProductOSs ? lookupProductOSs(cancel) : callApi('/swc/api/products/any/os', {method: 'GET'}, cancel))
      setProductData(({
        ...productDataResp,
        oss: productDataOSSResp
      }));
      setProductDataLoaded(true);
      setLoading(false);
    } catch (error) {
      if (error.message !== 'Cancelled') {
        let notification = {
          text: 'Error while loading build meta:',
          error: error,
          type: 'error',
          handleClose: () => {
          },
        }
        if (error?.type?.startsWith("https://www.advantest.com/swc/api/problem/")) {
          notification = {
            type: 'error',
            text: `${error.title}. ${error.detail}`,
            handleClose: () => { // something that should be done on notification close
            }
          }
        }
        notify('commonNotificator', notification);
        setProductData({...initialProductData});
        resetState();
        setProductDataLoaded(false);
        setLoading(false);
      }
    }
  }

  function handleSearch(state) {
    updateState(state);
  }

  useEffect(() => {
    setTableRows((tableData?.items || []).map(item => createInstanceContainer(item, productData)));
  }, [tableData]);

  useEffect(() => {
    if (productDataLoaded) {
      queryData();
    }
  }, [showResults, productDataLoaded, state]);

  useEffect(() => {
    loadProductData();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [lookupProductOSs]);

  const searchForm = createSearchForm({state, handleSearch, productData, loading, isShowing, toggle});

  return (<div>

    <Paper sx={{
      padding: (theme) => theme.spacing(1),
      marginBottom: (theme) => theme.spacing(3),
    }} square>

      <Backdrop sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }} open={loading}>
        <LoadingIcon color={brandColor}/>
      </Backdrop>

      {searchForm}

      {showResults ?
        createFilterChipContainer({searchState, headRow, disabled: loading, productData, toggle}) : null
      }

      {showResults ?
        createBuildTable({state, headRow, tableRows, loading, tableData, setPagination, setOrder, onRowClicked}) : null
      }

      {/*<pre>productMetaList = {JSON.stringify(productData?.productMetas)}</pre>*/}
      {/*<pre>buildTypeList   = {JSON.stringify(productData?.buildTypes)}</pre>*/}
      {/*<pre>osList          = {JSON.stringify(productData?.oss)}</pre>*/}
      {/*<pre>mainVersionSubstring = {JSON.stringify(state?.mainVersionSubstring)}</pre>*/}
      {/*<pre>order           = {JSON.stringify(order)}</pre>*/}
      {/*<pre>pagination      = {JSON.stringify(tableData?.pagination)}</pre>*/}
      {/*<pre>loading         = {JSON.stringify(loading)}</pre>*/}

    </Paper>
  </div>);
}


