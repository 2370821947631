import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {useNotifications} from '../../contexts/NotificationsContext';
import LoadingIcon from '../LoadingIcon';
import styled from "@emotion/styled";

const StyledTextField = styled(TextField)`
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
  width: 400px;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;
const StyledButton = styled(Button)`
  display: flex;
  margin-right: ${({theme}) => theme.spacing(1)};
  width: 80px;
  margin-left: 4px;
`;

export default function VmCreateOrRenameDialog({
                                                 handleSubmit,
                                                 hide,
                                                 meta,
                                                 dialogMode,
                                                 editableInstance,
                                                 instanceCreationTaskRunning
                                               }) {
  const {notificator} = useNotifications();

  const [name, setName] = useState(editableInstance !== null && dialogMode === 'edit' ? editableInstance.name : '');
  const [regions, setRegions] = useState(undefined);
  const [region, setRegion] = useState(undefined);
  const [regionTooltip, setRegionTooltip] = useState("Please select a region");
  const [images, setImages] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [imageTooltip, setImageTooltip] = useState("Please select an image");
  const [machineSizes, setMachineSizes] = useState(undefined);
  const [machineSize, setMachineSize] = useState(undefined);
  const [machineSizeTooltip, setMachineSizeTooltip] = useState("Please select a machine size");

  const findItemDescription = (key, items) => {
    const selectedItem = items.filter(itemValue => itemValue.key === key);
    return selectedItem !== null && selectedItem !== undefined && selectedItem.length > 0 ? selectedItem[0].description : undefined;
  };

  function handleRegionChanged(selectedRegionKey) {
    setRegion(selectedRegionKey);

    const regionDescription = findItemDescription(selectedRegionKey, regions);
    setRegionTooltip(regionDescription);

    setImages(undefined);
    setImage(undefined);
    setMachineSizes(undefined);
    setMachineSize(undefined);

    const currentRegion = regions.filter(reg => reg.key === selectedRegionKey)[0];
    setImages(currentRegion && currentRegion.images);
  }

  function handleNameChanged(newName) {
    setName(newName);
  }

  function handleImageChanged(selectedImageKey) {
    setImage(selectedImageKey);

    const imageDescription = findItemDescription(selectedImageKey, images);
    setImageTooltip(imageDescription);

    const currentRegion = regions.filter(reg => reg.key === region)[0];
    setMachineSizes(currentRegion && currentRegion.machineSizes);
  }

  function handleMachineSizeChanged(selectedMachineSizeKey) {
    setMachineSize(selectedMachineSizeKey);

    const machineSizeDescription = findItemDescription(selectedMachineSizeKey, images);
    setMachineSizeTooltip(machineSizeDescription);
  }

  function renderSubmitButton() {
    if (instanceCreationTaskRunning) {
      return <LoadingIcon color="primary" sx={{
        position: 'absolute',
        left: '25%',
        top: '20%',
        width: '100%',
        height: '100%',
        textAlign: 'center',
      }} size={24}/>;
    }
    return dialogMode === 'edit' ? 'Edit' : 'Create';
  }

  useEffect(() => {
    const regions = meta && meta.regions ? meta.regions.filter(reg => reg.images.length) : [];
    setRegions(regions);
  }, [meta]);

  useEffect(() => {
    if (regions && regions.length === 1) {
      handleRegionChanged(regions[0].key);
    }
    if (regions && dialogMode === 'edit' && editableInstance !== null) {
      handleRegionChanged(editableInstance.regionKey);
    }
  }, [regions]);

  useEffect(() => {
    if (images && images.length === 1) {
      handleImageChanged(images[0].key);
    }
    if (images && dialogMode === 'edit' && editableInstance !== null) {
      handleImageChanged(editableInstance.imageId);
    }
  }, [images]);

  useEffect(() => {
    if (machineSizes && machineSizes.length === 1) {
      handleMachineSizeChanged(machineSizes[0].key);
    }
    if (machineSizes && dialogMode === 'edit' && editableInstance !== null) {
      handleMachineSizeChanged(editableInstance.type);
    }
  }, [machineSizes]);

  const regionsDisabled = !regions || regions.length <= 1;
  const imagesDisabled = !images || images.length <= 1;
  const machineSizesDisabled = !machineSizes || images.machineSizes <= 1;
  const submitButtonDisabled = !(region && image && machineSize);

  return (
    <div>
      {notificator('createInstanceNotificator')}
      {meta && (
        <form onSubmit={handleSubmit}>
          <input type="hidden"
                 value={editableInstance !== null ? editableInstance.instanceId : null}
                 name="instanceId"/>
          <div>
            <StyledTextField
              required
              id="name"
              name="name"
              label="VM name"
              margin="normal"
              variant="outlined"
              onChange={event => handleNameChanged(event.target.value)}
              disabled={instanceCreationTaskRunning}
              value={name}
            />
          </div>
          <div>
            <StyledTextField
              required
              helperText={regionTooltip}
              id="select-region-key"
              name="region"
              select
              label="Region"
              onChange={event => handleRegionChanged(event.target.value)}
              value={region || ''}
              margin="normal"
              variant="outlined"
              disabled={regionsDisabled || instanceCreationTaskRunning || dialogMode === 'edit'}
            >
              {regions ? regions.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              )) : <MenuItem key="" value=""/>}
            </StyledTextField>
          </div>
          <div>
            <StyledTextField
              required
              helperText={imageTooltip}
              id="select-image-key"
              name="image"
              select
              label="Image"
              onChange={event => handleImageChanged(event.target.value)}
              value={image || ''}
              margin="normal"
              variant="outlined"
              disabled={imagesDisabled || instanceCreationTaskRunning || dialogMode === 'edit'}
            >
              {images ? images.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              )) : <MenuItem key="" value=""/>}
            </StyledTextField>
          </div>
          <div>
            <StyledTextField
              required
              helperText={machineSizeTooltip}
              id="select-machineSize"
              name="machineSize"
              select
              label="Machine size"
              onChange={event => handleMachineSizeChanged(event.target.value)}
              value={machineSize || ''}
              margin="normal"
              variant="outlined"
              disabled={machineSizesDisabled || instanceCreationTaskRunning || dialogMode === 'edit'}
            >
              {machineSizes ? machineSizes.map(option => (
                <MenuItem
                  key={option.key}
                  value={option.key}
                >
                  {option.label}
                </MenuItem>
              )) : <MenuItem key="" value=""/>
              }
            </StyledTextField>
          </div>
          <StyledButtonContainer>
            <StyledButton
              variant="outlined"
              color="primary"
              type="submit"
              disabled={submitButtonDisabled || instanceCreationTaskRunning}
            >
              {renderSubmitButton()}
            </StyledButton>
            <StyledButton disabled={instanceCreationTaskRunning}
                          variant="outlined"
                          type="button"
                          onClick={hide}>Cancel</StyledButton>
          </StyledButtonContainer>
        </form>
      )
      }
    </div>
  );
}

VmCreateOrRenameDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    regions: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      machineSizes: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })),
      images: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })).isRequired,
    })).isRequired,
  }).isRequired,
  instanceCreationTaskRunning: PropTypes.bool.isRequired,
};
