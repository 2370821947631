import React from 'react';

import {TextField} from "@mui/material";
import {StyledSearchFormAutocomplete} from "./SearchForm";


export default function BuildTypesSelection({buildTypesList, selectedBuildTypes, setSelectedBuildTypes, disabled}) {

    const sortedBuildTypes = [...buildTypesList].sort();
    const sortedSelectedBuildTypes = [...selectedBuildTypes].filter(selected => buildTypesList.indexOf(selected) > -1).sort();

    const handleChange = (event, value) => {
        setSelectedBuildTypes((value || []))
    };

    return (
        <StyledSearchFormAutocomplete
            disabled={disabled}
            multiple
            id="search-selected-build-types"
            options={sortedBuildTypes}
            getOptionLabel={(option) => option || ""}
            filterSelectedOptions
            onChange={handleChange}
            value={sortedSelectedBuildTypes}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Selected types"
                    placeholder="Types"
                />
            )}
        />
    );
}
