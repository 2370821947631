import React, {useEffect, useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useNotifications} from '../../contexts/NotificationsContext';
import {useAuth} from '../../react-auth-wrapper';
import ScrollableList from '../../components/ScrollableList';
import LoadingIcon from '../../components/LoadingIcon';
import ListWrapper from '../../components/ListWrapper';
import {usePermissions} from '../../contexts/PermissionsContext';
import styled from "@emotion/styled";

const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(1)};
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin-bottom: 10px;
`;
const StyledContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(1)};
`;

function CachePanel({title, children}) {
  return (
    <StyledPaper>
      <StyledContainer>
        <Typography variant="h6">
          {title}
        </Typography>
      </StyledContainer>
      <StyledContainer>
        {children}
      </StyledContainer>
    </StyledPaper>
  );
}

export default function SWCDetails() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [details, setDetails] = useState(null);
  const {hasPermission} = usePermissions();
  const [cacheRefreshSnackbarOpen, setCacheRefreshSnackbarOpen] = useState(false);
  const [links, setLinks] = useState([]);
  const {notify, notificator} = useNotifications();
  const {callApi} = useAuth();
  const cancel = {};
  const hasRefreshCachePermission = hasPermission('REFRESH_BUILD_CACHE');

  const handleRefreshCacheClick = async (event) => {
    event.preventDefault();
    if (hasRefreshCachePermission) {
      try {
        setCacheRefreshSnackbarOpen(false);
        await callApi('/swc/api/admin/builds/cache/refresh', {method: 'PUT'}, cancel);
        setCacheRefreshSnackbarOpen(true);
      } catch (error) {
        if (error.message !== 'Cancelled') {
          setCacheRefreshSnackbarOpen(false);
          notify('swcDetailsErrors', {
            text: 'Error while refreshing cache:',
            error,
            type: 'error',
            handleClose: () => {
            },
          });
        }
      }
    }
  };

  const handleSnackbarClose = () => {
    setCacheRefreshSnackbarOpen(false);
  };

  const detailsHeadRow = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Detail'},
    {id: 'value', numeric: false, disablePadding: false, label: 'Value'},
  ];
  const linksHeadRow = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Detail'},
    {id: 'value', numeric: false, disablePadding: false, label: 'Link'},
  ];

  const valuesContainer = (detail) => [
    detail.name,
    detail.value,
  ];

  const linksContainer = (link) => [
    link.name,
    <a
      key={link.path}
      href={link.path}
      rel="noreferrer"
      target="_blank"
    >
      {link.label}
    </a>,
  ];

  const getDetails = () => {
    callApi('/swc/api/details', {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[SWC Details]', response);
        setDetails(response.details);
        setDataLoaded(true);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setDataLoaded(false);
          setDetails(null);
          notify('swcDetailsErrors', {
            text: 'Error while loading SWC details:',
            error,
            type: 'error',
            handleClose: () => {
            },
          });
        }
      });
  };

  const initLinks = () => {
    setLinks([{path: '/swagger-ui.html', label: 'SWAGGER UI', name: 'REST API Documentation'}]);
  };

  useEffect(() => {
    getDetails();
    initLinks();
    return () => {
      if (cancel?.doCancel) cancel.doCancel();
    };
  }, []);

  return (
    <ListWrapper>
      {notificator('swcDetailsErrors')}
      {links && (
        <ScrollableList
          headRow={linksHeadRow}
          showHeadRow
          items={links}
          itemContainer={linksContainer}
          title="Links"
        />
      )}
      {hasRefreshCachePermission && (
        <CachePanel title="Builds Cache">
          <Button variant="outlined" color="primary" onClick={handleRefreshCacheClick}>
            Trigger Refresh
          </Button>
          <Snackbar
            open={cacheRefreshSnackbarOpen}
            autoHideDuration={5000}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
            >
              Cache refresh triggered! It might take a while to complete.
            </Alert>
          </Snackbar>
        </CachePanel>
      )}
      {dataLoaded && details && (
        <ScrollableList
          headRow={detailsHeadRow}
          showHeadRow
          items={details}
          itemContainer={valuesContainer}
          title="Properties"
        />
      )}
      {!dataLoaded && <LoadingIcon sx={{
        marginTop: (theme) => theme.spacing(20),
        margin: 'auto',
      }}/>}
    </ListWrapper>
  );
}
