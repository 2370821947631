import React, {useState} from "react";
import {SWC_PROP_TYPES} from "../../SwcPropTypes";
import {Paper} from "@mui/material";
import PropTypes from "prop-types";
import BuildDetailPanel from "./BuildDetailPanel";
import Typography from "@mui/material/Typography";
import PanelHeaderBox from "./PanelHeaderBox";
import CopyToClipboardButton from "../CopyToClipboardButton";
import styled from "@emotion/styled";

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #e3e3e3;
  width: 100%;
  float: left;
  min-height: 80px;
  align-items: center;
  padding: ${({theme}) => theme.spacing(2)};
`;
const StyledCommand = styled.div`
  min-width: 30px;
`;
const StyledSmall = styled.small`
  margin: auto;
  color: black;
  padding: ${({theme}) => theme.spacing(2)};
`;
export default function BuildDetailPanelInstallCommand({versionItem, disabled}) {
  const [showButton, setShowButton] = useState(false);
  const {version: versionObj} = versionItem;

  function CopyCommandIcon() {
    return (
      <StyledCommand>
        {showButton ? (<CopyToClipboardButton
          disabled={disabled}
          data={{text: versionObj.installCommand?.command, copySuccessText: "Copied!"}}
          btnTitle="Copy install cmd to clipboard"/>) : null}
      </StyledCommand>
    );
  }

  function Title() {
    return (
      <PanelHeaderBox title={
        <Typography variant={'h6'}>Install Command</Typography>
      }/>
    );
  }

  return versionObj.installCommand ? (
    <BuildDetailPanel
      title={<Title/>}
      disabled={disabled}
    >
      <StyledPaper
        onMouseEnter={() => setShowButton(versionObj?.installCommand)}
        onMouseLeave={() => setShowButton(false)}>
        <div>
          <StyledSmall>{versionObj.installCommand.command}</StyledSmall>
        </div>
        <CopyCommandIcon/>
      </StyledPaper>
    </BuildDetailPanel>
  ) : null;
}

BuildDetailPanelInstallCommand.prototype = {
  versionItem: SWC_PROP_TYPES.versionItem.isRequired,
  disabled: PropTypes.bool,
}
