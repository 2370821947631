import React, {useEffect, useState} from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StyledTextValidator = styled(TextValidator)`
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
  min-width: 300px;
`;
const StyledButton = styled(Button)`
  margin-right: ${({theme}) => theme.spacing(1)};
`;
const StyledButtonContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;

const initialNotificationState = {path: '', value: ''};

export default function ManageNotificationDialog({handleCreate, handleUpdate, hide, editableNotification}) {
  const [notification, setNotification] = useState(initialNotificationState);
  const isEdit = editableNotification !== undefined;

  const handleChange = (event) => {
    setNotification({...notification, [event.target.name]: event.target.value});
  };

  useEffect(() => {
    if (isEdit) {
      setNotification(editableNotification);
    }
  }, []);

  ValidatorForm.addValidationRule('nonEmptyString',
    value => value !== undefined && value.length > 0 && value.replace(/\s/g, '') !== '');

  return (
    <React.Fragment>
      <CssBaseline/>
      <Container maxWidth="lg">
        <Typography component="div">
          <ValidatorForm onSubmit={(event) => isEdit ? handleUpdate(event,
            notification) : handleCreate(event, notification)}>
            <div>
              <StyledTextValidator
                label="Path"
                onChange={handleChange}
                value={notification.path || ''}
                name="path"
                validators={['required', 'nonEmptyString']}
                errorMessages={['Please set not empty Path']}
                helperText="For example: /vms, /, /admin"
                margin="normal"
                variant="outlined"
              />
            </div>
            <div>
              <StyledTextValidator
                label="Value"
                onChange={handleChange}
                value={notification.value}
                name="value"
                multiline
                fullWidth
                rows="10"
                validators={['required', 'nonEmptyString']}
                errorMessages={['Please set not empty Value']}
                helperText="Non empty string with html / or plain text for email templates"
                margin="normal"
                variant="outlined"
              />
            </div>
            <StyledButtonContainer>
              <StyledButton
                variant="outlined"
                color="primary"
                type="submit"
              >
                {isEdit ? 'Save' : 'Create'}
              </StyledButton>
              <Button variant="outlined" type="button" onClick={hide}>Cancel</Button>
            </StyledButtonContainer>
          </ValidatorForm>
        </Typography>
      </Container>
    </React.Fragment>
  );
}

ManageNotificationDialog.ptopTypes = {
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  editableNotification: PropTypes.shape({
    path: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};
