import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export function getProductOrBucketLabel(productOrCloudKey, metaData) {
  const metaDataForKey = (metaData || []).find((item) => item.key === productOrCloudKey);
  const rawLabel = (metaDataForKey?.label) || productOrCloudKey || '';
  const label = (metaDataForKey?.type) === 'CloudBucket' ? `${rawLabel} (S3)` : rawLabel;
  const tooltip = (metaDataForKey?.description) || rawLabel;

  return {label, tooltip};
}

export default function ProductOrBucketCell({productOrCloudKey, metaData}) {
  const labelAndTooltip = getProductOrBucketLabel(productOrCloudKey, metaData);
  return labelAndTooltip.label ? (
    <Tooltip title={labelAndTooltip.tooltip}>
      <span>{labelAndTooltip.label}</span>
    </Tooltip>
  ) : null;
}
