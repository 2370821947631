export const AWS_COMMAND = {
  START: 'START',
  STOP: 'STOP',
  TERMINATE: 'TERMINATE',
};

export const AWS_STATE = {
  UNKNOWN: 'unknown',
  PENDING: 'pending',
  RUNNING: 'running',
  STOPPED: 'stopped',
  STOPPING: 'stopping',
  TERMINATED: 'terminated',
  SHUTTING_DOWN: 'shutting_down',
  INITIALIZING: 'initializing',
  ERROR: 'error'
};

export function awsCommands() {
  return [AWS_COMMAND.START, AWS_COMMAND.STOP, AWS_COMMAND.TERMINATE ];
}

export function awsCommandLabel(command) {
  const AWS_COMMAND_LABELS = {};
  AWS_COMMAND_LABELS[AWS_COMMAND.START] = 'Start';
  AWS_COMMAND_LABELS[AWS_COMMAND.STOP] = 'Stop';
  AWS_COMMAND_LABELS[AWS_COMMAND.TERMINATE] = 'Delete';

  return AWS_COMMAND_LABELS[command] || command;
}

export function awsStateLabel(state) {
  const AWS_STATE_LABELS = {};
  AWS_STATE_LABELS[AWS_STATE.PENDING] = 'starting';
  AWS_STATE_LABELS[AWS_STATE.RUNNING] = 'running';
  AWS_STATE_LABELS[AWS_STATE.STOPPED] = 'stopped';
  AWS_STATE_LABELS[AWS_STATE.STOPPING] = 'stopping';
  AWS_STATE_LABELS[AWS_STATE.TERMINATED] = 'deleted';
  AWS_STATE_LABELS[AWS_STATE.SHUTTING_DOWN] = 'deleting';
  AWS_STATE_LABELS[AWS_STATE.UNKNOWN] = 'unknown';
  AWS_STATE_LABELS[AWS_STATE.INITIALIZING] = 'initializing';
  AWS_STATE_LABELS[AWS_STATE.ERROR] = 'error';

  return AWS_STATE_LABELS[state] || state;
}

export function awsStateCommands(state) {

  const AWS_STATE_COMMANDS = {};

  AWS_STATE_COMMANDS[AWS_STATE.RUNNING] = [
      AWS_COMMAND.STOP,
      AWS_COMMAND.TERMINATE,
  ];
  AWS_STATE_COMMANDS[AWS_STATE.STOPPED] = [
      AWS_COMMAND.START,
      AWS_COMMAND.TERMINATE,
  ];
  AWS_STATE_COMMANDS[AWS_STATE.ERROR] = [
    AWS_COMMAND.TERMINATE,
  ];

  return AWS_STATE_COMMANDS[state] || [];
}
