import React from 'react';
import {FilterChip} from "./FilterChip";

export default function FilterChipGroupMainVersion({mainVersionSubstring, setMainVersionSubstring, disabled}) {

  if (mainVersionSubstring && mainVersionSubstring.trim()) {
    return (
      <FilterChip
        tooltip={"Version prefix"}
        disabled={disabled}
        label={mainVersionSubstring}
        onDelete={() => setMainVersionSubstring("")}
      />);
  } else {
    return null;
  }
}
