import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Chart, CommonSeriesSettings, Format, Label, LoadingIndicator, Series} from 'devextreme-react/chart';
import {useNotifications} from '../../../contexts/NotificationsContext';
import {useAuth} from '../../../react-auth-wrapper';
import SimpleTableWithPaging from '../../../components/SimpleTableWithPaging';
import LastAndThisMonthCosts from '../../../components/LastAndThisMonthCosts';
import Paper from '@mui/material/Paper';
import LoadingIcon from '../../../components/LoadingIcon';
import useClouds from '../../../hooks/useClouds';
import {useParams} from "react-router-dom";

export default function Billing({visible = false}) {

  let {cloudKey} = useParams();

  const [cloudCostsProMonth, setCloudCostsProMonth] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [lastMonthCost, setLastMonthCost] = useState(null);
  const [thisMonthCost, setThisMonthCost] = useState(null);
  const [currentChartDetail, setCurrentChartDetail] = useState(null);
  const [costDetailTitle, setCostDetailTitle] = useState(null);
  const chartRef = React.createRef();
  const {notificator, notify, clearNotificator} = useNotifications();
  const {callApi} = useAuth();
  const cancel = {};
  const {validCloudKey} = useClouds(cloudKey, "commonNotificator", cancel)

  const headRow = [
    {
      id: 'email', numeric: false, disablePadding: false, label: 'Email Address',
    },
    {
      id: 'cost',
      numeric: true,
      disablePadding: false,
      label: 'Cost (in USD)',
    }
  ];

  function loadCloudCosts() {
    if (validCloudKey === null) {
      return;
    }
    console.log('[Billing]', 'Fetching cloud data...');
    clearNotificator("refreshableBillingNotificator");
    callApi(`/swc/api/clouds/${validCloudKey}/cost`, {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[Billing]', 'cloud cost', response);
        calculateChartData(response.userCostsPerMonth)
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setChartData(null);
          setCurrentChartDetail(null);
          setLastMonthCost(null);
          setThisMonthCost(null);
          notify('refreshableBillingNotificator',
            {
              text: "Error while loading cloud cost:",
              error: error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      })
      .finally(() => {
      });
  }

  const handlePointClick = (event) => {
    event.target.select();
    setCurrentChartData(event.target.argument)
  };

  const handleChartDrawn = (event) => {
    const firstSeries = event.component.getAllSeries()[0];
    const points = firstSeries.getPoints();
    if (points !== undefined && points.length > 0) {
      points[points.length - 1].select();
    }
  };

  const getMonthTitle = (month, year) => {
    return `${month} ${year}`
  };

  const setCurrentChartData = (selectedBarKey) => {
    calculateCurrentChartDetails(selectedBarKey, cloudCostsProMonth)
  };

  const calculateCurrentChartDetails = (selectedBarKey, costsProMonthMap) => {
    setCostDetailTitle(selectedBarKey);
    const monthDetails = costsProMonthMap.get(selectedBarKey);
    let detailRows = null;
    if (monthDetails !== undefined && monthDetails !== null) {
      detailRows = monthDetails.map(detail => [detail.emailAddress === '' ? 'Miscellaneous cost like support, networking, image store, ...' : detail.emailAddress, detail.cost !== undefined && detail.cost != null ? detail.cost.toFixed(
        2) : 0.0]);
    }
    setCurrentChartDetail(detailRows)
  };

  const calculateChartData = (costs) => {
    const data = costs.map((monthCosts) => {
      return {
        monthAndYear: getMonthTitle(monthCosts.monthName, monthCosts.year),
        total: monthCosts.total
      }
    });
    setChartData(data);

    const lastMonthCosts = costs.length > 1 ? costs[costs.length - 2] : null;
    setLastMonthCost(lastMonthCosts !== null ? lastMonthCosts.total : null);

    const thisMonthCosts = costs.length > 0 ? costs[costs.length - 1] : null;
    setThisMonthCost(thisMonthCosts !== null ? thisMonthCosts.total : null);

    const costsProMonthMap = new Map();
    costs.forEach(monthCosts => costsProMonthMap.set(getMonthTitle(monthCosts.monthName,
      monthCosts.year), monthCosts.userCostInfo));
    setCloudCostsProMonth(costsProMonthMap);

    if (thisMonthCosts !== undefined && thisMonthCosts !== null) {
      const thisMonthCostBarKey = getMonthTitle(thisMonthCosts.monthName, thisMonthCosts.year);
      calculateCurrentChartDetails(thisMonthCostBarKey, costsProMonthMap)
    }
  };

  useEffect(() => {
    loadCloudCosts();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, [validCloudKey]);

  useEffect(() => {
    if (visible && chartRef !== null && chartRef.current !== null) {
      chartRef.current.instance.render();
      console.log("[Billing] Rerender chart on component visible = true")
    }
  }, [visible]);

  return (<Paper sx={{
    padding: '10px',
  }} square>
    {notificator("refreshableBillingNotificator")}
    <LastAndThisMonthCosts lastMonthCost={lastMonthCost}
                           thisMonthCost={thisMonthCost}
                           centDigitLength={0}/>
    {chartData && <Chart ref={chartRef}
                         id="chart"
                         dataSource={chartData}
                         onPointClick={handlePointClick}
                         onDrawn={handleChartDrawn}>
      <CommonSeriesSettings
        argumentField="monthAndYear"
        type="bar"
        hoverMode="allArgumentPoints"
        selectionMode="allArgumentPoints"
      >
        <Label visible={true}>
          <Format type="fixedPoint" precision={0}/>
        </Label>
      </CommonSeriesSettings>
      <LoadingIndicator enabled={true}/>
      <Series
        valueField="total"
        argumentField="monthAndYear"
        name="Cost, USD"
        type="bar"
        color="#ffaa66"/>
    </Chart>}
    {currentChartDetail && <SimpleTableWithPaging
      title={costDetailTitle}
      headRow={headRow}
      rows={currentChartDetail}
      showHeadRow={true}
      showTitle={true}
      showPagination={true}
    />}
    {!chartData && <LoadingIcon/>}
  </Paper>);
}

Billing.propTypes = {
  match: PropTypes.object,
  isAdminView: PropTypes.bool,
  visible: PropTypes.bool,
};

Billing.defaultProps = {
  isAdminView: true,
  visible: false,
};
