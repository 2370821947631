import React from 'react';
import Typography from "@mui/material/Typography";
import FormattedDateTime from "../../../../components/FormattedDateTime";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  top: -2px;
  right: 10px;
  position: absolute;
`;
const StyledTypography = styled(Typography)`
  font-style: italic;
`;
export default function TaskDateInfo({lastModified, status}) {
  return (<StyledContainer>
    <StyledTypography display="block" gutterBottom><FormattedDateTime
      dateTimeString={lastModified}/> {status}</StyledTypography>
  </StyledContainer>);
}

TaskDateInfo.propTypes = {
  lastModified: PropTypes.string.isRequired,
}
