import LoadingIcon from "../LoadingIcon";
import {DeleteForever, Error, PlayCircleFilled, Stop} from "@mui/icons-material";
import React from "react";
import {AWS_COMMAND, AWS_STATE} from "./vmConstants";

const iconCss = {
  margin: '0px 2px -7px 2px',
  width: '24px',
  height: '24px',
};
export default function VmStateIcon({state: stateOrCommand}) {
  const {SHUTTING_DOWN, STOPPED, RUNNING, TERMINATED, PENDING, STOPPING, INITIALIZING, ERROR} = AWS_STATE;
  const {START, STOP, TERMINATE} = AWS_COMMAND;
  const pendingIcon = <LoadingIcon size={16} sx={iconCss} inline/>;
  const playIcon = <PlayCircleFilled sx={iconCss}/>;
  const stopIcon = <Stop sx={iconCss}/>;
  const deleteIcon = <DeleteForever sx={iconCss}/>;

  const stateIcons = {};
  stateIcons[RUNNING] = playIcon;
  stateIcons[STOPPED] = stopIcon;
  stateIcons[TERMINATED] = <DeleteForever sx={iconCss}/>;
  stateIcons[PENDING] = pendingIcon;
  stateIcons[SHUTTING_DOWN] = pendingIcon;
  stateIcons[STOPPING] = pendingIcon;
  stateIcons[INITIALIZING] = pendingIcon;
  stateIcons[ERROR] = <Error sx={iconCss}/>;
  stateIcons[START] = playIcon;
  stateIcons[STOP] = stopIcon;
  stateIcons[TERMINATE] = deleteIcon;

  return <span>{stateIcons[stateOrCommand] || null}</span>;
}
