import React, {useContext, useState} from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";

export const ThemeContext = React.createContext({});
export const useThemeContext = () => useContext(ThemeContext);

const ColorModeContext = React.createContext({
  toggleColorMode: () => {
  }
});
export const brandColor = "rgb(160, 1, 69)";

export const getDesignTokens = (mode) => {
  const result = {
    palette: {
      mode,
      ...(mode === 'light'
        ? {
          // palette values for light mode
          primary: {main: brandColor},
        }
        : {
          // palette values for dark mode
          primary: {main: brandColor},
        }),
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: mode === 'light' ? 'white' : '#303030',
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          outlinedPrimary: {
            color: mode === 'light' ? brandColor : 'white',
            borderColor: mode === 'light' ? brandColor : 'white',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          textColorPrimary: {
            MuiSelected: {
              color: mode === 'light' ? 'black' : 'white',
            },
          },
        },
      },
    },
  }
  return result;
}

export const SWCThemeProvider = ({children}) => {
  const [themeMode, setThemeMode] = useState(getThemeMode());
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setThemeMode((prevMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );
  const theme = React.useMemo(() => createTheme(getDesignTokens(themeMode)), [themeMode]);

  function handleThemeChange() {
    colorMode.toggleColorMode();
  }

  return <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={{handleThemeChange, theme}}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  </ColorModeContext.Provider>;
}

export function getThemeMode() {
  const themeMode = localStorage.getItem("themeMode");
  if (themeMode === 'auto') {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    // If the user's OS setting is dark and matches our .dark-mode class...
    if (prefersDarkScheme.matches) {
      console.log("[App] window.matchMedia found dark theme in user system preferences.");
      return 'dark';
    } else {
      return 'light';
    }
  } else if (themeMode === 'light') {
    return 'light';
  } else if (themeMode === 'dark') {
    return 'dark';
  }
  return 'light';
}
