import React, { useEffect } from 'react';
import { usePageMeta } from '../../../contexts/PageMetaContext';
import { ADMIN_SETTINGS } from '../../../components/PathConstants';
import VmStartScriptsCloudTabs from './VmStartScriptsCloudTabs';

export default function VmStartupScripts() {
  const { setTitle } = usePageMeta();
  const cancel = {};

  useEffect(() => {
    setTitle('Administration - VM Startup Script');
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  return null;
  // CLOUD-1927  return (
  //   <VmStartScriptsCloudTabs firstLevelActiveTabPath={ADMIN_SETTINGS.vmStartupScript.path} />
  // );
}
