import React, {useEffect} from 'react';
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText,} from '@mui/material';
import PropTypes from 'prop-types';
import OPERATIONS_META from './operationsMeta';

export default function OperationSelection({selection, selectCallBack}) {
  const toggleCheckbox = (checkBoxOperation) => {
    if (selection.includes(checkBoxOperation)) {
      selectCallBack(selection.filter(item => item !== checkBoxOperation));
    } else {
      selectCallBack([...selection, checkBoxOperation]);
    }
  };

  const createCheckbox = metaItem => (
    <FormControlLabel
      control={(
        <Checkbox
          checked={selection.includes(metaItem.operation)}
          onChange={() => toggleCheckbox(metaItem.operation)}
          ame={metaItem.operation}
        />
      )}
      label={metaItem.label}
    />
  );

  useEffect(() => {
  }, []);

  const checkboxes = OPERATIONS_META.map(metaItem => createCheckbox(metaItem));

  return (
    <FormControl
      component="fieldset"
      sx={{
        marginTop: (theme) => theme.spacing(0),
        margin: (theme) => theme.spacing(3),
      }}
    >
      <FormGroup>
        {checkboxes}
      </FormGroup>
      <FormHelperText>
        Please select at least one operation
      </FormHelperText>
    </FormControl>
  );
}

OperationSelection.propTypes = {
  selection: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectCallBack: PropTypes.func.isRequired,
};
