import {Icon} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  min-width: ${({theme}) => theme.spacing(12)};
`;
const StyledIcon = styled(Icon)`
  margin-right: ${({theme}) => theme.spacing(1)};
`;
export default function TaskState({task}) {
  const {label, description, icon} = task.stateMeta;
  return (
    <StyledContainer>
      <StyledIcon>{icon}</StyledIcon>
      <Tooltip
        title={description}
      >
        <span>{label}</span>
      </Tooltip>
    </StyledContainer>
  );
}
