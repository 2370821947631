import React, { useEffect, useState } from 'react';
import { SWC_PROP_TYPES } from "../../../../SwcPropTypes";
import * as PropTypes from "prop-types";
import FileUploadInfo from "./FileUploadInfo";
import FileMetas from "./FileMetas";
import TaskDetails from "./TaskDetails";

export default function UploadTaskRegistration({taskInfo, productMeta, selectedFiles, clickCallBack}) {

  const [completedCount, setCompletedCount] = useState(0);
  const [withErrorCompletedCount, setWithErrorCompletedCount] = useState(0);
  const [completed, setCompleted] = useState(selectedFiles?.length > 0 ? false : taskInfo?.files?.length > 0);

  const uploadCompleted = (withError) => {
    if (withError) {
      setWithErrorCompletedCount(oldCount => oldCount + 1);
    } else {
      setCompletedCount(oldCount => oldCount + 1);
    }
  }

  useEffect(() => {
    const condition = (completedCount + withErrorCompletedCount) === selectedFiles?.length && (completedCount > 0 || taskInfo?.files?.length > 0);
    if (condition) {
      console.log('[UploadTaskRegistration] All files uploaded.');
      setCompleted(true);
    }
  }, [completedCount, withErrorCompletedCount]);

  useEffect(() => {
    clickCallBack(completed);
  }, [completed]);

  return (<div>
    <TaskDetails
      key={`task_${taskInfo.id}`} task={taskInfo} productMeta={productMeta} moreDetails={true} index={0}/>
    {(selectedFiles || []).map(fileMeta => <FileUploadInfo key={fileMeta?.name} taskId={taskInfo?.id} fileMeta={fileMeta}
                                                           handleUploaded={uploadCompleted}/>)}
    {taskInfo?.files?.length > 0 &&
      <span><b>Uploaded file(s):</b> <FileMetas fileMetas={taskInfo?.files} removable={false}/></span>}
  </div>);
}

UploadTaskRegistration.propTypes = {
  selectedFiles: SWC_PROP_TYPES.uploadProductFiles,
  clickCallBack: PropTypes.func.isRequired,
};
