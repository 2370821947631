export const COPY_FILES = 'COPY_FILES';
export const GENERATE_ARCHIVE = 'GENERATE_ARCHIVE';
export const REMOVE_SOURCE_FILES = 'REMOVE_SOURCE_FILES';

const OPERATIONS_META = [
  { operation: COPY_FILES, label: 'Copy selected builds' },
  { operation: GENERATE_ARCHIVE, label: 'Generate archive files for selected builds' },
  { operation: REMOVE_SOURCE_FILES, label: 'Delete selected builds' },
];

export default OPERATIONS_META;
