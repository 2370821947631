import Menu from '@mui/material/Menu';
import React from 'react';

const StyledMenu = (props) => (<Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }}
    sx={{
        "& .MuiPaper-root": {
            border: '1px solid #d3d4d5',
            fontSize: '0.8125rem',
            backgroundColor: (theme) => theme.palette.grey[100],
            color: (theme) => theme.palette.grey[800],
        }
    }}
    {...props}
/>);
export default StyledMenu;
