import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import SWCLink from '../SWCLink';
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;
const StyledTooltip = styled(Tooltip)`
  width: 90%;
`;
export default function VmInstanceName({instance, showRenameDialog, showRename, path}) {

  function handleShowRenameDialog() {
    showRenameDialog(instance);
  }

  return (
    <StyledContainer>
      <StyledTooltip
        title={instance.name}
        data-instance-id={instance.instanceId}>
        <span><SWCLink path={path}>{instance.name}</SWCLink></span>
      </StyledTooltip>
      {showRename &&
        <Tooltip title="Rename">
          <IconButton edge="end" onClick={handleShowRenameDialog} size="large">
            <EditIcon fontSize="small"/>
          </IconButton>
        </Tooltip>
      }
    </StyledContainer>
  );
}

VmInstanceName.propTypes = {
  instance: PropTypes.shape({
    instanceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
