import React, {useEffect, useState} from 'react';
import {Autocomplete, FormControlLabel} from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import {BuildAudience} from "../../SwcPropTypes";
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import {styled as muiStyled} from "@mui/material/styles";
import styled from "@emotion/styled";

const getSelected = (initialAudiences, allAudiences) => {
  const initialValues = initialAudiences.map(audience => audience.buildAudience);
  return allAudiences.filter(audience => initialValues.includes(audience.buildAudience));
}
const StyledContainer = styled.div`
`;
const MuiStyledAudiences = muiStyled((props) => <div {...props} />)(
  () => ({
    "& .MuiIconButton-root": {
      padding: 0
    },
  }),
);
const StyledAudiences = styled(MuiStyledAudiences)`
  padding-top: 10px;
  width: 100%;
  overflow: auto;
`;
const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;
const StyledAutocomplete = styled(Autocomplete)`
`;
const StyledButtonContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;

export default function Restrictions({initialAudiences, allAudiences, hide, updateBuildAudiences}) {
  const [selectedItems, setSelectedItems] = useState(getSelected(initialAudiences, allAudiences));
  const [allSelected, setAllSelected] = useState(selectedItems.length === allAudiences.length);

  const sortedSelectedItems = [...selectedItems].sort((a, b) => a.label.localeCompare(b.label));

  const handleAllSelected = (event) => {
    const isAllSelected = event.target.checked;
    if (isAllSelected) {
      setSelectedItems(allAudiences);
    } else {
      setSelectedItems([]);
    }
  }

  const handleSelectionChanged = (_, value) => {
    setSelectedItems(value);
  }

  const handleApplyAudiences = () => {
    //...save
    updateBuildAudiences(selectedItems)
    hide();
  }

  useEffect(() => {
    setAllSelected(selectedItems.length === allAudiences.length);
  }, [selectedItems]);

  return (<StyledContainer>
    {allAudiences && <StyledAudiences>
      <StyledAutocomplete
          multiple
          id="checkboxes-audiences"
          options={allAudiences}
          onChange={handleSelectionChanged}
          getOptionLabel={(option) => option.label}
          filterSelectedOptions
          value={sortedSelectedItems}
          disableCloseOnSelect
          renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Build Audiences" placeholder="Audience"/>
          )}
      />
      <FormControlLabel
          control={
            <StyledCheckbox
                onChange={handleAllSelected}
                color={allSelected ? "primary" : "default"}
                checked={allSelected}/>}
          label="Select all"/>
    </StyledAudiences>
    }
    <StyledButtonContainer>
      <Button
        onClick={handleApplyAudiences}
        variant="outlined"
        color="primary"
        type="submit"
        sx={{
          marginRight: (theme) => theme.spacing(1),
        }}
      >
        Apply
      </Button>
      <Button variant="outlined" type="button" onClick={hide}>Cancel</Button>
    </StyledButtonContainer>
  </StyledContainer>);
}

Restrictions.propTypes = {
  hide: PropTypes.func.isRequired,
  initialAudiences: PropTypes.arrayOf(BuildAudience),
  allAudiences: PropTypes.arrayOf(BuildAudience),
}
