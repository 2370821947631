import React from 'react';
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import {SearchFormInputStyles} from "./SearchForm";

const StyledTextField = styled(TextField)`
  ${({theme}) => SearchFormInputStyles(theme)}
`;
export default function MainVersionInput({mainVersionSubstring, setMainVersionSubstring, disabled}) {
    return (
        <React.Fragment>
            <StyledTextField
                disabled={disabled}
                id="main-version-substring"
                name="mainVersionSubstring"
                label="Version Prefix"
                placeholder="Version Prefix"
                value={mainVersionSubstring}
                onChange={(event) => setMainVersionSubstring(event.target.value || '')}
            />
        </React.Fragment>
    );
}
