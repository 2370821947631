import React, {useEffect, useState} from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import {usePageMeta} from '../../contexts/PageMetaContext';
import {useAuth} from '../../react-auth-wrapper';
import {useNotifications} from '../../contexts/NotificationsContext';
import breadcrumbCalculator from './menuBreadcrumbsCalculator'
import BreadcrumbWithSubMenu from './BreadcrumbWithSubMenu';
import StyledBreadcrumb from './StyledBreadcrumb';
import {useLocation} from "react-router-dom";

export default function BreadcrumbsMenu() {

  const [menu, setMenu] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const currentLocation = useLocation();
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const {goTo} = usePageMeta();
  const cancel = {};

  function handleHomeClick(event) {
    event.preventDefault();
    goTo("/welcome")
  }

  function updateBreadcrumbs() {
    const newBreadcrumbs = breadcrumbCalculator(currentLocation.pathname, menu);
    setBreadcrumbs(newBreadcrumbs);
  }

  function loadMenu() {
    console.log('[BreadcrumbsMenu]', 'Fetching menu...');

    callApi('/swc/api/menu', {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[MenuAppBar]', 'menu', response);
        setMenu(response.menu);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setMenu([]);
          notify('commonNotificator',
            {
              text: "Error while loading menu:",
              error: error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      })
      .finally(() => {
      });
  }

  const handleClick = breadcrumb => (event) => {
    event.preventDefault();
    goTo(breadcrumb.path)
  }

  const hasClick = (breadcrumb) => {
    return breadcrumb.current !== true && breadcrumb.path
  }

  useEffect(() => {
    loadMenu();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  useEffect(() => {
    if (menu) {
      updateBreadcrumbs();
    }
  }, [currentLocation.pathname, menu]);

  return (<Breadcrumbs sx={{
    paddingBottom: (theme) => theme.spacing(2),
  }} aria-label="breadcrumb">
    <StyledBreadcrumb
      component="button"
      label="Home"
      icon={<HomeIcon fontSize="small"/>}
      onClick={handleHomeClick}
    />
    {breadcrumbs.map((breadcrumb, index) => breadcrumb.items && breadcrumb.items.length > 0 ?
      <BreadcrumbWithSubMenu
        key={index}
        index={index}
        breadcrumb={breadcrumb}
        currentPath={currentLocation.pathname}
      /> :
      <StyledBreadcrumb
        key={index} component="button"
        label={breadcrumb.label}
        onClick={hasClick(breadcrumb) ? handleClick(breadcrumb) : undefined}/>)}
  </Breadcrumbs>)
}
