import React from 'react';
import {Autocomplete, TextField} from '@mui/material';
import styled from "@emotion/styled";
import {ComplianceLogInputFieldStyle} from "../ComplianceLogSearchForm";

const StyledAutocomplete = styled(Autocomplete)`
  ${props => ComplianceLogInputFieldStyle(props.theme)}
`;
export default function ProductsOrBucketSelection({
                                                      disabled,
                                                      allItems,
                                                      selectedItemKeys,
                                                      onChange = (newSelectedItemKeys) => {
                                                      }
                                                  }) {

    const selectedItems = (allItems || []).filter((item) => (selectedItemKeys || []).indexOf(item.key) > -1);
    const calculateLabel = (item) => {
        let answer = item.label;
        if (item.type === 'CloudBucket') {
            answer = `${answer} (S3)`;
        }
        return answer;
    };

    const handleChange = (event, value) => {
        const newSelectedItemKeys = (value || []).map((item) => item?.key);
        onChange(newSelectedItemKeys);
    };

    return (
        <StyledAutocomplete
            disabled={disabled}
            multiple
            options={allItems || []}
            getOptionLabel={calculateLabel}
            defaultValue={[]}
            filterSelectedOptions
            onChange={handleChange}
            value={selectedItems}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Selected products or buckets"
                    placeholder="Selected products or buckets"
                />
            )}
        />
    );
}
