import React, {useState} from 'react';
import ModalDialog from './ModalDialog';
import Button from '@mui/material/Button';
import ErrorMessages from './ErrorMessages';
import Typography from '@mui/material/Typography';
import styled from "@emotion/styled";

const StyledButtonContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;

const StyledErrorDetails = styled.div`
  margin-top: ${({theme}) => theme.spacing(1)};
  overflow-wrap: break-word;
`;

const buttonCss = {
  marginRight: (theme) => theme.spacing(1),
};

export default function ErrorDialog({open, errorData, toggle, resetError}) {
  const [errorDetails, setErrorDetails] = useState(null)

  const handleClose = () => {
    resetError(null);
    setErrorDetails(null);
    toggle(false);
  }

  const handleGiveFeedback = () => {
    document.getElementById('software-center-jira-feedback-issue-collector-trigger').click();
    handleClose();
  }

  const handleSubmit = () => {
    const buildNo = document.getElementById('build-no');
    const response = errorData && errorData.error && errorData.error.response ? errorData.error.response : null;

    const summary = errorData.summary;
    const url = response && response.url ? response.url : getUrlFromInputData()
    const options = errorData && errorData.error && errorData.error.inputData ? JSON.stringify(
      errorData.error.inputData.options) : null
    const status = response ? response.status : null
    const message = response ? response.message : null
    const dateTime = errorData && errorData.error ? errorData.error.time : Date()

    setErrorDetails({
      summary: summary,
      url: url,
      buildNo: buildNo ? buildNo.textContent : null,
      options: options,
      status: status,
      message: message,
      dateTime: dateTime
    })
  }

  const getUrlFromInputData = () => {
    return errorData && errorData.error && errorData.error.inputData ? errorData.error.inputData.url : null;
  }

  return (
    <ModalDialog
      open={open}
      toggle={handleClose}
      title="Error"
      type="error"
    >
      <div>
        {errorData && <ErrorMessages message={errorData.summary} response={errorData.error}/>}
      </div>
      <StyledButtonContainer>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          sx={buttonCss}
          onClick={handleSubmit}
          disabled={errorDetails === null}
        >
          Report the problem
        </Button>
        <Button variant="outlined" type="button" onClick={handleClose}>Close</Button>
        {errorDetails && <StyledErrorDetails>
          {errorDetails.summary && <div>Summary: {errorDetails.summary}</div>}
          {errorDetails.url && <div>URL: {errorDetails.url}</div>}
          {errorDetails.buildNo && <div>Build No: {errorDetails.buildNo}</div>}
          {errorDetails.options && <div>Options: {errorDetails.options}</div>}
          {errorDetails.status && <div>Status: {errorDetails.status}</div>}
          {errorDetails.message && <div>Message: {errorDetails.message}</div>}
          {errorDetails.dateTime && <div>Time: {errorDetails.dateTime}</div>}
          <Typography variant="h6">
            Please attach screenshot of this error dialog to the issue!
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            sx={buttonCss}
            onClick={handleGiveFeedback}
          >
            Give Feedback
          </Button>
        </StyledErrorDetails>}
      </StyledButtonContainer>
    </ModalDialog>
  );
}
