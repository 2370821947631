const VIRTUAL_ROOT_MENU_ITEM_KEY = 'virtual-root';

const shouldMenuItemOpen = (currentPath, menuItemInfo) => currentPath === menuItemInfo.clickActionPath || currentPath.includes(`${menuItemInfo.clickActionPath}/`);

export default function (currentPath, menu) {
  const paths = (currentPath || "").split("/").filter(path => path.trim() !== '');
  console.log(`[breadcrumbCalculator] PATHs ${paths}`);

  const virtualRoot = {
    key: VIRTUAL_ROOT_MENU_ITEM_KEY,
    label: '',
    itemDescription: '',
    itemIcon: '',
    clickActionPath: '/',
    visualization: 'DEFAULT',
    items: menu,
  };
  const [mappedVirtualRoot, ...breadcrumbs] = calculateBreadcrumbs(currentPath, virtualRoot);

  return breadcrumbs;
}

function calculateBreadcrumbs(currentPath, menuItem) {

  const associatedMenuItemList = mapCurrentPathToMenuItemList(currentPath || '', menuItem);

  // if the last 2 elements have the same label, collapse them (e.g smartest as group and smartest as product)
  const [lastMenuItem1, lastMenuItem0] = [...associatedMenuItemList].reverse();
  if (lastMenuItem1 && lastMenuItem0 && lastMenuItem0.items?.length === 1 && lastMenuItem0.items[0].clickActionPath === lastMenuItem1.clickActionPath && lastMenuItem1.leaf) {
    associatedMenuItemList.splice(associatedMenuItemList.length-1, 1);
  }

  // if the last element has more than one child, add an empty menu item for navigation purpose
  const [lastMenuItem] = [...associatedMenuItemList].reverse();
  if (lastMenuItem && lastMenuItem.key !== VIRTUAL_ROOT_MENU_ITEM_KEY && lastMenuItem.items && lastMenuItem.items.length > 1) {
    associatedMenuItemList.push({
      key: 'empty',
      label: '',
      itemDescription: '',
      itemIcon: '',
      clickActionPath: lastMenuItem.clickActionPath,
      visualization: 'DEFAULT',
      items: lastMenuItem.items,
    });
  }

  const breadcrumbs = [];

  for (let index = 0; index < associatedMenuItemList.length; index += 1) {
    const item = associatedMenuItemList[index];
    const siblingItems = index === 0 ? [] : associatedMenuItemList[index - 1].items.filter(siblingOrSelf => siblingOrSelf.clickActionPath !== item.clickActionPath);

    breadcrumbs.push({
      label: item.label,
      items: siblingItems && mapToBreadcrumbItemList(siblingItems),
      path: item.clickActionPath,
      current: item,
    });
  }

  const productDetailVersion = extractVersionFromProductDetailPath(currentPath);
  if (productDetailVersion) {
    breadcrumbs.push({
      label: productDetailVersion,
    });
  }

  return removeNonRelevantBreadCrumbs(breadcrumbs);
}

const productDetailPagePathRegex = /(\/download\/[^\/]+\/builds\/[^\/]+\/[^\/]+)\/details\/mainversion\/[^\/]+\/os\/[^\/]+\/version\/([^\/]+)\//;

function currentPathPointsToProductDetails(currentPath) {
  return currentPath && productDetailPagePathRegex.test(currentPath)
}

function extractVersionFromProductDetailPath(currentPath) {
  let version = null
  if (currentPathPointsToProductDetails(currentPath)) {
    const rawVersion = currentPath.match(productDetailPagePathRegex)[2]
    if ( /^[a-zA-Z0-9:_\.\-]+$/.test(rawVersion)) {
      version = rawVersion
    }
  }

  return version;
}

function mapCurrentPathToMenuItemList(currentPath, menuItem) {

  let currentPathSanitized = currentPath
  if (currentPathPointsToProductDetails(currentPath)) {
    currentPathSanitized = currentPath.match(productDetailPagePathRegex)[1]
  }

  let pathOfMenuItems = [];
  if (!menuItem) {
    pathOfMenuItems = [];
  } else if (menuItem?.clickActionPath === currentPathSanitized) {
    pathOfMenuItems = [menuItem];
  } else if (!menuItem.leaf) {
    const [childrenPath] = (menuItem.items || [])
      .map(item => mapCurrentPathToMenuItemList(currentPathSanitized, item))
      .sort((path0, path1) => path1.length - path0.length);
    if (childrenPath && childrenPath.length > 0) {
      pathOfMenuItems = [menuItem, ...childrenPath];
    } else if (shouldMenuItemOpen(currentPath, menuItem)) {
      // handles foe example paths like '/admin/cloud-overview/cloud-vms/4160275686788'
      pathOfMenuItems = [menuItem];
    }
  }
  return pathOfMenuItems;
}


function mapToBreadcrumbItemList(menuItems) {
  return menuItems && menuItems.map(mapToBreadcrumbItem);
}


function mapToBreadcrumbItem(menuItem) {
  return {
    label: menuItem.label,
    path: menuItem.clickActionPath,
  };
}


function removeNonRelevantBreadCrumbs(breadCrumbList) {
  // just to be consistent with the previous breadcrumb implementation
  let cleanedUpBreadCrumbs = breadCrumbList.filter(breadCrumb => !['admin-section', 'downloads', 'clouds'].includes(breadCrumb.current?.key));

  // limit admin section to 3 elements. Using emdeed tabs for further navigation
  if (cleanedUpBreadCrumbs && cleanedUpBreadCrumbs.length > 3 && cleanedUpBreadCrumbs.some(bc => bc.path === '/admin')) {
    cleanedUpBreadCrumbs = cleanedUpBreadCrumbs.slice(0, 3);
  }
  return cleanedUpBreadCrumbs;
}

