export const TASK_TYPE = {
  BUILD_MOVE_TO_CLOUD_BUCKET: 'BUILD_MOVE_TO_CLOUD_BUCKET',
};

export const TASK_STATE = {
  OPEN: 'OPEN',
  RUNNING: 'RUNNING',
  CLOSED: 'CLOSED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
};

export function StateMeta(state, label, description, icon) {
  this.state = state;
  this.label = label;
  this.description = description;
  this.icon = icon;
}

export function StateGroupMeta(name, label, description, icon, states) {
  this.name = name;
  this.label = label;
  this.description = description;
  this.icon = icon;
  this.states = states;
}

export const STATE_GROUPS = [
  new StateGroupMeta(
    'enqueued',
    'Scheduled',
    'Waiting to be processed',
    'pause_circle',
    [new StateMeta(TASK_STATE.OPEN, 'Open', 'Open', 'pause_circle')],
  ),
  new StateGroupMeta(
    'running',
    'Running',
    'Running Tasks',
    'play_circle',
    [new StateMeta(TASK_STATE.RUNNING, 'Running', 'Running', 'play_circle')],
  ),
  new StateGroupMeta(
    'finished',
    'Processed',
    'Processed Tasks',
    'stop_circle',
    [
      new StateMeta(TASK_STATE.CLOSED, 'Closed', 'Closed', 'done'),
      new StateMeta(TASK_STATE.CANCELLED, 'Cancelled', 'Cancelled', 'clear'),
      new StateMeta(TASK_STATE.FAILED, 'Failed', 'Failed', 'error'),
    ],
  ),
];

export function meta(stateAsString) {
  for (let i = 0; i < STATE_GROUPS.length; i++) {
    const g = STATE_GROUPS[i];
    for (let j = 0; j < g.states.length; j++) {
      const s = g.states[j];
      if (stateAsString === s.state) {
        return { ...s, group: g };
      }
    }
  }
  return null;
}

export const postProcessTask = taskType => products => (taskParam) => {
  let task = taskParam;

  if (task != null) {
    const stateMeta = meta(task.state);
    const groupMeta = stateMeta && stateMeta.group;
    task = ({
      ...task,
      stateMeta: { ...stateMeta },
      groupMeta: { ...groupMeta },
    });
  }

  if (task != null && taskType === TASK_TYPE.BUILD_MOVE_TO_CLOUD_BUCKET && products != null) {
    const selectionList = task.parameter.selectionList.map((selection) => {
      const [product] = products.filter(p => p.productKey === selection.productKey);
      return ({
        ...selection,
        product,
      });
    });

    task = ({
      ...task,
      parameter: {
        ...task.parameter,
        selectionList,
      },
    });
  }
  return task;
};
