import React, {useEffect, useState} from 'react';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import HelpIcon from '@mui/icons-material/Help';
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

export default function LinkToCloud({cloudKey}) {
  const [linkToCloud, setLinkToCloud] = useState(null);
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const cancel = {};

  const getLinkToCloud = () => {
    callApi(`/swc/api/properties/propertyType/CLOUD/keyType/LINK`,
      {method: 'GET'},
      cancel)
      .then((response) => {
        console.log('[LinkToCloud] translations', response);
        const cloudLinks = response.values.filter(value => value.key === cloudKey);
        if (cloudLinks !== undefined && cloudLinks.length > 0) {
          const linkProps = cloudLinks[0];
          const linkToCloud = <Tooltip title="Go to help page">
            <Button
              aria-label="Help"
              startIcon={<HelpIcon/>}
              sx={{
                color: (theme) => theme.palette.action.active
              }}
              href={linkProps.value} target="_blank">
              Help
            </Button>
          </Tooltip>;
          setLinkToCloud(linkToCloud);
        }
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setLinkToCloud(null);
          notify('instancesNotificator', {
            text: "Error while loading translations:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
  };

  useEffect(() => {
    getLinkToCloud();
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, [cloudKey]);

  return linkToCloud ? linkToCloud : null;
}
