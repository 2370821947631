import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import useInterval from '../hooks/useInterval';
import useModal from '../hooks/useModal';
import VmInstanceStateWithActionMenu from '../components/vmInstances/VmInstanceStateWithActionMenu';
import VmInstanceName from '../components/vmInstances/VmInstanceName';
import VmRegion from '../components/vmInstances/VmRegion';
import VmImage from '../components/vmInstances/VmImage';
import VmMachineType from '../components/vmInstances/VmMachineType';
import ModalDialog from '../components/ModalDialog';
import VmCreateOrRenameDialog from '../components/vmInstances/VmCreateOrRenameDialog';
import {useAuth} from '../react-auth-wrapper';
import {usePageMeta} from '../contexts/PageMetaContext';
import VmInstanceLimitNotification from '../components/vmInstances/VmInstanceLimitNotification';
import VmLaunchTime from '../components/vmInstances/VmLaunchTime';
import {useNotifications} from '../contexts/NotificationsContext';
import ScrollableList from '../components/ScrollableList';
import LoadingIcon from '../components/LoadingIcon';
import UserCloudCost from '../components/UserCloudCost';
import ListWrapper from '../components/ListWrapper';
import VmInstanceSshTunnelFile from "../components/vmInstances/VmInstanceSshTunnelFile";
import {CLOUD_OVERVIEW} from '../components/PathConstants';
import useClouds from '../hooks/useClouds';
import {useNavigate, useParams} from "react-router-dom";
import styled from "@emotion/styled";

const StyledFlexContainer = styled.div`
  display: flex;
`;

const initialInstanceMetaState = {remaining: 0, limit: 0, used: 0};
const initialMetaState = {label: ''};

export default function VmInstances({isAdminView = false}) {

  let {cloudKey} = useParams();

  const [reloadInterval] = useState(45 * 1000);
  const [instances, setInstances] = useState(null);
  const [instancesMeta, setInstancesMeta] = useState(initialInstanceMetaState);
  const [meta, setMeta] = useState(initialMetaState);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [instanceCreationTaskRunning, setInstanceCreationTaskRunning] = useState(false);
  const [showNoSSHKeyWarning, setShowNoSSHKeyWarning] = useState(false);
  const [linuxUsernameValid, setLinuxUsernameValid] = useState(true);

  const [dialogMode, setDialogMode] = useState('create'); // edit or create
  const [editableInstance, setEditableInstance] = useState(null);
  const {isShowing, toggle} = useModal();
  const navigate = useNavigate();
  const {callApi} = useAuth();
  const {setTitle, setCloudKey, goTo} = usePageMeta();
  const {notificator, notify, clearNotificator} = useNotifications();
  const cancel = {};
  const {validCloudKey} = useClouds(cloudKey, "instancesRefreshableNotificator", cancel);

  function compareInstances(instanceA, instanceB) {
    if (instanceA.name === instanceB.name) {
      return instanceA.instanceId > instanceB.instanceId ? 1 : instanceA.instanceId < instanceB.instanceId ? -1 : 0;
    }
    return instanceA.name > instanceB.name ? 1 : -1;
  }

  function updateInstancesState(alteredOrNewInstance) {
    if (alteredOrNewInstance) {
      setInstances((previousList) => {
        const instancesWithoutAlteredInstance = previousList.filter(aPrevInstance => aPrevInstance.instanceId !== alteredOrNewInstance.instanceId);
        return [alteredOrNewInstance, ...instancesWithoutAlteredInstance].sort(compareInstances);
      });
    }
  }

  function createVM(event) {
    console.log('[VmInstances] createVM called');
    event.preventDefault();

    const name = event.target.name.value;
    const region = event.target.region.value;
    const image = event.target.image.value;
    const machineSize = event.target.machineSize.value;

    setInstanceCreationTaskRunning(true);
    callApi(`/swc/api/clouds/${validCloudKey}/regions/${region}/vms`, {
      method: 'POST', body: JSON.stringify({name, imageKey: image, machineSizeKey: machineSize}),
    }, cancel)
      .then((response) => {
        console.log('[VmInstances] createVM', response);
        toggle();
        setInstancesMeta(meta => ({
          ...meta,
          remaining: (meta.remaining - 1),
          used: (meta.used - 1)
        }));
        updateInstancesState(response.instance);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('createInstanceNotificator',
            {
              text: "Error while VM creation:",
              error: error,
              type: 'error',
              handleClose: () => {
              }
            });
        }
      }).finally(() => setInstanceCreationTaskRunning(false));
  }

  function renameVM(event) {
    console.log('[VmInstances] updateVM called');
    event.preventDefault();

    const instanceId = event.target.instanceId.value;
    const region = event.target.region.value;
    const newName = event.target.name.value;

    callApi(`/swc/api/clouds/${validCloudKey}/regions/${region}/vms/${instanceId}`, {
      method: 'PUT', body: JSON.stringify({name: newName}),
    }, cancel)
      .then((response) => {
        console.log('[VmInstances] updateVM', response);
        toggle();
        updateInstancesState(response.instance);
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          notify('createInstanceNotificator',
            {
              text: "Error while VM updating:",
              error: error,
              type: 'error',
              handleClose: () => {
              }
            });
        }
      }).finally();
  }

  const headRow = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Name'},
    {id: 'imageId', numeric: false, disablePadding: false, label: 'Image'},
    {id: 'regionKey', numeric: false, disablePadding: false, label: 'Region'},
    {id: 'type', numeric: false, disablePadding: false, label: 'Type'},
    {id: 'launchTime', numeric: false, disablePadding: false, label: 'Launch Time'},
    isAdminView
      ? {id: 'owner', numeric: false, disablePadding: false, label: 'Owner'}
      : {id: 'linuxUser', numeric: false, disablePadding: false, label: 'Linux User'},
    {id: 'publicIpAddress', numeric: false, disablePadding: false, label: 'IP Address'},
    // { id: 'publicDnsName', numeric: false, disablePadding: false, label: 'DNS Name' },
    {id: 'state', numeric: false, disablePadding: false, label: 'State'},
    //  pending, running, shutting-down, terminated, stopping, stopped, (unknown)
    !isAdminView ? {
      id: 'vncButton',
      numeric: false,
      disablePadding: false,
      label: 'View'
    } : {id: 'vncButton', numeric: false, disablePadding: false, label: ''},
  ];

  const createInstancesContainer = (instance) => {
    const vmDetailPath = getVmDetailPath(instance);
    return [
      <VmInstanceName instance={instance}
                      path={vmDetailPath}
                      showRenameDialog={showRenameDialog}
                      showRename={!isAdminView}/>,
      <VmImage instance={instance} path={vmDetailPath}/>,
      <VmRegion instance={instance}
                meta={meta}
                path={vmDetailPath}/>,
      <VmMachineType instance={instance}
                     meta={meta} path={vmDetailPath}/>,
      <VmLaunchTime instance={instance}
                    meta={meta} path={vmDetailPath}/>,
      isAdminView
        ? instance.owner
        : instance.linuxUser,
      instance.publicIpAddress,
      // instance.publicDnsName,
      <VmInstanceStateWithActionMenu isAdminView={isAdminView} instance={instance}
                                     alterStateCallback={updateInstancesState}/>,
      !isAdminView && <VmInstanceSshTunnelFile instance={instance}/>,
    ]
  };

  const reloadVmInstanceList = React.useCallback(() => {
    if (validCloudKey === null) {
      return;
    }
    console.log('[VmInstances]', 'Fetching data...');
    const vmsUrl = isAdminView ? `/swc/api/admin/clouds/${validCloudKey}/vms` : `/swc/api/clouds/${validCloudKey}/vms`;
    clearNotificator('instancesRefreshableNotificator');

    callApi(`/swc/api/clouds/${validCloudKey}/meta`, {method: 'GET'}, cancel)
      .then((response) => {
        console.log('[VmInstances]', 'meta', response);
        clearNotificator('instancesRefreshableNotificator');
        setMeta(response);
        return callApi(vmsUrl,
          {method: 'GET'}, cancel);
      })
      .then((response) => {
        console.log('[VmInstances]', 'instances', response);
        clearNotificator('instancesRefreshableNotificator');
        setInstances(response.instanceList.sort(compareInstances));
        setInstancesMeta(response.meta);
      })
      .catch((error) => {
        console.log('[VmInstances] response error', error);
        if (error.message !== 'Cancelled') {
          if (error.status === 403 && !isAdminView) {
            goTo("/vms")
          } else {
            setInstances([]);
            setMeta(initialMetaState);
            setInstancesMeta(initialInstanceMetaState);
            notify('instancesRefreshableNotificator',
              {
                text: "Error while loading VM instances:",
                error: error,
                type: 'error',
                handleClose: () => {
                },
              });
          }
        }
      })
      .finally(() => {
        setDataLoaded(true);
      });
  }, [validCloudKey]);

  function setPageTitle() {
    if (!isAdminView) {
      const headerTitle = meta && meta.label ? `${meta.label}` : 'Smartest Cloud';
      setTitle(headerTitle);
    }
  }

  function showRenameDialog(instance) {
    setDialogMode('edit');
    setEditableInstance(instance);
    toggle();
  }

  function showCreateDialog() {
    setDialogMode('create');
    toggle();
  }

  function redirectToValidCloudIfNeeded() {
    if (validCloudKey !== cloudKey && validCloudKey !== null) {
      navigate(isAdminView ? `${CLOUD_OVERVIEW.vms.path}/${validCloudKey}` : `/vms/${validCloudKey}`)
    }
  }

  function getVmDetailPath(instance) {
    return isAdminView ? `${CLOUD_OVERVIEW.vms.path}/${instance.cloudKey}/${instance.regionKey}/${instance.instanceId}` : `/vms/${instance.cloudKey}/${instance.regionKey}/${instance.instanceId}`;
  }

  useInterval(reloadVmInstanceList, reloadInterval);
  useEffect(() => {
    clearNotificator('instancesRefreshableNotificator');
    clearNotificator('instancesLimitNotificator');
    setDataLoaded(false);
    redirectToValidCloudIfNeeded();
    reloadVmInstanceList();
    setCloudKey(validCloudKey);
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, [validCloudKey]);
  useEffect(setPageTitle, [cloudKey, meta]);

  const disableCreateButton = showNoSSHKeyWarning || !linuxUsernameValid || instancesMeta.remaining === 0;

  return (
    <ListWrapper>
      {notificator('instancesRefreshableNotificator')}
      {notificator('instancesNotificator')}
      {notificator('instancesLimitNotificator')}
      <VmInstanceLimitNotification limit={instancesMeta.limit} remaining={instancesMeta.remaining}
                                   isAdminView={isAdminView}/>
      {dataLoaded && instances && (
        <div>

          {!isAdminView && <UserCloudCost cloudKey={validCloudKey}/>}
          <ScrollableList headRow={headRow}
                          showHeadRow
                          items={instances}
                          itemContainer={createInstancesContainer}/>
          {!isAdminView && <StyledFlexContainer><Button
            variant="outlined"
            color="primary"
            onClick={showCreateDialog}
            disabled={disableCreateButton}
          >
            Create VM
          </Button>
          </StyledFlexContainer>}
          <ModalDialog
            open={isShowing}
            toggle={toggle}
            title={dialogMode === 'create' ? 'Create VM' : 'Edit VM'}
          >
            <VmCreateOrRenameDialog handleSubmit={dialogMode === 'create' ? createVM : renameVM}
                                    hide={toggle}
                                    meta={meta}
                                    editableInstance={editableInstance}
                                    dialogMode={dialogMode}
                                    instanceCreationTaskRunning={instanceCreationTaskRunning}/>
          </ModalDialog>
        </div>
      )}
      {!dataLoaded && <LoadingIcon sx={{
        marginTop: (theme) => theme.spacing(20),
        margin: 'auto',
      }}/>}
    </ListWrapper>
  );
}
